(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.cb.dg === region.cN.dg)
	{
		return 'on line ' + region.cb.dg;
	}
	return 'on lines ' + region.cb.dg + ' through ' + region.cN.dg;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.e9,
		impl.f8,
		impl.fV,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		ae: func(record.ae),
		cc: record.cc,
		bZ: record.bZ
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.ae;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.cc;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.bZ) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.e9,
		impl.f8,
		impl.fV,
		function(sendToApp, initialModel) {
			var view = impl.f9;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.e9,
		impl.f8,
		impl.fV,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.b4 && impl.b4(sendToApp)
			var view = impl.f9;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.cw);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.d9) && (_VirtualDom_doc.title = title = doc.d9);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.fx;
	var onUrlRequest = impl.fy;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		b4: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.dK === next.dK
							&& curr.c_ === next.c_
							&& curr.dG.a === next.dG.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		e9: function(flags)
		{
			return A3(impl.e9, flags, _Browser_getUrl(), key);
		},
		f9: impl.f9,
		f8: impl.f8,
		fV: impl.fV
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { e2: 'hidden', ew: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { e2: 'mozHidden', ew: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { e2: 'msHidden', ew: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { e2: 'webkitHidden', ew: 'webkitvisibilitychange' }
		: { e2: 'hidden', ew: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		bk: _Browser_getScene(),
		br: {
			ci: _Browser_window.pageXOffset,
			ck: _Browser_window.pageYOffset,
			aH: _Browser_doc.documentElement.clientWidth,
			ax: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		aH: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		ax: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			bk: {
				aH: node.scrollWidth,
				ax: node.scrollHeight
			},
			br: {
				ci: node.scrollLeft,
				ck: node.scrollTop,
				aH: node.clientWidth,
				ax: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			bk: _Browser_getScene(),
			br: {
				ci: x,
				ck: y,
				aH: _Browser_doc.documentElement.clientWidth,
				ax: _Browser_doc.documentElement.clientHeight
			},
			eP: {
				ci: x + rect.left,
				ck: y + rect.top,
				aH: rect.width,
				ax: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.fq) { flags += 'm'; }
	if (options.ev) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.eQ.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.eQ.b, xhr)); });
		$elm$core$Maybe$isJust(request.f7) && _Http_track(router, xhr, request.f7.a);

		try {
			xhr.open(request.dm, request.ec, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.ec));
		}

		_Http_configureRequest(xhr, request);

		request.cw.a && xhr.setRequestHeader('Content-Type', request.cw.a);
		xhr.send(request.cw.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.e0; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.fZ.a || 0;
	xhr.responseType = request.eQ.d;
	xhr.withCredentials = request.em;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		ec: xhr.responseURL,
		fS: xhr.status,
		fT: xhr.statusText,
		e0: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			fO: event.loaded,
			d2: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			fH: event.loaded,
			d2: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.h) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.j),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.j);
		} else {
			var treeLen = builder.h * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.k) : builder.k;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.h);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.j) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.j);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{k: nodeList, h: (len / $elm$core$Array$branchFactor) | 0, j: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {cT: fragment, c_: host, dD: path, dG: port_, dK: protocol, dL: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $author$project$Auth$Anon = {$: 0};
var $author$project$Main$GeneratedUuidsForExamples = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Main$GotPageMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$GotViewport = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$InitWithAuthUser = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$PageMsgApp = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$PageStateApp = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$PageStateLanding = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$WithTime = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Types$AuthUser$AuthUser = F4(
	function (token, refreshToken, userId, email) {
		return {cM: email, fI: refreshToken, f5: token, ee: userId};
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Types$AuthUser$decoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Types$AuthUser$AuthUser,
	A2($elm$json$Json$Decode$field, 'access_token', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'refresh_token', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['user', 'id']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['user', 'email']),
		$elm$json$Json$Decode$string));
var $author$project$UuidDict$UuidDict = $elm$core$Basics$identity;
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $author$project$UuidDict$empty = $elm$core$Dict$empty;
var $author$project$Types$Segment$exampleSegmentDurations = _List_fromArray(
	[
		_List_fromArray(
		[7, 13]),
		_List_fromArray(
		[20]),
		_List_fromArray(
		[20]),
		_List_fromArray(
		[4, 16])
	]);
var $elm$random$Random$Generate = $elm$core$Basics$identity;
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$random$Random$init = A2(
	$elm$core$Task$andThen,
	function (time) {
		return $elm$core$Task$succeed(
			$elm$random$Random$initialSeed(
				$elm$time$Time$posixToMillis(time)));
	},
	$elm$time$Time$now);
var $elm$random$Random$step = F2(
	function (_v0, seed) {
		var generator = _v0;
		return generator(seed);
	});
var $elm$random$Random$onEffects = F3(
	function (router, commands, seed) {
		if (!commands.b) {
			return $elm$core$Task$succeed(seed);
		} else {
			var generator = commands.a;
			var rest = commands.b;
			var _v1 = A2($elm$random$Random$step, generator, seed);
			var value = _v1.a;
			var newSeed = _v1.b;
			return A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$random$Random$onEffects, router, rest, newSeed);
				},
				A2($elm$core$Platform$sendToApp, router, value));
		}
	});
var $elm$random$Random$onSelfMsg = F3(
	function (_v0, _v1, seed) {
		return $elm$core$Task$succeed(seed);
	});
var $elm$random$Random$Generator = $elm$core$Basics$identity;
var $elm$random$Random$map = F2(
	function (func, _v0) {
		var genA = _v0;
		return function (seed0) {
			var _v1 = genA(seed0);
			var a = _v1.a;
			var seed1 = _v1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var $elm$random$Random$cmdMap = F2(
	function (func, _v0) {
		var generator = _v0;
		return A2($elm$random$Random$map, func, generator);
	});
_Platform_effectManagers['Random'] = _Platform_createManager($elm$random$Random$init, $elm$random$Random$onEffects, $elm$random$Random$onSelfMsg, $elm$random$Random$cmdMap);
var $elm$random$Random$command = _Platform_leaf('Random');
var $elm$random$Random$generate = F2(
	function (tagger, generator) {
		return $elm$random$Random$command(
			A2($elm$random$Random$map, tagger, generator));
	});
var $elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var $author$project$App$DebounceBackendUpdateMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$DockBudget = 0;
var $author$project$App$GotRefreshedToken = function (a) {
	return {$: 21, a: a};
};
var $author$project$App$PageTimelines = {$: 0};
var $author$project$Auth$RefreshingToken = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$TriggerBackendUpdate = {$: 26};
var $Gizra$elm_debouncer$Debouncer$Internal$Config = $elm$core$Basics$identity;
var $Gizra$elm_debouncer$Debouncer$Internal$lastInput = F2(
	function (i, o) {
		return $elm$core$Maybe$Just(i);
	});
var $Gizra$elm_debouncer$Debouncer$Internal$manual = {ah: $Gizra$elm_debouncer$Debouncer$Internal$lastInput, L: $elm$core$Maybe$Nothing, M: $elm$core$Maybe$Nothing, R: $elm$core$Maybe$Nothing};
var $Gizra$elm_debouncer$Debouncer$Basic$manual = $Gizra$elm_debouncer$Debouncer$Internal$manual;
var $Gizra$elm_debouncer$Debouncer$Internal$settleWhenQuietFor = F2(
	function (time, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{R: time});
	});
var $Gizra$elm_debouncer$Debouncer$Basic$settleWhenQuietFor = $Gizra$elm_debouncer$Debouncer$Internal$settleWhenQuietFor;
var $Gizra$elm_debouncer$Debouncer$Basic$debounce = function (interval) {
	return A2(
		$Gizra$elm_debouncer$Debouncer$Basic$settleWhenQuietFor,
		$elm$core$Maybe$Just(interval),
		$Gizra$elm_debouncer$Debouncer$Basic$manual);
};
var $krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var $author$project$Auth$init = {bA: $krisajenkins$remotedata$RemoteData$NotAsked, ao: 'neil.det+sowtest@gmail.com', ap: 'password123', aq: $elm$core$Maybe$Nothing};
var $author$project$Projection$init = {am: _List_Nil};
var $author$project$ScheduleEditor$DndMsg = function (a) {
	return {$: 11, a: a};
};
var $annaghi$dnd_list$DnDList$Groups$InsertBefore = 1;
var $annaghi$dnd_list$DnDList$Groups$OnDrag = 0;
var $annaghi$dnd_list$DnDList$Groups$Rotate = 2;
var $annaghi$dnd_list$DnDList$Groups$Model = $elm$core$Basics$identity;
var $annaghi$dnd_list$DnDList$Groups$GotDragElement = function (a) {
	return {$: 6, a: a};
};
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$browser$Browser$Dom$getElement = _Browser_getElement;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $annaghi$dnd_list$DnDList$Groups$dragElementCommands = F2(
	function (stepMsg, state) {
		var _v0 = state.ad;
		if (_v0.$ === 1) {
			return A2(
				$elm$core$Task$attempt,
				A2($elm$core$Basics$composeL, stepMsg, $annaghi$dnd_list$DnDList$Groups$GotDragElement),
				$elm$browser$Browser$Dom$getElement(state.av));
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $annaghi$dnd_list$DnDList$Groups$GotDropElement = function (a) {
	return {$: 7, a: a};
};
var $annaghi$dnd_list$DnDList$Groups$dropElementCommands = F2(
	function (stepMsg, state) {
		return (!state.w) ? A2(
			$elm$core$Task$attempt,
			A2($elm$core$Basics$composeL, stepMsg, $annaghi$dnd_list$DnDList$Groups$GotDropElement),
			$elm$browser$Browser$Dom$getElement(state.ak)) : $elm$core$Platform$Cmd$none;
	});
var $annaghi$dnd_list$DnDList$Groups$commands = F2(
	function (stepMsg, _v0) {
		var model = _v0;
		if (model.$ === 1) {
			return $elm$core$Platform$Cmd$none;
		} else {
			var state = model.a;
			return $elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($annaghi$dnd_list$DnDList$Groups$dragElementCommands, stepMsg, state),
						A2($annaghi$dnd_list$DnDList$Groups$dropElementCommands, stepMsg, state)
					]));
		}
	});
var $annaghi$dnd_list$DnDList$Groups$DragStart = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $annaghi$dnd_list$Internal$Common$Utils$Position = F2(
	function (x, y) {
		return {ci: x, ck: y};
	});
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $annaghi$dnd_list$Internal$Common$Utils$clientX = A2($elm$json$Json$Decode$field, 'clientX', $elm$json$Json$Decode$float);
var $annaghi$dnd_list$Internal$Common$Utils$clientY = A2($elm$json$Json$Decode$field, 'clientY', $elm$json$Json$Decode$float);
var $annaghi$dnd_list$Internal$Common$Utils$decodeCoordinates = A3($elm$json$Json$Decode$map2, $annaghi$dnd_list$Internal$Common$Utils$Position, $annaghi$dnd_list$Internal$Common$Utils$clientX, $annaghi$dnd_list$Internal$Common$Utils$clientY);
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $annaghi$dnd_list$Internal$Common$Utils$decodeMainMouseButton = function (decoder) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (button) {
			return (!button) ? decoder : $elm$json$Json$Decode$fail('Event is only relevant when the main mouse button was pressed.');
		},
		A2($elm$json$Json$Decode$field, 'button', $elm$json$Json$Decode$int));
};
var $annaghi$dnd_list$Internal$Common$Utils$decodeCoordinatesWithButtonCheck = $annaghi$dnd_list$Internal$Common$Utils$decodeMainMouseButton($annaghi$dnd_list$Internal$Common$Utils$decodeCoordinates);
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $annaghi$dnd_list$DnDList$Groups$dragEvents = F3(
	function (stepMsg, dragIndex, dragElementId) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$Events$preventDefaultOn,
				'mousedown',
				A2(
					$elm$json$Json$Decode$map,
					function (msg) {
						return _Utils_Tuple2(msg, true);
					},
					A2(
						$elm$json$Json$Decode$map,
						A2(
							$elm$core$Basics$composeL,
							stepMsg,
							A2($annaghi$dnd_list$DnDList$Groups$DragStart, dragIndex, dragElementId)),
						$annaghi$dnd_list$Internal$Common$Utils$decodeCoordinatesWithButtonCheck)))
			]);
	});
var $annaghi$dnd_list$DnDList$Groups$DragEnter = function (a) {
	return {$: 3, a: a};
};
var $annaghi$dnd_list$DnDList$Groups$DragLeave = {$: 4};
var $annaghi$dnd_list$DnDList$Groups$DragOver = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onMouseEnter = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseenter',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onMouseLeave = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseleave',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onMouseOver = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseover',
		$elm$json$Json$Decode$succeed(msg));
};
var $annaghi$dnd_list$DnDList$Groups$dropEvents = F3(
	function (stepMsg, dropIndex, dropElementId) {
		return _List_fromArray(
			[
				$elm$html$Html$Events$onMouseOver(
				stepMsg(
					A2($annaghi$dnd_list$DnDList$Groups$DragOver, dropIndex, dropElementId))),
				$elm$html$Html$Events$onMouseEnter(
				stepMsg(
					$annaghi$dnd_list$DnDList$Groups$DragEnter(dropIndex))),
				$elm$html$Html$Events$onMouseLeave(
				stepMsg($annaghi$dnd_list$DnDList$Groups$DragLeave))
			]);
	});
var $annaghi$dnd_list$Internal$Common$Utils$px = function (n) {
	return $elm$core$String$fromInt(n) + 'px';
};
var $elm$core$Basics$round = _Basics_round;
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $annaghi$dnd_list$Internal$Common$Utils$translate = F2(
	function (x, y) {
		return 'translate3d(' + ($annaghi$dnd_list$Internal$Common$Utils$px(x) + (', ' + ($annaghi$dnd_list$Internal$Common$Utils$px(y) + ', 0)')));
	});
var $annaghi$dnd_list$DnDList$Groups$ghostStyles = function (_v0) {
	var model = _v0;
	if (model.$ === 1) {
		return _List_Nil;
	} else {
		var state = model.a;
		var _v2 = state.ad;
		if (!_v2.$) {
			var element = _v2.a.eP;
			var viewport = _v2.a.br;
			return _List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
					A2($elm$html$Html$Attributes$style, 'left', '0'),
					A2($elm$html$Html$Attributes$style, 'top', '0'),
					A2(
					$elm$html$Html$Attributes$style,
					'transform',
					A2(
						$annaghi$dnd_list$Internal$Common$Utils$translate,
						$elm$core$Basics$round(((state.K.ci - state.X.ci) + element.ci) - viewport.ci),
						$elm$core$Basics$round(((state.K.ck - state.X.ck) + element.ck) - viewport.ck))),
					A2(
					$elm$html$Html$Attributes$style,
					'height',
					$annaghi$dnd_list$Internal$Common$Utils$px(
						$elm$core$Basics$round(element.ax))),
					A2(
					$elm$html$Html$Attributes$style,
					'width',
					$annaghi$dnd_list$Internal$Common$Utils$px(
						$elm$core$Basics$round(element.aH))),
					A2($elm$html$Html$Attributes$style, 'pointer-events', 'none')
				]);
		} else {
			return _List_Nil;
		}
	}
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $annaghi$dnd_list$DnDList$Groups$info = function (_v0) {
	var model = _v0;
	return A2(
		$elm$core$Maybe$andThen,
		function (state) {
			return A3(
				$elm$core$Maybe$map2,
				F2(
					function (dragElement, dropElement) {
						return {K: state.K, ad: dragElement, av: state.av, cL: state.cL, aj: dropElement, ak: state.ak, eM: state.eM, X: state.X};
					}),
				state.ad,
				state.aj);
		},
		model);
};
var $annaghi$dnd_list$DnDList$Groups$Drag = function (a) {
	return {$: 1, a: a};
};
var $annaghi$dnd_list$DnDList$Groups$DragEnd = {$: 5};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {dE: pids, d4: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {cO: event, db: key};
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.dE,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.db;
		var event = _v0.cO;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.d4);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onMouseMove = A2($elm$browser$Browser$Events$on, 0, 'mousemove');
var $elm$browser$Browser$Events$onMouseUp = A2($elm$browser$Browser$Events$on, 0, 'mouseup');
var $annaghi$dnd_list$DnDList$Groups$subscriptions = F2(
	function (stepMsg, _v0) {
		var model = _v0;
		if (model.$ === 1) {
			return $elm$core$Platform$Sub$none;
		} else {
			return $elm$core$Platform$Sub$batch(
				_List_fromArray(
					[
						$elm$browser$Browser$Events$onMouseMove(
						A2(
							$elm$json$Json$Decode$map,
							A2($elm$core$Basics$composeL, stepMsg, $annaghi$dnd_list$DnDList$Groups$Drag),
							$annaghi$dnd_list$Internal$Common$Utils$decodeCoordinates)),
						$elm$browser$Browser$Events$onMouseUp(
						$elm$json$Json$Decode$succeed(
							stepMsg($annaghi$dnd_list$DnDList$Groups$DragEnd)))
					]));
		}
	});
var $annaghi$dnd_list$DnDList$Groups$OnDrop = 1;
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $annaghi$dnd_list$Internal$Groups$drags = F2(
	function (dragIndex, list) {
		return A2(
			$elm$core$List$take,
			1,
			A2($elm$core$List$drop, dragIndex, list));
	});
var $annaghi$dnd_list$Internal$Groups$drops = F2(
	function (dropIndex, list) {
		return A2(
			$elm$core$List$take,
			1,
			A2($elm$core$List$drop, dropIndex, list));
	});
var $annaghi$dnd_list$Internal$Groups$equalGroups = F4(
	function (comparator, dragIndex, dropIndex, list) {
		return A3(
			$elm$core$List$foldl,
			$elm$core$Basics$or,
			false,
			A3(
				$elm$core$List$map2,
				F2(
					function (dragItem, dropItem) {
						return A2(comparator, dragItem, dropItem);
					}),
				A2($annaghi$dnd_list$Internal$Groups$drags, dragIndex, list),
				A2($annaghi$dnd_list$Internal$Groups$drops, dropIndex, list)));
	});
var $annaghi$dnd_list$Internal$Groups$allGroupUpdate = F4(
	function (fn, i, j, l) {
		var middle = A2(
			$elm$core$List$take,
			(j - i) + 1,
			A2($elm$core$List$drop, i, l));
		var end = A2($elm$core$List$drop, j + 1, l);
		var beginning = A2($elm$core$List$take, i, l);
		return _Utils_ap(
			beginning,
			_Utils_ap(
				fn(middle),
				end));
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $annaghi$dnd_list$Internal$Groups$sublistByFirstItem = F2(
	function (comparator, list) {
		if (!list.b) {
			return _List_Nil;
		} else {
			var x = list.a;
			return A2(
				$elm$core$List$filter,
				function (item) {
					return A2(comparator, x, item);
				},
				list);
		}
	});
var $annaghi$dnd_list$Internal$Groups$bubbleGroupRecursive = F3(
	function (comparator, setter, list) {
		if (!list.b) {
			return _List_Nil;
		} else {
			if (!list.b.b) {
				var x = list.a;
				return _List_fromArray(
					[x]);
			} else {
				var x = list.a;
				var xs = list.b;
				var sublist = A2($annaghi$dnd_list$Internal$Groups$sublistByFirstItem, comparator, list);
				return (!_Utils_eq(sublist, _List_Nil)) ? _Utils_ap(
					$elm$core$List$reverse(
						A2($elm$core$List$drop, 1, sublist)),
					_Utils_ap(
						A3(
							$elm$core$List$map2,
							F2(
								function (prev, next) {
									return A2(setter, next, prev);
								}),
							A2($elm$core$List$take, 1, sublist),
							A2(
								$elm$core$List$take,
								1,
								A2(
									$elm$core$List$drop,
									$elm$core$List$length(sublist) - 1,
									xs))),
						A3(
							$annaghi$dnd_list$Internal$Groups$bubbleGroupRecursive,
							comparator,
							setter,
							_Utils_ap(
								A3(
									$elm$core$List$map2,
									F2(
										function (prev, next) {
											return A2(setter, prev, next);
										}),
									A2($elm$core$List$take, 1, sublist),
									A2(
										$elm$core$List$take,
										1,
										A2(
											$elm$core$List$drop,
											$elm$core$List$length(sublist) - 1,
											xs))),
								A2(
									$elm$core$List$drop,
									$elm$core$List$length(sublist),
									xs))))) : A2($elm$core$List$cons, x, xs);
			}
		}
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $annaghi$dnd_list$Internal$Groups$dragAndDropGroupUpdate = F4(
	function (setter, dragIndex, dropIndex, list) {
		var dropItem = A2($annaghi$dnd_list$Internal$Groups$drops, dropIndex, list);
		var dragItem = A2($annaghi$dnd_list$Internal$Groups$drags, dragIndex, list);
		return $elm$core$List$concat(
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (index, item) {
						return _Utils_eq(index, dragIndex) ? A3(
							$elm$core$List$map2,
							setter,
							dropItem,
							_List_fromArray(
								[item])) : (_Utils_eq(index, dropIndex) ? A3(
							$elm$core$List$map2,
							setter,
							dragItem,
							_List_fromArray(
								[item])) : _List_fromArray(
							[item]));
					}),
				list));
	});
var $annaghi$dnd_list$Internal$Groups$dragGroupUpdate = F4(
	function (setter, dragIndex, dropIndex, list) {
		var dropItem = A2($annaghi$dnd_list$Internal$Groups$drops, dropIndex, list);
		return $elm$core$List$concat(
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (index, item) {
						return _Utils_eq(index, dragIndex) ? A3(
							$elm$core$List$map2,
							setter,
							dropItem,
							_List_fromArray(
								[item])) : _List_fromArray(
							[item]);
					}),
				list));
	});
var $annaghi$dnd_list$Internal$Common$Operations$splitAt = F2(
	function (i, list) {
		return _Utils_Tuple2(
			A2($elm$core$List$take, i, list),
			A2($elm$core$List$drop, i, list));
	});
var $annaghi$dnd_list$Internal$Common$Operations$afterBackward = F3(
	function (i, j, list) {
		var _v0 = A2($annaghi$dnd_list$Internal$Common$Operations$splitAt, j + 1, list);
		var beginning = _v0.a;
		var rest = _v0.b;
		var _v1 = A2($annaghi$dnd_list$Internal$Common$Operations$splitAt, (i - j) - 1, rest);
		var middle = _v1.a;
		var end = _v1.b;
		var _v2 = A2($annaghi$dnd_list$Internal$Common$Operations$splitAt, 1, end);
		var head = _v2.a;
		var tail = _v2.b;
		return _Utils_ap(
			beginning,
			_Utils_ap(
				head,
				_Utils_ap(middle, tail)));
	});
var $annaghi$dnd_list$Internal$Common$Operations$afterForward = F3(
	function (i, j, list) {
		var _v0 = A2($annaghi$dnd_list$Internal$Common$Operations$splitAt, i, list);
		var beginning = _v0.a;
		var rest = _v0.b;
		var _v1 = A2($annaghi$dnd_list$Internal$Common$Operations$splitAt, (j - i) + 1, rest);
		var middle = _v1.a;
		var end = _v1.b;
		var _v2 = A2($annaghi$dnd_list$Internal$Common$Operations$splitAt, 1, middle);
		var head = _v2.a;
		var tail = _v2.b;
		return _Utils_ap(
			beginning,
			_Utils_ap(
				tail,
				_Utils_ap(head, end)));
	});
var $annaghi$dnd_list$Internal$Common$Operations$insertAfter = F3(
	function (dragIndex, dropIndex, list) {
		return (_Utils_cmp(dragIndex, dropIndex) < 0) ? A3($annaghi$dnd_list$Internal$Common$Operations$afterForward, dragIndex, dropIndex, list) : ((_Utils_cmp(dropIndex, dragIndex) < 0) ? A3($annaghi$dnd_list$Internal$Common$Operations$afterBackward, dragIndex, dropIndex, list) : list);
	});
var $annaghi$dnd_list$Internal$Common$Operations$beforeBackward = F3(
	function (i, j, list) {
		var _v0 = A2($annaghi$dnd_list$Internal$Common$Operations$splitAt, j, list);
		var beginning = _v0.a;
		var rest = _v0.b;
		var _v1 = A2($annaghi$dnd_list$Internal$Common$Operations$splitAt, i - j, rest);
		var middle = _v1.a;
		var end = _v1.b;
		var _v2 = A2($annaghi$dnd_list$Internal$Common$Operations$splitAt, 1, end);
		var head = _v2.a;
		var tail = _v2.b;
		return _Utils_ap(
			beginning,
			_Utils_ap(
				head,
				_Utils_ap(middle, tail)));
	});
var $annaghi$dnd_list$Internal$Common$Operations$beforeForward = F3(
	function (i, j, list) {
		var _v0 = A2($annaghi$dnd_list$Internal$Common$Operations$splitAt, i, list);
		var beginning = _v0.a;
		var rest = _v0.b;
		var _v1 = A2($annaghi$dnd_list$Internal$Common$Operations$splitAt, j - i, rest);
		var middle = _v1.a;
		var end = _v1.b;
		var _v2 = A2($annaghi$dnd_list$Internal$Common$Operations$splitAt, 1, middle);
		var head = _v2.a;
		var tail = _v2.b;
		return _Utils_ap(
			beginning,
			_Utils_ap(
				tail,
				_Utils_ap(head, end)));
	});
var $annaghi$dnd_list$Internal$Common$Operations$insertBefore = F3(
	function (dragIndex, dropIndex, list) {
		return (_Utils_cmp(dragIndex, dropIndex) < 0) ? A3($annaghi$dnd_list$Internal$Common$Operations$beforeForward, dragIndex, dropIndex, list) : ((_Utils_cmp(dropIndex, dragIndex) < 0) ? A3($annaghi$dnd_list$Internal$Common$Operations$beforeBackward, dragIndex, dropIndex, list) : list);
	});
var $annaghi$dnd_list$Internal$Common$Operations$rotate = F3(
	function (dragIndex, dropIndex, list) {
		return (_Utils_cmp(dragIndex, dropIndex) < 0) ? A3($annaghi$dnd_list$Internal$Common$Operations$afterForward, dragIndex, dropIndex, list) : ((_Utils_cmp(dropIndex, dragIndex) < 0) ? A3($annaghi$dnd_list$Internal$Common$Operations$beforeBackward, dragIndex, dropIndex, list) : list);
	});
var $annaghi$dnd_list$Internal$Common$Operations$swapAt = F3(
	function (i, j, list) {
		var item_j = A2(
			$elm$core$List$take,
			1,
			A2($elm$core$List$drop, j, list));
		var item_i = A2(
			$elm$core$List$take,
			1,
			A2($elm$core$List$drop, i, list));
		return $elm$core$List$concat(
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (index, item) {
						return _Utils_eq(index, i) ? item_j : (_Utils_eq(index, j) ? item_i : _List_fromArray(
							[item]));
					}),
				list));
	});
var $annaghi$dnd_list$Internal$Common$Operations$swap = F3(
	function (dragIndex, dropIndex, list) {
		return (!_Utils_eq(dragIndex, dropIndex)) ? A3($annaghi$dnd_list$Internal$Common$Operations$swapAt, dragIndex, dropIndex, list) : list;
	});
var $annaghi$dnd_list$DnDList$Groups$listUpdate = F6(
	function (operation, comparator, setter, dragIndex, dropIndex, list) {
		switch (operation) {
			case 0:
				return A3(
					$annaghi$dnd_list$Internal$Common$Operations$insertAfter,
					dragIndex,
					dropIndex,
					A4($annaghi$dnd_list$Internal$Groups$dragGroupUpdate, setter, dragIndex, dropIndex, list));
			case 1:
				return A3(
					$annaghi$dnd_list$Internal$Common$Operations$insertBefore,
					dragIndex,
					dropIndex,
					A4($annaghi$dnd_list$Internal$Groups$dragGroupUpdate, setter, dragIndex, dropIndex, list));
			case 2:
				return (_Utils_cmp(dragIndex, dropIndex) < 0) ? A3(
					$annaghi$dnd_list$Internal$Common$Operations$rotate,
					dragIndex,
					dropIndex,
					A4(
						$annaghi$dnd_list$Internal$Groups$allGroupUpdate,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$List$reverse,
							A2(
								$elm$core$Basics$composeR,
								A2($annaghi$dnd_list$Internal$Groups$bubbleGroupRecursive, comparator, setter),
								$elm$core$List$reverse)),
						dragIndex,
						dropIndex,
						list)) : ((_Utils_cmp(dropIndex, dragIndex) < 0) ? A3(
					$annaghi$dnd_list$Internal$Common$Operations$rotate,
					dragIndex,
					dropIndex,
					A4(
						$annaghi$dnd_list$Internal$Groups$allGroupUpdate,
						A2($annaghi$dnd_list$Internal$Groups$bubbleGroupRecursive, comparator, setter),
						dropIndex,
						dragIndex,
						list)) : list);
			case 3:
				return A3(
					$annaghi$dnd_list$Internal$Common$Operations$swap,
					dragIndex,
					dropIndex,
					A4($annaghi$dnd_list$Internal$Groups$dragAndDropGroupUpdate, setter, dragIndex, dropIndex, list));
			default:
				return list;
		}
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Basics$not = _Basics_not;
var $annaghi$dnd_list$DnDList$Groups$stateUpdate = F3(
	function (operation, dropIndex, state) {
		switch (operation) {
			case 0:
				return _Utils_update(
					state,
					{
						w: 0,
						cL: (_Utils_cmp(dropIndex, state.cL) < 0) ? (dropIndex + 1) : dropIndex
					});
			case 1:
				return _Utils_update(
					state,
					{
						w: 0,
						cL: (_Utils_cmp(state.cL, dropIndex) < 0) ? (dropIndex - 1) : dropIndex
					});
			case 2:
				return _Utils_update(
					state,
					{w: 0, cL: dropIndex});
			case 3:
				return _Utils_update(
					state,
					{w: 0, cL: dropIndex});
			default:
				return _Utils_update(
					state,
					{w: 0});
		}
	});
var $annaghi$dnd_list$DnDList$Groups$sublistUpdate = F4(
	function (operation, dragIndex, dropIndex, list) {
		switch (operation) {
			case 0:
				return A3($annaghi$dnd_list$Internal$Common$Operations$insertAfter, dragIndex, dropIndex, list);
			case 1:
				return A3($annaghi$dnd_list$Internal$Common$Operations$insertBefore, dragIndex, dropIndex, list);
			case 2:
				return A3($annaghi$dnd_list$Internal$Common$Operations$rotate, dragIndex, dropIndex, list);
			case 3:
				return A3($annaghi$dnd_list$Internal$Common$Operations$swap, dragIndex, dropIndex, list);
			default:
				return list;
		}
	});
var $annaghi$dnd_list$DnDList$Groups$update = F4(
	function (_v0, msg, _v1, list) {
		var beforeUpdate = _v0.er;
		var listen = _v0.dj;
		var operation = _v0.dA;
		var groups = _v0.eZ;
		var model = _v1;
		switch (msg.$) {
			case 0:
				var dragIndex = msg.a;
				var dragElementId = msg.b;
				var xy = msg.c;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						{K: xy, w: 0, ad: $elm$core$Maybe$Nothing, av: dragElementId, cL: dragIndex, aj: $elm$core$Maybe$Nothing, ak: dragElementId, eM: dragIndex, X: xy}),
					list);
			case 1:
				var xy = msg.a;
				return _Utils_Tuple2(
					A2(
						$elm$core$Maybe$map,
						function (state) {
							return _Utils_update(
								state,
								{K: xy, w: state.w + 1});
						},
						model),
					list);
			case 2:
				var dropIndex = msg.a;
				var dropElementId = msg.b;
				return _Utils_Tuple2(
					A2(
						$elm$core$Maybe$map,
						function (state) {
							return _Utils_update(
								state,
								{ak: dropElementId, eM: dropIndex});
						},
						model),
					list);
			case 3:
				var dropIndex = msg.a;
				if (!model.$) {
					var state = model.a;
					if ((state.w > 1) && (!_Utils_eq(state.cL, dropIndex))) {
						var equalGroups = A4($annaghi$dnd_list$Internal$Groups$equalGroups, groups.ez, state.cL, dropIndex, list);
						return ((!listen) && equalGroups) ? _Utils_Tuple2(
							$elm$core$Maybe$Just(
								A3($annaghi$dnd_list$DnDList$Groups$stateUpdate, operation, dropIndex, state)),
							A4(
								$annaghi$dnd_list$DnDList$Groups$sublistUpdate,
								operation,
								state.cL,
								dropIndex,
								A3(beforeUpdate, state.cL, dropIndex, list))) : (((!groups.dj) && (!equalGroups)) ? _Utils_Tuple2(
							$elm$core$Maybe$Just(
								A3($annaghi$dnd_list$DnDList$Groups$stateUpdate, groups.dA, dropIndex, state)),
							A6(
								$annaghi$dnd_list$DnDList$Groups$listUpdate,
								groups.dA,
								groups.ez,
								groups.fP,
								state.cL,
								dropIndex,
								A3(beforeUpdate, state.cL, dropIndex, list))) : _Utils_Tuple2(
							$elm$core$Maybe$Just(
								_Utils_update(
									state,
									{w: 0})),
							list));
					} else {
						return _Utils_Tuple2(model, list);
					}
				} else {
					return _Utils_Tuple2(model, list);
				}
			case 4:
				return _Utils_Tuple2(
					A2(
						$elm$core$Maybe$map,
						function (state) {
							return _Utils_update(
								state,
								{eM: state.cL});
						},
						model),
					list);
			case 5:
				if (!model.$) {
					var state = model.a;
					if (!_Utils_eq(state.cL, state.eM)) {
						var equalGroups = A4($annaghi$dnd_list$Internal$Groups$equalGroups, groups.ez, state.cL, state.eM, list);
						return ((listen === 1) && equalGroups) ? _Utils_Tuple2(
							$elm$core$Maybe$Nothing,
							A4(
								$annaghi$dnd_list$DnDList$Groups$sublistUpdate,
								operation,
								state.cL,
								state.eM,
								A3(beforeUpdate, state.cL, state.eM, list))) : (((groups.dj === 1) && (!equalGroups)) ? _Utils_Tuple2(
							$elm$core$Maybe$Nothing,
							A6(
								$annaghi$dnd_list$DnDList$Groups$listUpdate,
								groups.dA,
								groups.ez,
								groups.fP,
								state.cL,
								state.eM,
								A3(beforeUpdate, state.cL, state.eM, list))) : _Utils_Tuple2($elm$core$Maybe$Nothing, list));
					} else {
						return _Utils_Tuple2($elm$core$Maybe$Nothing, list);
					}
				} else {
					return _Utils_Tuple2($elm$core$Maybe$Nothing, list);
				}
			case 6:
				if (msg.a.$ === 1) {
					return _Utils_Tuple2(model, list);
				} else {
					var dragElement = msg.a.a;
					return _Utils_Tuple2(
						A2(
							$elm$core$Maybe$map,
							function (state) {
								return _Utils_update(
									state,
									{
										ad: $elm$core$Maybe$Just(dragElement),
										aj: $elm$core$Maybe$Just(dragElement)
									});
							},
							model),
						list);
				}
			default:
				if (msg.a.$ === 1) {
					return _Utils_Tuple2(model, list);
				} else {
					var dropElement = msg.a.a;
					return _Utils_Tuple2(
						A2(
							$elm$core$Maybe$map,
							function (state) {
								return _Utils_update(
									state,
									{
										aj: $elm$core$Maybe$Just(dropElement)
									});
							},
							model),
						list);
				}
		}
	});
var $annaghi$dnd_list$DnDList$Groups$create = F2(
	function (config, stepMsg) {
		return {
			ey: $annaghi$dnd_list$DnDList$Groups$commands(stepMsg),
			eK: $annaghi$dnd_list$DnDList$Groups$dragEvents(stepMsg),
			eL: $annaghi$dnd_list$DnDList$Groups$dropEvents(stepMsg),
			eY: $annaghi$dnd_list$DnDList$Groups$ghostStyles,
			c3: $annaghi$dnd_list$DnDList$Groups$info,
			fn: $elm$core$Maybe$Nothing,
			fV: $annaghi$dnd_list$DnDList$Groups$subscriptions(stepMsg),
			f8: $annaghi$dnd_list$DnDList$Groups$update(config)
		};
	});
var $author$project$ScheduleEditor$dndSystem = function () {
	var setter = F2(
		function (item1, item2) {
			return _Utils_update(
				item2,
				{b1: item1.b1});
		});
	var comparator = F2(
		function (item1, item2) {
			return _Utils_eq(item1.b1, item2.b1);
		});
	var dndConfig = {
		er: F3(
			function (_v0, _v1, list) {
				return list;
			}),
		eZ: {ez: comparator, dj: 0, dA: 1, fP: setter},
		dj: 0,
		dA: 2
	};
	return A2($annaghi$dnd_list$DnDList$Groups$create, dndConfig, $author$project$ScheduleEditor$DndMsg);
}();
var $author$project$ScheduleEditor$init = {ai: $author$project$ScheduleEditor$dndSystem.fn};
var $author$project$UserConfig$init = {
	a1: $elm$core$Maybe$Just(1),
	bx: 0.3,
	an: 60000,
	b_: true,
	bo: 0.48
};
var $author$project$TimelineEditor$DragAndDropMsg = function (a) {
	return {$: 0, a: a};
};
var $annaghi$dnd_list$DnDList$Horizontal = 1;
var $annaghi$dnd_list$DnDList$OnDrag = 0;
var $annaghi$dnd_list$DnDList$Swap = 3;
var $annaghi$dnd_list$DnDList$Model = $elm$core$Basics$identity;
var $annaghi$dnd_list$DnDList$GotDragElement = function (a) {
	return {$: 6, a: a};
};
var $annaghi$dnd_list$DnDList$dragElementCommands = F2(
	function (stepMsg, state) {
		var _v0 = state.ad;
		if (_v0.$ === 1) {
			return A2(
				$elm$core$Task$attempt,
				A2($elm$core$Basics$composeL, stepMsg, $annaghi$dnd_list$DnDList$GotDragElement),
				$elm$browser$Browser$Dom$getElement(state.av));
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $annaghi$dnd_list$DnDList$GotDropElement = function (a) {
	return {$: 7, a: a};
};
var $annaghi$dnd_list$DnDList$dropElementCommands = F2(
	function (stepMsg, state) {
		return (!state.w) ? A2(
			$elm$core$Task$attempt,
			A2($elm$core$Basics$composeL, stepMsg, $annaghi$dnd_list$DnDList$GotDropElement),
			$elm$browser$Browser$Dom$getElement(state.ak)) : $elm$core$Platform$Cmd$none;
	});
var $annaghi$dnd_list$DnDList$commands = F2(
	function (stepMsg, _v0) {
		var model = _v0;
		if (model.$ === 1) {
			return $elm$core$Platform$Cmd$none;
		} else {
			var state = model.a;
			return $elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($annaghi$dnd_list$DnDList$dragElementCommands, stepMsg, state),
						A2($annaghi$dnd_list$DnDList$dropElementCommands, stepMsg, state)
					]));
		}
	});
var $annaghi$dnd_list$DnDList$DragStart = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $annaghi$dnd_list$DnDList$dragEvents = F3(
	function (stepMsg, dragIndex, dragElementId) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$Events$preventDefaultOn,
				'mousedown',
				A2(
					$elm$json$Json$Decode$map,
					function (msg) {
						return _Utils_Tuple2(msg, true);
					},
					A2(
						$elm$json$Json$Decode$map,
						A2(
							$elm$core$Basics$composeL,
							stepMsg,
							A2($annaghi$dnd_list$DnDList$DragStart, dragIndex, dragElementId)),
						$annaghi$dnd_list$Internal$Common$Utils$decodeCoordinatesWithButtonCheck)))
			]);
	});
var $annaghi$dnd_list$DnDList$DragEnter = function (a) {
	return {$: 3, a: a};
};
var $annaghi$dnd_list$DnDList$DragLeave = {$: 4};
var $annaghi$dnd_list$DnDList$DragOver = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $annaghi$dnd_list$DnDList$dropEvents = F3(
	function (stepMsg, dropIndex, dropElementId) {
		return _List_fromArray(
			[
				$elm$html$Html$Events$onMouseOver(
				stepMsg(
					A2($annaghi$dnd_list$DnDList$DragOver, dropIndex, dropElementId))),
				$elm$html$Html$Events$onMouseEnter(
				stepMsg(
					$annaghi$dnd_list$DnDList$DragEnter(dropIndex))),
				$elm$html$Html$Events$onMouseLeave(
				stepMsg($annaghi$dnd_list$DnDList$DragLeave))
			]);
	});
var $annaghi$dnd_list$DnDList$ghostStyles = F2(
	function (movement, _v0) {
		var model = _v0;
		if (model.$ === 1) {
			return _List_Nil;
		} else {
			var state = model.a;
			var _v2 = state.ad;
			if (!_v2.$) {
				var element = _v2.a.eP;
				var viewport = _v2.a.br;
				var transform = function () {
					switch (movement) {
						case 1:
							return A2(
								$elm$html$Html$Attributes$style,
								'transform',
								A2(
									$annaghi$dnd_list$Internal$Common$Utils$translate,
									$elm$core$Basics$round(((state.K.ci - state.X.ci) + element.ci) - viewport.ci),
									$elm$core$Basics$round(element.ck - viewport.ck)));
						case 2:
							return A2(
								$elm$html$Html$Attributes$style,
								'transform',
								A2(
									$annaghi$dnd_list$Internal$Common$Utils$translate,
									$elm$core$Basics$round(element.ci - viewport.ci),
									$elm$core$Basics$round(((state.K.ck - state.X.ck) + element.ck) - viewport.ck)));
						default:
							return A2(
								$elm$html$Html$Attributes$style,
								'transform',
								A2(
									$annaghi$dnd_list$Internal$Common$Utils$translate,
									$elm$core$Basics$round(((state.K.ci - state.X.ci) + element.ci) - viewport.ci),
									$elm$core$Basics$round(((state.K.ck - state.X.ck) + element.ck) - viewport.ck)));
					}
				}();
				var baseStyles = _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
						A2($elm$html$Html$Attributes$style, 'top', '0'),
						A2($elm$html$Html$Attributes$style, 'left', '0'),
						A2(
						$elm$html$Html$Attributes$style,
						'height',
						$annaghi$dnd_list$Internal$Common$Utils$px(
							$elm$core$Basics$round(element.ax))),
						A2(
						$elm$html$Html$Attributes$style,
						'width',
						$annaghi$dnd_list$Internal$Common$Utils$px(
							$elm$core$Basics$round(element.aH))),
						A2($elm$html$Html$Attributes$style, 'pointer-events', 'none')
					]);
				return A2($elm$core$List$cons, transform, baseStyles);
			} else {
				return _List_Nil;
			}
		}
	});
var $annaghi$dnd_list$DnDList$info = function (_v0) {
	var model = _v0;
	return A2(
		$elm$core$Maybe$andThen,
		function (state) {
			return A3(
				$elm$core$Maybe$map2,
				F2(
					function (dragElement, dropElement) {
						return {K: state.K, ad: dragElement, av: state.av, cL: state.cL, aj: dropElement, ak: state.ak, eM: state.eM, X: state.X};
					}),
				state.ad,
				state.aj);
		},
		model);
};
var $annaghi$dnd_list$DnDList$Drag = function (a) {
	return {$: 1, a: a};
};
var $annaghi$dnd_list$DnDList$DragEnd = {$: 5};
var $annaghi$dnd_list$DnDList$subscriptions = F2(
	function (stepMsg, _v0) {
		var model = _v0;
		if (model.$ === 1) {
			return $elm$core$Platform$Sub$none;
		} else {
			return $elm$core$Platform$Sub$batch(
				_List_fromArray(
					[
						$elm$browser$Browser$Events$onMouseMove(
						A2(
							$elm$json$Json$Decode$map,
							A2($elm$core$Basics$composeL, stepMsg, $annaghi$dnd_list$DnDList$Drag),
							$annaghi$dnd_list$Internal$Common$Utils$decodeCoordinates)),
						$elm$browser$Browser$Events$onMouseUp(
						$elm$json$Json$Decode$succeed(
							stepMsg($annaghi$dnd_list$DnDList$DragEnd)))
					]));
		}
	});
var $annaghi$dnd_list$DnDList$listUpdate = F4(
	function (operation, dragIndex, dropIndex, list) {
		switch (operation) {
			case 0:
				return A3($annaghi$dnd_list$Internal$Common$Operations$insertAfter, dragIndex, dropIndex, list);
			case 1:
				return A3($annaghi$dnd_list$Internal$Common$Operations$insertBefore, dragIndex, dropIndex, list);
			case 2:
				return A3($annaghi$dnd_list$Internal$Common$Operations$rotate, dragIndex, dropIndex, list);
			case 3:
				return A3($annaghi$dnd_list$Internal$Common$Operations$swap, dragIndex, dropIndex, list);
			default:
				return list;
		}
	});
var $annaghi$dnd_list$DnDList$stateUpdate = F3(
	function (operation, dropIndex, state) {
		switch (operation) {
			case 0:
				return _Utils_update(
					state,
					{
						w: 0,
						cL: (_Utils_cmp(dropIndex, state.cL) < 0) ? (dropIndex + 1) : dropIndex
					});
			case 1:
				return _Utils_update(
					state,
					{
						w: 0,
						cL: (_Utils_cmp(state.cL, dropIndex) < 0) ? (dropIndex - 1) : dropIndex
					});
			case 2:
				return _Utils_update(
					state,
					{w: 0, cL: dropIndex});
			case 3:
				return _Utils_update(
					state,
					{w: 0, cL: dropIndex});
			default:
				return _Utils_update(
					state,
					{w: 0});
		}
	});
var $annaghi$dnd_list$DnDList$update = F4(
	function (_v0, msg, _v1, list) {
		var beforeUpdate = _v0.er;
		var listen = _v0.dj;
		var operation = _v0.dA;
		var model = _v1;
		switch (msg.$) {
			case 0:
				var dragIndex = msg.a;
				var dragElementId = msg.b;
				var xy = msg.c;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(
						{K: xy, w: 0, ad: $elm$core$Maybe$Nothing, av: dragElementId, cL: dragIndex, aj: $elm$core$Maybe$Nothing, ak: dragElementId, eM: dragIndex, X: xy}),
					list);
			case 1:
				var xy = msg.a;
				return _Utils_Tuple2(
					A2(
						$elm$core$Maybe$map,
						function (state) {
							return _Utils_update(
								state,
								{K: xy, w: state.w + 1});
						},
						model),
					list);
			case 2:
				var dropIndex = msg.a;
				var dropElementId = msg.b;
				return _Utils_Tuple2(
					A2(
						$elm$core$Maybe$map,
						function (state) {
							return _Utils_update(
								state,
								{ak: dropElementId, eM: dropIndex});
						},
						model),
					list);
			case 3:
				var dropIndex = msg.a;
				var _v3 = _Utils_Tuple2(model, listen);
				if ((!_v3.a.$) && (!_v3.b)) {
					var state = _v3.a.a;
					var _v4 = _v3.b;
					return ((state.w > 1) && (!_Utils_eq(state.cL, dropIndex))) ? _Utils_Tuple2(
						$elm$core$Maybe$Just(
							A3($annaghi$dnd_list$DnDList$stateUpdate, operation, dropIndex, state)),
						A4(
							$annaghi$dnd_list$DnDList$listUpdate,
							operation,
							state.cL,
							dropIndex,
							A3(beforeUpdate, state.cL, dropIndex, list))) : _Utils_Tuple2(model, list);
				} else {
					return _Utils_Tuple2(
						A2(
							$elm$core$Maybe$map,
							function (state) {
								return _Utils_update(
									state,
									{w: 0});
							},
							model),
						list);
				}
			case 4:
				return _Utils_Tuple2(
					A2(
						$elm$core$Maybe$map,
						function (state) {
							return _Utils_update(
								state,
								{eM: state.cL});
						},
						model),
					list);
			case 5:
				var _v5 = _Utils_Tuple2(model, listen);
				if ((!_v5.a.$) && (_v5.b === 1)) {
					var state = _v5.a.a;
					var _v6 = _v5.b;
					return (!_Utils_eq(state.cL, state.eM)) ? _Utils_Tuple2(
						$elm$core$Maybe$Nothing,
						A4(
							$annaghi$dnd_list$DnDList$listUpdate,
							operation,
							state.cL,
							state.eM,
							A3(beforeUpdate, state.cL, state.eM, list))) : _Utils_Tuple2($elm$core$Maybe$Nothing, list);
				} else {
					return _Utils_Tuple2($elm$core$Maybe$Nothing, list);
				}
			case 6:
				if (msg.a.$ === 1) {
					return _Utils_Tuple2(model, list);
				} else {
					var dragElement = msg.a.a;
					return _Utils_Tuple2(
						A2(
							$elm$core$Maybe$map,
							function (state) {
								return _Utils_update(
									state,
									{
										ad: $elm$core$Maybe$Just(dragElement),
										aj: $elm$core$Maybe$Just(dragElement)
									});
							},
							model),
						list);
				}
			default:
				if (msg.a.$ === 1) {
					return _Utils_Tuple2(model, list);
				} else {
					var dropElement = msg.a.a;
					return _Utils_Tuple2(
						A2(
							$elm$core$Maybe$map,
							function (state) {
								return _Utils_update(
									state,
									{
										aj: $elm$core$Maybe$Just(dropElement)
									});
							},
							model),
						list);
				}
		}
	});
var $annaghi$dnd_list$DnDList$create = F2(
	function (config, stepMsg) {
		return {
			ey: $annaghi$dnd_list$DnDList$commands(stepMsg),
			eK: $annaghi$dnd_list$DnDList$dragEvents(stepMsg),
			eL: $annaghi$dnd_list$DnDList$dropEvents(stepMsg),
			eY: $annaghi$dnd_list$DnDList$ghostStyles(config.fp),
			c3: $annaghi$dnd_list$DnDList$info,
			fn: $elm$core$Maybe$Nothing,
			fV: $annaghi$dnd_list$DnDList$subscriptions(stepMsg),
			f8: $annaghi$dnd_list$DnDList$update(config)
		};
	});
var $author$project$TimelineEditor$dndSystem = function () {
	var config = {
		er: F3(
			function (_v0, _v1, list) {
				return list;
			}),
		dj: 0,
		fp: 1,
		dA: 3
	};
	return A2($annaghi$dnd_list$DnDList$create, config, $author$project$TimelineEditor$DragAndDropMsg);
}();
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $author$project$TimelineEditor$init = {_: _List_Nil, ai: $author$project$TimelineEditor$dndSystem.fn, ba: $elm$core$Maybe$Nothing, W: $elm$core$Maybe$Nothing, S: $elm$core$Set$empty};
var $author$project$WeekEditor$DragAndDropMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$WeekEditor$dndSystem = function () {
	var config = {
		er: F3(
			function (_v0, _v1, list) {
				return list;
			}),
		dj: 0,
		fp: 1,
		dA: 3
	};
	return A2($annaghi$dnd_list$DnDList$create, config, $author$project$WeekEditor$DragAndDropMsg);
}();
var $author$project$WeekEditor$init = {_: _List_Nil, T: $elm$core$Maybe$Nothing, ai: $author$project$WeekEditor$dndSystem.fn, S: $elm$core$Set$empty};
var $elm$core$Elm$JsArray$map = _JsArray_map;
var $elm$core$Array$map = F2(
	function (func, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = function (node) {
			if (!node.$) {
				var subTree = node.a;
				return $elm$core$Array$SubTree(
					A2($elm$core$Elm$JsArray$map, helper, subTree));
			} else {
				var values = node.a;
				return $elm$core$Array$Leaf(
					A2($elm$core$Elm$JsArray$map, func, values));
			}
		};
		return A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A2($elm$core$Elm$JsArray$map, helper, tree),
			A2($elm$core$Elm$JsArray$map, func, tail));
	});
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $danyx23$elm_uuid$Uuid$toString = function (_v0) {
	var internalString = _v0;
	return internalString;
};
var $author$project$UuidDict$fromList = function (list) {
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			$elm$core$Tuple$mapFirst($danyx23$elm_uuid$Uuid$toString),
			list));
};
var $danyx23$elm_uuid$Uuid$Uuid = $elm$core$Basics$identity;
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {e7: index, fk: match, ft: number, fU: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{ev: false, fq: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $danyx23$elm_uuid$Uuid$Barebones$uuidRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^[0-9A-Fa-f]{8,8}-[0-9A-Fa-f]{4,4}-[1-5][0-9A-Fa-f]{3,3}-[8-9A-Ba-b][0-9A-Fa-f]{3,3}-[0-9A-Fa-f]{12,12}$'));
var $danyx23$elm_uuid$Uuid$Barebones$isValidUuid = function (uuidAsString) {
	return A2($elm$regex$Regex$contains, $danyx23$elm_uuid$Uuid$Barebones$uuidRegex, uuidAsString);
};
var $elm$core$String$toLower = _String_toLower;
var $danyx23$elm_uuid$Uuid$fromString = function (text) {
	return $danyx23$elm_uuid$Uuid$Barebones$isValidUuid(text) ? $elm$core$Maybe$Just(
		$elm$core$String$toLower(text)) : $elm$core$Maybe$Nothing;
};
var $elm_community$maybe_extra$Maybe$Extra$cons = F2(
	function (item, list) {
		if (!item.$) {
			var v = item.a;
			return A2($elm$core$List$cons, v, list);
		} else {
			return list;
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$values = A2($elm$core$List$foldr, $elm_community$maybe_extra$Maybe$Extra$cons, _List_Nil);
var $author$project$UuidDict$keys = function (_v0) {
	var dict = _v0;
	return $elm_community$maybe_extra$Maybe$Extra$values(
		A2(
			$elm$core$List$map,
			$danyx23$elm_uuid$Uuid$fromString,
			$elm$core$Dict$keys(dict)));
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$UuidDict$values = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$values(dict);
};
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm_community$list_extra$List$Extra$zip = $elm$core$List$map2($elm$core$Tuple$pair);
var $author$project$UuidDict$toList = function (dict) {
	return A2(
		$elm_community$list_extra$List$Extra$zip,
		$author$project$UuidDict$keys(dict),
		$author$project$UuidDict$values(dict));
};
var $author$project$UuidDict$map = F2(
	function (f, uuidDict) {
		return $author$project$UuidDict$fromList(
			A2(
				$elm$core$List$map,
				function (_v0) {
					var k = _v0.a;
					var v = _v0.b;
					return _Utils_Tuple2(
						k,
						A2(f, k, v));
				},
				$author$project$UuidDict$toList(uuidDict)));
	});
var $author$project$App$loadBoardIntoModel = F2(
	function (board, model) {
		var b = board.bv;
		return _Utils_update(
			model,
			{
				n: A2(
					$author$project$UuidDict$map,
					F2(
						function (_v0, v) {
							return _Utils_Tuple2(v, $author$project$WeekEditor$init);
						}),
					b.n),
				a4: _List_Nil,
				z: b.z,
				C: b.C,
				g: A2(
					$elm$core$Array$map,
					function (t) {
						return _Utils_Tuple2(t, $author$project$TimelineEditor$init);
					},
					b.g)
			});
	});
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $author$project$Util$msgToCmd = function (m) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$always(m),
		$elm$core$Task$succeed(0));
};
var $Gizra$elm_debouncer$Debouncer$Basic$ProvideInput = function (a) {
	return {$: 0, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Basic$provideInput = $Gizra$elm_debouncer$Debouncer$Basic$ProvideInput;
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Api$const_BASE_URL = 'https://nshilbeiwtyoiswgxjvy.supabase.co';
var $author$project$Api$const_SUPABASE_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5zaGlsYmVpd3R5b2lzd2d4anZ5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzUxNzYyMjksImV4cCI6MjA1MDc1MjIyOX0.V1t9p8kgV0eWNEGVp3hIi4Ji0c6hA0IaAjCh1ESF1vQ';
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.fS));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {dR: reqs, d4: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.f7;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.dR));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.d4)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					em: r.em,
					cw: r.cw,
					eQ: A2(_Http_mapExpect, func, r.eQ),
					e0: r.e0,
					dm: r.dm,
					fZ: r.fZ,
					f7: r.f7,
					ec: r.ec
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{em: false, cw: r.cw, eQ: r.eQ, e0: r.e0, dm: r.dm, fZ: r.fZ, f7: r.f7, ec: r.ec}));
};
var $author$project$Api$request_ = F2(
	function (headers, p) {
		var baseHeaders = _List_fromArray(
			[
				A2($elm$http$Http$header, 'apikey', $author$project$Api$const_SUPABASE_KEY)
			]);
		return $elm$http$Http$request(
			{
				cw: p.cw,
				eQ: A2($elm$http$Http$expectJson, p.f2, p.eF),
				e0: _Utils_ap(headers, baseHeaders),
				dm: p.dm,
				fZ: $elm$core$Maybe$Nothing,
				f7: $elm$core$Maybe$Nothing,
				ec: _Utils_ap($author$project$Api$const_BASE_URL, p.ec)
			});
	});
var $author$project$Api$requestUnAuth = $author$project$Api$request_(_List_Nil);
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Api$Auth$refreshToken = F2(
	function (authUser, toMsg) {
		return $author$project$Api$requestUnAuth(
			{
				cw: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'refresh_token',
								$elm$json$Json$Encode$string(authUser.fI))
							]))),
				eF: $author$project$Types$AuthUser$decoder,
				dm: 'POST',
				f2: toMsg,
				ec: '/auth/v1/token?grant_type=refresh_token'
			});
	});
var $Gizra$elm_debouncer$Debouncer$Internal$Debouncer = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $Gizra$elm_debouncer$Debouncer$Internal$Settled = {$: 0};
var $Gizra$elm_debouncer$Debouncer$Internal$nothingIfNegative = $elm$core$Maybe$andThen(
	function (num) {
		return (num < 0) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(num);
	});
var $Gizra$elm_debouncer$Debouncer$Internal$sanitizeConfig = function (_v0) {
	var config = _v0;
	return {
		ah: config.ah,
		L: $Gizra$elm_debouncer$Debouncer$Internal$nothingIfNegative(config.L),
		M: $Gizra$elm_debouncer$Debouncer$Internal$nothingIfNegative(config.M),
		R: $Gizra$elm_debouncer$Debouncer$Internal$nothingIfNegative(config.R)
	};
};
var $Gizra$elm_debouncer$Debouncer$Internal$toDebouncer = function (config) {
	return A2(
		$Gizra$elm_debouncer$Debouncer$Internal$Debouncer,
		$Gizra$elm_debouncer$Debouncer$Internal$sanitizeConfig(config),
		$Gizra$elm_debouncer$Debouncer$Internal$Settled);
};
var $Gizra$elm_debouncer$Debouncer$Basic$toDebouncer = $Gizra$elm_debouncer$Debouncer$Internal$toDebouncer;
var $author$project$App$init = function (initType) {
	var initModel = {
		aJ: $author$project$Auth$init,
		F: $author$project$Auth$Anon,
		ab: false,
		n: $author$project$UuidDict$empty,
		a3: $Gizra$elm_debouncer$Debouncer$Basic$toDebouncer(
			$Gizra$elm_debouncer$Debouncer$Basic$debounce(200)),
		a4: _List_Nil,
		t: $elm$core$Result$Ok(0),
		z: $author$project$UuidDict$empty,
		A: $author$project$App$PageTimelines,
		aB: $author$project$Projection$init,
		aD: $author$project$ScheduleEditor$init,
		C: $author$project$UuidDict$empty,
		g: $elm$core$Array$empty,
		d: $author$project$UserConfig$init,
		Y: true
	};
	if (!initType.$) {
		var authState = initType.a;
		var board = initType.b;
		var modelWithBoard = A2($author$project$App$loadBoardIntoModel, board, initModel);
		if (authState.$ === 4) {
			return _Utils_Tuple2(
				_Utils_update(
					modelWithBoard,
					{F: authState, ab: false}),
				$author$project$Util$msgToCmd(
					$author$project$App$DebounceBackendUpdateMsg(
						$Gizra$elm_debouncer$Debouncer$Basic$provideInput($author$project$App$TriggerBackendUpdate))));
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					modelWithBoard,
					{F: authState}),
				$elm$core$Platform$Cmd$none);
		}
	} else {
		var authUser = initType.a;
		return _Utils_Tuple2(
			_Utils_update(
				initModel,
				{
					F: $author$project$Auth$RefreshingToken(authUser)
				}),
			A2($author$project$Api$Auth$refreshToken, authUser, $author$project$App$GotRefreshedToken));
	}
};
var $author$project$Tutorial$init = function (b) {
	var initModel = {
		n: A2(
			$author$project$UuidDict$map,
			F2(
				function (_v0, v) {
					return _Utils_Tuple2(v, $author$project$WeekEditor$init);
				}),
			b.n),
		a4: _List_Nil,
		z: b.z,
		aB: $author$project$Projection$init,
		C: b.C,
		g: A2(
			$elm$core$Array$map,
			function (t) {
				return _Utils_Tuple2(t, $author$project$TimelineEditor$init);
			},
			b.g)
	};
	return initModel;
};
var $author$project$Landing$init = function (boardBlob) {
	return {
		J: $author$project$Tutorial$init(boardBlob)
	};
};
var $elm$random$Random$listHelp = F4(
	function (revList, n, gen, seed) {
		listHelp:
		while (true) {
			if (n < 1) {
				return _Utils_Tuple2(revList, seed);
			} else {
				var _v0 = gen(seed);
				var value = _v0.a;
				var newSeed = _v0.b;
				var $temp$revList = A2($elm$core$List$cons, value, revList),
					$temp$n = n - 1,
					$temp$gen = gen,
					$temp$seed = newSeed;
				revList = $temp$revList;
				n = $temp$n;
				gen = $temp$gen;
				seed = $temp$seed;
				continue listHelp;
			}
		}
	});
var $elm$random$Random$list = F2(
	function (n, _v0) {
		var gen = _v0;
		return function (seed) {
			return A4($elm$random$Random$listHelp, _List_Nil, n, gen, seed);
		};
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$random$Random$map2 = F3(
	function (func, _v0, _v1) {
		var genA = _v0;
		var genB = _v1;
		return function (seed0) {
			var _v2 = genA(seed0);
			var a = _v2.a;
			var seed1 = _v2.b;
			var _v3 = genB(seed1);
			var b = _v3.a;
			var seed2 = _v3.b;
			return _Utils_Tuple2(
				A2(func, a, b),
				seed2);
		};
	});
var $elm$random$Random$map4 = F5(
	function (func, _v0, _v1, _v2, _v3) {
		var genA = _v0;
		var genB = _v1;
		var genC = _v2;
		var genD = _v3;
		return function (seed0) {
			var _v4 = genA(seed0);
			var a = _v4.a;
			var seed1 = _v4.b;
			var _v5 = genB(seed1);
			var b = _v5.a;
			var seed2 = _v5.b;
			var _v6 = genC(seed2);
			var c = _v6.a;
			var seed3 = _v6.b;
			var _v7 = genD(seed3);
			var d = _v7.a;
			var seed4 = _v7.b;
			return _Utils_Tuple2(
				A4(func, a, b, c, d),
				seed4);
		};
	});
var $author$project$Types$Budget$V1$cuttingDown = _Utils_Tuple2(
	'Cutting down expenses',
	function (b) {
		return _Utils_update(
			b,
			{
				eS: _List_fromArray(
					[
						_Utils_Tuple2(
						'Rent',
						$elm$core$Result$Ok(
							_Utils_Tuple2('300 * 4', 300 * 4))),
						_Utils_Tuple2(
						'Groceries',
						$elm$core$Result$Ok(
							_Utils_Tuple2('200 * 4', 200 * 4))),
						_Utils_Tuple2(
						'Phone',
						$elm$core$Result$Ok(
							_Utils_Tuple2('50', 50))),
						_Utils_Tuple2(
						'Gym',
						$elm$core$Result$Ok(
							_Utils_Tuple2('50', 50))),
						_Utils_Tuple2(
						'Leisure',
						$elm$core$Result$Ok(
							_Utils_Tuple2('100', 100))),
						_Utils_Tuple2(
						'Public Transport',
						$elm$core$Result$Ok(
							_Utils_Tuple2('30', 30)))
					]),
				e6: _List_fromArray(
					[
						_Utils_Tuple2(
						'Salary',
						$elm$core$Result$Ok(
							_Utils_Tuple2('5000', 5000)))
					])
			});
	});
var $author$project$Types$Budget$V1$paycheckToPaycheck = _Utils_Tuple2(
	'Paycheck to Paycheck',
	function (b) {
		return _Utils_update(
			b,
			{
				eS: _List_fromArray(
					[
						_Utils_Tuple2(
						'Rent',
						$elm$core$Result$Ok(
							_Utils_Tuple2('600 * 4', 2400))),
						_Utils_Tuple2(
						'Car',
						$elm$core$Result$Ok(
							_Utils_Tuple2('400 * 4', 1600))),
						_Utils_Tuple2(
						'Groceries',
						$elm$core$Result$Ok(
							_Utils_Tuple2('150 * 4', 600))),
						_Utils_Tuple2(
						'Party',
						$elm$core$Result$Ok(
							_Utils_Tuple2('150 * 2', 300))),
						_Utils_Tuple2(
						'Public Transport',
						$elm$core$Result$Ok(
							_Utils_Tuple2('30', 30)))
					]),
				e6: _List_fromArray(
					[
						_Utils_Tuple2(
						'Salary',
						$elm$core$Result$Ok(
							_Utils_Tuple2('5000', 5000)))
					])
			});
	});
var $author$project$Types$Budget$V1$quitJob = _Utils_Tuple2(
	'Quit job',
	function (b) {
		return _Utils_update(
			b,
			{
				eS: _List_fromArray(
					[
						_Utils_Tuple2(
						'Rent',
						$elm$core$Result$Ok(
							_Utils_Tuple2('300 * 4', 300 * 4))),
						_Utils_Tuple2(
						'Groceries',
						$elm$core$Result$Ok(
							_Utils_Tuple2('200 * 4', 200 * 4))),
						_Utils_Tuple2(
						'Phone',
						$elm$core$Result$Ok(
							_Utils_Tuple2('50', 50))),
						_Utils_Tuple2(
						'Gym',
						$elm$core$Result$Ok(
							_Utils_Tuple2('50', 50))),
						_Utils_Tuple2(
						'Leisure',
						$elm$core$Result$Ok(
							_Utils_Tuple2('100', 100))),
						_Utils_Tuple2(
						'Public Transport',
						$elm$core$Result$Ok(
							_Utils_Tuple2('30', 30)))
					]),
				e6: _List_fromArray(
					[
						_Utils_Tuple2(
						'Salary',
						$elm$core$Result$Ok(
							_Utils_Tuple2('200', 200)))
					])
			});
	});
var $author$project$Types$Budget$V1$workPartTime = _Utils_Tuple2(
	'Work Part Time',
	function (b) {
		return _Utils_update(
			b,
			{
				eS: _List_fromArray(
					[
						_Utils_Tuple2(
						'Rent',
						$elm$core$Result$Ok(
							_Utils_Tuple2('300 * 4', 200 * 4))),
						_Utils_Tuple2(
						'Groceries',
						$elm$core$Result$Ok(
							_Utils_Tuple2('100 * 4', 100 * 4))),
						_Utils_Tuple2(
						'Phone',
						$elm$core$Result$Ok(
							_Utils_Tuple2('50', 50))),
						_Utils_Tuple2(
						'Gym',
						$elm$core$Result$Ok(
							_Utils_Tuple2('50', 50))),
						_Utils_Tuple2(
						'Leisure',
						$elm$core$Result$Ok(
							_Utils_Tuple2('100', 100))),
						_Utils_Tuple2(
						'Public Transport',
						$elm$core$Result$Ok(
							_Utils_Tuple2('30', 30)))
					]),
				e6: _List_fromArray(
					[
						_Utils_Tuple2(
						'Salary',
						$elm$core$Result$Ok(
							_Utils_Tuple2('2000', 2000)))
					])
			});
	});
var $author$project$Types$Budget$V1$sampleBudgets = _List_fromArray(
	[$author$project$Types$Budget$V1$paycheckToPaycheck, $author$project$Types$Budget$V1$cuttingDown, $author$project$Types$Budget$V1$quitJob, $author$project$Types$Budget$V1$workPartTime]);
var $author$project$Types$Budget$sampleBudgets = $author$project$Types$Budget$V1$sampleBudgets;
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $elm$random$Random$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _v0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _v0.a;
			var hi = _v0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & $elm$random$Random$peel(seed0)) >>> 0) + lo,
					$elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = $elm$random$Random$peel(seed);
						var seedN = $elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var $danyx23$elm_uuid$Uuid$Barebones$hexGenerator = A2($elm$random$Random$int, 0, 15);
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$core$String$fromList = _String_fromList;
var $elm$core$Bitwise$or = _Bitwise_or;
var $danyx23$elm_uuid$Uuid$Barebones$limitDigitRange8ToB = function (digit) {
	return (digit & 3) | 8;
};
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Char$fromCode = _Char_fromCode;
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{k: nodeList, h: nodeListSize, j: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $danyx23$elm_uuid$Uuid$Barebones$hexDigits = function () {
	var mapChars = F2(
		function (offset, digit) {
			return $elm$core$Char$fromCode(digit + offset);
		});
	return $elm$core$Array$fromList(
		_Utils_ap(
			A2(
				$elm$core$List$map,
				mapChars(48),
				A2($elm$core$List$range, 0, 9)),
			A2(
				$elm$core$List$map,
				mapChars(97),
				A2($elm$core$List$range, 0, 5))));
}();
var $danyx23$elm_uuid$Uuid$Barebones$mapToHex = function (index) {
	var maybeResult = A2($elm$core$Array$get, index, $danyx23$elm_uuid$Uuid$Barebones$hexDigits);
	if (maybeResult.$ === 1) {
		return 'x';
	} else {
		var result = maybeResult.a;
		return result;
	}
};
var $danyx23$elm_uuid$Uuid$Barebones$toUuidString = function (thirtyOneHexDigits) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2($elm$core$List$take, 8, thirtyOneHexDigits))),
				'-',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						4,
						A2($elm$core$List$drop, 8, thirtyOneHexDigits)))),
				'-',
				'4',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						3,
						A2($elm$core$List$drop, 12, thirtyOneHexDigits)))),
				'-',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$map,
						$danyx23$elm_uuid$Uuid$Barebones$limitDigitRange8ToB,
						A2(
							$elm$core$List$take,
							1,
							A2($elm$core$List$drop, 15, thirtyOneHexDigits))))),
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						3,
						A2($elm$core$List$drop, 16, thirtyOneHexDigits)))),
				'-',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						12,
						A2($elm$core$List$drop, 19, thirtyOneHexDigits))))
			]));
};
var $danyx23$elm_uuid$Uuid$Barebones$uuidStringGenerator = A2(
	$elm$random$Random$map,
	$danyx23$elm_uuid$Uuid$Barebones$toUuidString,
	A2($elm$random$Random$list, 31, $danyx23$elm_uuid$Uuid$Barebones$hexGenerator));
var $danyx23$elm_uuid$Uuid$uuidGenerator = A2($elm$random$Random$map, $elm$core$Basics$identity, $danyx23$elm_uuid$Uuid$Barebones$uuidStringGenerator);
var $author$project$Main$init = function (data) {
	var initModel = {
		I: $author$project$Main$PageStateLanding(
			_Utils_Tuple2(
				$author$project$Landing$init(
					{n: $author$project$UuidDict$empty, z: $author$project$UuidDict$empty, C: $author$project$UuidDict$empty, g: $elm$core$Array$empty}),
				$author$project$Auth$Anon)),
		br: {
			bk: {ax: 0, aH: 0},
			br: {ax: 1080, aH: 1920, ci: 0, ck: 0}
		}
	};
	var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Types$AuthUser$decoder, data);
	if (!_v0.$) {
		var authUser = _v0.a;
		var _v1 = $author$project$App$init(
			$author$project$App$InitWithAuthUser(authUser));
		var appState = _v1.a;
		var appCmds = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				initModel,
				{
					I: $author$project$Main$PageStateApp(appState)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$elm$core$Platform$Cmd$map,
						A2($elm$core$Basics$composeR, $author$project$Main$PageMsgApp, $author$project$Main$GotPageMsg),
						appCmds),
						A2($elm$core$Task$perform, $author$project$Main$GotViewport, $elm$browser$Browser$Dom$getViewport)
					])));
	} else {
		return _Utils_Tuple2(
			initModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$elm$random$Random$generate,
						A2($elm$core$Basics$composeL, $author$project$Main$WithTime, $author$project$Main$GeneratedUuidsForExamples),
						A5(
							$elm$random$Random$map4,
							F4(
								function (budgets, schedules, defaultInvestmentStrategy, timelines) {
									return {n: budgets, cI: defaultInvestmentStrategy, C: schedules, g: timelines};
								}),
							A2(
								$elm$random$Random$list,
								$elm$core$List$length($author$project$Types$Budget$sampleBudgets),
								$danyx23$elm_uuid$Uuid$uuidGenerator),
							A3(
								$elm$random$Random$map2,
								F2(
									function (x, y) {
										return {bs: x, a$: y};
									}),
								$danyx23$elm_uuid$Uuid$uuidGenerator,
								$danyx23$elm_uuid$Uuid$uuidGenerator),
							$danyx23$elm_uuid$Uuid$uuidGenerator,
							A2(
								$elm$random$Random$list,
								$elm$core$List$length($author$project$Types$Segment$exampleSegmentDurations),
								$danyx23$elm_uuid$Uuid$uuidGenerator))),
						A2($elm$core$Task$perform, $author$project$Main$GotViewport, $elm$browser$Browser$Dom$getViewport)
					])));
	}
};
var $author$project$Main$PageMsgLanding = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $author$project$App$ScheduleEditorMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$ScheduleWeekEditorMsg = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$App$TimelineEditorMsg = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$ScheduleEditor$subscriptions = function (model) {
	return $author$project$ScheduleEditor$dndSystem.fV(model.ai);
};
var $author$project$TimelineEditor$subscriptions = function (model) {
	return $author$project$TimelineEditor$dndSystem.fV(model.ai);
};
var $author$project$WeekEditor$subscriptions = function (model) {
	return $author$project$WeekEditor$dndSystem.fV(model.ai);
};
var $author$project$App$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		$elm$core$List$concat(
			_List_fromArray(
				[
					A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, _v0) {
							var t = _v0.b;
							return A2(
								$elm$core$Platform$Sub$map,
								$author$project$App$TimelineEditorMsg(i),
								$author$project$TimelineEditor$subscriptions(t));
						}),
					$elm$core$Array$toList(model.g)),
					A2(
					$elm$core$List$map,
					function (_v1) {
						var bId = _v1.a;
						var _v2 = _v1.b;
						var e = _v2.b;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$App$ScheduleWeekEditorMsg(
								{ac: bId}),
							$author$project$WeekEditor$subscriptions(e));
					},
					$author$project$UuidDict$toList(model.n)),
					_List_fromArray(
					[
						A2(
						$elm$core$Platform$Sub$map,
						$author$project$App$ScheduleEditorMsg,
						$author$project$ScheduleEditor$subscriptions(model.aD))
					])
				])));
};
var $author$project$Landing$TutorialMsg = $elm$core$Basics$identity;
var $author$project$Tutorial$TimelineEditorMsg = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Tutorial$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		$elm$core$List$concat(
			_List_fromArray(
				[
					A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, _v0) {
							var t = _v0.b;
							return A2(
								$elm$core$Platform$Sub$map,
								$author$project$Tutorial$TimelineEditorMsg(i),
								$author$project$TimelineEditor$subscriptions(t));
						}),
					$elm$core$Array$toList(model.g))
				])));
};
var $author$project$Landing$subscriptions = function (model) {
	return A2(
		$elm$core$Platform$Sub$map,
		$elm$core$Basics$identity,
		$author$project$Tutorial$subscriptions(model.J));
};
var $author$project$Main$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$elm$browser$Browser$Events$onResize(
				F2(
					function (w, h) {
						return $author$project$Main$GotViewport(
							{
								bk: {ax: model.br.bk.ax, aH: model.br.bk.aH},
								br: {ax: h, aH: w, ci: model.br.br.ci, ck: model.br.br.ck}
							});
					})),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPageMsg,
				function () {
					var _v0 = model.I;
					if (!_v0.$) {
						var _v1 = _v0.a;
						var m = _v1.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Main$PageMsgLanding,
							$author$project$Landing$subscriptions(m));
					} else {
						var m = _v0.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Main$PageMsgApp,
							$author$project$App$subscriptions(m));
					}
				}())
			]));
};
var $author$project$Main$GotoApp = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $author$project$App$InitFromTutorial = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Types$Budget$cuttingDown = $author$project$Types$Budget$V1$cuttingDown;
var $author$project$Types$InvestmentStrategy$Other = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$InvestmentStrategy$createOther = F3(
	function (name, interestRate, investmentStrategy) {
		return function (is) {
			return _Utils_update(
				is,
				{
					aS: A3(
						$elm$core$Dict$insert,
						name,
						{a0: 0, ay: interestRate},
						is.aS)
				});
		}(investmentStrategy);
	});
var $author$project$Types$InvestmentStrategy$V1$init = F2(
	function (id, description) {
		return {
			eI: description,
			c1: id,
			aS: $elm$core$Dict$empty,
			aC: {a0: 100, ay: 3, a8: 20000}
		};
	});
var $author$project$Types$InvestmentStrategy$init = $author$project$Types$InvestmentStrategy$V1$init;
var $author$project$Types$InvestmentStrategy$ReallocationIsGreaterThanSavings = 1;
var $author$project$Types$InvestmentStrategy$updateAlloc = F2(
	function (newAlloc, a) {
		return _Utils_update(
			a,
			{a0: newAlloc});
	});
var $author$project$Types$InvestmentStrategy$reallocateFromSavings = F3(
	function (assetType, amount, investmentStrategy) {
		var is = investmentStrategy;
		var savingsAlloc = is.aC.a0;
		if (!assetType.$) {
			return $elm$core$Result$Ok(is);
		} else {
			var key = assetType.a;
			var _v1 = A2($elm$core$Dict$get, key, is.aS);
			if (!_v1.$) {
				var existingOther = _v1.a;
				return (_Utils_cmp(amount, existingOther.a0) < 1) ? $elm$core$Result$Ok(
					_Utils_update(
						investmentStrategy,
						{
							aS: A3(
								$elm$core$Dict$update,
								key,
								$elm$core$Maybe$map(
									$author$project$Types$InvestmentStrategy$updateAlloc(amount)),
								is.aS),
							aC: A2($author$project$Types$InvestmentStrategy$updateAlloc, is.aC.a0 + (existingOther.a0 - amount), is.aC)
						})) : ((_Utils_cmp(amount - existingOther.a0, savingsAlloc) < 1) ? $elm$core$Result$Ok(
					_Utils_update(
						investmentStrategy,
						{
							aS: A3(
								$elm$core$Dict$update,
								key,
								$elm$core$Maybe$map(
									$author$project$Types$InvestmentStrategy$updateAlloc(amount)),
								is.aS),
							aC: A2($author$project$Types$InvestmentStrategy$updateAlloc, is.aC.a0 - (amount - existingOther.a0), is.aC)
						})) : $elm$core$Result$Err(1));
			} else {
				return function (newIs) {
					return (_Utils_cmp(amount, savingsAlloc) < 1) ? $elm$core$Result$Ok(
						_Utils_update(
							investmentStrategy,
							{
								aS: A3(
									$elm$core$Dict$update,
									key,
									$elm$core$Maybe$map(
										$author$project$Types$InvestmentStrategy$updateAlloc(amount)),
									newIs.aS),
								aC: A2($author$project$Types$InvestmentStrategy$updateAlloc, newIs.aC.a0 - amount, newIs.aC)
							})) : $elm$core$Result$Err(1);
				}(
					A3($author$project$Types$InvestmentStrategy$createOther, key, 0, investmentStrategy));
			}
		}
	});
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Types$InvestmentStrategy$default = function (id) {
	return A2(
		$elm$core$Result$withDefault,
		A2($author$project$Types$InvestmentStrategy$init, id, 'Default'),
		A3(
			$author$project$Types$InvestmentStrategy$reallocateFromSavings,
			$author$project$Types$InvestmentStrategy$Other('ASX:VGS'),
			70,
			A3(
				$author$project$Types$InvestmentStrategy$createOther,
				'ASX:VGS',
				8,
				A2($author$project$Types$InvestmentStrategy$init, id, 'Default'))));
};
var $author$project$Types$Schedule$Other = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $author$project$Types$Schedule$V3$initAllocation = {a0: 0, cC: '#555'};
var $author$project$Types$Schedule$initAllocation = $author$project$Types$Schedule$V3$initAllocation;
var $j_maas$elm_ordered_containers$OrderedDict$OrderedDict = $elm$core$Basics$identity;
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $j_maas$elm_ordered_containers$OrderedDict$insert = F3(
	function (key, value, _v0) {
		var orderedDict = _v0;
		var filteredOrder = A2($elm$core$Dict$member, key, orderedDict.f) ? A2(
			$elm$core$List$filter,
			function (k) {
				return !_Utils_eq(k, key);
			},
			orderedDict.l) : orderedDict.l;
		var newOrder = _Utils_ap(
			filteredOrder,
			_List_fromArray(
				[key]));
		return {
			f: A3($elm$core$Dict$insert, key, value, orderedDict.f),
			l: newOrder
		};
	});
var $author$project$Types$Schedule$createOther = F2(
	function (name, schedule) {
		return function (is) {
			return _Utils_update(
				is,
				{
					aS: A3($j_maas$elm_ordered_containers$OrderedDict$insert, name, $author$project$Types$Schedule$initAllocation, is.aS)
				});
		}(schedule);
	});
var $j_maas$elm_ordered_containers$OrderedDict$empty = {f: $elm$core$Dict$empty, l: _List_Nil};
var $author$project$Types$Schedule$init = F2(
	function (id, description) {
		return {
			eI: description,
			eW: function (a) {
				return _Utils_update(
					a,
					{a0: 24});
			}($author$project$Types$Schedule$initAllocation),
			c1: id,
			aS: $j_maas$elm_ordered_containers$OrderedDict$empty
		};
	});
var $author$project$Types$Schedule$ReallocationIsGreaterThanFreetime = 1;
var $j_maas$elm_ordered_containers$OrderedDict$get = F2(
	function (key, _v0) {
		var orderedDict = _v0;
		return A2($elm$core$Dict$get, key, orderedDict.f);
	});
var $j_maas$elm_ordered_containers$OrderedDict$remove = F2(
	function (key, _v0) {
		var orderedDict = _v0;
		return {
			f: A2($elm$core$Dict$remove, key, orderedDict.f),
			l: A2($elm$core$Dict$member, key, orderedDict.f) ? A2(
				$elm$core$List$filter,
				function (k) {
					return !_Utils_eq(k, key);
				},
				orderedDict.l) : orderedDict.l
		};
	});
var $j_maas$elm_ordered_containers$OrderedDict$update = F3(
	function (key, alter, original) {
		var orderedDict = original;
		var _v0 = A2($elm$core$Dict$get, key, orderedDict.f);
		if (!_v0.$) {
			var oldItem = _v0.a;
			var _v1 = alter(
				$elm$core$Maybe$Just(oldItem));
			if (!_v1.$) {
				var newItem = _v1.a;
				return {
					f: A3(
						$elm$core$Dict$update,
						key,
						$elm$core$Basics$always(
							$elm$core$Maybe$Just(newItem)),
						orderedDict.f),
					l: orderedDict.l
				};
			} else {
				return A2($j_maas$elm_ordered_containers$OrderedDict$remove, key, original);
			}
		} else {
			var _v2 = alter($elm$core$Maybe$Nothing);
			if (!_v2.$) {
				var newItem = _v2.a;
				return A3($j_maas$elm_ordered_containers$OrderedDict$insert, key, newItem, original);
			} else {
				return original;
			}
		}
	});
var $author$project$Types$Schedule$updateAlloc = F2(
	function (newAlloc, a) {
		return _Utils_update(
			a,
			{a0: newAlloc});
	});
var $author$project$Types$Schedule$reallocateFromFreetime = F3(
	function (activityType, amount, schedule) {
		var is = schedule;
		var freetimeAlloc = is.eW.a0;
		if (!activityType.$) {
			return $elm$core$Result$Ok(is);
		} else {
			var key = activityType.a;
			var _v1 = A2($j_maas$elm_ordered_containers$OrderedDict$get, key, is.aS);
			if (!_v1.$) {
				var existingOther = _v1.a;
				return (_Utils_cmp(amount, existingOther.a0) < 1) ? $elm$core$Result$Ok(
					_Utils_update(
						schedule,
						{
							eW: A2($author$project$Types$Schedule$updateAlloc, is.eW.a0 + (existingOther.a0 - amount), is.eW),
							aS: A3(
								$j_maas$elm_ordered_containers$OrderedDict$update,
								key,
								$elm$core$Maybe$map(
									$author$project$Types$Schedule$updateAlloc(amount)),
								is.aS)
						})) : ((_Utils_cmp(amount - existingOther.a0, freetimeAlloc) < 1) ? $elm$core$Result$Ok(
					_Utils_update(
						schedule,
						{
							eW: A2($author$project$Types$Schedule$updateAlloc, is.eW.a0 - (amount - existingOther.a0), is.eW),
							aS: A3(
								$j_maas$elm_ordered_containers$OrderedDict$update,
								key,
								$elm$core$Maybe$map(
									$author$project$Types$Schedule$updateAlloc(amount)),
								is.aS)
						})) : $elm$core$Result$Err(1));
			} else {
				return function (newIs) {
					return (_Utils_cmp(amount, freetimeAlloc) < 1) ? $elm$core$Result$Ok(
						_Utils_update(
							schedule,
							{
								eW: A2($author$project$Types$Schedule$updateAlloc, newIs.eW.a0 - amount, newIs.eW),
								aS: A3(
									$j_maas$elm_ordered_containers$OrderedDict$update,
									key,
									$elm$core$Maybe$map(
										$author$project$Types$Schedule$updateAlloc(amount)),
									newIs.aS)
							})) : $elm$core$Result$Err(1);
				}(
					A2($author$project$Types$Schedule$createOther, key, schedule));
			}
		}
	});
var $author$project$Types$Schedule$setColorHex = F3(
	function (key, colorHex, schedule) {
		var setColor = function (alloc) {
			return _Utils_update(
				alloc,
				{cC: colorHex});
		};
		var is = schedule;
		return _Utils_update(
			schedule,
			{
				aS: A3(
					$j_maas$elm_ordered_containers$OrderedDict$update,
					key,
					$elm$core$Maybe$map(setColor),
					is.aS)
			});
	});
var $author$project$Types$Schedule$default = function (_v0) {
	var weekday = _v0.a;
	var weekend = _v0.b;
	return _Utils_Tuple2(
		A2(
			$elm$core$Result$withDefault,
			A2($author$project$Types$Schedule$init, weekday, 'Weekday'),
			A2(
				$elm$core$Result$andThen,
				A2(
					$author$project$Types$Schedule$reallocateFromFreetime,
					$author$project$Types$Schedule$Other('Relax'),
					6),
				A2(
					$elm$core$Result$andThen,
					A2(
						$author$project$Types$Schedule$reallocateFromFreetime,
						$author$project$Types$Schedule$Other('Sleep'),
						8),
					A2(
						$elm$core$Result$andThen,
						A2(
							$author$project$Types$Schedule$reallocateFromFreetime,
							$author$project$Types$Schedule$Other('Commute 2'),
							1),
						A2(
							$elm$core$Result$andThen,
							A2(
								$author$project$Types$Schedule$reallocateFromFreetime,
								$author$project$Types$Schedule$Other('Commute'),
								1),
							A3(
								$author$project$Types$Schedule$reallocateFromFreetime,
								$author$project$Types$Schedule$Other('Work'),
								8,
								A3(
									$author$project$Types$Schedule$setColorHex,
									'Relax',
									'#0656EA',
									A2(
										$author$project$Types$Schedule$createOther,
										'Relax',
										A3(
											$author$project$Types$Schedule$setColorHex,
											'Commute 2',
											'#F40101',
											A2(
												$author$project$Types$Schedule$createOther,
												'Commute 2',
												A3(
													$author$project$Types$Schedule$setColorHex,
													'Work',
													'#E7DF04',
													A2(
														$author$project$Types$Schedule$createOther,
														'Work',
														A3(
															$author$project$Types$Schedule$setColorHex,
															'Commute',
															'#F40101',
															A2(
																$author$project$Types$Schedule$createOther,
																'Commute',
																A3(
																	$author$project$Types$Schedule$setColorHex,
																	'Sleep',
																	'#333',
																	A2(
																		$author$project$Types$Schedule$createOther,
																		'Sleep',
																		A2($author$project$Types$Schedule$init, weekday, 'Weekday'))))))))))))))))),
		A2(
			$elm$core$Result$withDefault,
			A2($author$project$Types$Schedule$init, weekday, 'Weekend'),
			A2(
				$elm$core$Result$andThen,
				A2(
					$author$project$Types$Schedule$reallocateFromFreetime,
					$author$project$Types$Schedule$Other('Explore'),
					14),
				A3(
					$author$project$Types$Schedule$reallocateFromFreetime,
					$author$project$Types$Schedule$Other('Sleep'),
					10,
					A3(
						$author$project$Types$Schedule$setColorHex,
						'Explore',
						'#16BD00',
						A2(
							$author$project$Types$Schedule$createOther,
							'Explore',
							A3(
								$author$project$Types$Schedule$setColorHex,
								'Sleep',
								'#333',
								A2(
									$author$project$Types$Schedule$createOther,
									'Sleep',
									A2($author$project$Types$Schedule$init, weekend, 'Weekend')))))))));
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Types$Budget$V1$init = F3(
	function (budgetId, calendar, description) {
		return {eu: calendar, eI: description, eS: _List_Nil, c1: budgetId, e6: _List_Nil, aO: $elm$core$Maybe$Nothing};
	});
var $author$project$Types$Budget$init = $author$project$Types$Budget$V1$init;
var $author$project$Types$Timeline$V1$Timeline = F4(
	function (id, created, description, segments) {
		return {eC: created, eI: description, c1: id, dZ: segments};
	});
var $author$project$Types$Segment$V1$makeTestSegments = function (_v0) {
	var budgets = _v0.n;
	var defaultInvestmentStrategy = _v0.cI;
	var durations = _v0.eO;
	return A2(
		$elm$core$List$indexedMap,
		F2(
			function (i, _v1) {
				var budgetUuid = _v1.a;
				var duration = _v1.b;
				return {
					ac: budgetUuid,
					eI: 'Segment #' + $elm$core$String$fromInt(i),
					aL: duration,
					e: defaultInvestmentStrategy
				};
			}),
		A2($elm_community$list_extra$List$Extra$zip, budgets, durations));
};
var $author$project$Types$Timeline$V1$makeTestTimelines = F5(
	function (budgets, defaultInvestmentStrategy, time, name, _v0) {
		var uuid = _v0.a;
		var durations = _v0.b;
		return A4(
			$author$project$Types$Timeline$V1$Timeline,
			uuid,
			time,
			name,
			$author$project$Types$Segment$V1$makeTestSegments(
				{n: budgets, cI: defaultInvestmentStrategy, eO: durations}));
	});
var $author$project$Types$Timeline$makeTestTimelines = $author$project$Types$Timeline$V1$makeTestTimelines;
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Types$Budget$paycheckToPaycheck = $author$project$Types$Budget$V1$paycheckToPaycheck;
var $author$project$Types$Budget$quitJob = $author$project$Types$Budget$V1$quitJob;
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Ports$setupLandingPage = _Platform_outgoingPort(
	'setupLandingPage',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$BudgetEditor$Amount = 1;
var $author$project$ScheduleEditor$Amount = 1;
var $author$project$App$AuthMsg = function (a) {
	return {$: 20, a: a};
};
var $author$project$App$BudgetEditorMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$CloneTimeline = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Types$Budget$Expense = 1;
var $author$project$App$FocusBudgetLineItem = function (a) {
	return {$: 14, a: a};
};
var $author$project$App$FocusScheduleLineItem = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$App$GotBoard = F2(
	function (a, b) {
		return {$: 25, a: a, b: b};
	});
var $author$project$App$GotUuidForNewBoard = F2(
	function (a, b) {
		return {$: 24, a: a, b: b};
	});
var $author$project$Types$Budget$Income = 0;
var $author$project$App$InvestmentStrategyEditorMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$LoggedIn = function (a) {
	return {$: 22, a: a};
};
var $author$project$Auth$LoggedInGettingBoard = function (a) {
	return {$: 2, a: a};
};
var $author$project$Auth$LoggedInGotBoard = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$ProjectionMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Registered = function (a) {
	return {$: 23, a: a};
};
var $author$project$Auth$RegisteredCreatingBoard = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$authHeader = function (_v0) {
	var token = _v0.f5;
	return A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token);
};
var $author$project$Api$Board$boardVersion = 3;
var $author$project$Types$Board$V3$Board = F3(
	function (id, description, blob) {
		return {bv: blob, eI: description, c1: id};
	});
var $danyx23$elm_uuid$Uuid$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		var _v0 = $danyx23$elm_uuid$Uuid$fromString(string);
		if (!_v0.$) {
			var uuid = _v0.a;
			return $elm$json$Json$Decode$succeed(uuid);
		} else {
			return $elm$json$Json$Decode$fail('Not a valid UUID');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Types$Board$V3$BoardBlob = F4(
	function (timelines, budgets, schedules, investmentStrategies) {
		return {n: budgets, z: investmentStrategies, C: schedules, g: timelines};
	});
var $elm$json$Json$Decode$array = _Json_decodeArray;
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $author$project$UuidDict$decode = function (decoder) {
	var uuidKeyValuePairs = $elm$core$List$filterMap(
		function (_v0) {
			var kStr = _v0.a;
			var v = _v0.b;
			var _v1 = $danyx23$elm_uuid$Uuid$fromString(kStr);
			if (_v1.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var u = _v1.a;
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(u, v));
			}
		});
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$UuidDict$fromList,
		A2(
			$elm$json$Json$Decode$map,
			uuidKeyValuePairs,
			$elm$json$Json$Decode$keyValuePairs(decoder)));
};
var $author$project$Types$Budget$V1$Budget = F6(
	function (id, description, expenses, income, calendar, mortgage) {
		return {eu: calendar, eI: description, eS: expenses, c1: id, e6: income, aO: mortgage};
	});
var $author$project$Types$Schedule$V3$ScheduleBlock = F2(
	function (scheduleId, duration) {
		return {eN: duration, b1: scheduleId};
	});
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Types$Schedule$V3$calendarDecoder = $elm$json$Json$Decode$list(
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Types$Schedule$V3$ScheduleBlock,
		A2($elm$json$Json$Decode$field, 'scheduleId', $danyx23$elm_uuid$Uuid$decoder),
		A2($elm$json$Json$Decode$field, 'duration', $elm$json$Json$Decode$float)));
var $author$project$Types$Schedule$calendarDecoder = $author$project$Types$Schedule$V3$calendarDecoder;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Types$CalcResult$V1$const_KEY_EXPR = 'expr';
var $author$project$Types$CalcResult$V1$const_KEY_RESULT_IS_OK = 'resultIsOk';
var $author$project$Types$CalcResult$V1$const_KEY_VALUE = 'value';
var $author$project$Types$CalcResult$V1$decoder = function () {
	var decodeTuple = A3(
		$elm$json$Json$Decode$map2,
		$elm$core$Tuple$pair,
		A2($elm$json$Json$Decode$field, $author$project$Types$CalcResult$V1$const_KEY_EXPR, $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, $author$project$Types$CalcResult$V1$const_KEY_VALUE, $elm$json$Json$Decode$int));
	return A2(
		$elm$json$Json$Decode$andThen,
		function (isOk) {
			return isOk ? A2($elm$json$Json$Decode$map, $elm$core$Result$Ok, decodeTuple) : A2($elm$json$Json$Decode$map, $elm$core$Result$Err, decodeTuple);
		},
		A2($elm$json$Json$Decode$field, $author$project$Types$CalcResult$V1$const_KEY_RESULT_IS_OK, $elm$json$Json$Decode$bool));
}();
var $author$project$Types$CalcResult$decoder = $author$project$Types$CalcResult$V1$decoder;
var $elm$json$Json$Decode$map6 = _Json_map6;
var $author$project$Types$Budget$V1$MortgageParams = F4(
	function (interest, principal, lengthYears, additionalPayment) {
		return {cn: additionalPayment, ay: interest, bQ: lengthYears, aA: principal};
	});
var $author$project$Types$Budget$V1$mortgageParamsDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Types$Budget$V1$MortgageParams,
	A2($elm$json$Json$Decode$field, 'interest', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'principal', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'lengthYears', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'additionalPayment', $elm$json$Json$Decode$int));
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Types$Budget$V1$decoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Types$Budget$V1$Budget,
	A2($elm$json$Json$Decode$field, 'id', $danyx23$elm_uuid$Uuid$decoder),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'expenses',
		$elm$json$Json$Decode$keyValuePairs($author$project$Types$CalcResult$decoder)),
	A2(
		$elm$json$Json$Decode$field,
		'income',
		$elm$json$Json$Decode$keyValuePairs($author$project$Types$CalcResult$decoder)),
	A2($elm$json$Json$Decode$field, 'calendar', $author$project$Types$Schedule$calendarDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'mortgage',
		$elm$json$Json$Decode$nullable($author$project$Types$Budget$V1$mortgageParamsDecoder)));
var $author$project$Types$InvestmentStrategy$V1$InvestmentStrategy = F4(
	function (id, description, savings, other) {
		return {eI: description, c1: id, aS: other, aC: savings};
	});
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Types$InvestmentStrategy$V1$decoder = function () {
	var maxDecoder = A2($elm$json$Json$Decode$field, 'max', $elm$json$Json$Decode$float);
	var interestDecoder = A2($elm$json$Json$Decode$field, 'interest', $elm$json$Json$Decode$float);
	var allocationDecoder = A2($elm$json$Json$Decode$field, 'allocation', $elm$json$Json$Decode$float);
	return A5(
		$elm$json$Json$Decode$map4,
		$author$project$Types$InvestmentStrategy$V1$InvestmentStrategy,
		A2($elm$json$Json$Decode$field, 'id', $danyx23$elm_uuid$Uuid$decoder),
		A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
		A2(
			$elm$json$Json$Decode$field,
			'savings',
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, i, m) {
						return {a0: a, ay: i, a8: m};
					}),
				allocationDecoder,
				interestDecoder,
				maxDecoder)),
		A2(
			$elm$json$Json$Decode$field,
			'other',
			$elm$json$Json$Decode$dict(
				A3(
					$elm$json$Json$Decode$map2,
					F2(
						function (a, i) {
							return {a0: a, ay: i};
						}),
					allocationDecoder,
					interestDecoder))));
}();
var $author$project$Types$Schedule$V3$Schedule = F4(
	function (id, description, freetime, other) {
		return {eI: description, eW: freetime, c1: id, aS: other};
	});
var $author$project$Types$Schedule$V3$Allocation = F2(
	function (allocation, colorHex) {
		return {a0: allocation, cC: colorHex};
	});
var $author$project$Types$Schedule$V3$allocationDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Types$Schedule$V3$Allocation,
	A2($elm$json$Json$Decode$field, 'allocation', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'color', $elm$json$Json$Decode$string));
var $j_maas$elm_ordered_containers$OrderedDict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($j_maas$elm_ordered_containers$OrderedDict$insert, key, value, dict);
			}),
		$j_maas$elm_ordered_containers$OrderedDict$empty,
		assocs);
};
var $author$project$Types$Schedule$V3$decoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Types$Schedule$V3$Schedule,
	A2($elm$json$Json$Decode$field, 'id', $danyx23$elm_uuid$Uuid$decoder),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'freetime', $author$project$Types$Schedule$V3$allocationDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'other',
		A2(
			$elm$json$Json$Decode$map,
			$j_maas$elm_ordered_containers$OrderedDict$fromList,
			$elm$json$Json$Decode$keyValuePairs($author$project$Types$Schedule$V3$allocationDecoder))));
var $author$project$Types$Segment$V1$Segment = F4(
	function (budgetId, investmentStrategyId, description, durationYears) {
		return {ac: budgetId, eI: description, aL: durationYears, e: investmentStrategyId};
	});
var $author$project$Types$Segment$V1$decoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Types$Segment$V1$Segment,
	A2($elm$json$Json$Decode$field, 'budgetId', $danyx23$elm_uuid$Uuid$decoder),
	A2($elm$json$Json$Decode$field, 'investmentStrategyId', $danyx23$elm_uuid$Uuid$decoder),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'durationYears', $elm$json$Json$Decode$float));
var $author$project$Types$Timeline$V1$decoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Types$Timeline$V1$Timeline,
	A2($elm$json$Json$Decode$field, 'id', $danyx23$elm_uuid$Uuid$decoder),
	A2(
		$elm$json$Json$Decode$map,
		$elm$time$Time$millisToPosix,
		A2($elm$json$Json$Decode$field, 'created', $elm$json$Json$Decode$int)),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'segments',
		$elm$json$Json$Decode$list($author$project$Types$Segment$V1$decoder)));
var $author$project$Types$Board$V3$decoderBlob = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Types$Board$V3$BoardBlob,
	A2(
		$elm$json$Json$Decode$field,
		'timelines',
		$elm$json$Json$Decode$array($author$project$Types$Timeline$V1$decoder)),
	A2(
		$elm$json$Json$Decode$field,
		'budgets',
		$author$project$UuidDict$decode($author$project$Types$Budget$V1$decoder)),
	A2(
		$elm$json$Json$Decode$field,
		'schedules',
		$author$project$UuidDict$decode($author$project$Types$Schedule$V3$decoder)),
	A2(
		$elm$json$Json$Decode$field,
		'investmentStrategies',
		$author$project$UuidDict$decode($author$project$Types$InvestmentStrategy$V1$decoder)));
var $author$project$Types$Board$V3$decoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Types$Board$V3$Board,
	A2($elm$json$Json$Decode$field, 'id', $danyx23$elm_uuid$Uuid$decoder),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'blob', $author$project$Types$Board$V3$decoderBlob));
var $author$project$Types$Board$decoder = $author$project$Types$Board$V3$decoder;
var $danyx23$elm_uuid$Uuid$encode = A2($elm$core$Basics$composeR, $danyx23$elm_uuid$Uuid$toString, $elm$json$Json$Encode$string);
var $elm$core$Elm$JsArray$foldl = _JsArray_foldl;
var $elm$core$Array$foldl = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldl, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldl, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldl,
			func,
			A3($elm$core$Elm$JsArray$foldl, helper, baseCase, tree),
			tail);
	});
var $elm$json$Json$Encode$array = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$Array$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$json$Json$Encode$float = _Json_wrap;
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Types$Schedule$V3$encodeCalendar = $elm$json$Json$Encode$list(
	function (x) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'scheduleId',
					$danyx23$elm_uuid$Uuid$encode(x.b1)),
					_Utils_Tuple2(
					'duration',
					$elm$json$Json$Encode$float(x.eN))
				]));
	});
var $author$project$Types$Schedule$encodeCalendar = $author$project$Types$Schedule$V3$encodeCalendar;
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Types$Budget$V1$encodeMortgageParams = function (record) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'interest',
				$elm$json$Json$Encode$float(record.ay)),
				_Utils_Tuple2(
				'principal',
				$elm$json$Json$Encode$int(record.aA)),
				_Utils_Tuple2(
				'lengthYears',
				$elm$json$Json$Encode$int(record.bQ)),
				_Utils_Tuple2(
				'additionalPayment',
				$elm$json$Json$Encode$int(record.cn))
			]));
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Types$CalcResult$V1$encoder = function (c) {
	var toObject = function (_v2) {
		var expr = _v2.a;
		var val = _v2.b;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					$author$project$Types$CalcResult$V1$const_KEY_RESULT_IS_OK,
					$elm$json$Json$Encode$bool(
						function () {
							if (c.$ === 1) {
								return false;
							} else {
								return true;
							}
						}())),
					_Utils_Tuple2(
					$author$project$Types$CalcResult$V1$const_KEY_EXPR,
					$elm$json$Json$Encode$string(expr)),
					_Utils_Tuple2(
					$author$project$Types$CalcResult$V1$const_KEY_VALUE,
					$elm$json$Json$Encode$int(val))
				]));
	};
	if (c.$ === 1) {
		var e = c.a;
		return toObject(e);
	} else {
		var o = c.a;
		return toObject(o);
	}
};
var $author$project$Types$CalcResult$encoder = $author$project$Types$CalcResult$V1$encoder;
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $elm_community$json_extra$Json$Encode$Extra$maybe = function (encoder) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(encoder),
		$elm$core$Maybe$withDefault($elm$json$Json$Encode$null));
};
var $author$project$Types$Budget$V1$encode = function (b) {
	var descValEncode = A2(
		$elm$core$Basics$composeL,
		$elm$json$Json$Encode$object,
		$elm$core$List$map(
			$elm$core$Tuple$mapSecond($author$project$Types$CalcResult$encoder)));
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$danyx23$elm_uuid$Uuid$encode(b.c1)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(b.eI)),
				_Utils_Tuple2(
				'expenses',
				descValEncode(b.eS)),
				_Utils_Tuple2(
				'income',
				descValEncode(b.e6)),
				_Utils_Tuple2(
				'calendar',
				$author$project$Types$Schedule$encodeCalendar(b.eu)),
				_Utils_Tuple2(
				'mortgage',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $author$project$Types$Budget$V1$encodeMortgageParams, b.aO))
			]));
};
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(0),
				dictionary));
	});
var $author$project$Types$InvestmentStrategy$V1$encode = function (is) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$danyx23$elm_uuid$Uuid$encode(is.c1)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(is.eI)),
				_Utils_Tuple2(
				'savings',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'allocation',
							$elm$json$Json$Encode$float(is.aC.a0)),
							_Utils_Tuple2(
							'interest',
							$elm$json$Json$Encode$float(is.aC.ay)),
							_Utils_Tuple2(
							'max',
							$elm$json$Json$Encode$float(is.aC.a8))
						]))),
				_Utils_Tuple2(
				'other',
				A3(
					$elm$json$Json$Encode$dict,
					$elm$core$Basics$identity,
					function (v) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'allocation',
									$elm$json$Json$Encode$float(v.a0)),
									_Utils_Tuple2(
									'interest',
									$elm$json$Json$Encode$float(v.ay))
								]));
					},
					is.aS))
			]));
};
var $author$project$Types$Schedule$V3$allocationEncoder = function (v) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'allocation',
				$elm$json$Json$Encode$float(v.a0)),
				_Utils_Tuple2(
				'color',
				$elm$json$Json$Encode$string(v.cC))
			]));
};
var $j_maas$elm_ordered_containers$OrderedDict$toList = function (_v0) {
	var orderedDict = _v0;
	return A2(
		$elm$core$List$filterMap,
		function (key) {
			return A2(
				$elm$core$Maybe$map,
				function (value) {
					return _Utils_Tuple2(key, value);
				},
				A2($elm$core$Dict$get, key, orderedDict.f));
		},
		orderedDict.l);
};
var $author$project$Types$Schedule$V3$encode = function (s) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$danyx23$elm_uuid$Uuid$encode(s.c1)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(s.eI)),
				_Utils_Tuple2(
				'freetime',
				$author$project$Types$Schedule$V3$allocationEncoder(s.eW)),
				_Utils_Tuple2(
				'other',
				$elm$json$Json$Encode$object(
					A2(
						$elm$core$List$map,
						$elm$core$Tuple$mapSecond($author$project$Types$Schedule$V3$allocationEncoder),
						$j_maas$elm_ordered_containers$OrderedDict$toList(s.aS))))
			]));
};
var $author$project$Types$Segment$V1$encode = function (s) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'budgetId',
				$danyx23$elm_uuid$Uuid$encode(s.ac)),
				_Utils_Tuple2(
				'investmentStrategyId',
				$danyx23$elm_uuid$Uuid$encode(s.e)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(s.eI)),
				_Utils_Tuple2(
				'durationYears',
				$elm$json$Json$Encode$float(s.aL))
			]));
};
var $author$project$Types$Timeline$V1$encode = function (t) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$danyx23$elm_uuid$Uuid$encode(t.c1)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(t.eI)),
				_Utils_Tuple2(
				'created',
				$elm$json$Json$Encode$int(
					$elm$time$Time$posixToMillis(t.eC))),
				_Utils_Tuple2(
				'segments',
				A2($elm$json$Json$Encode$list, $author$project$Types$Segment$V1$encode, t.dZ))
			]));
};
var $author$project$UuidDict$encode = F2(
	function (mapVal, _v0) {
		var dict = _v0;
		return A3($elm$json$Json$Encode$dict, $elm$core$Basics$identity, mapVal, dict);
	});
var $author$project$Types$Board$V3$encodeBlob = function (blob) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'timelines',
				A2($elm$json$Json$Encode$array, $author$project$Types$Timeline$V1$encode, blob.g)),
				_Utils_Tuple2(
				'budgets',
				A2($author$project$UuidDict$encode, $author$project$Types$Budget$V1$encode, blob.n)),
				_Utils_Tuple2(
				'schedules',
				A2($author$project$UuidDict$encode, $author$project$Types$Schedule$V3$encode, blob.C)),
				_Utils_Tuple2(
				'investmentStrategies',
				A2($author$project$UuidDict$encode, $author$project$Types$InvestmentStrategy$V1$encode, blob.z))
			]));
};
var $author$project$Types$Board$encodeBlob = $author$project$Types$Board$V3$encodeBlob;
var $author$project$Api$postgrestSingle = A2($elm$http$Http$header, 'Accept', 'application/vnd.pgrst.object+json');
var $author$project$Api$preferReturnRep = A2($elm$http$Http$header, 'Prefer', 'return=representation');
var $author$project$Api$Board$url = function (params) {
	return '/rest/v1/Board?' + A2($elm$core$String$join, '&', params);
};
var $author$project$Api$Board$createBoard = F3(
	function (authUser, newBoard, toMsg) {
		return A2(
			$author$project$Api$request_,
			_List_fromArray(
				[
					$author$project$Api$authHeader(
					{f5: authUser.f5}),
					$author$project$Api$preferReturnRep,
					$author$project$Api$postgrestSingle
				]),
			{
				cw: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user_id',
								$elm$json$Json$Encode$string(authUser.ee)),
								_Utils_Tuple2(
								'id',
								$danyx23$elm_uuid$Uuid$encode(newBoard.c1)),
								_Utils_Tuple2(
								'blob',
								$author$project$Types$Board$encodeBlob(newBoard.bv)),
								_Utils_Tuple2(
								'description',
								$elm$json$Json$Encode$string(newBoard.eI)),
								_Utils_Tuple2(
								'version',
								$elm$json$Json$Encode$int($author$project$Api$Board$boardVersion))
							]))),
				eF: $author$project$Types$Board$decoder,
				dm: 'POST',
				f2: toMsg,
				ec: $author$project$Api$Board$url(_List_Nil)
			});
	});
var $author$project$Types$AuthUser$encode = function (u) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'access_token',
				$elm$json$Json$Encode$string(u.f5)),
				_Utils_Tuple2(
				'refresh_token',
				$elm$json$Json$Encode$string(u.fI)),
				_Utils_Tuple2(
				'user',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$string(u.ee)),
							_Utils_Tuple2(
							'email',
							$elm$json$Json$Encode$string(u.cM))
						])))
			]));
};
var $elm_community$list_extra$List$Extra$findIndexHelp = F3(
	function (index, predicate, list) {
		findIndexHelp:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					return $elm$core$Maybe$Just(index);
				} else {
					var $temp$index = index + 1,
						$temp$predicate = predicate,
						$temp$list = xs;
					index = $temp$index;
					predicate = $temp$predicate;
					list = $temp$list;
					continue findIndexHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$findIndex = $elm_community$list_extra$List$Extra$findIndexHelp(0);
var $author$project$Ports$focusSmooth = _Platform_outgoingPort('focusSmooth', $elm$json$Json$Encode$string);
var $author$project$BudgetEditor$generateItemDomId = F3(
	function (itemDescOrAmount, incomeOrExpense, _v0) {
		var budgetId = _v0.ac;
		var itemIdx = _v0.fg;
		return A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[
					'item',
					function () {
					if (!itemDescOrAmount) {
						return 'desc';
					} else {
						return 'amount';
					}
				}(),
					$danyx23$elm_uuid$Uuid$toString(budgetId),
					function () {
					if (!incomeOrExpense) {
						return 'income';
					} else {
						return 'expense';
					}
				}(),
					$elm$core$String$fromInt(itemIdx)
				]));
	});
var $author$project$ScheduleEditor$generateItemDomId = F3(
	function (key, itemDescOrAmount, _v0) {
		var scheduleId = _v0.b1;
		return A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[
					'schedule-item',
					function () {
					if (!itemDescOrAmount) {
						return 'desc';
					} else {
						return 'amount';
					}
				}(),
					$danyx23$elm_uuid$Uuid$toString(scheduleId),
					key
				]));
	});
var $author$project$App$generateTimelineDomId = function (idx) {
	return A2(
		$elm$core$String$join,
		'-',
		_List_fromArray(
			[
				'timeline',
				$elm$core$String$fromInt(idx)
			]));
};
var $author$project$UuidDict$get = F2(
	function (uuid, _v0) {
		var dict = _v0;
		return A2(
			$elm$core$Dict$get,
			$danyx23$elm_uuid$Uuid$toString(uuid),
			dict);
	});
var $author$project$Types$Board$V1$Board = F3(
	function (id, description, blob) {
		return {bv: blob, eI: description, c1: id};
	});
var $author$project$Types$Board$V1$BoardBlob = F4(
	function (timelines, budgets, schedules, investmentStrategies) {
		return {n: budgets, z: investmentStrategies, C: schedules, g: timelines};
	});
var $author$project$Types$Schedule$V1$Schedule = F4(
	function (id, description, freetime, other) {
		return {eI: description, eW: freetime, c1: id, aS: other};
	});
var $author$project$Types$Schedule$V1$decoder = function () {
	var allocationDecoder = A2(
		$elm$json$Json$Decode$map,
		function (x) {
			return {a0: x};
		},
		A2($elm$json$Json$Decode$field, 'allocation', $elm$json$Json$Decode$float));
	return A5(
		$elm$json$Json$Decode$map4,
		$author$project$Types$Schedule$V1$Schedule,
		A2($elm$json$Json$Decode$field, 'id', $danyx23$elm_uuid$Uuid$decoder),
		A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'freetime', allocationDecoder),
		A2(
			$elm$json$Json$Decode$field,
			'other',
			$elm$json$Json$Decode$dict(allocationDecoder)));
}();
var $author$project$Types$Board$V1$decoderBlob = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Types$Board$V1$BoardBlob,
	A2(
		$elm$json$Json$Decode$field,
		'timelines',
		$elm$json$Json$Decode$array($author$project$Types$Timeline$V1$decoder)),
	A2(
		$elm$json$Json$Decode$field,
		'budgets',
		$author$project$UuidDict$decode($author$project$Types$Budget$V1$decoder)),
	A2(
		$elm$json$Json$Decode$field,
		'schedules',
		$author$project$UuidDict$decode($author$project$Types$Schedule$V1$decoder)),
	A2(
		$elm$json$Json$Decode$field,
		'investmentStrategies',
		$author$project$UuidDict$decode($author$project$Types$InvestmentStrategy$V1$decoder)));
var $author$project$Types$Board$V1$decoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Types$Board$V1$Board,
	A2($elm$json$Json$Decode$field, 'id', $danyx23$elm_uuid$Uuid$decoder),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'blob', $author$project$Types$Board$V1$decoderBlob));
var $author$project$Types$Board$V2$Board = F3(
	function (id, description, blob) {
		return {bv: blob, eI: description, c1: id};
	});
var $author$project$Types$Board$V2$BoardBlob = F4(
	function (timelines, budgets, schedules, investmentStrategies) {
		return {n: budgets, z: investmentStrategies, C: schedules, g: timelines};
	});
var $author$project$Types$Schedule$V2$Schedule = F4(
	function (id, description, freetime, other) {
		return {eI: description, eW: freetime, c1: id, aS: other};
	});
var $author$project$Types$Schedule$V2$Allocation = F2(
	function (allocation, colorHex) {
		return {a0: allocation, cC: colorHex};
	});
var $author$project$Types$Schedule$V2$allocationDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Types$Schedule$V2$Allocation,
	A2($elm$json$Json$Decode$field, 'allocation', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'color', $elm$json$Json$Decode$string));
var $author$project$Types$Schedule$V2$decoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Types$Schedule$V2$Schedule,
	A2($elm$json$Json$Decode$field, 'id', $danyx23$elm_uuid$Uuid$decoder),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'freetime', $author$project$Types$Schedule$V2$allocationDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'other',
		$elm$json$Json$Decode$dict($author$project$Types$Schedule$V2$allocationDecoder)));
var $author$project$Types$Board$V2$decoderBlob = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Types$Board$V2$BoardBlob,
	A2(
		$elm$json$Json$Decode$field,
		'timelines',
		$elm$json$Json$Decode$array($author$project$Types$Timeline$V1$decoder)),
	A2(
		$elm$json$Json$Decode$field,
		'budgets',
		$author$project$UuidDict$decode($author$project$Types$Budget$V1$decoder)),
	A2(
		$elm$json$Json$Decode$field,
		'schedules',
		$author$project$UuidDict$decode($author$project$Types$Schedule$V2$decoder)),
	A2(
		$elm$json$Json$Decode$field,
		'investmentStrategies',
		$author$project$UuidDict$decode($author$project$Types$InvestmentStrategy$V1$decoder)));
var $author$project$Types$Board$V2$decoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Types$Board$V2$Board,
	A2($elm$json$Json$Decode$field, 'id', $danyx23$elm_uuid$Uuid$decoder),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'blob', $author$project$Types$Board$V2$decoderBlob));
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Types$Schedule$migrate_1_2 = function (v1) {
	var allocF = function (a) {
		return function (alloc) {
			return _Utils_update(
				alloc,
				{a0: a.a0});
		}($author$project$Types$Schedule$initAllocation);
	};
	return {
		eI: v1.eI,
		eW: allocF(v1.eW),
		c1: v1.c1,
		aS: A2(
			$elm$core$Dict$map,
			function (key) {
				return allocF;
			},
			v1.aS)
	};
};
var $author$project$Types$Board$migrate_1_2 = function (b) {
	return {
		bv: {
			n: b.bv.n,
			z: b.bv.z,
			C: A2(
				$author$project$UuidDict$map,
				F2(
					function (_v0, v) {
						return $author$project$Types$Schedule$migrate_1_2(v);
					}),
				b.bv.C),
			g: b.bv.g
		},
		eI: b.eI,
		c1: b.c1
	};
};
var $author$project$Types$Schedule$migrate_2_3 = function (v2) {
	return {
		eI: v2.eI,
		eW: v2.eW,
		c1: v2.c1,
		aS: $j_maas$elm_ordered_containers$OrderedDict$fromList(
			$elm$core$Dict$toList(v2.aS))
	};
};
var $author$project$Types$Board$migrate_2_3 = function (b) {
	return {
		bv: {
			n: b.bv.n,
			z: b.bv.z,
			C: A2(
				$author$project$UuidDict$map,
				F2(
					function (_v0, v) {
						return $author$project$Types$Schedule$migrate_2_3(v);
					}),
				b.bv.C),
			g: b.bv.g
		},
		eI: b.eI,
		c1: b.c1
	};
};
var $author$project$Api$Board$boardDecodeAndMigrate = function (version) {
	switch (version) {
		case 1:
			return A2(
				$elm$json$Json$Decode$map,
				A2($elm$core$Basics$composeR, $author$project$Types$Board$migrate_1_2, $author$project$Types$Board$migrate_2_3),
				$author$project$Types$Board$V1$decoder);
		case 2:
			return A2($elm$json$Json$Decode$map, $author$project$Types$Board$migrate_2_3, $author$project$Types$Board$V2$decoder);
		case 3:
			return $author$project$Types$Board$V3$decoder;
		default:
			return $elm$json$Json$Decode$fail(
				'Trying to decode board, but version ' + ($elm$core$String$fromInt(version) + ' is not supported.'));
	}
};
var $author$project$Api$Board$const_VERSION_KEY = 'version';
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $author$project$Api$Board$getLatestBoard = F2(
	function (authUser, toMsg) {
		return A2(
			$author$project$Api$request_,
			_List_fromArray(
				[
					$author$project$Api$authHeader(
					{f5: authUser.f5}),
					$author$project$Api$postgrestSingle
				]),
			{
				cw: $elm$http$Http$emptyBody,
				eF: A2(
					$elm$json$Json$Decode$andThen,
					$author$project$Api$Board$boardDecodeAndMigrate,
					A2($elm$json$Json$Decode$field, $author$project$Api$Board$const_VERSION_KEY, $elm$json$Json$Decode$int)),
				dm: 'GET',
				f2: toMsg,
				ec: $author$project$Api$Board$url(
					_List_fromArray(
						['select=*', 'user_id=eq.' + authUser.ee, 'order=updated_at.desc', 'limit=1']))
			});
	});
var $author$project$UuidDict$insert = F3(
	function (uuid, value, _v0) {
		var dict = _v0;
		return A3(
			$elm$core$Dict$insert,
			$danyx23$elm_uuid$Uuid$toString(uuid),
			value,
			dict);
	});
var $elm$core$Elm$JsArray$appendN = _JsArray_appendN;
var $elm$core$Elm$JsArray$slice = _JsArray_slice;
var $elm$core$Array$appendHelpBuilder = F2(
	function (tail, builder) {
		var tailLen = $elm$core$Elm$JsArray$length(tail);
		var notAppended = ($elm$core$Array$branchFactor - $elm$core$Elm$JsArray$length(builder.j)) - tailLen;
		var appended = A3($elm$core$Elm$JsArray$appendN, $elm$core$Array$branchFactor, builder.j, tail);
		return (notAppended < 0) ? {
			k: A2(
				$elm$core$List$cons,
				$elm$core$Array$Leaf(appended),
				builder.k),
			h: builder.h + 1,
			j: A3($elm$core$Elm$JsArray$slice, notAppended, tailLen, tail)
		} : ((!notAppended) ? {
			k: A2(
				$elm$core$List$cons,
				$elm$core$Array$Leaf(appended),
				builder.k),
			h: builder.h + 1,
			j: $elm$core$Elm$JsArray$empty
		} : {k: builder.k, h: builder.h, j: appended});
	});
var $elm$core$Elm$JsArray$push = _JsArray_push;
var $elm$core$Elm$JsArray$singleton = _JsArray_singleton;
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$insertTailInTree = F4(
	function (shift, index, tail, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		if (_Utils_cmp(
			pos,
			$elm$core$Elm$JsArray$length(tree)) > -1) {
			if (shift === 5) {
				return A2(
					$elm$core$Elm$JsArray$push,
					$elm$core$Array$Leaf(tail),
					tree);
			} else {
				var newSub = $elm$core$Array$SubTree(
					A4($elm$core$Array$insertTailInTree, shift - $elm$core$Array$shiftStep, index, tail, $elm$core$Elm$JsArray$empty));
				return A2($elm$core$Elm$JsArray$push, newSub, tree);
			}
		} else {
			var value = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!value.$) {
				var subTree = value.a;
				var newSub = $elm$core$Array$SubTree(
					A4($elm$core$Array$insertTailInTree, shift - $elm$core$Array$shiftStep, index, tail, subTree));
				return A3($elm$core$Elm$JsArray$unsafeSet, pos, newSub, tree);
			} else {
				var newSub = $elm$core$Array$SubTree(
					A4(
						$elm$core$Array$insertTailInTree,
						shift - $elm$core$Array$shiftStep,
						index,
						tail,
						$elm$core$Elm$JsArray$singleton(value)));
				return A3($elm$core$Elm$JsArray$unsafeSet, pos, newSub, tree);
			}
		}
	});
var $elm$core$Array$unsafeReplaceTail = F2(
	function (newTail, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		var originalTailLen = $elm$core$Elm$JsArray$length(tail);
		var newTailLen = $elm$core$Elm$JsArray$length(newTail);
		var newArrayLen = len + (newTailLen - originalTailLen);
		if (_Utils_eq(newTailLen, $elm$core$Array$branchFactor)) {
			var overflow = _Utils_cmp(newArrayLen >>> $elm$core$Array$shiftStep, 1 << startShift) > 0;
			if (overflow) {
				var newShift = startShift + $elm$core$Array$shiftStep;
				var newTree = A4(
					$elm$core$Array$insertTailInTree,
					newShift,
					len,
					newTail,
					$elm$core$Elm$JsArray$singleton(
						$elm$core$Array$SubTree(tree)));
				return A4($elm$core$Array$Array_elm_builtin, newArrayLen, newShift, newTree, $elm$core$Elm$JsArray$empty);
			} else {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					newArrayLen,
					startShift,
					A4($elm$core$Array$insertTailInTree, startShift, len, newTail, tree),
					$elm$core$Elm$JsArray$empty);
			}
		} else {
			return A4($elm$core$Array$Array_elm_builtin, newArrayLen, startShift, tree, newTail);
		}
	});
var $elm$core$Array$appendHelpTree = F2(
	function (toAppend, array) {
		var len = array.a;
		var tree = array.c;
		var tail = array.d;
		var itemsToAppend = $elm$core$Elm$JsArray$length(toAppend);
		var notAppended = ($elm$core$Array$branchFactor - $elm$core$Elm$JsArray$length(tail)) - itemsToAppend;
		var appended = A3($elm$core$Elm$JsArray$appendN, $elm$core$Array$branchFactor, tail, toAppend);
		var newArray = A2($elm$core$Array$unsafeReplaceTail, appended, array);
		if (notAppended < 0) {
			var nextTail = A3($elm$core$Elm$JsArray$slice, notAppended, itemsToAppend, toAppend);
			return A2($elm$core$Array$unsafeReplaceTail, nextTail, newArray);
		} else {
			return newArray;
		}
	});
var $elm$core$Array$builderFromArray = function (_v0) {
	var len = _v0.a;
	var tree = _v0.c;
	var tail = _v0.d;
	var helper = F2(
		function (node, acc) {
			if (!node.$) {
				var subTree = node.a;
				return A3($elm$core$Elm$JsArray$foldl, helper, acc, subTree);
			} else {
				return A2($elm$core$List$cons, node, acc);
			}
		});
	return {
		k: A3($elm$core$Elm$JsArray$foldl, helper, _List_Nil, tree),
		h: (len / $elm$core$Array$branchFactor) | 0,
		j: tail
	};
};
var $elm$core$Array$append = F2(
	function (a, _v0) {
		var aTail = a.d;
		var bLen = _v0.a;
		var bTree = _v0.c;
		var bTail = _v0.d;
		if (_Utils_cmp(bLen, $elm$core$Array$branchFactor * 4) < 1) {
			var foldHelper = F2(
				function (node, array) {
					if (!node.$) {
						var tree = node.a;
						return A3($elm$core$Elm$JsArray$foldl, foldHelper, array, tree);
					} else {
						var leaf = node.a;
						return A2($elm$core$Array$appendHelpTree, leaf, array);
					}
				});
			return A2(
				$elm$core$Array$appendHelpTree,
				bTail,
				A3($elm$core$Elm$JsArray$foldl, foldHelper, a, bTree));
		} else {
			var foldHelper = F2(
				function (node, builder) {
					if (!node.$) {
						var tree = node.a;
						return A3($elm$core$Elm$JsArray$foldl, foldHelper, builder, tree);
					} else {
						var leaf = node.a;
						return A2($elm$core$Array$appendHelpBuilder, leaf, builder);
					}
				});
			return A2(
				$elm$core$Array$builderToArray,
				true,
				A2(
					$elm$core$Array$appendHelpBuilder,
					bTail,
					A3(
						$elm$core$Elm$JsArray$foldl,
						foldHelper,
						$elm$core$Array$builderFromArray(a),
						bTree)));
		}
	});
var $elm$core$Array$length = function (_v0) {
	var len = _v0.a;
	return len;
};
var $elm$core$Array$push = F2(
	function (a, array) {
		var tail = array.d;
		return A2(
			$elm$core$Array$unsafeReplaceTail,
			A2($elm$core$Elm$JsArray$push, a, tail),
			array);
	});
var $elm$core$Array$sliceLeft = F2(
	function (from, array) {
		var len = array.a;
		var tree = array.c;
		var tail = array.d;
		if (!from) {
			return array;
		} else {
			if (_Utils_cmp(
				from,
				$elm$core$Array$tailIndex(len)) > -1) {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					len - from,
					$elm$core$Array$shiftStep,
					$elm$core$Elm$JsArray$empty,
					A3(
						$elm$core$Elm$JsArray$slice,
						from - $elm$core$Array$tailIndex(len),
						$elm$core$Elm$JsArray$length(tail),
						tail));
			} else {
				var skipNodes = (from / $elm$core$Array$branchFactor) | 0;
				var helper = F2(
					function (node, acc) {
						if (!node.$) {
							var subTree = node.a;
							return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
						} else {
							var leaf = node.a;
							return A2($elm$core$List$cons, leaf, acc);
						}
					});
				var leafNodes = A3(
					$elm$core$Elm$JsArray$foldr,
					helper,
					_List_fromArray(
						[tail]),
					tree);
				var nodesToInsert = A2($elm$core$List$drop, skipNodes, leafNodes);
				if (!nodesToInsert.b) {
					return $elm$core$Array$empty;
				} else {
					var head = nodesToInsert.a;
					var rest = nodesToInsert.b;
					var firstSlice = from - (skipNodes * $elm$core$Array$branchFactor);
					var initialBuilder = {
						k: _List_Nil,
						h: 0,
						j: A3(
							$elm$core$Elm$JsArray$slice,
							firstSlice,
							$elm$core$Elm$JsArray$length(head),
							head)
					};
					return A2(
						$elm$core$Array$builderToArray,
						true,
						A3($elm$core$List$foldl, $elm$core$Array$appendHelpBuilder, initialBuilder, rest));
				}
			}
		}
	});
var $elm$core$Array$fetchNewTail = F4(
	function (shift, end, treeEnd, tree) {
		fetchNewTail:
		while (true) {
			var pos = $elm$core$Array$bitMask & (treeEnd >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var sub = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$end = end,
					$temp$treeEnd = treeEnd,
					$temp$tree = sub;
				shift = $temp$shift;
				end = $temp$end;
				treeEnd = $temp$treeEnd;
				tree = $temp$tree;
				continue fetchNewTail;
			} else {
				var values = _v0.a;
				return A3($elm$core$Elm$JsArray$slice, 0, $elm$core$Array$bitMask & end, values);
			}
		}
	});
var $elm$core$Array$hoistTree = F3(
	function (oldShift, newShift, tree) {
		hoistTree:
		while (true) {
			if ((_Utils_cmp(oldShift, newShift) < 1) || (!$elm$core$Elm$JsArray$length(tree))) {
				return tree;
			} else {
				var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, 0, tree);
				if (!_v0.$) {
					var sub = _v0.a;
					var $temp$oldShift = oldShift - $elm$core$Array$shiftStep,
						$temp$newShift = newShift,
						$temp$tree = sub;
					oldShift = $temp$oldShift;
					newShift = $temp$newShift;
					tree = $temp$tree;
					continue hoistTree;
				} else {
					return tree;
				}
			}
		}
	});
var $elm$core$Array$sliceTree = F3(
	function (shift, endIdx, tree) {
		var lastPos = $elm$core$Array$bitMask & (endIdx >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, lastPos, tree);
		if (!_v0.$) {
			var sub = _v0.a;
			var newSub = A3($elm$core$Array$sliceTree, shift - $elm$core$Array$shiftStep, endIdx, sub);
			return (!$elm$core$Elm$JsArray$length(newSub)) ? A3($elm$core$Elm$JsArray$slice, 0, lastPos, tree) : A3(
				$elm$core$Elm$JsArray$unsafeSet,
				lastPos,
				$elm$core$Array$SubTree(newSub),
				A3($elm$core$Elm$JsArray$slice, 0, lastPos + 1, tree));
		} else {
			return A3($elm$core$Elm$JsArray$slice, 0, lastPos, tree);
		}
	});
var $elm$core$Array$sliceRight = F2(
	function (end, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		if (_Utils_eq(end, len)) {
			return array;
		} else {
			if (_Utils_cmp(
				end,
				$elm$core$Array$tailIndex(len)) > -1) {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					end,
					startShift,
					tree,
					A3($elm$core$Elm$JsArray$slice, 0, $elm$core$Array$bitMask & end, tail));
			} else {
				var endIdx = $elm$core$Array$tailIndex(end);
				var depth = $elm$core$Basics$floor(
					A2(
						$elm$core$Basics$logBase,
						$elm$core$Array$branchFactor,
						A2($elm$core$Basics$max, 1, endIdx - 1)));
				var newShift = A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep);
				return A4(
					$elm$core$Array$Array_elm_builtin,
					end,
					newShift,
					A3(
						$elm$core$Array$hoistTree,
						startShift,
						newShift,
						A3($elm$core$Array$sliceTree, startShift, endIdx, tree)),
					A4($elm$core$Array$fetchNewTail, startShift, end, endIdx, tree));
			}
		}
	});
var $elm$core$Array$translateIndex = F2(
	function (index, _v0) {
		var len = _v0.a;
		var posIndex = (index < 0) ? (len + index) : index;
		return (posIndex < 0) ? 0 : ((_Utils_cmp(posIndex, len) > 0) ? len : posIndex);
	});
var $elm$core$Array$slice = F3(
	function (from, to, array) {
		var correctTo = A2($elm$core$Array$translateIndex, to, array);
		var correctFrom = A2($elm$core$Array$translateIndex, from, array);
		return (_Utils_cmp(correctFrom, correctTo) > 0) ? $elm$core$Array$empty : A2(
			$elm$core$Array$sliceLeft,
			correctFrom,
			A2($elm$core$Array$sliceRight, correctTo, array));
	});
var $elm_community$array_extra$Array$Extra$insertAt = F3(
	function (index, val, values) {
		var length = $elm$core$Array$length(values);
		if ((index >= 0) && (_Utils_cmp(index, length) < 1)) {
			var before = A3($elm$core$Array$slice, 0, index, values);
			var after = A3($elm$core$Array$slice, index, length, values);
			return A2(
				$elm$core$Array$append,
				A2($elm$core$Array$push, val, before),
				after);
		} else {
			return values;
		}
	});
var $author$project$App$modelToBoardBlob = function (m) {
	return {
		n: A2(
			$author$project$UuidDict$map,
			F2(
				function (k, v) {
					return v.a;
				}),
			m.n),
		z: m.z,
		C: m.C,
		g: A2($elm$core$Array$map, $elm$core$Tuple$first, m.g)
	};
};
var $elm_community$maybe_extra$Maybe$Extra$or = F2(
	function (ma, mb) {
		if (ma.$ === 1) {
			return mb;
		} else {
			return ma;
		}
	});
var $elm_community$array_extra$Array$Extra$splitAt = F2(
	function (index, xs) {
		var len = $elm$core$Array$length(xs);
		var _v0 = _Utils_Tuple2(
			index > 0,
			_Utils_cmp(index, len) < 0);
		if (_v0.a) {
			if (_v0.b) {
				return _Utils_Tuple2(
					A3($elm$core$Array$slice, 0, index, xs),
					A3($elm$core$Array$slice, index, len, xs));
			} else {
				return _Utils_Tuple2(xs, $elm$core$Array$empty);
			}
		} else {
			if (_v0.b) {
				return _Utils_Tuple2($elm$core$Array$empty, xs);
			} else {
				return _Utils_Tuple2($elm$core$Array$empty, $elm$core$Array$empty);
			}
		}
	});
var $elm_community$array_extra$Array$Extra$removeAt = F2(
	function (index, xs) {
		var _v0 = A2($elm_community$array_extra$Array$Extra$splitAt, index, xs);
		var xs0 = _v0.a;
		var xs1 = _v0.b;
		var len1 = $elm$core$Array$length(xs1);
		return (!len1) ? xs0 : A2(
			$elm$core$Array$append,
			xs0,
			A3($elm$core$Array$slice, 1, len1, xs1));
	});
var $author$project$Ports$saveAuthUserToLocalStorage = _Platform_outgoingPort('saveAuthUserToLocalStorage', $elm$core$Basics$identity);
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $elm$core$String$toFloat = _String_toFloat;
var $author$project$Auth$GotAuthUser = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $author$project$Api$Auth$encodeEmailPass = function (_v0) {
	var email = _v0.cM;
	var password = _v0.dC;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(email)),
				_Utils_Tuple2(
				'password',
				$elm$json$Json$Encode$string(password))
			]));
};
var $author$project$Api$Auth$login = F2(
	function (signInBody, toMsg) {
		return $author$project$Api$requestUnAuth(
			{
				cw: $elm$http$Http$jsonBody(
					$author$project$Api$Auth$encodeEmailPass(signInBody)),
				eF: $author$project$Types$AuthUser$decoder,
				dm: 'POST',
				f2: toMsg,
				ec: '/auth/v1/token?grant_type=password'
			});
	});
var $author$project$Api$Auth$register = F2(
	function (signInBody, toMsg) {
		return $author$project$Api$requestUnAuth(
			{
				cw: $elm$http$Http$jsonBody(
					$author$project$Api$Auth$encodeEmailPass(signInBody)),
				eF: $author$project$Types$AuthUser$decoder,
				dm: 'POST',
				f2: toMsg,
				ec: '/auth/v1/signup'
			});
	});
var $author$project$Auth$update = F3(
	function (h, msg, model) {
		switch (msg.$) {
			case 0:
				var email = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ao: email}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var password = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ap: password}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var email = msg.a.cM;
				var password = msg.a.dC;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$Api$Auth$login,
						{cM: model.ao, dC: model.ap},
						A2($elm$core$Basics$composeR, $author$project$Auth$GotAuthUser, h.f2)));
			case 3:
				var res = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bA: $krisajenkins$remotedata$RemoteData$fromResult(res)
						}),
					function () {
						if (!res.$) {
							var authUser = res.a;
							return $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Ports$saveAuthUserToLocalStorage(
										$author$project$Types$AuthUser$encode(authUser)),
										function () {
										var _v2 = model.aq;
										if (!_v2.$) {
											return $author$project$Util$msgToCmd(
												h.fw(authUser));
										} else {
											return $author$project$Util$msgToCmd(
												h.fv(authUser));
										}
									}()
									]));
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 4:
				var isRegistering = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aq: isRegistering ? $elm$core$Maybe$Just(
								{fa: ''}) : $elm$core$Maybe$Nothing
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var body = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$Api$Auth$register,
						body,
						A2($elm$core$Basics$composeR, $author$project$Auth$GotAuthUser, h.f2)));
			default:
				var email = msg.a.cM;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$BudgetEditor$AddNewBudgetUuidGenerated = function (a) {
	return {$: 5, a: a};
};
var $author$project$BudgetEditor$CloneBudgetUuidGenerated = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$BudgetEditor$Desc = 0;
var $author$project$BudgetEditor$generateBudgetDescDomId = function (_v0) {
	var budgetId = _v0.ac;
	return A2(
		$elm$core$String$join,
		'-',
		_List_fromArray(
			[
				'budget',
				'desc',
				$danyx23$elm_uuid$Uuid$toString(budgetId)
			]));
};
var $author$project$Types$Budget$V1$initMortgage = {cn: 0, ay: 5, bQ: 30, aA: 500000};
var $author$project$Types$Budget$initMortgage = $author$project$Types$Budget$V1$initMortgage;
var $author$project$UuidDict$remove = F2(
	function (uuid, _v0) {
		var dict = _v0;
		return A2(
			$elm$core$Dict$remove,
			$danyx23$elm_uuid$Uuid$toString(uuid),
			dict);
	});
var $elm_community$list_extra$List$Extra$removeAt = F2(
	function (index, l) {
		if (index < 0) {
			return l;
		} else {
			var _v0 = A2($elm$core$List$drop, index, l);
			if (!_v0.b) {
				return l;
			} else {
				var rest = _v0.b;
				return _Utils_ap(
					A2($elm$core$List$take, index, l),
					rest);
			}
		}
	});
var $elm_community$list_extra$List$Extra$updateAt = F3(
	function (index, fn, list) {
		if (index < 0) {
			return list;
		} else {
			var tail = A2($elm$core$List$drop, index, list);
			var head = A2($elm$core$List$take, index, list);
			if (tail.b) {
				var x = tail.a;
				var xs = tail.b;
				return _Utils_ap(
					head,
					A2(
						$elm$core$List$cons,
						fn(x),
						xs));
			} else {
				return list;
			}
		}
	});
var $elm_community$list_extra$List$Extra$setAt = F2(
	function (index, value) {
		return A2(
			$elm_community$list_extra$List$Extra$updateAt,
			index,
			$elm$core$Basics$always(value));
	});
var $author$project$BudgetEditor$update = F3(
	function (msg, budgets, defaultCalendar) {
		switch (msg.$) {
			case 0:
				var incomeOrExpense = msg.a;
				var budgetId = msg.b.ac;
				var itemIdx = msg.b.fg;
				var item = msg.c;
				return _Utils_Tuple2(
					function () {
						var _v1 = A2($author$project$UuidDict$get, budgetId, budgets);
						if (!_v1.$) {
							var budget = _v1.a;
							return A3(
								$author$project$UuidDict$insert,
								budgetId,
								function () {
									if (!incomeOrExpense) {
										return _Utils_update(
											budget,
											{
												e6: A3($elm_community$list_extra$List$Extra$setAt, itemIdx, item, budget.e6)
											});
									} else {
										return _Utils_update(
											budget,
											{
												eS: A3($elm_community$list_extra$List$Extra$setAt, itemIdx, item, budget.eS)
											});
									}
								}(),
								budgets);
						} else {
							return budgets;
						}
					}(),
					$elm$core$Platform$Cmd$none);
			case 1:
				var incomeOrExpense = msg.a;
				var budgetId = msg.b.ac;
				var _v3 = A2($author$project$UuidDict$get, budgetId, budgets);
				if (!_v3.$) {
					var budget = _v3.a;
					return _Utils_Tuple2(
						A3(
							$author$project$UuidDict$insert,
							budgetId,
							function () {
								if (!incomeOrExpense) {
									return _Utils_update(
										budget,
										{
											e6: _Utils_ap(
												budget.e6,
												_List_fromArray(
													[
														_Utils_Tuple2(
														'New income',
														$elm$core$Result$Ok(
															_Utils_Tuple2('0', 0)))
													]))
										});
								} else {
									return _Utils_update(
										budget,
										{
											eS: _Utils_ap(
												budget.eS,
												_List_fromArray(
													[
														_Utils_Tuple2(
														'New expense',
														$elm$core$Result$Ok(
															_Utils_Tuple2('0', 0)))
													]))
										});
								}
							}(),
							budgets),
						$author$project$Ports$focusSmooth(
							A3(
								$author$project$BudgetEditor$generateItemDomId,
								0,
								incomeOrExpense,
								{
									ac: budgetId,
									fg: $elm$core$List$length(
										function () {
											if (!incomeOrExpense) {
												return function ($) {
													return $.e6;
												};
											} else {
												return function ($) {
													return $.eS;
												};
											}
										}()(budget))
								})));
				} else {
					return _Utils_Tuple2(budgets, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var incomeOrExpense = msg.a;
				var budgetId = msg.b.ac;
				var itemIdx = msg.b.fg;
				return _Utils_Tuple2(
					function () {
						var _v6 = A2($author$project$UuidDict$get, budgetId, budgets);
						if (!_v6.$) {
							var budget = _v6.a;
							return A3(
								$author$project$UuidDict$insert,
								budgetId,
								function () {
									if (!incomeOrExpense) {
										return _Utils_update(
											budget,
											{
												e6: A2($elm_community$list_extra$List$Extra$removeAt, itemIdx, budget.e6)
											});
									} else {
										return _Utils_update(
											budget,
											{
												eS: A2($elm_community$list_extra$List$Extra$removeAt, itemIdx, budget.eS)
											});
									}
								}(),
								budgets);
						} else {
							return budgets;
						}
					}(),
					$elm$core$Platform$Cmd$none);
			case 3:
				var budgetId = msg.a.ac;
				var description = msg.b;
				return _Utils_Tuple2(
					function () {
						var _v8 = A2($author$project$UuidDict$get, budgetId, budgets);
						if (!_v8.$) {
							var budget = _v8.a;
							return A3(
								$author$project$UuidDict$insert,
								budgetId,
								_Utils_update(
									budget,
									{eI: description}),
								budgets);
						} else {
							return budgets;
						}
					}(),
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					budgets,
					A2($elm$random$Random$generate, $author$project$BudgetEditor$AddNewBudgetUuidGenerated, $danyx23$elm_uuid$Uuid$uuidGenerator));
			case 5:
				var uuid = msg.a;
				return _Utils_Tuple2(
					A3(
						$author$project$UuidDict$insert,
						uuid,
						A3($author$project$Types$Budget$init, uuid, defaultCalendar, 'New Budget'),
						budgets),
					$author$project$Ports$focusSmooth(
						$author$project$BudgetEditor$generateBudgetDescDomId(
							{ac: uuid})));
			case 6:
				var budgetToClone = msg.a;
				return _Utils_Tuple2(
					budgets,
					A2(
						$elm$random$Random$generate,
						$author$project$BudgetEditor$CloneBudgetUuidGenerated(budgetToClone),
						$danyx23$elm_uuid$Uuid$uuidGenerator));
			case 7:
				var budgetToClone = msg.a;
				var uuid = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$UuidDict$insert,
						uuid,
						_Utils_update(
							budgetToClone,
							{eI: budgetToClone.eI + ' (copy)', c1: uuid}),
						budgets),
					$author$project$Ports$focusSmooth(
						$author$project$BudgetEditor$generateBudgetDescDomId(
							{ac: uuid})));
			case 8:
				var budgetId = msg.a.ac;
				return _Utils_Tuple2(
					A2($author$project$UuidDict$remove, budgetId, budgets),
					$elm$core$Platform$Cmd$none);
			case 9:
				var budgetId = msg.a.ac;
				var _v9 = A2($author$project$UuidDict$get, budgetId, budgets);
				if (!_v9.$) {
					var is = _v9.a;
					return _Utils_Tuple2(
						A3(
							$author$project$UuidDict$insert,
							budgetId,
							_Utils_update(
								is,
								{
									aO: $elm$core$Maybe$Just($author$project$Types$Budget$initMortgage)
								}),
							budgets),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(budgets, $elm$core$Platform$Cmd$none);
				}
			case 10:
				var budgetId = msg.a.ac;
				var newMortgage = msg.b;
				var _v10 = A2($author$project$UuidDict$get, budgetId, budgets);
				if (!_v10.$) {
					var is = _v10.a;
					return _Utils_Tuple2(
						A3(
							$author$project$UuidDict$insert,
							budgetId,
							_Utils_update(
								is,
								{
									aO: $elm$core$Maybe$Just(newMortgage)
								}),
							budgets),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(budgets, $elm$core$Platform$Cmd$none);
				}
			default:
				var budgetId = msg.a.ac;
				var _v11 = A2($author$project$UuidDict$get, budgetId, budgets);
				if (!_v11.$) {
					var b = _v11.a;
					return _Utils_Tuple2(
						A3(
							$author$project$UuidDict$insert,
							budgetId,
							_Utils_update(
								b,
								{aO: $elm$core$Maybe$Nothing}),
							budgets),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(budgets, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $Gizra$elm_debouncer$Debouncer$Internal$Check = function (a) {
	return {$: 4, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Internal$InputProvidedAt = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $Gizra$elm_debouncer$Debouncer$Internal$ManualEmitAt = function (a) {
	return {$: 3, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Basic$MsgInternal = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Process$sleep = _Process_sleep;
var $Gizra$elm_debouncer$Debouncer$Internal$Unsettled = function (a) {
	return {$: 1, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Internal$cancel = function (_v0) {
	var config = _v0.a;
	var state = _v0.b;
	return A2($Gizra$elm_debouncer$Debouncer$Internal$Debouncer, config, $Gizra$elm_debouncer$Debouncer$Internal$Settled);
};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$Set$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$Set$insert, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$unique = function (list) {
	return A4($elm_community$list_extra$List$Extra$uniqueHelp, $elm$core$Basics$identity, $elm$core$Set$empty, list, _List_Nil);
};
var $Gizra$elm_debouncer$Debouncer$Internal$update = F2(
	function (msg, debouncer) {
		var wrappedConfig = debouncer.a;
		var config = wrappedConfig;
		var state = debouncer.b;
		switch (msg.$) {
			case 0:
				var input = msg.a;
				var time = msg.b;
				var newState = function () {
					if (!state.$) {
						return $Gizra$elm_debouncer$Debouncer$Internal$Unsettled(
							{
								az: $elm$core$Maybe$Nothing,
								a7: time,
								H: A2(config.ah, input, $elm$core$Maybe$Nothing),
								bp: time
							});
					} else {
						var unsettled = state.a;
						return $Gizra$elm_debouncer$Debouncer$Internal$Unsettled(
							_Utils_update(
								unsettled,
								{
									a7: time,
									H: A2(config.ah, input, unsettled.H)
								}));
					}
				}();
				var newDebouncer = A2($Gizra$elm_debouncer$Debouncer$Internal$Debouncer, wrappedConfig, newState);
				var checks = function () {
					if (!state.$) {
						return $elm_community$list_extra$List$Extra$unique(
							A2(
								$elm$core$List$filterMap,
								$elm$core$Basics$identity,
								_List_fromArray(
									[config.L, config.M, config.R])));
					} else {
						return A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							_List_fromArray(
								[config.R]));
					}
				}();
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$List$isEmpty),
					A2(
						$elm$core$List$partition,
						function (interval) {
							return interval <= 0;
						},
						checks));
				var checkNow = _v1.a;
				var checkLater = _v1.b;
				var _v2 = checkNow ? A2(
					$Gizra$elm_debouncer$Debouncer$Internal$update,
					$Gizra$elm_debouncer$Debouncer$Internal$Check(time),
					newDebouncer) : _Utils_Tuple3(newDebouncer, _List_Nil, $elm$core$Maybe$Nothing);
				var checkedDebouncer = _v2.a;
				var checkedIntervals = _v2.b;
				var emit = _v2.c;
				return _Utils_Tuple3(
					checkedDebouncer,
					_Utils_ap(checkedIntervals, checkLater),
					emit);
			case 1:
				return _Utils_Tuple3(
					$Gizra$elm_debouncer$Debouncer$Internal$cancel(debouncer),
					_List_Nil,
					$elm$core$Maybe$Nothing);
			case 2:
				var emit = function () {
					if (!state.$) {
						return $elm$core$Maybe$Nothing;
					} else {
						var unsettled = state.a;
						return unsettled.H;
					}
				}();
				return _Utils_Tuple3(
					$Gizra$elm_debouncer$Debouncer$Internal$cancel(debouncer),
					_List_Nil,
					emit);
			case 3:
				var time = msg.a;
				if (!state.$) {
					return _Utils_Tuple3(debouncer, _List_Nil, $elm$core$Maybe$Nothing);
				} else {
					var unsettled = state.a;
					var _v7 = unsettled.H;
					if (!_v7.$) {
						var newState = $Gizra$elm_debouncer$Debouncer$Internal$Unsettled(
							_Utils_update(
								unsettled,
								{
									az: $elm$core$Maybe$Just(time),
									H: $elm$core$Maybe$Nothing
								}));
						var intervals = function () {
							var _v8 = config.L;
							if (!_v8.$) {
								var emit = _v8.a;
								return _List_fromArray(
									[emit]);
							} else {
								return _List_Nil;
							}
						}();
						return _Utils_Tuple3(
							A2($Gizra$elm_debouncer$Debouncer$Internal$Debouncer, wrappedConfig, newState),
							intervals,
							unsettled.H);
					} else {
						return _Utils_Tuple3(debouncer, _List_Nil, $elm$core$Maybe$Nothing);
					}
				}
			default:
				var time = msg.a;
				if (!state.$) {
					return _Utils_Tuple3(debouncer, _List_Nil, $elm$core$Maybe$Nothing);
				} else {
					var unsettled = state.a;
					var shouldSettle = A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							function (interval) {
								return _Utils_cmp(unsettled.a7 + interval, time) < 1;
							},
							config.R));
					var becauseEmitWhileUnsettled = function () {
						var _v13 = config.M;
						if (!_v13.$) {
							var interval = _v13.a;
							var _v14 = unsettled.az;
							if (!_v14.$) {
								var lastEmittedAt = _v14.a;
								return _Utils_cmp(lastEmittedAt + interval, time) < 1;
							} else {
								return _Utils_cmp(unsettled.bp + interval, time) < 1;
							}
						} else {
							return false;
						}
					}();
					var becauseEmitWhenUnsettled = function () {
						var _v11 = config.L;
						if (!_v11.$) {
							var interval = _v11.a;
							var _v12 = unsettled.az;
							if (!_v12.$) {
								return false;
							} else {
								return _Utils_cmp(unsettled.bp + interval, time) < 1;
							}
						} else {
							return false;
						}
					}();
					var shouldEmit = (!_Utils_eq(unsettled.H, $elm$core$Maybe$Nothing)) && (shouldSettle || (becauseEmitWhenUnsettled || becauseEmitWhileUnsettled));
					var emit = shouldEmit ? unsettled.H : $elm$core$Maybe$Nothing;
					var intervals = function () {
						if (shouldEmit && (!shouldSettle)) {
							var _v10 = config.M;
							if (!_v10.$) {
								var interval = _v10.a;
								return _List_fromArray(
									[interval]);
							} else {
								return _List_Nil;
							}
						} else {
							return _List_Nil;
						}
					}();
					var newState = shouldSettle ? $Gizra$elm_debouncer$Debouncer$Internal$Settled : (shouldEmit ? $Gizra$elm_debouncer$Debouncer$Internal$Unsettled(
						_Utils_update(
							unsettled,
							{
								az: $elm$core$Maybe$Just(time),
								H: $elm$core$Maybe$Nothing
							})) : state);
					return _Utils_Tuple3(
						A2($Gizra$elm_debouncer$Debouncer$Internal$Debouncer, wrappedConfig, newState),
						intervals,
						emit);
				}
		}
	});
var $Gizra$elm_debouncer$Debouncer$Basic$update = F2(
	function (msg, debouncer) {
		switch (msg.$) {
			case 0:
				var input = msg.a;
				return _Utils_Tuple3(
					debouncer,
					A2(
						$elm$core$Task$perform,
						A2(
							$elm$core$Basics$composeL,
							A2(
								$elm$core$Basics$composeL,
								$Gizra$elm_debouncer$Debouncer$Basic$MsgInternal,
								$Gizra$elm_debouncer$Debouncer$Internal$InputProvidedAt(input)),
							$elm$time$Time$posixToMillis),
						$elm$time$Time$now),
					$elm$core$Maybe$Nothing);
			case 1:
				return _Utils_Tuple3(
					debouncer,
					A2(
						$elm$core$Task$perform,
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $Gizra$elm_debouncer$Debouncer$Basic$MsgInternal, $Gizra$elm_debouncer$Debouncer$Internal$ManualEmitAt),
							$elm$time$Time$posixToMillis),
						$elm$time$Time$now),
					$elm$core$Maybe$Nothing);
			default:
				var subMsg = msg.a;
				var _v1 = A2($Gizra$elm_debouncer$Debouncer$Internal$update, subMsg, debouncer);
				var updatedDebouncer = _v1.a;
				var intervals = _v1.b;
				var output = _v1.c;
				var cmds = $elm$core$Platform$Cmd$batch(
					A2(
						$elm$core$List$map,
						function (interval) {
							return A2(
								$elm$core$Task$perform,
								A2(
									$elm$core$Basics$composeL,
									A2($elm$core$Basics$composeL, $Gizra$elm_debouncer$Debouncer$Basic$MsgInternal, $Gizra$elm_debouncer$Debouncer$Internal$Check),
									$elm$time$Time$posixToMillis),
								A2(
									$elm$core$Task$andThen,
									$elm$core$Basics$always($elm$time$Time$now),
									$elm$core$Process$sleep(interval)));
						},
						intervals));
				return _Utils_Tuple3(updatedDebouncer, cmds, output);
		}
	});
var $author$project$InvestmentStrategyEditor$AddNewInvestmentStrategyUuidGenerated = function (a) {
	return {$: 10, a: a};
};
var $author$project$InvestmentStrategyEditor$CloneInvestmentStrategyUuidGenerated = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$InvestmentStrategyEditor$generateInvestmentStrategyDescDomId = function (_v0) {
	var investmentStrategyId = _v0.e;
	return A2(
		$elm$core$String$join,
		'-',
		_List_fromArray(
			[
				'investmentStrategy',
				'desc',
				$danyx23$elm_uuid$Uuid$toString(investmentStrategyId)
			]));
};
var $author$project$InvestmentStrategyEditor$generateInvestmentStrategyOtherDescDomId = F2(
	function (_v0, key) {
		var investmentStrategyId = _v0.e;
		return A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[
					'investmentStrategy',
					'desc',
					$danyx23$elm_uuid$Uuid$toString(investmentStrategyId),
					key
				]));
	});
var $author$project$Types$InvestmentStrategy$removeAndDeallocate = F2(
	function (assetType, investmentStrategy) {
		var savingsAlloc = investmentStrategy.aC.a0;
		if (!assetType.$) {
			return investmentStrategy;
		} else {
			var key = assetType.a;
			var _v1 = A2($elm$core$Dict$get, key, investmentStrategy.aS);
			if (!_v1.$) {
				var allocation = _v1.a.a0;
				return _Utils_update(
					investmentStrategy,
					{
						aS: A2($elm$core$Dict$remove, key, investmentStrategy.aS),
						aC: A2($author$project$Types$InvestmentStrategy$updateAlloc, investmentStrategy.aC.a0 + allocation, investmentStrategy.aC)
					});
			} else {
				return investmentStrategy;
			}
		}
	});
var $author$project$InvestmentStrategyEditor$update = F2(
	function (msg, investmentStrategys) {
		switch (msg.$) {
			case 0:
				var investmentStrategyId = msg.a.e;
				var key = msg.b;
				var newAllocAmount = msg.c;
				var _v1 = A2($author$project$UuidDict$get, investmentStrategyId, investmentStrategys);
				if (!_v1.$) {
					var is = _v1.a;
					var _v2 = A3(
						$author$project$Types$InvestmentStrategy$reallocateFromSavings,
						$author$project$Types$InvestmentStrategy$Other(key),
						newAllocAmount,
						is);
					if (!_v2.$) {
						var newIs = _v2.a;
						return _Utils_Tuple2(
							A3($author$project$UuidDict$insert, investmentStrategyId, newIs, investmentStrategys),
							$elm$core$Platform$Cmd$none);
					} else {
						var reallocErr = _v2.a;
						return _Utils_Tuple2(investmentStrategys, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(investmentStrategys, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var investmentStrategyId = msg.a.e;
				var newKey = 'Change to add more';
				var _v3 = A2($author$project$UuidDict$get, investmentStrategyId, investmentStrategys);
				if (!_v3.$) {
					var is = _v3.a;
					return _Utils_Tuple2(
						A3(
							$author$project$UuidDict$insert,
							investmentStrategyId,
							A3($author$project$Types$InvestmentStrategy$createOther, newKey, 0, is),
							investmentStrategys),
						$author$project$Ports$focusSmooth(
							A2(
								$author$project$InvestmentStrategyEditor$generateInvestmentStrategyOtherDescDomId,
								{e: investmentStrategyId},
								newKey)));
				} else {
					return _Utils_Tuple2(investmentStrategys, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var investmentStrategyId = msg.a.e;
				var key = msg.b;
				var _v4 = A2($author$project$UuidDict$get, investmentStrategyId, investmentStrategys);
				if (!_v4.$) {
					var is = _v4.a;
					return _Utils_Tuple2(
						A3(
							$author$project$UuidDict$insert,
							investmentStrategyId,
							A2(
								$author$project$Types$InvestmentStrategy$removeAndDeallocate,
								$author$project$Types$InvestmentStrategy$Other(key),
								is),
							investmentStrategys),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(investmentStrategys, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var investmentStrategyId = msg.a.e;
				var oldKey = msg.a.dw;
				var newKey = msg.a.ds;
				var allocation = msg.a.a0;
				var interest = msg.a.ay;
				var _v5 = A2($author$project$UuidDict$get, investmentStrategyId, investmentStrategys);
				if (!_v5.$) {
					var is = _v5.a;
					return _Utils_Tuple2(
						A3(
							$author$project$UuidDict$insert,
							investmentStrategyId,
							_Utils_update(
								is,
								{
									aS: A2(
										$elm$core$Dict$remove,
										oldKey,
										A3(
											$elm$core$Dict$insert,
											newKey,
											{a0: allocation, ay: interest},
											is.aS))
								}),
							investmentStrategys),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(investmentStrategys, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var investmentStrategyId = msg.a.e;
				var key = msg.a.db;
				var interest = msg.a.ay;
				var _v6 = A2($author$project$UuidDict$get, investmentStrategyId, investmentStrategys);
				if (!_v6.$) {
					var is = _v6.a;
					return _Utils_Tuple2(
						A3(
							$author$project$UuidDict$insert,
							investmentStrategyId,
							_Utils_update(
								is,
								{
									aS: A3(
										$elm$core$Dict$update,
										key,
										$elm$core$Maybe$map(
											function (o) {
												return _Utils_update(
													o,
													{ay: interest});
											}),
										is.aS)
								}),
							investmentStrategys),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(investmentStrategys, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var investmentStrategyId = msg.a.e;
				var interest = msg.a.ay;
				var _v7 = A2($author$project$UuidDict$get, investmentStrategyId, investmentStrategys);
				if (!_v7.$) {
					var is = _v7.a;
					return _Utils_Tuple2(
						A3(
							$author$project$UuidDict$insert,
							investmentStrategyId,
							_Utils_update(
								is,
								{
									aC: function (s) {
										return _Utils_update(
											s,
											{ay: interest});
									}(is.aC)
								}),
							investmentStrategys),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(investmentStrategys, $elm$core$Platform$Cmd$none);
				}
			case 6:
				var p = msg.a;
				var investmentStrategyId = p.e;
				var _v8 = A2($author$project$UuidDict$get, investmentStrategyId, investmentStrategys);
				if (!_v8.$) {
					var is = _v8.a;
					return _Utils_Tuple2(
						A3(
							$author$project$UuidDict$insert,
							investmentStrategyId,
							_Utils_update(
								is,
								{
									aC: function (s) {
										return _Utils_update(
											s,
											{a8: p.a8});
									}(is.aC)
								}),
							investmentStrategys),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(investmentStrategys, $elm$core$Platform$Cmd$none);
				}
			case 8:
				var investmentStrategyId = msg.a.e;
				return _Utils_Tuple2(
					A2($author$project$UuidDict$remove, investmentStrategyId, investmentStrategys),
					$elm$core$Platform$Cmd$none);
			case 7:
				var investmentStrategyId = msg.a.e;
				var description = msg.b;
				return _Utils_Tuple2(
					function () {
						var _v9 = A2($author$project$UuidDict$get, investmentStrategyId, investmentStrategys);
						if (!_v9.$) {
							var investmentStrategy = _v9.a;
							return A3(
								$author$project$UuidDict$insert,
								investmentStrategyId,
								_Utils_update(
									investmentStrategy,
									{eI: description}),
								investmentStrategys);
						} else {
							return investmentStrategys;
						}
					}(),
					$elm$core$Platform$Cmd$none);
			case 9:
				return _Utils_Tuple2(
					investmentStrategys,
					A2($elm$random$Random$generate, $author$project$InvestmentStrategyEditor$AddNewInvestmentStrategyUuidGenerated, $danyx23$elm_uuid$Uuid$uuidGenerator));
			case 10:
				var uuid = msg.a;
				return _Utils_Tuple2(
					A3(
						$author$project$UuidDict$insert,
						uuid,
						A2($author$project$Types$InvestmentStrategy$init, uuid, 'New Investment Strategy'),
						investmentStrategys),
					$author$project$Ports$focusSmooth(
						$author$project$InvestmentStrategyEditor$generateInvestmentStrategyDescDomId(
							{e: uuid})));
			case 11:
				var investmentStrategyToClone = msg.a;
				return _Utils_Tuple2(
					investmentStrategys,
					A2(
						$elm$random$Random$generate,
						$author$project$InvestmentStrategyEditor$CloneInvestmentStrategyUuidGenerated(investmentStrategyToClone),
						$danyx23$elm_uuid$Uuid$uuidGenerator));
			default:
				var investmentStrategyToClone = msg.a;
				var uuid = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$UuidDict$insert,
						uuid,
						_Utils_update(
							investmentStrategyToClone,
							{eI: investmentStrategyToClone.eI + ' (copy)', c1: uuid}),
						investmentStrategys),
					$author$project$Ports$focusSmooth(
						$author$project$InvestmentStrategyEditor$generateInvestmentStrategyDescDomId(
							{e: uuid})));
		}
	});
var $author$project$Projection$update = F2(
	function (msg, model) {
		var hovered = msg;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{am: hovered}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$ScheduleEditor$AddNewScheduleUuidGenerated = function (a) {
	return {$: 8, a: a};
};
var $author$project$ScheduleEditor$CloneScheduleUuidGenerated = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$ScheduleEditor$Desc = 0;
var $elm_community$list_extra$List$Extra$gatherWith = F2(
	function (testFn, list) {
		var helper = F2(
			function (scattered, gathered) {
				if (!scattered.b) {
					return $elm$core$List$reverse(gathered);
				} else {
					var toGather = scattered.a;
					var population = scattered.b;
					var _v1 = A2(
						$elm$core$List$partition,
						testFn(toGather),
						population);
					var gathering = _v1.a;
					var remaining = _v1.b;
					return A2(
						helper,
						remaining,
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(toGather, gathering),
							gathered));
				}
			});
		return A2(helper, list, _List_Nil);
	});
var $elm_community$list_extra$List$Extra$gatherEqualsBy = F2(
	function (extract, list) {
		return A2(
			$elm_community$list_extra$List$Extra$gatherWith,
			F2(
				function (a, b) {
					return _Utils_eq(
						extract(a),
						extract(b));
				}),
			list);
	});
var $author$project$ScheduleEditor$generateScheduleDescDomId = function (_v0) {
	var scheduleId = _v0.b1;
	return A2(
		$elm$core$String$join,
		'-',
		_List_fromArray(
			[
				'schedule',
				'desc',
				$danyx23$elm_uuid$Uuid$toString(scheduleId)
			]));
};
var $author$project$ScheduleEditor$Item = F2(
	function (scheduleId, allocation) {
		return {a0: allocation, b1: scheduleId};
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$ScheduleEditor$getItems = function (schedules) {
	return $elm$core$List$concat(
		A2(
			$elm$core$List$map,
			function (_v0) {
				var sId = _v0.a;
				var s = _v0.b;
				return A2(
					$elm$core$List$map,
					function (a) {
						return A2($author$project$ScheduleEditor$Item, sId, a);
					},
					$j_maas$elm_ordered_containers$OrderedDict$toList(s.aS));
			},
			A2(
				$elm$core$List$sortBy,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Tuple$second,
					function ($) {
						return $.eI;
					}),
				$author$project$UuidDict$toList(schedules))));
};
var $author$project$Types$Schedule$removeAndDeallocate = F2(
	function (activityType, schedule) {
		var freetimeAlloc = schedule.eW.a0;
		if (!activityType.$) {
			return schedule;
		} else {
			var key = activityType.a;
			var _v1 = A2($j_maas$elm_ordered_containers$OrderedDict$get, key, schedule.aS);
			if (!_v1.$) {
				var allocation = _v1.a.a0;
				return _Utils_update(
					schedule,
					{
						eW: A2($author$project$Types$Schedule$updateAlloc, schedule.eW.a0 + allocation, schedule.eW),
						aS: A2($j_maas$elm_ordered_containers$OrderedDict$remove, key, schedule.aS)
					});
			} else {
				return schedule;
			}
		}
	});
var $author$project$UuidDict$update = F3(
	function (key, func, _v0) {
		var dict = _v0;
		return A3(
			$elm$core$Dict$update,
			$danyx23$elm_uuid$Uuid$toString(key),
			func,
			dict);
	});
var $author$project$ScheduleEditor$update = F3(
	function (msg, schedules, model) {
		var noState = function (_v11) {
			var newSchedules = _v11.a;
			var m = _v11.b;
			return _Utils_Tuple3(newSchedules, model, m);
		};
		switch (msg.$) {
			case 11:
				var dMsg = msg.a;
				var insert = F2(
					function (_v4, dict) {
						var key = _v4.a;
						var val = _v4.b;
						return A3(
							$author$project$UuidDict$update,
							key,
							$elm$core$Maybe$map(
								function (s) {
									return _Utils_update(
										s,
										{
											aS: $j_maas$elm_ordered_containers$OrderedDict$fromList(val)
										});
								}),
							dict);
					});
				var _v1 = A3(
					$author$project$ScheduleEditor$dndSystem.f8,
					dMsg,
					model.ai,
					$author$project$ScheduleEditor$getItems(schedules));
				var dnd = _v1.a;
				var items = _v1.b;
				var aaa = A2(
					$elm$core$List$map,
					function (_v2) {
						var head = _v2.a;
						var tail = _v2.b;
						return function (b) {
							return _Utils_Tuple2(head.b1, b);
						}(
							$elm$core$List$concat(
								A2(
									$elm$core$List$map,
									function (_v3) {
										var h = _v3.a;
										var t = _v3.b;
										return A2(
											$elm$core$List$cons,
											h.a0,
											A2(
												$elm$core$List$map,
												function (a) {
													return a.a0;
												},
												t));
									},
									A2(
										$elm_community$list_extra$List$Extra$gatherEqualsBy,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.a0;
											},
											$elm$core$Tuple$first),
										A2($elm$core$List$cons, head, tail)))));
					},
					A2(
						$elm_community$list_extra$List$Extra$gatherEqualsBy,
						function ($) {
							return $.b1;
						},
						items));
				return _Utils_Tuple3(
					A3($elm$core$List$foldl, insert, schedules, aaa),
					_Utils_update(
						model,
						{ai: dnd}),
					$author$project$ScheduleEditor$dndSystem.ey(dnd));
			case 0:
				var scheduleId = msg.a.b1;
				var key = msg.b;
				var newAllocAmount = msg.c;
				return noState(
					function () {
						var _v5 = A2($author$project$UuidDict$get, scheduleId, schedules);
						if (!_v5.$) {
							var is = _v5.a;
							var _v6 = A3(
								$author$project$Types$Schedule$reallocateFromFreetime,
								$author$project$Types$Schedule$Other(key),
								newAllocAmount,
								is);
							if (!_v6.$) {
								var newIs = _v6.a;
								return _Utils_Tuple2(
									A3($author$project$UuidDict$insert, scheduleId, newIs, schedules),
									$elm$core$Platform$Cmd$none);
							} else {
								var reallocErr = _v6.a;
								return _Utils_Tuple2(schedules, $elm$core$Platform$Cmd$none);
							}
						} else {
							return _Utils_Tuple2(schedules, $elm$core$Platform$Cmd$none);
						}
					}());
			case 1:
				var scheduleId = msg.a.b1;
				var key = msg.b;
				var colorHex = msg.c.cC;
				return noState(
					_Utils_Tuple2(
						A3(
							$author$project$UuidDict$update,
							scheduleId,
							$elm$core$Maybe$map(
								A2($author$project$Types$Schedule$setColorHex, key, colorHex)),
							schedules),
						$elm$core$Platform$Cmd$none));
			case 2:
				var scheduleId = msg.a.b1;
				var newKey = 'Change to add more';
				return noState(
					function () {
						var _v7 = A2($author$project$UuidDict$get, scheduleId, schedules);
						if (!_v7.$) {
							var is = _v7.a;
							return _Utils_Tuple2(
								A3(
									$author$project$UuidDict$insert,
									scheduleId,
									A2($author$project$Types$Schedule$createOther, newKey, is),
									schedules),
								$author$project$Ports$focusSmooth(
									A3(
										$author$project$ScheduleEditor$generateItemDomId,
										newKey,
										0,
										{b1: scheduleId})));
						} else {
							return _Utils_Tuple2(schedules, $elm$core$Platform$Cmd$none);
						}
					}());
			case 3:
				var scheduleId = msg.a.b1;
				var key = msg.b;
				return noState(
					function () {
						var _v8 = A2($author$project$UuidDict$get, scheduleId, schedules);
						if (!_v8.$) {
							var is = _v8.a;
							return _Utils_Tuple2(
								A3(
									$author$project$UuidDict$insert,
									scheduleId,
									A2(
										$author$project$Types$Schedule$removeAndDeallocate,
										$author$project$Types$Schedule$Other(key),
										is),
									schedules),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(schedules, $elm$core$Platform$Cmd$none);
						}
					}());
			case 4:
				var scheduleId = msg.a.b1;
				var oldKey = msg.a.dw;
				var newKey = msg.a.ds;
				var allocation = msg.a.a0;
				return noState(
					function () {
						var _v9 = A2($author$project$UuidDict$get, scheduleId, schedules);
						if (!_v9.$) {
							var is = _v9.a;
							return _Utils_Tuple2(
								A3(
									$author$project$UuidDict$insert,
									scheduleId,
									_Utils_update(
										is,
										{
											aS: A2(
												$j_maas$elm_ordered_containers$OrderedDict$remove,
												oldKey,
												A3($j_maas$elm_ordered_containers$OrderedDict$insert, newKey, allocation, is.aS))
										}),
									schedules),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(schedules, $elm$core$Platform$Cmd$none);
						}
					}());
			case 6:
				var scheduleId = msg.a.b1;
				return noState(
					_Utils_Tuple2(
						A2($author$project$UuidDict$remove, scheduleId, schedules),
						$elm$core$Platform$Cmd$none));
			case 5:
				var scheduleId = msg.a.b1;
				var description = msg.b;
				return noState(
					_Utils_Tuple2(
						function () {
							var _v10 = A2($author$project$UuidDict$get, scheduleId, schedules);
							if (!_v10.$) {
								var schedule = _v10.a;
								return A3(
									$author$project$UuidDict$insert,
									scheduleId,
									_Utils_update(
										schedule,
										{eI: description}),
									schedules);
							} else {
								return schedules;
							}
						}(),
						$elm$core$Platform$Cmd$none));
			case 7:
				return noState(
					_Utils_Tuple2(
						schedules,
						A2($elm$random$Random$generate, $author$project$ScheduleEditor$AddNewScheduleUuidGenerated, $danyx23$elm_uuid$Uuid$uuidGenerator)));
			case 8:
				var uuid = msg.a;
				return noState(
					_Utils_Tuple2(
						A3(
							$author$project$UuidDict$insert,
							uuid,
							A2($author$project$Types$Schedule$init, uuid, 'New Schedule'),
							schedules),
						$author$project$Ports$focusSmooth(
							$author$project$ScheduleEditor$generateScheduleDescDomId(
								{b1: uuid}))));
			case 9:
				var scheduleToClone = msg.a;
				return noState(
					_Utils_Tuple2(
						schedules,
						A2(
							$elm$random$Random$generate,
							$author$project$ScheduleEditor$CloneScheduleUuidGenerated(scheduleToClone),
							$danyx23$elm_uuid$Uuid$uuidGenerator)));
			default:
				var scheduleToClone = msg.a;
				var uuid = msg.b;
				return noState(
					_Utils_Tuple2(
						A3(
							$author$project$UuidDict$insert,
							uuid,
							_Utils_update(
								scheduleToClone,
								{eI: scheduleToClone.eI + ' (copy)', c1: uuid}),
							schedules),
						$author$project$Ports$focusSmooth(
							$author$project$ScheduleEditor$generateScheduleDescDomId(
								{b1: uuid}))));
		}
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $elm_community$list_extra$List$Extra$splitAt = F2(
	function (n, xs) {
		return _Utils_Tuple2(
			A2($elm$core$List$take, n, xs),
			A2($elm$core$List$drop, n, xs));
	});
var $author$project$TimelineEditor$update = F5(
	function (scalingFactors, h, message, model, timeline) {
		switch (message.$) {
			case 13:
				var segmentIdx = message.a.E;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							S: A2($elm$core$Set$member, segmentIdx, model.S) ? A2($elm$core$Set$remove, segmentIdx, model.S) : A2($elm$core$Set$insert, segmentIdx, model.S)
						}),
					timeline,
					$elm$core$Platform$Cmd$none);
			case 0:
				var msg = message.a;
				var _v1 = A3($author$project$TimelineEditor$dndSystem.f8, msg, model.ai, timeline.dZ);
				var dnd = _v1.a;
				var segments = _v1.b;
				var affected = function () {
					var _v2 = $author$project$TimelineEditor$dndSystem.c3(dnd);
					if (!_v2.$) {
						var dragIndex = _v2.a.cL;
						var dropIndex = _v2.a.eM;
						return (!_Utils_eq(dragIndex, dropIndex)) ? A2(
							$elm$core$List$cons,
							dragIndex,
							A2($elm$core$List$cons, dropIndex, model._)) : model._;
					} else {
						return model._;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{_: affected, ai: dnd, S: $elm$core$Set$empty}),
					_Utils_update(
						timeline,
						{dZ: segments}),
					A2(
						$elm$core$Platform$Cmd$map,
						h.f2,
						$author$project$TimelineEditor$dndSystem.ey(dnd)));
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{_: _List_Nil}),
					timeline,
					$elm$core$Platform$Cmd$none);
			case 3:
				var mouseEvent = message.a;
				var const_SHIFT_AMOUNT = (11 / 365) / scalingFactors.aH;
				var applyIfSegmentDurationWontDropBelow = F3(
					function (minimum, segmentIndexBeingDecremented, func) {
						var _v4 = A2($elm_community$list_extra$List$Extra$getAt, segmentIndexBeingDecremented, timeline.dZ);
						if (!_v4.$) {
							var segment = _v4.a;
							return (_Utils_cmp(segment.aL - const_SHIFT_AMOUNT, minimum) > 0) ? _Utils_Tuple3(
								model,
								_Utils_update(
									timeline,
									{
										dZ: func(timeline.dZ)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple3(
								_Utils_update(
									model,
									{W: $elm$core$Maybe$Nothing}),
								timeline,
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{W: $elm$core$Maybe$Nothing}),
								timeline,
								$elm$core$Platform$Cmd$none);
						}
					});
				var _v3 = model.W;
				if (!_v3.$) {
					var initialMouseDownX = _v3.a.bO;
					var segmentIndex = _v3.a.b3;
					var minimumDuration = 0.3;
					var delta = (mouseEvent.by.a - initialMouseDownX) * 0.0005;
					return (_Utils_cmp(mouseEvent.by.a, initialMouseDownX) > 0) ? A3(
						applyIfSegmentDurationWontDropBelow,
						minimumDuration,
						segmentIndex + 1,
						A2(
							$elm$core$Basics$composeR,
							A2(
								$elm_community$list_extra$List$Extra$updateAt,
								segmentIndex,
								function (s) {
									return _Utils_update(
										s,
										{aL: s.aL + delta});
								}),
							A2(
								$elm_community$list_extra$List$Extra$updateAt,
								segmentIndex + 1,
								function (s) {
									return _Utils_update(
										s,
										{aL: s.aL - delta});
								}))) : A3(
						applyIfSegmentDurationWontDropBelow,
						minimumDuration,
						segmentIndex,
						A2(
							$elm$core$Basics$composeR,
							A2(
								$elm_community$list_extra$List$Extra$updateAt,
								segmentIndex,
								function (s) {
									return _Utils_update(
										s,
										{aL: s.aL - (delta * (-1))});
								}),
							A2(
								$elm_community$list_extra$List$Extra$updateAt,
								segmentIndex + 1,
								function (s) {
									return _Utils_update(
										s,
										{aL: s.aL + (delta * (-1))});
								})));
				} else {
					return _Utils_Tuple3(model, timeline, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var segmentResizing = message.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							W: $elm$core$Maybe$Just(segmentResizing)
						}),
					timeline,
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{W: $elm$core$Maybe$Nothing}),
					timeline,
					$elm$core$Platform$Cmd$none);
			case 5:
				var segmentIdx = message.a.E;
				var budgetId = message.a.ac;
				return _Utils_Tuple3(
					model,
					_Utils_update(
						timeline,
						{
							dZ: A3(
								$elm_community$list_extra$List$Extra$updateAt,
								segmentIdx,
								function (s) {
									return _Utils_update(
										s,
										{ac: budgetId});
								},
								timeline.dZ)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var segmentIdx = message.a.E;
				var investmentStrategyId = message.a.e;
				return _Utils_Tuple3(
					model,
					_Utils_update(
						timeline,
						{
							dZ: A3(
								$elm_community$list_extra$List$Extra$updateAt,
								segmentIdx,
								function (s) {
									return _Utils_update(
										s,
										{e: investmentStrategyId});
								},
								timeline.dZ)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var segmentIdx = message.a.E;
				var newDurationYears = message.a.dr;
				var isPositive = function (val) {
					return (val > 0) ? $elm$core$Maybe$Just(val) : $elm$core$Maybe$Nothing;
				};
				var _v5 = A2(
					$elm$core$Maybe$andThen,
					isPositive,
					$elm$core$String$toFloat(newDurationYears));
				if (!_v5.$) {
					var years = _v5.a;
					return _Utils_Tuple3(
						model,
						_Utils_update(
							timeline,
							{
								dZ: A3(
									$elm_community$list_extra$List$Extra$updateAt,
									segmentIdx,
									function (s) {
										return _Utils_update(
											s,
											{aL: years});
									},
									timeline.dZ)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple3(model, timeline, $elm$core$Platform$Cmd$none);
				}
			case 8:
				var segmentIdx = message.a.E;
				var segToDelete = message.b;
				if ((!segmentIdx) && ($elm$core$List$length(timeline.dZ) === 1)) {
					return _Utils_Tuple3(model, timeline, $elm$core$Platform$Cmd$none);
				} else {
					var getSeg = function (idx) {
						return A2($elm_community$list_extra$List$Extra$getAt, idx, timeline.dZ);
					};
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{S: $elm$core$Set$empty}),
						_Utils_update(
							timeline,
							{
								dZ: function () {
									var _v6 = _Utils_Tuple2(
										getSeg(segmentIdx - 1),
										getSeg(segmentIdx + 1));
									if (!_v6.a.$) {
										if (_v6.b.$ === 1) {
											var segL = _v6.a.a;
											var _v8 = _v6.b;
											return A2(
												$elm_community$list_extra$List$Extra$removeAt,
												segmentIdx,
												A3(
													$elm_community$list_extra$List$Extra$setAt,
													segmentIdx - 1,
													_Utils_update(
														segL,
														{aL: segL.aL + segToDelete.aL}),
													timeline.dZ));
										} else {
											var segL = _v6.a.a;
											var segR = _v6.b.a;
											return A2(
												$elm_community$list_extra$List$Extra$removeAt,
												segmentIdx,
												A3(
													$elm_community$list_extra$List$Extra$setAt,
													segmentIdx + 1,
													_Utils_update(
														segR,
														{aL: segR.aL + (segToDelete.aL / 2)}),
													A3(
														$elm_community$list_extra$List$Extra$setAt,
														segmentIdx - 1,
														_Utils_update(
															segL,
															{aL: segL.aL + (segToDelete.aL / 2)}),
														timeline.dZ)));
										}
									} else {
										if (!_v6.b.$) {
											var _v7 = _v6.a;
											var segR = _v6.b.a;
											return A2(
												$elm_community$list_extra$List$Extra$removeAt,
												segmentIdx,
												A3(
													$elm_community$list_extra$List$Extra$setAt,
													segmentIdx + 1,
													_Utils_update(
														segR,
														{aL: segR.aL + segToDelete.aL}),
													timeline.dZ));
										} else {
											var _v9 = _v6.a;
											var _v10 = _v6.b;
											return timeline.dZ;
										}
									}
								}()
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 9:
				var segmentIdx = message.a.E;
				var segment = message.b;
				var halfSegment = _Utils_update(
					segment,
					{aL: segment.aL / 2});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{S: $elm$core$Set$empty}),
					_Utils_update(
						timeline,
						{
							dZ: function (_v11) {
								var left = _v11.a;
								var right = _v11.b;
								return _Utils_ap(
									left,
									_Utils_ap(
										_List_fromArray(
											[halfSegment]),
										right));
							}(
								A2(
									$elm_community$list_extra$List$Extra$splitAt,
									segmentIdx,
									A3($elm_community$list_extra$List$Extra$setAt, segmentIdx, halfSegment, timeline.dZ)))
						}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var budgetDetails = message.a;
				return _Utils_Tuple3(
					model,
					timeline,
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$identity,
						$elm$core$Task$succeed(
							h.e_(budgetDetails))));
			case 11:
				var description = message.a;
				return _Utils_Tuple3(
					model,
					_Utils_update(
						timeline,
						{eI: description}),
					$elm$core$Platform$Cmd$none);
			default:
				var mortgageChartData = message.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ba: $elm$core$Maybe$Just(mortgageChartData)
						}),
					timeline,
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$UserConfig$budgetBlockHeightFactorMax = 2;
var $author$project$UserConfig$budgetBlockWidthFactorMax = 2;
var $author$project$UserConfig$chartHeightPercentMin = 0.25;
var $author$project$UserConfig$const_TIMELINE_HEIGHT_MIN = 0.1;
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$UserConfig$update = F2(
	function (msg, userConfig) {
		var boundTo = F3(
			function (min, max, val) {
				return ((_Utils_cmp(val, min) > 0) && (_Utils_cmp(val, max) < 1)) ? $elm$core$Maybe$Just(val) : $elm$core$Maybe$Nothing;
			});
		switch (msg.$) {
			case 0:
				var newScalingFactor = msg.a;
				return _Utils_update(
					userConfig,
					{
						bo: A2(
							$elm$core$Maybe$withDefault,
							userConfig.bo,
							A2(
								$elm$core$Maybe$andThen,
								A2(boundTo, 0, $author$project$UserConfig$budgetBlockWidthFactorMax),
								$elm$core$String$toFloat(newScalingFactor)))
					});
			case 1:
				var newScalingFactor = msg.a;
				return _Utils_update(
					userConfig,
					{
						a1: _Utils_eq(
							newScalingFactor,
							$elm$core$String$fromFloat($author$project$UserConfig$const_TIMELINE_HEIGHT_MIN)) ? $elm$core$Maybe$Nothing : A2(
							$elm$core$Maybe$andThen,
							A2(boundTo, 0, $author$project$UserConfig$budgetBlockHeightFactorMax),
							$elm$core$String$toFloat(newScalingFactor))
					});
			case 2:
				var newChartHeight = msg.a;
				return _Utils_update(
					userConfig,
					{
						bx: A2(
							$elm$core$Maybe$withDefault,
							$author$project$UserConfig$chartHeightPercentMin,
							A2(
								$elm$core$Maybe$andThen,
								A2(boundTo, $author$project$UserConfig$chartHeightPercentMin, 0.98),
								$elm$core$String$toFloat(newChartHeight)))
					});
			default:
				var bool = msg.a;
				return _Utils_update(
					userConfig,
					{b_: bool});
		}
	});
var $author$project$WeekEditor$update = F5(
	function (scalingFactors, h, message, model, budget) {
		switch (message.$) {
			case 11:
				var scheduleBlockIdx = message.a.Q;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							S: A2($elm$core$Set$member, scheduleBlockIdx, model.S) ? A2($elm$core$Set$remove, scheduleBlockIdx, model.S) : A2($elm$core$Set$insert, scheduleBlockIdx, model.S)
						}),
					budget,
					$elm$core$Platform$Cmd$none);
			case 0:
				var msg = message.a;
				var _v1 = A3($author$project$WeekEditor$dndSystem.f8, msg, model.ai, budget.eu);
				var dnd = _v1.a;
				var calendar = _v1.b;
				var affected = function () {
					var _v2 = $author$project$WeekEditor$dndSystem.c3(dnd);
					if (!_v2.$) {
						var dragIndex = _v2.a.cL;
						var dropIndex = _v2.a.eM;
						return (!_Utils_eq(dragIndex, dropIndex)) ? A2(
							$elm$core$List$cons,
							dragIndex,
							A2($elm$core$List$cons, dropIndex, model._)) : model._;
					} else {
						return model._;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{_: affected, ai: dnd}),
					_Utils_update(
						budget,
						{eu: calendar}),
					A2(
						$elm$core$Platform$Cmd$map,
						h.f2,
						$author$project$WeekEditor$dndSystem.ey(dnd)));
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{_: _List_Nil}),
					budget,
					$elm$core$Platform$Cmd$none);
			case 3:
				var mouseEvent = message.a;
				var const_SHIFT_AMOUNT = (11 / 365) / scalingFactors.aH;
				var applyIfCalendarDurationWontDropBelow = F3(
					function (minimum, calendarIndexBeingDecremented, func) {
						var _v4 = A2($elm_community$list_extra$List$Extra$getAt, calendarIndexBeingDecremented, budget.eu);
						if (!_v4.$) {
							var calendar = _v4.a;
							return (_Utils_cmp(calendar.eN - const_SHIFT_AMOUNT, minimum) > 0) ? _Utils_Tuple3(
								model,
								_Utils_update(
									budget,
									{
										eu: func(budget.eu)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple3(
								_Utils_update(
									model,
									{T: $elm$core$Maybe$Nothing}),
								budget,
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{T: $elm$core$Maybe$Nothing}),
								budget,
								$elm$core$Platform$Cmd$none);
						}
					});
				var _v3 = model.T;
				if (!_v3.$) {
					var initialMouseDownX = _v3.a.bO;
					var calendarIndex = _v3.a.bw;
					var minimumDurationDays = 1;
					return (_Utils_cmp(mouseEvent.by.a, initialMouseDownX) > 0) ? A3(
						applyIfCalendarDurationWontDropBelow,
						minimumDurationDays,
						calendarIndex + 1,
						A2(
							$elm$core$Basics$composeR,
							A2(
								$elm_community$list_extra$List$Extra$updateAt,
								calendarIndex,
								function (s) {
									return _Utils_update(
										s,
										{eN: s.eN + const_SHIFT_AMOUNT});
								}),
							A2(
								$elm_community$list_extra$List$Extra$updateAt,
								calendarIndex + 1,
								function (s) {
									return _Utils_update(
										s,
										{eN: s.eN - const_SHIFT_AMOUNT});
								}))) : A3(
						applyIfCalendarDurationWontDropBelow,
						minimumDurationDays,
						calendarIndex,
						A2(
							$elm$core$Basics$composeR,
							A2(
								$elm_community$list_extra$List$Extra$updateAt,
								calendarIndex,
								function (s) {
									return _Utils_update(
										s,
										{eN: s.eN - const_SHIFT_AMOUNT});
								}),
							A2(
								$elm_community$list_extra$List$Extra$updateAt,
								calendarIndex + 1,
								function (s) {
									return _Utils_update(
										s,
										{eN: s.eN + const_SHIFT_AMOUNT});
								})));
				} else {
					return _Utils_Tuple3(model, budget, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var calendarResizing = message.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							T: $elm$core$Maybe$Just(calendarResizing)
						}),
					budget,
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{T: $elm$core$Maybe$Nothing}),
					budget,
					$elm$core$Platform$Cmd$none);
			case 5:
				var scheduleBlockIdx = message.a.Q;
				var scheduleId = message.a.b1;
				return _Utils_Tuple3(
					model,
					_Utils_update(
						budget,
						{
							eu: A3(
								$elm_community$list_extra$List$Extra$updateAt,
								scheduleBlockIdx,
								function (s) {
									return _Utils_update(
										s,
										{b1: scheduleId});
								},
								budget.eu)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var scheduleBlockIdx = message.a.Q;
				var newDuration = message.a.dq;
				var isPositive = function (val) {
					return (val > 0) ? $elm$core$Maybe$Just(val) : $elm$core$Maybe$Nothing;
				};
				var _v5 = A2(
					$elm$core$Maybe$andThen,
					isPositive,
					$elm$core$String$toFloat(newDuration));
				if (!_v5.$) {
					var years = _v5.a;
					return _Utils_Tuple3(
						model,
						_Utils_update(
							budget,
							{
								eu: A3(
									$elm_community$list_extra$List$Extra$updateAt,
									scheduleBlockIdx,
									function (s) {
										return _Utils_update(
											s,
											{eN: years});
									},
									budget.eu)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple3(model, budget, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var scheduleBlockIdx = message.a.Q;
				var segToDelete = message.b;
				if ((!scheduleBlockIdx) && ($elm$core$List$length(budget.eu) === 1)) {
					return _Utils_Tuple3(model, budget, $elm$core$Platform$Cmd$none);
				} else {
					var getSeg = function (idx) {
						return A2($elm_community$list_extra$List$Extra$getAt, idx, budget.eu);
					};
					return _Utils_Tuple3(
						model,
						_Utils_update(
							budget,
							{
								eu: function () {
									var _v6 = _Utils_Tuple2(
										getSeg(scheduleBlockIdx - 1),
										getSeg(scheduleBlockIdx + 1));
									if (!_v6.a.$) {
										if (_v6.b.$ === 1) {
											var segL = _v6.a.a;
											var _v8 = _v6.b;
											return A2(
												$elm_community$list_extra$List$Extra$removeAt,
												scheduleBlockIdx,
												A3(
													$elm_community$list_extra$List$Extra$setAt,
													scheduleBlockIdx - 1,
													_Utils_update(
														segL,
														{eN: segL.eN + segToDelete.eN}),
													budget.eu));
										} else {
											var segL = _v6.a.a;
											var segR = _v6.b.a;
											return A2(
												$elm_community$list_extra$List$Extra$removeAt,
												scheduleBlockIdx,
												A3(
													$elm_community$list_extra$List$Extra$setAt,
													scheduleBlockIdx + 1,
													_Utils_update(
														segR,
														{eN: segR.eN + (segToDelete.eN / 2)}),
													A3(
														$elm_community$list_extra$List$Extra$setAt,
														scheduleBlockIdx - 1,
														_Utils_update(
															segL,
															{eN: segL.eN + (segToDelete.eN / 2)}),
														budget.eu)));
										}
									} else {
										if (!_v6.b.$) {
											var _v7 = _v6.a;
											var segR = _v6.b.a;
											return A2(
												$elm_community$list_extra$List$Extra$removeAt,
												scheduleBlockIdx,
												A3(
													$elm_community$list_extra$List$Extra$setAt,
													scheduleBlockIdx + 1,
													_Utils_update(
														segR,
														{eN: segR.eN + segToDelete.eN}),
													budget.eu));
										} else {
											var _v9 = _v6.a;
											var _v10 = _v6.b;
											return budget.eu;
										}
									}
								}()
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 8:
				var scheduleBlockIdx = message.a.Q;
				var scheduleBlock = message.b;
				var halfScheduleBlock = _Utils_update(
					scheduleBlock,
					{eN: scheduleBlock.eN / 2});
				return _Utils_Tuple3(
					model,
					_Utils_update(
						budget,
						{
							eu: function (_v11) {
								var left = _v11.a;
								var right = _v11.b;
								return _Utils_ap(
									left,
									_Utils_ap(
										_List_fromArray(
											[halfScheduleBlock]),
										right));
							}(
								A2(
									$elm_community$list_extra$List$Extra$splitAt,
									scheduleBlockIdx,
									A3($elm_community$list_extra$List$Extra$setAt, scheduleBlockIdx, halfScheduleBlock, budget.eu)))
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var schedule = message.a;
				var activity = message.b;
				return _Utils_Tuple3(
					model,
					budget,
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$identity,
						$elm$core$Task$succeed(
							A2(h.e$, schedule, activity))));
			default:
				var description = message.a;
				return _Utils_Tuple3(
					model,
					_Utils_update(
						budget,
						{eI: description}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Types$Board$decoderBlob = $author$project$Types$Board$V3$decoderBlob;
var $author$project$Api$Board$updateBoard = F3(
	function (authUser, newBoard, toMsg) {
		return A2(
			$author$project$Api$request_,
			_List_fromArray(
				[
					$author$project$Api$authHeader(
					{f5: authUser.f5}),
					$author$project$Api$preferReturnRep
				]),
			{
				cw: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'blob',
								$author$project$Types$Board$encodeBlob(newBoard.bv)),
								_Utils_Tuple2(
								'version',
								$elm$json$Json$Encode$int($author$project$Api$Board$boardVersion))
							]))),
				eF: A2(
					$elm$json$Json$Decode$andThen,
					function (b) {
						return $elm$json$Json$Decode$succeed(
							_Utils_update(
								newBoard,
								{bv: b}));
					},
					$author$project$Types$Board$decoderBlob),
				dm: 'PATCH',
				f2: toMsg,
				ec: $author$project$Api$Board$url(
					_List_fromArray(
						[
							'id=eq.' + $danyx23$elm_uuid$Uuid$toString(newBoard.c1)
						]))
			});
	});
var $author$project$App$update = F2(
	function (msg, model) {
		var withBoardUpdate = function (_v28) {
			var m = _v28.a;
			var c = _v28.b;
			return _Utils_Tuple2(
				m,
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							c,
							_Utils_eq(
							$author$project$App$modelToBoardBlob(m),
							$author$project$App$modelToBoardBlob(model)) ? $elm$core$Platform$Cmd$none : $author$project$Util$msgToCmd(
							$author$project$App$DebounceBackendUpdateMsg(
								$Gizra$elm_debouncer$Debouncer$Basic$provideInput($author$project$App$TriggerBackendUpdate)))
						])));
		};
		var budgetsWithoutEditors = A2(
			$author$project$UuidDict$map,
			F2(
				function (_v27, v) {
					return v.a;
				}),
			model.n);
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 26:
				var _v1 = model.F;
				if (_v1.$ === 4) {
					var user = _v1.a.ed;
					var boardId = _v1.a.cv;
					var boardDesc = _v1.a.cu;
					return _Utils_Tuple2(
						model,
						A3(
							$author$project$Api$Board$updateBoard,
							user,
							{
								bv: $author$project$App$modelToBoardBlob(model),
								eI: boardDesc,
								c1: boardId
							},
							$author$project$App$GotBoard(user)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var debouncerMsg = msg.a;
				var _v2 = A2($Gizra$elm_debouncer$Debouncer$Basic$update, debouncerMsg, model.a3);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				var emittedMsg = _v2.c;
				var mappedCmd = A2($elm$core$Platform$Cmd$map, $author$project$App$DebounceBackendUpdateMsg, subCmd);
				var updatedModel = _Utils_update(
					model,
					{a3: subModel});
				if (!emittedMsg.$) {
					var emitted = emittedMsg.a;
					return A2(
						$elm$core$Tuple$mapSecond,
						function (cmd) {
							return $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[cmd, mappedCmd]));
						},
						A2($author$project$App$update, emitted, updatedModel));
				} else {
					return _Utils_Tuple2(updatedModel, mappedCmd);
				}
			case 4:
				var m = msg.a;
				var _v4 = A2($author$project$Projection$update, m, model.aB);
				var updatedProjection = _v4.a;
				var projectionCmds = _v4.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aB: updatedProjection}),
					A2($elm$core$Platform$Cmd$map, $author$project$App$ProjectionMsg, projectionCmds));
			case 2:
				var i = msg.a;
				var m = msg.b;
				return withBoardUpdate(
					function () {
						var _v5 = A2($elm$core$Array$get, i, model.g);
						if (!_v5.$) {
							var _v6 = _v5.a;
							var timeline = _v6.a;
							var timelineEditor = _v6.b;
							var _v7 = A5(
								$author$project$TimelineEditor$update,
								{ax: model.d.a1, aH: model.d.bo},
								{
									e_: $author$project$App$FocusBudgetLineItem,
									f2: $author$project$App$TimelineEditorMsg(i)
								},
								m,
								timelineEditor,
								timeline);
							var updatedTimelineEditor = _v7.a;
							var updatedTimeline = _v7.b;
							var timelineEditorCmds = _v7.c;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										g: A3(
											$elm$core$Array$set,
											i,
											_Utils_Tuple2(updatedTimeline, updatedTimelineEditor),
											model.g)
									}),
								timelineEditorCmds);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}());
			case 3:
				var budgetId = msg.a.ac;
				var m = msg.b;
				return withBoardUpdate(
					function () {
						var _v8 = A2($author$project$UuidDict$get, budgetId, model.n);
						if (!_v8.$) {
							var _v9 = _v8.a;
							var budget = _v9.a;
							var weekEditor = _v9.b;
							var _v10 = A5(
								$author$project$WeekEditor$update,
								{ax: model.d.a1, aH: model.d.bo},
								{
									e$: $author$project$App$FocusScheduleLineItem,
									f2: $author$project$App$ScheduleWeekEditorMsg(
										{ac: budgetId})
								},
								m,
								weekEditor,
								budget);
							var updatedWeekEditor = _v10.a;
							var updatedBudget = _v10.b;
							var weekEditorCmds = _v10.c;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										n: A3(
											$author$project$UuidDict$insert,
											budget.c1,
											_Utils_Tuple2(updatedBudget, updatedWeekEditor),
											model.n)
									}),
								weekEditorCmds);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}());
			case 5:
				var m = msg.a;
				return withBoardUpdate(
					function () {
						var _v11 = A3($author$project$BudgetEditor$update, m, budgetsWithoutEditors, model.a4);
						var newBudgets = _v11.a;
						var budgetEditorCmds = _v11.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									n: A2(
										$author$project$UuidDict$map,
										F2(
											function (_v12, v) {
												return _Utils_Tuple2(v, $author$project$WeekEditor$init);
											}),
										newBudgets)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$App$BudgetEditorMsg, budgetEditorCmds)
									])));
					}());
			case 6:
				var m = msg.a;
				return withBoardUpdate(
					function () {
						var _v13 = A2($author$project$InvestmentStrategyEditor$update, m, model.z);
						var newInvestmentStrategies = _v13.a;
						var investmentStrategyEditorCmds = _v13.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{z: newInvestmentStrategies}),
							A2($elm$core$Platform$Cmd$map, $author$project$App$InvestmentStrategyEditorMsg, investmentStrategyEditorCmds));
					}());
			case 7:
				var m = msg.a;
				return withBoardUpdate(
					function () {
						var _v14 = A3($author$project$ScheduleEditor$update, m, model.C, model.aD);
						var newSchedules = _v14.a;
						var newScheduleEditor = _v14.b;
						var scheduleEditorCmds = _v14.c;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aD: newScheduleEditor, C: newSchedules}),
							A2($elm$core$Platform$Cmd$map, $author$project$App$ScheduleEditorMsg, scheduleEditorCmds));
					}());
			case 8:
				var m = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: A2($author$project$UserConfig$update, m, model.d)
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var newInitialAmount = msg.a;
				var setUserConfig = function (userConfig) {
					return _Utils_update(
						userConfig,
						{
							an: A2(
								$elm$core$Maybe$withDefault,
								userConfig.an,
								$elm$core$String$toFloat(newInitialAmount))
						});
				};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: setUserConfig(model.d)
						}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var d = msg.a;
				return _Utils_Tuple2(
					function () {
						if ((!d.$) && (d.a === 2)) {
							var _v16 = d.a;
							return _Utils_update(
								model,
								{t: d, Y: false});
						} else {
							return _Utils_update(
								model,
								{t: d});
						}
					}(),
					$elm$core$Platform$Cmd$none);
			case 11:
				var m = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{A: m, d: model.d}),
					function () {
						if (m.$ === 1) {
							return $author$project$Ports$focusSmooth('breakdown');
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 12:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Y: !model.Y}),
					$elm$core$Platform$Cmd$none);
			case 13:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ab: !model.ab}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var itemDetails = msg.a;
				var maybeItemId = function (incomeOrExpense) {
					return A2(
						$elm$core$Maybe$map,
						function (i) {
							return A3(
								$author$project$BudgetEditor$generateItemDomId,
								1,
								incomeOrExpense,
								{ac: itemDetails.ac, fg: i});
						},
						A2(
							$elm$core$Maybe$andThen,
							$elm_community$list_extra$List$Extra$findIndex(
								function (_v20) {
									var description = _v20.a;
									return _Utils_eq(itemDetails.eI, description);
								}),
							A2(
								$elm$core$Maybe$map,
								function () {
									if (!incomeOrExpense) {
										return function ($) {
											return $.e6;
										};
									} else {
										return function ($) {
											return $.eS;
										};
									}
								}(),
								A2($author$project$UuidDict$get, itemDetails.ac, budgetsWithoutEditors))));
				};
				var _v18 = A2(
					$elm_community$maybe_extra$Maybe$Extra$or,
					maybeItemId(1),
					maybeItemId(0));
				if (!_v18.$) {
					var itemId = _v18.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								t: $elm$core$Result$Ok(0)
							}),
						$author$project$Ports$focusSmooth(itemId));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 15:
				if (msg.b.$ === 1) {
					var scheduleId = msg.a.b1;
					var key = msg.b.a;
					return _Utils_Tuple2(
						model,
						$author$project$Ports$focusSmooth(
							A3(
								$author$project$ScheduleEditor$generateItemDomId,
								key,
								1,
								{b1: scheduleId})));
				} else {
					var scheduleId = msg.a.b1;
					var _v21 = msg.b;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 16:
				var timelineIdx = msg.a.r;
				var time = msg.b;
				var _v22 = A2($elm$core$Array$get, timelineIdx, model.g);
				if (!_v22.$) {
					var _v23 = _v22.a;
					var timeline = _v23.a;
					var timelineEditor = _v23.b;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$random$Random$generate,
							function (uuid) {
								return A2(
									$author$project$App$CloneTimeline,
									{r: timelineIdx},
									_Utils_Tuple2(
										_Utils_update(
											timeline,
											{eC: time, eI: 'Copy of ' + timeline.eI, c1: uuid}),
										timelineEditor));
							},
							$danyx23$elm_uuid$Uuid$uuidGenerator));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 17:
				var timelineIdx = msg.a.r;
				var timeline = msg.b;
				return withBoardUpdate(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: A3($elm_community$array_extra$Array$Extra$insertAt, timelineIdx + 1, timeline, model.g)
							}),
						$author$project$Ports$focusSmooth(
							$author$project$App$generateTimelineDomId(timelineIdx + 1))));
			case 18:
				var timelineIdx = msg.a.r;
				return withBoardUpdate(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: A2($elm_community$array_extra$Array$Extra$removeAt, timelineIdx, model.g)
							}),
						$author$project$Ports$focusSmooth(
							$author$project$App$generateTimelineDomId(timelineIdx - 1))));
			case 19:
				var msgToFire = msg.a;
				return _Utils_Tuple2(
					model,
					A2($elm$core$Task$perform, msgToFire, $elm$time$Time$now));
			case 20:
				var authMsg = msg.a;
				var _v24 = A3(
					$author$project$Auth$update,
					{fv: $author$project$App$LoggedIn, fw: $author$project$App$Registered, f2: $author$project$App$AuthMsg},
					authMsg,
					model.aJ);
				var newAuth = _v24.a;
				var authCmd = _v24.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aJ: newAuth}),
					authCmd);
			case 22:
				var authUser = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							F: $author$project$Auth$LoggedInGettingBoard(authUser)
						}),
					A2(
						$author$project$Api$Board$getLatestBoard,
						authUser,
						$author$project$App$GotBoard(authUser)));
			case 23:
				var authUser = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$random$Random$generate,
						$author$project$App$GotUuidForNewBoard(authUser),
						$danyx23$elm_uuid$Uuid$uuidGenerator));
			case 24:
				var authUser = msg.a;
				var uuid = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							F: $author$project$Auth$RegisteredCreatingBoard(authUser)
						}),
					A3(
						$author$project$Api$Board$createBoard,
						authUser,
						{
							bv: $author$project$App$modelToBoardBlob(model),
							eI: 'First Board',
							c1: uuid
						},
						$author$project$App$GotBoard(authUser)));
			case 25:
				var authUser = msg.a;
				var boardRes = msg.b;
				return _Utils_Tuple2(
					function () {
						if (!boardRes.$) {
							var board = boardRes.a;
							return A2(
								$author$project$App$loadBoardIntoModel,
								board,
								_Utils_update(
									model,
									{
										F: $author$project$Auth$LoggedInGotBoard(
											{cu: board.eI, cv: board.c1, ed: authUser}),
										ab: false
									}));
						} else {
							var err = boardRes.a;
							return model;
						}
					}(),
					$elm$core$Platform$Cmd$none);
			default:
				var result = msg.a;
				if (!result.$) {
					var authUser = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								F: $author$project$Auth$LoggedInGettingBoard(authUser)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$Board$getLatestBoard,
									authUser,
									$author$project$App$GotBoard(authUser)),
									$author$project$Ports$saveAuthUserToLocalStorage(
									$author$project$Types$AuthUser$encode(authUser))
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Tutorial$BudgetEditorMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Tutorial$CloneTimeline = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Tutorial$FocusBudgetLineItem = function (a) {
	return {$: 4, a: a};
};
var $author$project$Tutorial$ProjectionMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Tutorial$update = F2(
	function (msg, model) {
		var budgetsWithoutEditors = A2(
			$author$project$UuidDict$map,
			F2(
				function (_v12, v) {
					return v.a;
				}),
			model.n);
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 2:
				var m = msg.a;
				var _v1 = A2($author$project$Projection$update, m, model.aB);
				var updatedProjection = _v1.a;
				var projectionCmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aB: updatedProjection}),
					A2($elm$core$Platform$Cmd$map, $author$project$Tutorial$ProjectionMsg, projectionCmds));
			case 1:
				var i = msg.a;
				var m = msg.b;
				var _v2 = A2($elm$core$Array$get, i, model.g);
				if (!_v2.$) {
					var _v3 = _v2.a;
					var timeline = _v3.a;
					var timelineEditor = _v3.b;
					var _v4 = A5(
						$author$project$TimelineEditor$update,
						{
							ax: $elm$core$Maybe$Just(10),
							aH: 10
						},
						{
							e_: $author$project$Tutorial$FocusBudgetLineItem,
							f2: $author$project$Tutorial$TimelineEditorMsg(i)
						},
						m,
						timelineEditor,
						timeline);
					var updatedTimelineEditor = _v4.a;
					var updatedTimeline = _v4.b;
					var timelineEditorCmds = _v4.c;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: A3(
									$elm$core$Array$set,
									i,
									_Utils_Tuple2(updatedTimeline, updatedTimelineEditor),
									model.g)
							}),
						timelineEditorCmds);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var m = msg.a;
				var _v5 = A3($author$project$BudgetEditor$update, m, budgetsWithoutEditors, _List_Nil);
				var newBudgets = _v5.a;
				var budgetEditorCmds = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							n: A2(
								$author$project$UuidDict$map,
								F2(
									function (_v6, v) {
										return _Utils_Tuple2(v, $author$project$WeekEditor$init);
									}),
								newBudgets)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$Tutorial$BudgetEditorMsg, budgetEditorCmds)
							])));
			case 4:
				var itemDetails = msg.a;
				var maybeItemId = function (incomeOrExpense) {
					return A2(
						$elm$core$Maybe$map,
						function (i) {
							return A3(
								$author$project$BudgetEditor$generateItemDomId,
								1,
								incomeOrExpense,
								{ac: itemDetails.ac, fg: i});
						},
						A2(
							$elm$core$Maybe$andThen,
							$elm_community$list_extra$List$Extra$findIndex(
								function (_v9) {
									var description = _v9.a;
									return _Utils_eq(itemDetails.eI, description);
								}),
							A2(
								$elm$core$Maybe$map,
								function () {
									if (!incomeOrExpense) {
										return function ($) {
											return $.e6;
										};
									} else {
										return function ($) {
											return $.eS;
										};
									}
								}(),
								A2($author$project$UuidDict$get, itemDetails.ac, budgetsWithoutEditors))));
				};
				var _v7 = A2(
					$elm_community$maybe_extra$Maybe$Extra$or,
					maybeItemId(1),
					maybeItemId(0));
				if (!_v7.$) {
					var itemId = _v7.a;
					return _Utils_Tuple2(
						model,
						$author$project$Ports$focusSmooth(itemId));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var timelineIdx = msg.a.r;
				var time = msg.b;
				var _v10 = A2($elm$core$Array$get, timelineIdx, model.g);
				if (!_v10.$) {
					var _v11 = _v10.a;
					var timeline = _v11.a;
					var timelineEditor = _v11.b;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$random$Random$generate,
							function (uuid) {
								return A2(
									$author$project$Tutorial$CloneTimeline,
									{r: timelineIdx},
									_Utils_Tuple2(
										_Utils_update(
											timeline,
											{eC: time, eI: 'Copy of ' + timeline.eI, c1: uuid}),
										timelineEditor));
							},
							$danyx23$elm_uuid$Uuid$uuidGenerator));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				var timelineIdx = msg.a.r;
				var timeline = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							g: A3($elm_community$array_extra$Array$Extra$insertAt, timelineIdx + 1, timeline, model.g)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var timelineIdx = msg.a.r;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							g: A2($elm_community$array_extra$Array$Extra$removeAt, timelineIdx, model.g)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var msgToFire = msg.a;
				return _Utils_Tuple2(
					model,
					A2($elm$core$Task$perform, msgToFire, $elm$time$Time$now));
		}
	});
var $author$project$Landing$update = F2(
	function (msg, model) {
		var tutorialMsg = msg;
		var _v1 = A2($author$project$Tutorial$update, tutorialMsg, model.J);
		var newTutorial = _v1.a;
		var tutorialMsgs = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{J: newTutorial}),
			A2($elm$core$Platform$Cmd$map, $elm$core$Basics$identity, tutorialMsgs));
	});
var $author$project$Types$Budget$workPartTime = $author$project$Types$Budget$V1$workPartTime;
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				var msgToFire = msg.a;
				return _Utils_Tuple2(
					model,
					A2($elm$core$Task$perform, msgToFire, $elm$time$Time$now));
			case 2:
				var defaultInvestmentStrategy = msg.a.cI;
				var schedules = msg.a.C;
				var budgets = msg.a.n;
				var timelines = msg.a.g;
				var time = msg.b;
				var weekPlans = {
					dt: _List_fromArray(
						[
							{eN: 7, b1: schedules.a$}
						]),
					dB: _List_fromArray(
						[
							{eN: 2, b1: schedules.bs},
							{eN: 5, b1: schedules.a$}
						]),
					dO: _List_fromArray(
						[
							{eN: 5, b1: schedules.bs},
							{eN: 2, b1: schedules.a$}
						])
				};
				var budgetModelsList = A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, _v8) {
							var budgetId = _v8.a;
							var _v9 = _v8.b;
							var desc = _v9.a;
							var f = _v9.b;
							return _Utils_Tuple2(
								budgetId,
								A2(
									$elm$core$List$member,
									i,
									_List_fromArray(
										[0, 1])) ? f(
									A3($author$project$Types$Budget$init, budgetId, weekPlans.dO, desc)) : (A2(
									$elm$core$List$member,
									i,
									_List_fromArray(
										[3])) ? f(
									A3($author$project$Types$Budget$init, budgetId, weekPlans.dB, desc)) : f(
									A3($author$project$Types$Budget$init, budgetId, weekPlans.dt, desc))));
						}),
					A2($elm_community$list_extra$List$Extra$zip, budgets, $author$project$Types$Budget$sampleBudgets));
				var timelineModels = $elm$core$Array$fromList(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, t) {
								var foo = F2(
									function (timelineName, b) {
										return A5($author$project$Types$Timeline$makeTestTimelines, b, defaultInvestmentStrategy, time, timelineName, t);
									});
								var find = function (budgetName) {
									return A2(
										$elm$core$Maybe$map,
										$elm$core$Tuple$first,
										$elm$core$List$head(
											A2(
												$elm$core$List$filter,
												function (_v7) {
													var b = _v7.b;
													return _Utils_eq(b.eI, budgetName);
												},
												budgetModelsList)));
								};
								switch (i) {
									case 0:
										return A2(
											foo,
											'Work for 7 years then retire',
											function () {
												var _v3 = _Utils_Tuple2(
													find($author$project$Types$Budget$cuttingDown.a),
													find($author$project$Types$Budget$quitJob.a));
												if ((!_v3.a.$) && (!_v3.b.$)) {
													var b1 = _v3.a.a;
													var b2 = _v3.b.a;
													return _List_fromArray(
														[b1, b2]);
												} else {
													return budgets;
												}
											}());
									case 1:
										return A2(
											foo,
											'Work for 20 years',
											function () {
												var _v4 = find($author$project$Types$Budget$paycheckToPaycheck.a);
												if (_v4.$ === 1) {
													return budgets;
												} else {
													var bId = _v4.a;
													return _List_fromArray(
														[bId]);
												}
											}());
									case 2:
										return A2(
											foo,
											'Work and save for 20 years',
											function () {
												var _v5 = find($author$project$Types$Budget$cuttingDown.a);
												if (_v5.$ === 1) {
													return budgets;
												} else {
													var bId = _v5.a;
													return _List_fromArray(
														[bId]);
												}
											}());
									case 3:
										return A2(
											foo,
											'Work for 4 years and work part time',
											function () {
												var _v6 = _Utils_Tuple2(
													find($author$project$Types$Budget$cuttingDown.a),
													find($author$project$Types$Budget$workPartTime.a));
												if ((!_v6.a.$) && (!_v6.b.$)) {
													var b1 = _v6.a.a;
													var b2 = _v6.b.a;
													return _List_fromArray(
														[b1, b2]);
												} else {
													return budgets;
												}
											}());
									default:
										return A2(
											foo,
											'Scenario #' + $elm$core$String$fromInt(i),
											budgets);
								}
							}),
						A2($elm_community$list_extra$List$Extra$zip, timelines, $author$project$Types$Segment$exampleSegmentDurations)));
				var _v1 = $author$project$Types$Schedule$default(
					_Utils_Tuple2(schedules.bs, schedules.a$));
				var weekday = _v1.a;
				var weekend = _v1.b;
				var initSchedules = $author$project$UuidDict$fromList(
					_List_fromArray(
						[
							_Utils_Tuple2(weekday.c1, weekday),
							_Utils_Tuple2(weekend.c1, weekend)
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							I: $author$project$Main$PageStateLanding(
								_Utils_Tuple2(
									$author$project$Landing$init(
										{
											n: $author$project$UuidDict$fromList(budgetModelsList),
											z: $author$project$UuidDict$fromList(
												_List_fromArray(
													[
														_Utils_Tuple2(
														defaultInvestmentStrategy,
														$author$project$Types$InvestmentStrategy$default(defaultInvestmentStrategy))
													])),
											C: initSchedules,
											g: timelineModels
										}),
									$author$project$Auth$Anon))
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var boardBlob = msg.a;
				var authState = msg.b;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$random$Random$generate,
						A2($author$project$Main$GotoApp, boardBlob, authState),
						$danyx23$elm_uuid$Uuid$uuidGenerator));
			case 5:
				var boardBlob = msg.a;
				var authState = msg.b;
				var uuid = msg.c;
				var _v10 = $author$project$App$init(
					A2(
						$author$project$App$InitFromTutorial,
						authState,
						{bv: boardBlob, eI: 'First board', c1: uuid}));
				var appState = _v10.a;
				var appCmds = _v10.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							I: $author$project$Main$PageStateApp(appState)
						}),
					A2(
						$elm$core$Platform$Cmd$map,
						A2($elm$core$Basics$composeR, $author$project$Main$PageMsgApp, $author$project$Main$GotPageMsg),
						appCmds));
			case 3:
				var boardBlob = msg.a;
				var authState = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							I: $author$project$Main$PageStateLanding(
								_Utils_Tuple2(
									$author$project$Landing$init(boardBlob),
									authState))
						}),
					$author$project$Ports$setupLandingPage(0));
			case 6:
				var viewport = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{br: viewport}),
					$elm$core$Platform$Cmd$none);
			default:
				var pageMsg = msg.a;
				var _v11 = _Utils_Tuple2(model.I, pageMsg);
				_v11$2:
				while (true) {
					if (!_v11.a.$) {
						if (!_v11.b.$) {
							var _v12 = _v11.a.a;
							var ps = _v12.a;
							var auth = _v12.b;
							var pm = _v11.b.a;
							var _v13 = A2($author$project$Landing$update, pm, ps);
							var newPage = _v13.a;
							var pageCmds = _v13.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										I: $author$project$Main$PageStateLanding(
											_Utils_Tuple2(newPage, auth))
									}),
								A2(
									$elm$core$Platform$Cmd$map,
									A2($elm$core$Basics$composeR, $author$project$Main$PageMsgLanding, $author$project$Main$GotPageMsg),
									pageCmds));
						} else {
							break _v11$2;
						}
					} else {
						if (_v11.b.$ === 1) {
							var ps = _v11.a.a;
							var pm = _v11.b.a;
							var _v14 = A2($author$project$App$update, pm, ps);
							var newPage = _v14.a;
							var pageCmds = _v14.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										I: $author$project$Main$PageStateApp(newPage)
									}),
								A2(
									$elm$core$Platform$Cmd$map,
									A2($elm$core$Basics$composeR, $author$project$Main$PageMsgApp, $author$project$Main$GotPageMsg),
									pageCmds));
						} else {
							break _v11$2;
						}
					}
				}
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Main$GotoAppRequest = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Main$GotoLandingPage = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Tutorial$modelToBoardBlob = function (m) {
	return {
		n: A2(
			$author$project$UuidDict$map,
			F2(
				function (k, v) {
					return v.a;
				}),
			m.n),
		z: m.z,
		C: m.C,
		g: A2($elm$core$Array$map, $elm$core$Tuple$first, m.g)
	};
};
var $author$project$Landing$modelToBoardBlob = function (m) {
	return $author$project$Tutorial$modelToBoardBlob(m.J);
};
var $author$project$Style$withTrailingSpace = function (s) {
	return s + ' ';
};
var $author$project$Style$bar = $author$project$Style$withTrailingSpace('flex flex-row justify-between bg-gray-700 text-white p-2 cursor-pointer');
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$UI$pageBackButton = F2(
	function (msg, body) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class($author$project$Style$bar + 'fixed rounded-br-md z-50 space-x-4 flex-flex-row items-center'),
					A2($elm$html$Html$Attributes$style, 'cursor', 'pointer'),
					A2($elm$html$Html$Attributes$style, 'top', '0px'),
					A2($elm$html$Html$Attributes$style, 'left', '0px'),
					$elm$html$Html$Events$onClick(msg)
				]),
			body);
	});
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Icon$ArrowLeft = 7;
var $author$project$App$DockSchedule = 2;
var $author$project$App$SetPage = function (a) {
	return {$: 11, a: a};
};
var $avh4$elm_color$Color$RgbaSpace = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $avh4$elm_color$Color$scaleFrom255 = function (c) {
	return c / 255;
};
var $avh4$elm_color$Color$rgb255 = F3(
	function (r, g, b) {
		return A4(
			$avh4$elm_color$Color$RgbaSpace,
			$avh4$elm_color$Color$scaleFrom255(r),
			$avh4$elm_color$Color$scaleFrom255(g),
			$avh4$elm_color$Color$scaleFrom255(b),
			1.0);
	});
var $terezka$line_charts$LineChart$Colors$blue = A3($avh4$elm_color$Color$rgb255, 3, 169, 244);
var $terezka$line_charts$LineChart$Colors$gold = A3($avh4$elm_color$Color$rgb255, 205, 145, 60);
var $terezka$line_charts$LineChart$Colors$green = A3($avh4$elm_color$Color$rgb255, 67, 160, 71);
var $terezka$line_charts$LineChart$Colors$pink = A3($avh4$elm_color$Color$rgb255, 245, 105, 215);
var $terezka$line_charts$LineChart$Colors$purple = A3($avh4$elm_color$Color$rgb255, 156, 39, 176);
var $terezka$line_charts$LineChart$Colors$red = A3($avh4$elm_color$Color$rgb255, 216, 27, 96);
var $terezka$line_charts$LineChart$Colors$rust = A3($avh4$elm_color$Color$rgb255, 205, 102, 51);
var $terezka$line_charts$LineChart$Colors$teal = A3($avh4$elm_color$Color$rgb255, 29, 233, 182);
var $author$project$App$allColors = _List_fromArray(
	[$terezka$line_charts$LineChart$Colors$purple, $terezka$line_charts$LineChart$Colors$green, $terezka$line_charts$LineChart$Colors$red, $terezka$line_charts$LineChart$Colors$blue, $terezka$line_charts$LineChart$Colors$gold, $terezka$line_charts$LineChart$Colors$rust, $terezka$line_charts$LineChart$Colors$pink, $terezka$line_charts$LineChart$Colors$teal]);
var $author$project$Responsive$biggerThanIpad = function (viewport) {
	return true;
};
var $elm_community$list_extra$List$Extra$reverseAppend = F2(
	function (list1, list2) {
		return A3($elm$core$List$foldl, $elm$core$List$cons, list2, list1);
	});
var $elm_community$list_extra$List$Extra$cycleHelp = F3(
	function (acc, n, list) {
		cycleHelp:
		while (true) {
			if (n > 0) {
				var $temp$acc = A2($elm_community$list_extra$List$Extra$reverseAppend, list, acc),
					$temp$n = n - 1,
					$temp$list = list;
				acc = $temp$acc;
				n = $temp$n;
				list = $temp$list;
				continue cycleHelp;
			} else {
				return acc;
			}
		}
	});
var $elm_community$list_extra$List$Extra$cycle = F2(
	function (len, list) {
		var cycleLength = $elm$core$List$length(list);
		return ((!cycleLength) || _Utils_eq(cycleLength, len)) ? list : ((_Utils_cmp(cycleLength, len) < 0) ? $elm$core$List$reverse(
			A2(
				$elm_community$list_extra$List$Extra$reverseAppend,
				A2($elm$core$List$take, len % cycleLength, list),
				A3($elm_community$list_extra$List$Extra$cycleHelp, _List_Nil, (len / cycleLength) | 0, list))) : A2($elm$core$List$take, len, list));
	});
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $feathericons$elm_feather$FeatherIcons$Icon = $elm$core$Basics$identity;
var $feathericons$elm_feather$FeatherIcons$defaultAttributes = function (name) {
	return {
		a2: $elm$core$Maybe$Just('feather feather-' + name),
		d2: 24,
		aV: '',
		bn: 2,
		bq: '0 0 24 24'
	};
};
var $feathericons$elm_feather$FeatherIcons$makeBuilder = F2(
	function (name, src) {
		return {
			s: $feathericons$elm_feather$FeatherIcons$defaultAttributes(name),
			a: src
		};
	});
var $elm$svg$Svg$Attributes$points = _VirtualDom_attribute('points');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$polyline = $elm$svg$Svg$trustedNode('polyline');
var $feathericons$elm_feather$FeatherIcons$activity = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'activity',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('22 12 18 12 15 21 9 3 6 12 2 12')
				]),
			_List_Nil)
		]));
var $elm$svg$Svg$line = $elm$svg$Svg$trustedNode('line');
var $elm$svg$Svg$Attributes$x1 = _VirtualDom_attribute('x1');
var $elm$svg$Svg$Attributes$x2 = _VirtualDom_attribute('x2');
var $elm$svg$Svg$Attributes$y1 = _VirtualDom_attribute('y1');
var $elm$svg$Svg$Attributes$y2 = _VirtualDom_attribute('y2');
var $feathericons$elm_feather$FeatherIcons$alignJustify = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'align-justify',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('21'),
					$elm$svg$Svg$Attributes$y1('10'),
					$elm$svg$Svg$Attributes$x2('3'),
					$elm$svg$Svg$Attributes$y2('10')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('21'),
					$elm$svg$Svg$Attributes$y1('6'),
					$elm$svg$Svg$Attributes$x2('3'),
					$elm$svg$Svg$Attributes$y2('6')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('21'),
					$elm$svg$Svg$Attributes$y1('14'),
					$elm$svg$Svg$Attributes$x2('3'),
					$elm$svg$Svg$Attributes$y2('14')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('21'),
					$elm$svg$Svg$Attributes$y1('18'),
					$elm$svg$Svg$Attributes$x2('3'),
					$elm$svg$Svg$Attributes$y2('18')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$arrowLeft = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'arrow-left',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('19'),
					$elm$svg$Svg$Attributes$y1('12'),
					$elm$svg$Svg$Attributes$x2('5'),
					$elm$svg$Svg$Attributes$y2('12')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('12 19 5 12 12 5')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$chevronDown = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'chevron-down',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('6 9 12 15 18 9')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$chevronUp = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'chevron-up',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('18 15 12 9 6 15')
				]),
			_List_Nil)
		]));
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $feathericons$elm_feather$FeatherIcons$columns = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'columns',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18')
				]),
			_List_Nil)
		]));
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$rect = $elm$svg$Svg$trustedNode('rect');
var $elm$svg$Svg$Attributes$rx = _VirtualDom_attribute('rx');
var $elm$svg$Svg$Attributes$ry = _VirtualDom_attribute('ry');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $feathericons$elm_feather$FeatherIcons$copy = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'copy',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$rect,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x('9'),
					$elm$svg$Svg$Attributes$y('9'),
					$elm$svg$Svg$Attributes$width('13'),
					$elm$svg$Svg$Attributes$height('13'),
					$elm$svg$Svg$Attributes$rx('2'),
					$elm$svg$Svg$Attributes$ry('2')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$creditCard = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'credit-card',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$rect,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x('1'),
					$elm$svg$Svg$Attributes$y('4'),
					$elm$svg$Svg$Attributes$width('22'),
					$elm$svg$Svg$Attributes$height('16'),
					$elm$svg$Svg$Attributes$rx('2'),
					$elm$svg$Svg$Attributes$ry('2')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('1'),
					$elm$svg$Svg$Attributes$y1('10'),
					$elm$svg$Svg$Attributes$x2('23'),
					$elm$svg$Svg$Attributes$y2('10')
				]),
			_List_Nil)
		]));
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$map = $elm$virtual_dom$VirtualDom$map;
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$strokeLinecap = _VirtualDom_attribute('stroke-linecap');
var $elm$svg$Svg$Attributes$strokeLinejoin = _VirtualDom_attribute('stroke-linejoin');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $feathericons$elm_feather$FeatherIcons$toHtml = F2(
	function (attributes, _v0) {
		var src = _v0.a;
		var attrs = _v0.s;
		var strSize = $elm$core$String$fromFloat(attrs.d2);
		var baseAttributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$fill('none'),
				$elm$svg$Svg$Attributes$height(
				_Utils_ap(strSize, attrs.aV)),
				$elm$svg$Svg$Attributes$width(
				_Utils_ap(strSize, attrs.aV)),
				$elm$svg$Svg$Attributes$stroke('currentColor'),
				$elm$svg$Svg$Attributes$strokeLinecap('round'),
				$elm$svg$Svg$Attributes$strokeLinejoin('round'),
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(attrs.bn)),
				$elm$svg$Svg$Attributes$viewBox(attrs.bq)
			]);
		var combinedAttributes = _Utils_ap(
			function () {
				var _v1 = attrs.a2;
				if (!_v1.$) {
					var c = _v1.a;
					return A2(
						$elm$core$List$cons,
						$elm$svg$Svg$Attributes$class(c),
						baseAttributes);
				} else {
					return baseAttributes;
				}
			}(),
			attributes);
		return A2(
			$elm$svg$Svg$svg,
			combinedAttributes,
			A2(
				$elm$core$List$map,
				$elm$svg$Svg$map($elm$core$Basics$never),
				src));
	});
var $feathericons$elm_feather$FeatherIcons$withSize = F2(
	function (size, _v0) {
		var attrs = _v0.s;
		var src = _v0.a;
		return {
			s: _Utils_update(
				attrs,
				{d2: size}),
			a: src
		};
	});
var $author$project$Icon$featherIcon = F2(
	function (icon, attrs) {
		return A2(
			$feathericons$elm_feather$FeatherIcons$toHtml,
			attrs,
			A2($feathericons$elm_feather$FeatherIcons$withSize, 16, icon));
	});
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $feathericons$elm_feather$FeatherIcons$helpCircle = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'help-circle',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('12'),
					$elm$svg$Svg$Attributes$cy('12'),
					$elm$svg$Svg$Attributes$r('10')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('12'),
					$elm$svg$Svg$Attributes$y1('17'),
					$elm$svg$Svg$Attributes$x2('12.01'),
					$elm$svg$Svg$Attributes$y2('17')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$scissors = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'scissors',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('6'),
					$elm$svg$Svg$Attributes$cy('6'),
					$elm$svg$Svg$Attributes$r('3')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('6'),
					$elm$svg$Svg$Attributes$cy('18'),
					$elm$svg$Svg$Attributes$r('3')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('20'),
					$elm$svg$Svg$Attributes$y1('4'),
					$elm$svg$Svg$Attributes$x2('8.12'),
					$elm$svg$Svg$Attributes$y2('15.88')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('14.47'),
					$elm$svg$Svg$Attributes$y1('14.48'),
					$elm$svg$Svg$Attributes$x2('20'),
					$elm$svg$Svg$Attributes$y2('20')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('8.12'),
					$elm$svg$Svg$Attributes$y1('8.12'),
					$elm$svg$Svg$Attributes$x2('12'),
					$elm$svg$Svg$Attributes$y2('12')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$search = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'search',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('11'),
					$elm$svg$Svg$Attributes$cy('11'),
					$elm$svg$Svg$Attributes$r('8')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('21'),
					$elm$svg$Svg$Attributes$y1('21'),
					$elm$svg$Svg$Attributes$x2('16.65'),
					$elm$svg$Svg$Attributes$y2('16.65')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$settings = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'settings',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('12'),
					$elm$svg$Svg$Attributes$cy('12'),
					$elm$svg$Svg$Attributes$r('3')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$trash2 = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'trash-2',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('3 6 5 6 21 6')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('10'),
					$elm$svg$Svg$Attributes$y1('11'),
					$elm$svg$Svg$Attributes$x2('10'),
					$elm$svg$Svg$Attributes$y2('17')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('14'),
					$elm$svg$Svg$Attributes$y1('11'),
					$elm$svg$Svg$Attributes$x2('14'),
					$elm$svg$Svg$Attributes$y2('17')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$x = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'x',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('18'),
					$elm$svg$Svg$Attributes$y1('6'),
					$elm$svg$Svg$Attributes$x2('6'),
					$elm$svg$Svg$Attributes$y2('18')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('6'),
					$elm$svg$Svg$Attributes$y1('6'),
					$elm$svg$Svg$Attributes$x2('18'),
					$elm$svg$Svg$Attributes$y2('18')
				]),
			_List_Nil)
		]));
var $author$project$Icon$view = F2(
	function (icon, attrs) {
		var fa = function (faIcon) {
			return A2($author$project$Icon$featherIcon, faIcon, attrs);
		};
		switch (icon) {
			case 0:
				return fa($feathericons$elm_feather$FeatherIcons$chevronUp);
			case 1:
				return fa($feathericons$elm_feather$FeatherIcons$chevronDown);
			case 2:
				return fa($feathericons$elm_feather$FeatherIcons$copy);
			case 3:
				return fa($feathericons$elm_feather$FeatherIcons$scissors);
			case 4:
				return fa($feathericons$elm_feather$FeatherIcons$trash2);
			case 5:
				return fa($feathericons$elm_feather$FeatherIcons$x);
			case 6:
				return fa($feathericons$elm_feather$FeatherIcons$search);
			case 7:
				return fa($feathericons$elm_feather$FeatherIcons$arrowLeft);
			case 8:
				return fa($feathericons$elm_feather$FeatherIcons$helpCircle);
			case 9:
				return fa($feathericons$elm_feather$FeatherIcons$settings);
			case 10:
				return fa($feathericons$elm_feather$FeatherIcons$alignJustify);
			case 11:
				return fa($feathericons$elm_feather$FeatherIcons$columns);
			case 12:
				return fa($feathericons$elm_feather$FeatherIcons$creditCard);
			default:
				return fa($feathericons$elm_feather$FeatherIcons$activity);
		}
	});
var $author$project$Responsive$ipadWidth = 1200;
var $author$project$Responsive$biggerThanIpad2 = function (viewport) {
	return _Utils_cmp(viewport.br.aH, $author$project$Responsive$ipadWidth) > 0;
};
var $elm_community$list_extra$List$Extra$groupsOfWithStep = F3(
	function (size, step, xs) {
		var xs_ = A2($elm$core$List$drop, step, xs);
		var thisGroup = A2($elm$core$List$take, size, xs);
		var okayLength = _Utils_eq(
			size,
			$elm$core$List$length(thisGroup));
		var okayArgs = (size > 0) && (step > 0);
		return (okayArgs && okayLength) ? A2(
			$elm$core$List$cons,
			thisGroup,
			A3($elm_community$list_extra$List$Extra$groupsOfWithStep, size, step, xs_)) : _List_Nil;
	});
var $elm_community$list_extra$List$Extra$groupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$groupsOfWithStep, size, size, xs);
	});
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $author$project$Util$yearsToWeeks = function (years) {
	return $elm$core$Basics$round(years * 52);
};
var $author$project$LifeCalendar$getYearsInWeeks = F2(
	function (schedules, budgets) {
		var a = function (schedule) {
			return A2(
				$elm$core$List$map,
				function (_v1) {
					var alloc = _v1.b;
					return {
						bz: alloc.cC,
						bD: $elm$core$Basics$round(alloc.a0)
					};
				},
				$j_maas$elm_ordered_containers$OrderedDict$toList(schedule.aS));
		};
		var scheduleBlockToWeek = function (sb) {
			return A2(
				$elm$core$List$repeat,
				$elm$core$Basics$round(sb.eN),
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2(
						$elm$core$Maybe$map,
						a,
						A2($author$project$UuidDict$get, sb.b1, schedules))));
		};
		return A2(
			$elm_community$list_extra$List$Extra$groupsOf,
			52,
			$elm$core$List$concat(
				A2(
					$elm$core$List$map,
					function (_v0) {
						var durationYears = _v0.aL;
						var budget = _v0.cx;
						return A2(
							$elm$core$List$repeat,
							$author$project$Util$yearsToWeeks(durationYears),
							$elm$core$List$concat(
								A2($elm$core$List$map, scheduleBlockToWeek, budget.eu)));
					},
					budgets)));
	});
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $author$project$LifeCalendar$view = F3(
	function (viewport, schedules, budgets) {
		var hourCharacter = $author$project$Responsive$biggerThanIpad2(viewport) ? 'o' : '/';
		var viewDay = A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$div(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-row content-center items-center text-center tracking-normal')
					])),
			$elm$core$List$map(
				function (_v0) {
					var durationHours = _v0.bD;
					var color = _v0.bz;
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'color', color)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2($elm$core$String$repeat, durationHours, hourCharacter))
							]));
				}));
		var viewWeek = function (week) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('font-xs flex flex-row items-center border-b'),
						A2($elm$html$Html$Attributes$style, 'height', '1.25em'),
						A2($elm$html$Html$Attributes$style, 'vertical-align', 'center')
					]),
				$elm$core$List$concat(
					_List_fromArray(
						[
							A2(
							$elm$core$List$intersperse,
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-grow')
									]),
								_List_Nil),
							A2($elm$core$List$map, viewDay, week))
						])));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('m-2 space-y-8 mb-32')
				]),
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (yearI, year) {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('space-y-4')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('sticky top-0 text-white underline'),
											A2($elm$html$Html$Attributes$style, 'font-size', '10vh'),
											A2($elm$html$Html$Attributes$style, 'line-height', '10vh'),
											A2($elm$html$Html$Attributes$style, 'direction', 'rtl'),
											A2($elm$html$Html$Attributes$style, 'text-shadow', 'black 1px 1px 5px'),
											A2($elm$html$Html$Attributes$style, 'overflow-x', 'scroll')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$elm$core$String$fromInt(yearI + 1))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('p-4 bg-white bg-opacity-40 rounded-xl')
										]),
									A2($elm$core$List$map, viewWeek, year))
								]));
					}),
				A2($author$project$LifeCalendar$getYearsInWeeks, schedules, budgets)));
	});
var $author$project$Projection$Hover = $elm$core$Basics$identity;
var $terezka$line_charts$LineChart$Container$Margin = F4(
	function (top, right, bottom, left) {
		return {es: bottom, dd: left, fL: right, ea: top};
	});
var $terezka$line_charts$LineChart$Colors$black = A3($avh4$elm_color$Color$rgb255, 0, 0, 0);
var $terezka$line_charts$Internal$Dots$Circle = 1;
var $terezka$line_charts$LineChart$Dots$circle = 1;
var $terezka$line_charts$Internal$Axis$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$custom = $elm$core$Basics$identity;
var $terezka$line_charts$LineChart$Axis$custom = $terezka$line_charts$Internal$Axis$custom;
var $terezka$line_charts$Internal$Container$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Container$custom = $elm$core$Basics$identity;
var $terezka$line_charts$LineChart$Container$custom = $terezka$line_charts$Internal$Container$custom;
var $terezka$line_charts$Internal$Dots$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Dots$customAny = $elm$core$Basics$identity;
var $terezka$line_charts$LineChart$Dots$customAny = $terezka$line_charts$Internal$Dots$customAny;
var $terezka$line_charts$Internal$Area$None = {$: 0};
var $terezka$line_charts$Internal$Area$none = $terezka$line_charts$Internal$Area$None;
var $terezka$line_charts$LineChart$Area$default = $terezka$line_charts$Internal$Area$none;
var $terezka$line_charts$Internal$Axis$Values$Around = function (a) {
	return {$: 1, a: a};
};
var $terezka$line_charts$Internal$Axis$Values$around = $terezka$line_charts$Internal$Axis$Values$Around;
var $terezka$line_charts$Internal$Axis$Title$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$Title$custom = F4(
	function (position, x, y, title) {
		return {
			dv: _Utils_Tuple2(x, y),
			bh: position,
			f9: title
		};
	});
var $elm$svg$Svg$Attributes$style = _VirtualDom_attribute('style');
var $elm$svg$Svg$text = $elm$virtual_dom$VirtualDom$text;
var $elm$svg$Svg$text_ = $elm$svg$Svg$trustedNode('text');
var $elm$svg$Svg$tspan = $elm$svg$Svg$trustedNode('tspan');
var $terezka$line_charts$Internal$Svg$label = F2(
	function (color, string) {
		return A2(
			$elm$svg$Svg$text_,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$fill(color),
					$elm$svg$Svg$Attributes$style('pointer-events: none;')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$tspan,
					_List_Nil,
					_List_fromArray(
						[
							$elm$svg$Svg$text(string)
						]))
				]));
	});
var $terezka$line_charts$Internal$Axis$Title$atPosition = F3(
	function (position, x, y) {
		return A2(
			$elm$core$Basics$composeL,
			A3($terezka$line_charts$Internal$Axis$Title$custom, position, x, y),
			$terezka$line_charts$Internal$Svg$label('inherit'));
	});
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $terezka$line_charts$Internal$Axis$Title$atDataMax = function () {
	var position = F2(
		function (data, range) {
			return A2($elm$core$Basics$min, data.a8, range.a8);
		});
	return $terezka$line_charts$Internal$Axis$Title$atPosition(position);
}();
var $terezka$line_charts$Internal$Axis$Ticks$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$Ticks$custom = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$Values$ceilingTo = F2(
	function (prec, number) {
		return prec * $elm$core$Basics$ceiling(number / prec);
	});
var $terezka$line_charts$Internal$Axis$Values$getBeginning = F2(
	function (min, interval) {
		var multiple = min / interval;
		return _Utils_eq(
			multiple,
			$elm$core$Basics$round(multiple)) ? min : A2($terezka$line_charts$Internal$Axis$Values$ceilingTo, interval, min);
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $elm$core$String$cons = _String_cons;
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $terezka$line_charts$Internal$Axis$Values$correctFloat = function (prec) {
	return A2(
		$elm$core$Basics$composeR,
		$myrho$elm_round$Round$round(prec),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$String$toFloat,
			$elm$core$Maybe$withDefault(0)));
};
var $terezka$line_charts$Internal$Axis$Values$getMultiples = F3(
	function (magnitude, allowDecimals, hasTickAmount) {
		var defaults = hasTickAmount ? _List_fromArray(
			[1, 1.2, 1.5, 2, 2.5, 3, 4, 5, 6, 8, 10]) : _List_fromArray(
			[1, 2, 2.5, 5, 10]);
		return allowDecimals ? defaults : ((magnitude === 1) ? A2(
			$elm$core$List$filter,
			function (n) {
				return _Utils_eq(
					$elm$core$Basics$round(n),
					n);
			},
			defaults) : ((magnitude <= 0.1) ? _List_fromArray(
			[1 / magnitude]) : defaults));
	});
var $terezka$line_charts$Internal$Axis$Values$getPrecision = function (number) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(number));
	if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
		var before = _v0.a;
		var _v1 = _v0.b;
		var after = _v1.a;
		return $elm$core$Basics$abs(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(after)));
	} else {
		var _v2 = A2(
			$elm$core$String$split,
			'.',
			$elm$core$String$fromFloat(number));
		if ((_v2.b && _v2.b.b) && (!_v2.b.b.b)) {
			var before = _v2.a;
			var _v3 = _v2.b;
			var after = _v3.a;
			return $elm$core$String$length(after);
		} else {
			return 0;
		}
	}
};
var $elm$core$Basics$e = _Basics_e;
var $elm$core$Basics$pow = _Basics_pow;
var $terezka$line_charts$Internal$Utils$magnitude = function (num) {
	return A2(
		$elm$core$Basics$pow,
		10,
		$elm$core$Basics$floor(
			A2($elm$core$Basics$logBase, $elm$core$Basics$e, num) / A2($elm$core$Basics$logBase, $elm$core$Basics$e, 10)));
};
var $terezka$line_charts$Internal$Axis$Values$getInterval = F3(
	function (intervalRaw, allowDecimals, hasTickAmount) {
		var magnitude = $terezka$line_charts$Internal$Utils$magnitude(intervalRaw);
		var multiples = A3($terezka$line_charts$Internal$Axis$Values$getMultiples, magnitude, allowDecimals, hasTickAmount);
		var normalized = intervalRaw / magnitude;
		var findMultipleExact = function (multiples_) {
			findMultipleExact:
			while (true) {
				if (multiples_.b) {
					var m1 = multiples_.a;
					var rest = multiples_.b;
					if (_Utils_cmp(m1 * magnitude, intervalRaw) > -1) {
						return m1;
					} else {
						var $temp$multiples_ = rest;
						multiples_ = $temp$multiples_;
						continue findMultipleExact;
					}
				} else {
					return 1;
				}
			}
		};
		var findMultiple = function (multiples_) {
			findMultiple:
			while (true) {
				if (multiples_.b) {
					if (multiples_.b.b) {
						var m1 = multiples_.a;
						var _v2 = multiples_.b;
						var m2 = _v2.a;
						var rest = _v2.b;
						if (_Utils_cmp(normalized, (m1 + m2) / 2) < 1) {
							return m1;
						} else {
							var $temp$multiples_ = A2($elm$core$List$cons, m2, rest);
							multiples_ = $temp$multiples_;
							continue findMultiple;
						}
					} else {
						var m1 = multiples_.a;
						var rest = multiples_.b;
						if (_Utils_cmp(normalized, m1) < 1) {
							return m1;
						} else {
							var $temp$multiples_ = rest;
							multiples_ = $temp$multiples_;
							continue findMultiple;
						}
					}
				} else {
					return 1;
				}
			}
		};
		var multiple = hasTickAmount ? findMultipleExact(multiples) : findMultiple(multiples);
		var precision = $terezka$line_charts$Internal$Axis$Values$getPrecision(magnitude) + $terezka$line_charts$Internal$Axis$Values$getPrecision(multiple);
		return A2($terezka$line_charts$Internal$Axis$Values$correctFloat, precision, multiple * magnitude);
	});
var $terezka$line_charts$Internal$Axis$Values$positions = F5(
	function (range, beginning, interval, m, acc) {
		positions:
		while (true) {
			var next = A2(
				$terezka$line_charts$Internal$Axis$Values$correctFloat,
				$terezka$line_charts$Internal$Axis$Values$getPrecision(interval),
				beginning + (m * interval));
			if (_Utils_cmp(next, range.a8) > 0) {
				return acc;
			} else {
				var $temp$range = range,
					$temp$beginning = beginning,
					$temp$interval = interval,
					$temp$m = m + 1,
					$temp$acc = _Utils_ap(
					acc,
					_List_fromArray(
						[next]));
				range = $temp$range;
				beginning = $temp$beginning;
				interval = $temp$interval;
				m = $temp$m;
				acc = $temp$acc;
				continue positions;
			}
		}
	});
var $terezka$line_charts$Internal$Axis$Values$values = F4(
	function (allowDecimals, exact, amountRough, range) {
		var intervalRough = (range.a8 - range.fl) / amountRough;
		var interval = A3($terezka$line_charts$Internal$Axis$Values$getInterval, intervalRough, allowDecimals, exact);
		var intervalSafe = (!interval) ? 1 : interval;
		var beginning = A2($terezka$line_charts$Internal$Axis$Values$getBeginning, range.fl, intervalSafe);
		var amountRoughSafe = (!amountRough) ? 1 : amountRough;
		return A5($terezka$line_charts$Internal$Axis$Values$positions, range, beginning, intervalSafe, 0, _List_Nil);
	});
var $terezka$line_charts$Internal$Axis$Values$float = function (amount) {
	if (!amount.$) {
		var amount_ = amount.a;
		return A3($terezka$line_charts$Internal$Axis$Values$values, true, true, amount_);
	} else {
		var amount_ = amount.a;
		return A3($terezka$line_charts$Internal$Axis$Values$values, true, false, amount_);
	}
};
var $terezka$line_charts$Internal$Axis$Tick$Negative = 0;
var $terezka$line_charts$Internal$Axis$Tick$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$Tick$custom = $elm$core$Basics$identity;
var $terezka$line_charts$LineChart$Colors$gray = A3($avh4$elm_color$Color$rgb255, 163, 163, 163);
var $terezka$line_charts$Internal$Axis$Tick$float = function (n) {
	return $terezka$line_charts$Internal$Axis$Tick$custom(
		{
			bz: $terezka$line_charts$LineChart$Colors$gray,
			bC: 0,
			cY: true,
			fi: $elm$core$Maybe$Just(
				A2(
					$terezka$line_charts$Internal$Svg$label,
					'inherit',
					$elm$core$String$fromFloat(n))),
			bP: 5,
			bh: n,
			aH: 1
		});
};
var $terezka$line_charts$LineChart$Axis$Tick$float = $terezka$line_charts$Internal$Axis$Tick$float;
var $terezka$line_charts$Internal$Axis$Range$Padded = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $terezka$line_charts$Internal$Axis$Range$padded = $terezka$line_charts$Internal$Axis$Range$Padded;
var $terezka$line_charts$Internal$Axis$Line$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$Line$custom = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Coordinate$smallestRange = F2(
	function (data, range_) {
		return {
			a8: A2($elm$core$Basics$min, data.a8, range_.a8),
			fl: A2($elm$core$Basics$max, data.fl, range_.fl)
		};
	});
var $terezka$line_charts$Internal$Axis$Line$rangeFrame = function (color) {
	return $terezka$line_charts$Internal$Axis$Line$custom(
		F2(
			function (data, range) {
				var smallest = A2($terezka$line_charts$Internal$Coordinate$smallestRange, data, range);
				return {bz: color, cN: smallest.a8, cP: _List_Nil, cb: smallest.fl, aH: 1};
			}));
};
var $terezka$line_charts$Internal$Axis$default = F3(
	function (pixels_, title_, variable_) {
		return $terezka$line_charts$Internal$Axis$custom(
			{
				cr: $terezka$line_charts$Internal$Axis$Line$rangeFrame($terezka$line_charts$LineChart$Colors$gray),
				dF: pixels_,
				dM: A2($terezka$line_charts$Internal$Axis$Range$padded, 20, 20),
				d7: $terezka$line_charts$Internal$Axis$Ticks$custom(
					F2(
						function (data, range_) {
							var smallest = A2($terezka$line_charts$Internal$Coordinate$smallestRange, data, range_);
							var rangeSmall = smallest.a8 - smallest.fl;
							var rangeLong = range_.a8 - range_.fl;
							var diff = 1 - ((rangeLong - rangeSmall) / rangeLong);
							var amount = $elm$core$Basics$round((diff * pixels_) / 90);
							return A2(
								$elm$core$List$map,
								$terezka$line_charts$LineChart$Axis$Tick$float,
								A2(
									$terezka$line_charts$Internal$Axis$Values$float,
									$terezka$line_charts$Internal$Axis$Values$around(amount),
									smallest));
						})),
				d9: A3($terezka$line_charts$Internal$Axis$Title$atDataMax, 0, 0, title_),
				ef: A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, variable_)
			});
	});
var $terezka$line_charts$LineChart$Axis$default = $terezka$line_charts$Internal$Axis$default;
var $terezka$line_charts$Internal$Axis$Intersection$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Data$Point = F2(
	function (x, y) {
		return {ci: x, ck: y};
	});
var $terezka$line_charts$Internal$Axis$Intersection$custom = F2(
	function (toX, toY) {
		return function (_v0) {
			var x = _v0.ci;
			var y = _v0.ck;
			return A2(
				$terezka$line_charts$Internal$Data$Point,
				toX(x),
				toY(y));
		};
	});
var $terezka$line_charts$Internal$Axis$Intersection$default = A2(
	$terezka$line_charts$Internal$Axis$Intersection$custom,
	function ($) {
		return $.fl;
	},
	function ($) {
		return $.fl;
	});
var $terezka$line_charts$LineChart$Axis$Intersection$default = $terezka$line_charts$Internal$Axis$Intersection$default;
var $terezka$line_charts$Internal$Axis$Range$default = A2($terezka$line_charts$Internal$Axis$Range$padded, 0, 0);
var $terezka$line_charts$LineChart$Axis$Range$default = $terezka$line_charts$Internal$Axis$Range$default;
var $terezka$line_charts$Internal$Axis$Ticks$floatCustom = F2(
	function (amount, tick) {
		return $terezka$line_charts$Internal$Axis$Ticks$custom(
			F2(
				function (data, range) {
					return A2(
						$elm$core$List$map,
						tick,
						A2(
							$terezka$line_charts$Internal$Axis$Values$float,
							$terezka$line_charts$Internal$Axis$Values$around(amount),
							A2($terezka$line_charts$Internal$Coordinate$smallestRange, data, range)));
				}));
	});
var $terezka$line_charts$Internal$Axis$Ticks$float = function (amount) {
	return A2($terezka$line_charts$Internal$Axis$Ticks$floatCustom, amount, $terezka$line_charts$LineChart$Axis$Tick$float);
};
var $terezka$line_charts$LineChart$Axis$Ticks$default = $terezka$line_charts$Internal$Axis$Ticks$float(5);
var $terezka$line_charts$Internal$Axis$Title$atAxisMax = function () {
	var position = F2(
		function (data, range) {
			return range.a8;
		});
	return $terezka$line_charts$Internal$Axis$Title$atPosition(position);
}();
var $terezka$line_charts$Internal$Axis$Title$default = A2($terezka$line_charts$Internal$Axis$Title$atAxisMax, 0, 0);
var $terezka$line_charts$LineChart$Axis$Title$default = $terezka$line_charts$Internal$Axis$Title$default;
var $terezka$line_charts$Internal$Interpolation$Linear = 0;
var $terezka$line_charts$LineChart$Interpolation$linear = 0;
var $terezka$line_charts$LineChart$Interpolation$default = $terezka$line_charts$LineChart$Interpolation$linear;
var $terezka$line_charts$Internal$Line$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Line$Style = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Line$style = F2(
	function (width, color_) {
		return {bz: color_, aH: width};
	});
var $terezka$line_charts$Internal$Line$default = function (_v0) {
	return A2($terezka$line_charts$Internal$Line$style, 1, $elm$core$Basics$identity);
};
var $terezka$line_charts$LineChart$Line$default = $terezka$line_charts$Internal$Line$default;
var $elm$regex$Regex$findAtMost = _Regex_findAtMost;
var $elm_community$string_extra$String$Extra$firstResultHelp = F2(
	function (_default, list) {
		firstResultHelp:
		while (true) {
			if (!list.b) {
				return _default;
			} else {
				if (!list.a.$) {
					var a = list.a.a;
					return a;
				} else {
					var _v1 = list.a;
					var rest = list.b;
					var $temp$default = _default,
						$temp$list = rest;
					_default = $temp$default;
					list = $temp$list;
					continue firstResultHelp;
				}
			}
		}
	});
var $elm_community$string_extra$String$Extra$firstResult = function (list) {
	return A2($elm_community$string_extra$String$Extra$firstResultHelp, '', list);
};
var $elm_community$string_extra$String$Extra$regexFromString = A2(
	$elm$core$Basics$composeR,
	$elm$regex$Regex$fromString,
	$elm$core$Maybe$withDefault($elm$regex$Regex$never));
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $elm_community$string_extra$String$Extra$regexEscape = A2(
	$elm$regex$Regex$replace,
	$elm_community$string_extra$String$Extra$regexFromString('[-/\\^$*+?.()|[\\]{}]'),
	function (_v0) {
		var match = _v0.fk;
		return '\\' + match;
	});
var $elm_community$string_extra$String$Extra$leftOf = F2(
	function (pattern, string) {
		return A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.fU;
					},
					$elm_community$string_extra$String$Extra$firstResult),
				A3(
					$elm$regex$Regex$findAtMost,
					1,
					$elm_community$string_extra$String$Extra$regexFromString(
						'^(.*?)' + $elm_community$string_extra$String$Extra$regexEscape(pattern)),
					string)));
	});
var $elm_community$string_extra$String$Extra$rightOf = F2(
	function (pattern, string) {
		return A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.fU;
					},
					$elm_community$string_extra$String$Extra$firstResult),
				A3(
					$elm$regex$Regex$findAtMost,
					1,
					$elm_community$string_extra$String$Extra$regexFromString(
						$elm_community$string_extra$String$Extra$regexEscape(pattern) + '(.*)$'),
					string)));
	});
var $elm_community$string_extra$String$Extra$breaker = F3(
	function (width, string, acc) {
		breaker:
		while (true) {
			if (string === '') {
				return $elm$core$List$reverse(acc);
			} else {
				var $temp$width = width,
					$temp$string = A2($elm$core$String$dropLeft, width, string),
					$temp$acc = A2(
					$elm$core$List$cons,
					A3($elm$core$String$slice, 0, width, string),
					acc);
				width = $temp$width;
				string = $temp$string;
				acc = $temp$acc;
				continue breaker;
			}
		}
	});
var $elm_community$string_extra$String$Extra$break = F2(
	function (width, string) {
		return ((!width) || (string === '')) ? _List_fromArray(
			[string]) : A3($elm_community$string_extra$String$Extra$breaker, width, string, _List_Nil);
	});
var $elm_community$string_extra$String$Extra$wrapWith = F3(
	function (width, separator, string) {
		return A2(
			$elm$core$String$join,
			separator,
			A2($elm_community$string_extra$String$Extra$break, width, string));
	});
var $author$project$Util$formatMoney = A2(
	$elm$core$Basics$composeR,
	$myrho$elm_round$Round$round(2),
	A2(
		$elm$core$Basics$composeR,
		function (rounded) {
			return _Utils_Tuple2(
				A2($elm_community$string_extra$String$Extra$leftOf, '.', rounded),
				A2($elm_community$string_extra$String$Extra$rightOf, '.', rounded));
		},
		function (_v0) {
			var left = _v0.a;
			var right = _v0.b;
			return A2(
				$elm$core$String$join,
				'',
				_List_fromArray(
					[
						'$',
						$elm$core$String$reverse(
						A3(
							$elm_community$string_extra$String$Extra$wrapWith,
							3,
							',',
							$elm$core$String$reverse(left))),
						'.',
						right
					]));
		}));
var $terezka$line_charts$Internal$Axis$Line$full = function (color) {
	return $terezka$line_charts$Internal$Axis$Line$custom(
		F2(
			function (data, range) {
				return {bz: color, cN: range.a8, cP: _List_Nil, cb: range.fl, aH: 1};
			}));
};
var $terezka$line_charts$LineChart$Axis$Line$full = $terezka$line_charts$Internal$Axis$Line$full;
var $terezka$line_charts$Internal$Dots$Full = {$: 3};
var $terezka$line_charts$Internal$Dots$Style = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Dots$style = F2(
	function (radius, variety) {
		return {b$: radius, ce: variety};
	});
var $terezka$line_charts$Internal$Dots$full = function (radius) {
	return A2($terezka$line_charts$Internal$Dots$style, radius, $terezka$line_charts$Internal$Dots$Full);
};
var $terezka$line_charts$LineChart$Dots$full = $terezka$line_charts$Internal$Dots$full;
var $author$project$Types$Budget$V1$calculateMortgageLengthMonths = function (_v0) {
	var principal = _v0.aA;
	var repayment = _v0.dQ;
	var interest = _v0.ay;
	var p = principal;
	var m = repayment;
	var ln = $elm$core$Basics$logBase($elm$core$Basics$e);
	var i = (interest / 100) / 12;
	return $elm$core$Basics$round(
		ln(m / (m - (i * p))) / ln(1 + i));
};
var $author$project$Types$Budget$V1$calculateMortgageRepaymentMonthlyMinimum = function (m) {
	var r = (m.ay / 100) / 12;
	var p = m.aA;
	var n = 12 * m.bQ;
	return ((p * r) * A2($elm$core$Basics$pow, 1 + r, n)) / (A2($elm$core$Basics$pow, 1 + r, n) - 1);
};
var $author$project$Types$Budget$V1$generateMortgagePrincipalAndInterestSplit_ = F2(
	function (m, something) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (repaymentAmt, _v0) {
					var currPrincipal = _v0.a;
					var list = _v0.b;
					var interestPaid = currPrincipal * ((m.ay / 100) / 12);
					var principalReduction = repaymentAmt - interestPaid;
					return _Utils_Tuple2(
						currPrincipal - principalReduction,
						_Utils_ap(
							list,
							_List_fromArray(
								[
									{c6: interestPaid, bi: principalReduction}
								])));
				}),
			_Utils_Tuple2(m.aA, _List_Nil),
			something).b;
	});
var $author$project$Types$Budget$V1$generateMortgagePrincipalAndInterestSplitWithExtraRepayments = function (m) {
	var minimumRepayment = $author$project$Types$Budget$V1$calculateMortgageRepaymentMonthlyMinimum(m);
	var actualRepayment = minimumRepayment + m.cn;
	return A2(
		$author$project$Types$Budget$V1$generateMortgagePrincipalAndInterestSplit_,
		m,
		A2(
			$elm$core$List$repeat,
			$author$project$Types$Budget$V1$calculateMortgageLengthMonths(
				{ay: m.ay, aA: m.aA, dQ: actualRepayment}),
			actualRepayment));
};
var $author$project$Types$Budget$generateMortgagePrincipalAndInterestSplitWithExtraRepayments = $author$project$Types$Budget$V1$generateMortgagePrincipalAndInterestSplitWithExtraRepayments;
var $author$project$Types$Budget$V1$calculateMortgageRepaymentMonthlyActual = function (m) {
	return $author$project$Types$Budget$V1$calculateMortgageRepaymentMonthlyMinimum(m) + m.cn;
};
var $author$project$Types$CalcResult$V1$getValue = function (r) {
	if (r.$ === 1) {
		var _v1 = r.a;
		var v = _v1.b;
		return v;
	} else {
		var _v2 = r.a;
		var v = _v2.b;
		return v;
	}
};
var $author$project$Types$CalcResult$getValue = $author$project$Types$CalcResult$V1$getValue;
var $author$project$Types$Budget$V1$getExpensesSum = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.eS;
	},
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(
			A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $author$project$Types$CalcResult$getValue)),
		$elm$core$List$sum));
var $author$project$Types$Budget$V1$getIncomeSum = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.e6;
	},
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(
			A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $author$project$Types$CalcResult$getValue)),
		$elm$core$List$sum));
var $elm_community$maybe_extra$Maybe$Extra$unwrap = F3(
	function (_default, f, m) {
		if (m.$ === 1) {
			return _default;
		} else {
			var a = m.a;
			return f(a);
		}
	});
var $author$project$Types$Budget$V1$getBalance_ = F2(
	function (_v0, budget) {
		var withMortgage = _v0.ch;
		var mortgagePayments = withMortgage ? $elm$core$Basics$round(
			A3($elm_community$maybe_extra$Maybe$Extra$unwrap, 0, $author$project$Types$Budget$V1$calculateMortgageRepaymentMonthlyActual, budget.aO)) : 0;
		var income = $author$project$Types$Budget$V1$getIncomeSum(budget);
		var expenses = $author$project$Types$Budget$V1$getExpensesSum(budget);
		return (income - expenses) - mortgagePayments;
	});
var $author$project$Types$Budget$V1$getBalanceWithoutMortgage = $author$project$Types$Budget$V1$getBalance_(
	{ch: false});
var $author$project$Types$Budget$getBalanceWithoutMortgage = $author$project$Types$Budget$V1$getBalanceWithoutMortgage;
var $author$project$Projection$calcReccuringMonthlyIncome = F2(
	function (_v0, budget) {
		var years = _v0.ek;
		var segmentMonths = $elm$core$Basics$round(years * 12);
		var repayments = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2($elm$core$Maybe$map, $author$project$Types$Budget$generateMortgagePrincipalAndInterestSplitWithExtraRepayments, budget.aO));
		var monthsMissing = segmentMonths - $elm$core$List$length(repayments);
		var trimmedOrPaddedList = (_Utils_cmp(
			$elm$core$List$length(repayments),
			segmentMonths) < 0) ? _Utils_ap(
			repayments,
			A2(
				$elm$core$List$repeat,
				monthsMissing,
				{c6: 0, bi: 0})) : A2($elm$core$List$take, segmentMonths, repayments);
		return A2(
			$elm$core$List$map,
			function (_v1) {
				var interestPaid = _v1.c6;
				var principalReduction = _v1.bi;
				return {
					e6: ($author$project$Types$Budget$getBalanceWithoutMortgage(budget) - interestPaid) - principalReduction,
					bi: principalReduction
				};
			},
			trimmedOrPaddedList);
	});
var $author$project$Projection$equityStr = 'Equity';
var $author$project$Types$InvestmentStrategy$Savings = {$: 0};
var $author$project$Types$InvestmentStrategy$assetToString = function (asset) {
	if (!asset.$) {
		return 'Savings';
	} else {
		var k = asset.a;
		return k;
	}
};
var $author$project$Projection$initAssets = function (_v0) {
	var initialAmount = _v0.an;
	return _List_fromArray(
		[
			_Utils_Tuple2(
			$author$project$Types$InvestmentStrategy$assetToString($author$project$Types$InvestmentStrategy$Savings),
			initialAmount)
		]);
};
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$Projection$isSavings = function (s) {
	return _Utils_eq(
		s,
		$author$project$Types$InvestmentStrategy$assetToString($author$project$Types$InvestmentStrategy$Savings));
};
var $author$project$Projection$getSavingsFromAssets = function (assets) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2(
			$elm$core$Maybe$map,
			function (_v1) {
				var v = _v1.b;
				return v;
			},
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (_v0) {
						var t = _v0.a;
						return $author$project$Projection$isSavings(t);
					},
					assets))));
};
var $author$project$Projection$increase = F2(
	function (interest, amount) {
		return (1 + ((interest / 12) / 100)) * amount;
	});
var $author$project$Projection$calculateInterest = F3(
	function (income, assets, _v0) {
		var description = _v0.a;
		var allocation = _v0.b.a0;
		var interest = _v0.b.ay;
		var sumNW = $elm$core$List$sum(
			A2($elm$core$List$map, $elm$core$Tuple$second, assets));
		var savings = $author$project$Projection$getSavingsFromAssets(assets);
		var assetAmountInLastPeriod = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Tuple$second,
				A2(
					$elm_community$list_extra$List$Extra$find,
					function (_v1) {
						var desc = _v1.a;
						return _Utils_eq(desc, description);
					},
					assets)));
		var handleNegativeNW = $author$project$Projection$isSavings(description) ? (income + assetAmountInLastPeriod) : assetAmountInLastPeriod;
		return _Utils_Tuple2(
			description,
			(sumNW <= 0) ? handleNegativeNW : A2($author$project$Projection$increase, interest, ((allocation / 100) * income) + assetAmountInLastPeriod));
	});
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $author$project$Types$InvestmentStrategy$toList = function (is) {
	return $elm$core$List$concat(
		_List_fromArray(
			[
				_List_fromArray(
				[
					_Utils_Tuple2(
					$author$project$Types$InvestmentStrategy$assetToString($author$project$Types$InvestmentStrategy$Savings),
					{a0: is.aC.a0, ay: is.aC.ay})
				]),
				$elm$core$Dict$toList(is.aS)
			]));
};
var $elm_community$list_extra$List$Extra$updateIf = F3(
	function (predicate, update, list) {
		return A2(
			$elm$core$List$map,
			function (item) {
				return predicate(item) ? update(item) : item;
			},
			list);
	});
var $author$project$Projection$stepAssets = F3(
	function (cashIncome, strat, assets) {
		var getSavings = $author$project$Projection$getSavingsFromAssets(assets);
		var assetsWithUpdatedSavings = function (newSavings) {
			return A3(
				$elm_community$list_extra$List$Extra$updateIf,
				function (_v2) {
					var t = _v2.a;
					return $author$project$Projection$isSavings(t);
				},
				function (_v3) {
					var d = _v3.a;
					var v = _v3.b;
					return _Utils_Tuple2(d, newSavings);
				},
				assets);
		};
		var assetsNoLongerInStrat = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$second,
				A2(
					$elm$core$List$filter,
					function (_v1) {
						var t = _v1.a;
						return !A2(
							$elm$core$List$member,
							t,
							A2(
								$elm$core$List$cons,
								$author$project$Projection$equityStr,
								A2(
									$elm$core$List$cons,
									$author$project$Types$InvestmentStrategy$assetToString($author$project$Types$InvestmentStrategy$Savings),
									$elm$core$Dict$keys(strat.aS))));
					},
					assets)));
		var getIncome = cashIncome + assetsNoLongerInStrat;
		var toProcess = function () {
			var savingsAlloc = strat.aC.a0 / $elm$core$Dict$size(strat.aS);
			var mapAlloc = function (f) {
				return A2(
					$elm$core$Dict$map,
					F2(
						function (_v0, v) {
							return f(v);
						}),
					strat.aS);
			};
			var redistributeSavingsAlloc = _Utils_update(
				strat,
				{
					aS: mapAlloc(
						function (is) {
							return _Utils_update(
								is,
								{a0: is.a0 + savingsAlloc});
						}),
					aC: function (s) {
						return _Utils_update(
							s,
							{a0: 0});
					}(strat.aC)
				});
			var justSavingsAlloc = _Utils_update(
				strat,
				{
					aS: mapAlloc(
						function (is) {
							return _Utils_update(
								is,
								{a0: 0});
						}),
					aC: function (s) {
						return _Utils_update(
							s,
							{a0: 100});
					}(strat.aC)
				});
			var ret = {
				bu: assets,
				e6: getIncome,
				bm: (cashIncome <= 0) ? ((getSavings > 0) ? justSavingsAlloc : redistributeSavingsAlloc) : strat
			};
			return (!strat.aC.a8) ? ret : ((_Utils_cmp(
				A2($author$project$Projection$increase, strat.aC.ay, getSavings),
				strat.aC.a8) > -1) ? (((assetsNoLongerInStrat > 0) && (!$elm$core$Dict$size(strat.aS))) ? ret : {
				bu: assetsWithUpdatedSavings(strat.aC.a8),
				e6: getIncome + (getSavings - strat.aC.a8),
				bm: redistributeSavingsAlloc
			}) : ((_Utils_cmp(getSavings, strat.aC.a8) < 0) ? _Utils_update(
				ret,
				{bm: justSavingsAlloc}) : ret));
		}();
		return A2(
			$elm$core$List$map,
			A2($author$project$Projection$calculateInterest, toProcess.e6, toProcess.bu),
			$author$project$Types$InvestmentStrategy$toList(toProcess.bm));
	});
var $author$project$Projection$generate_ = F4(
	function (initialAmount, budgets, investmentStrategys, track) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, networthList) {
					var strat = _v1.a;
					var income = _v1.b.e6;
					var principalReduction = _v1.b.bi;
					var _v2 = $elm_community$list_extra$List$Extra$last(networthList);
					if (_v2.$ === 1) {
						return _List_Nil;
					} else {
						var tail = _v2.a;
						var newAssets = A3($author$project$Projection$stepAssets, income, strat, tail);
						var equity = _Utils_Tuple2(
							$author$project$Projection$equityStr,
							principalReduction + A2(
								$elm$core$Maybe$withDefault,
								0,
								A2(
									$elm$core$Maybe$map,
									function (_v4) {
										var v = _v4.b;
										return v;
									},
									$elm$core$List$head(
										A2(
											$elm$core$List$filter,
											function (_v3) {
												var t = _v3.a;
												return _Utils_eq(t, $author$project$Projection$equityStr);
											},
											tail)))));
						return _Utils_ap(
							networthList,
							_List_fromArray(
								[
									A2($elm$core$List$cons, equity, newAssets)
								]));
					}
				}),
			_List_fromArray(
				[
					$author$project$Projection$initAssets(initialAmount)
				]),
			$elm$core$List$concat(
				A2(
					$elm$core$List$map,
					function (segment) {
						var _v0 = _Utils_Tuple2(
							A2($author$project$UuidDict$get, segment.ac, budgets),
							A2($author$project$UuidDict$get, segment.e, investmentStrategys));
						if ((!_v0.a.$) && (!_v0.b.$)) {
							var budget = _v0.a.a;
							var is = _v0.b.a;
							return A2(
								$elm$core$List$map,
								function (x) {
									return _Utils_Tuple2(is, x);
								},
								A2(
									$author$project$Projection$calcReccuringMonthlyIncome,
									{ek: segment.aL},
									budget));
						} else {
							return _List_Nil;
						}
					},
					track)));
	});
var $author$project$Projection$generateMonthlyAssets = F4(
	function (initialAmount, budgets, investmentStrategys, track) {
		return A2(
			$elm$core$List$indexedMap,
			F2(
				function (i, networthAtMoment) {
					var month = i + 1;
					return {
						U: month,
						V: $elm$core$List$sum(
							A2($elm$core$List$map, $elm$core$Tuple$second, networthAtMoment)),
						bl: function () {
							var isSegEnd = A2(
								$elm$core$List$member,
								month,
								A3(
									$elm$core$List$foldl,
									F2(
										function (toAdd, list) {
											var last = A2(
												$elm$core$Maybe$withDefault,
												0,
												$elm$core$List$head(
													$elm$core$List$reverse(list)));
											return _Utils_ap(
												list,
												_List_fromArray(
													[toAdd + last]));
										}),
									_List_Nil,
									A2(
										$elm$core$List$map,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.aL;
											},
											A2(
												$elm$core$Basics$composeR,
												function (x) {
													return x * 12;
												},
												$elm$core$Basics$round)),
										track)));
							var isFinalMonth = _Utils_eq(
								$elm$core$Basics$round(
									$elm$core$List$sum(
										A2(
											$elm$core$List$map,
											function ($) {
												return $.aL;
											},
											track))),
								(month / 12) | 0);
							return isSegEnd && (!isFinalMonth);
						}()
					};
				}),
			A4($author$project$Projection$generate_, initialAmount, budgets, investmentStrategys, track));
	});
var $terezka$line_charts$LineChart$Colors$grayLight = A3($avh4$elm_color$Color$rgb255, 211, 211, 211);
var $terezka$line_charts$Internal$Events$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Events$custom = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Events$Decoder = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Coordinate$lengthX = function (system) {
	return A2($elm$core$Basics$max, 1, (system.a6.d2.aH - system.a6.dl.dd) - system.a6.dl.fL);
};
var $terezka$line_charts$Internal$Coordinate$reachX = function (system) {
	var diff = system.ci.a8 - system.ci.fl;
	return (diff > 0) ? diff : 1;
};
var $terezka$line_charts$LineChart$Coordinate$scaleSvgX = F2(
	function (system, value) {
		return (value * $terezka$line_charts$Internal$Coordinate$lengthX(system)) / $terezka$line_charts$Internal$Coordinate$reachX(system);
	});
var $terezka$line_charts$LineChart$Coordinate$toSvgX = F2(
	function (system, value) {
		return A2($terezka$line_charts$LineChart$Coordinate$scaleSvgX, system, value - system.ci.fl) + system.a6.dl.dd;
	});
var $terezka$line_charts$Internal$Events$distanceX = F3(
	function (system, searched, dot) {
		return $elm$core$Basics$abs(
			A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, dot.ci) - A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, searched.ci));
	});
var $terezka$line_charts$Internal$Events$getNearestXHelp = F3(
	function (points, system, searched) {
		var distanceX_ = A2($terezka$line_charts$Internal$Events$distanceX, system, searched);
		var getClosest = F2(
			function (point, allClosest) {
				var _v0 = $elm$core$List$head(allClosest);
				if (!_v0.$) {
					var closest = _v0.a;
					return _Utils_eq(closest.fC.ci, point.fC.ci) ? A2($elm$core$List$cons, point, allClosest) : ((_Utils_cmp(
						distanceX_(closest.fC),
						distanceX_(point.fC)) > 0) ? _List_fromArray(
						[point]) : allClosest);
				} else {
					return _List_fromArray(
						[point]);
				}
			});
		return A3($elm$core$List$foldl, getClosest, _List_Nil, points);
	});
var $terezka$line_charts$LineChart$Coordinate$scaleDataX = F2(
	function (system, value) {
		return (value * $terezka$line_charts$Internal$Coordinate$reachX(system)) / $terezka$line_charts$Internal$Coordinate$lengthX(system);
	});
var $terezka$line_charts$LineChart$Coordinate$toDataX = F2(
	function (system, value) {
		return system.ci.fl + A2($terezka$line_charts$LineChart$Coordinate$scaleDataX, system, value - system.a6.dl.dd);
	});
var $terezka$line_charts$Internal$Coordinate$lengthY = function (system) {
	return A2($elm$core$Basics$max, 1, (system.a6.d2.ax - system.a6.dl.es) - system.a6.dl.ea);
};
var $terezka$line_charts$Internal$Coordinate$reachY = function (system) {
	var diff = system.ck.a8 - system.ck.fl;
	return (diff > 0) ? diff : 1;
};
var $terezka$line_charts$LineChart$Coordinate$scaleDataY = F2(
	function (system, value) {
		return (value * $terezka$line_charts$Internal$Coordinate$reachY(system)) / $terezka$line_charts$Internal$Coordinate$lengthY(system);
	});
var $terezka$line_charts$LineChart$Coordinate$toDataY = F2(
	function (system, value) {
		return system.ck.a8 - A2($terezka$line_charts$LineChart$Coordinate$scaleDataY, system, value - system.a6.dl.ea);
	});
var $terezka$line_charts$LineChart$Coordinate$toData = F2(
	function (system, point) {
		return {
			ci: A2($terezka$line_charts$LineChart$Coordinate$toDataX, system, point.ci),
			ck: A2($terezka$line_charts$LineChart$Coordinate$toDataY, system, point.ck)
		};
	});
var $terezka$line_charts$Internal$Events$getNearestX = F3(
	function (points, system, searchedSvg) {
		var searched = A2($terezka$line_charts$LineChart$Coordinate$toData, system, searchedSvg);
		return A2(
			$elm$core$List$map,
			function ($) {
				return $.ed;
			},
			A3($terezka$line_charts$Internal$Events$getNearestXHelp, points, system, searched));
	});
var $terezka$line_charts$Internal$Events$Event = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$svg$Svg$Events$on = $elm$html$Html$Events$on;
var $terezka$line_charts$Internal$Events$onMouseLeave = function (msg) {
	return A2(
		$terezka$line_charts$Internal$Events$Event,
		false,
		F2(
			function (_v0, _v1) {
				return A2(
					$elm$svg$Svg$Events$on,
					'mouseleave',
					$elm$json$Json$Decode$succeed(msg));
			}));
};
var $terezka$line_charts$Internal$Events$Options = F3(
	function (stopPropagation, preventDefault, catchOutsideChart) {
		return {cy: catchOutsideChart, bZ: preventDefault, cc: stopPropagation};
	});
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $elm$svg$Svg$Events$custom = $elm$html$Html$Events$custom;
var $terezka$line_charts$Internal$Events$map = F2(
	function (f, _v0) {
		var a = _v0;
		return F3(
			function (ps, s, p) {
				return f(
					A3(a, ps, s, p));
			});
	});
var $terezka$line_charts$LineChart$Coordinate$Point = F2(
	function (x, y) {
		return {ci: x, ck: y};
	});
var $debois$elm_dom$DOM$offsetHeight = A2($elm$json$Json$Decode$field, 'offsetHeight', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$offsetWidth = A2($elm$json$Json$Decode$field, 'offsetWidth', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$offsetLeft = A2($elm$json$Json$Decode$field, 'offsetLeft', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$offsetParent = F2(
	function (x, decoder) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$field,
					'offsetParent',
					$elm$json$Json$Decode$null(x)),
					A2($elm$json$Json$Decode$field, 'offsetParent', decoder)
				]));
	});
var $debois$elm_dom$DOM$offsetTop = A2($elm$json$Json$Decode$field, 'offsetTop', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$scrollLeft = A2($elm$json$Json$Decode$field, 'scrollLeft', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$scrollTop = A2($elm$json$Json$Decode$field, 'scrollTop', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$position = F2(
	function (x, y) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (_v0) {
				var x_ = _v0.a;
				var y_ = _v0.b;
				return A2(
					$debois$elm_dom$DOM$offsetParent,
					_Utils_Tuple2(x_, y_),
					A2($debois$elm_dom$DOM$position, x_, y_));
			},
			A5(
				$elm$json$Json$Decode$map4,
				F4(
					function (scrollLeftP, scrollTopP, offsetLeftP, offsetTopP) {
						return _Utils_Tuple2((x + offsetLeftP) - scrollLeftP, (y + offsetTopP) - scrollTopP);
					}),
				$debois$elm_dom$DOM$scrollLeft,
				$debois$elm_dom$DOM$scrollTop,
				$debois$elm_dom$DOM$offsetLeft,
				$debois$elm_dom$DOM$offsetTop));
	});
var $debois$elm_dom$DOM$boundingClientRect = A4(
	$elm$json$Json$Decode$map3,
	F3(
		function (_v0, width, height) {
			var x = _v0.a;
			var y = _v0.b;
			return {ax: height, dd: x, ea: y, aH: width};
		}),
	A2($debois$elm_dom$DOM$position, 0, 0),
	$debois$elm_dom$DOM$offsetWidth,
	$debois$elm_dom$DOM$offsetHeight);
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
var $debois$elm_dom$DOM$parentElement = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'parentElement', decoder);
};
function $terezka$line_charts$Internal$Events$cyclic$position() {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$debois$elm_dom$DOM$boundingClientRect,
				$elm$json$Json$Decode$lazy(
				function (_v0) {
					return $debois$elm_dom$DOM$parentElement(
						$terezka$line_charts$Internal$Events$cyclic$position());
				})
			]));
}
var $terezka$line_charts$Internal$Events$position = $terezka$line_charts$Internal$Events$cyclic$position();
$terezka$line_charts$Internal$Events$cyclic$position = function () {
	return $terezka$line_charts$Internal$Events$position;
};
var $debois$elm_dom$DOM$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $terezka$line_charts$Internal$Events$toJsonDecoder = F4(
	function (options, data, system, _v0) {
		var decoder = _v0;
		var withOptions = function (msg) {
			return {ae: msg, bZ: options.bZ, cc: options.cc};
		};
		var handle = F3(
			function (mouseX, mouseY, _v1) {
				var left = _v1.dd;
				var top = _v1.ea;
				var height = _v1.ax;
				var width = _v1.aH;
				var y = mouseY - top;
				var x = mouseX - left;
				var widthPercent = width / system.a6.d2.aH;
				var newSize = {ax: height, aH: width};
				var heightPercent = height / system.a6.d2.ax;
				var newMargin = {es: system.a6.dl.es * heightPercent, dd: system.a6.dl.dd * widthPercent, fL: system.a6.dl.fL * widthPercent, ea: system.a6.dl.ea * heightPercent};
				var newSystem = _Utils_update(
					system,
					{
						a6: {dl: newMargin, d2: newSize}
					});
				return A3(
					decoder,
					data,
					newSystem,
					A2($terezka$line_charts$LineChart$Coordinate$Point, x, y));
			});
		return A2(
			$elm$json$Json$Decode$map,
			withOptions,
			A4(
				$elm$json$Json$Decode$map3,
				handle,
				A2($elm$json$Json$Decode$field, 'pageX', $elm$json$Json$Decode$float),
				A2($elm$json$Json$Decode$field, 'pageY', $elm$json$Json$Decode$float),
				$debois$elm_dom$DOM$target($terezka$line_charts$Internal$Events$position)));
	});
var $terezka$line_charts$Internal$Events$on = F3(
	function (event, toMsg, decoder) {
		return A2(
			$terezka$line_charts$Internal$Events$Event,
			false,
			F2(
				function (data, system) {
					var defaultOptions = A3($terezka$line_charts$Internal$Events$Options, false, false, false);
					return A2(
						$elm$svg$Svg$Events$custom,
						event,
						A4(
							$terezka$line_charts$Internal$Events$toJsonDecoder,
							defaultOptions,
							data,
							system,
							A2($terezka$line_charts$Internal$Events$map, toMsg, decoder)));
				}));
	});
var $terezka$line_charts$Internal$Events$onMouseMove = $terezka$line_charts$Internal$Events$on('mousemove');
var $terezka$line_charts$Internal$Events$hoverMany = function (msg) {
	return $terezka$line_charts$Internal$Events$custom(
		_List_fromArray(
			[
				A2($terezka$line_charts$Internal$Events$onMouseMove, msg, $terezka$line_charts$Internal$Events$getNearestX),
				$terezka$line_charts$Internal$Events$onMouseLeave(
				msg(_List_Nil))
			]));
};
var $terezka$line_charts$LineChart$Events$hoverMany = $terezka$line_charts$Internal$Events$hoverMany;
var $terezka$line_charts$Internal$Junk$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Junk$find = F2(
	function (hovered, data) {
		find:
		while (true) {
			if (!hovered.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = hovered.a;
				var rest = hovered.b;
				if (A2(
					$elm$core$List$any,
					$elm$core$Basics$eq(first),
					data)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$hovered = rest,
						$temp$data = data;
					hovered = $temp$hovered;
					data = $temp$data;
					continue find;
				}
			}
		}
	});
var $terezka$line_charts$Internal$Junk$shouldFlip = F2(
	function (system, x) {
		return _Utils_cmp(x - system.ci.fl, system.ci.a8 - x) > 0;
	});
var $terezka$line_charts$Internal$Junk$standardStyles = _List_fromArray(
	[
		_Utils_Tuple2('padding', '5px'),
		_Utils_Tuple2('min-width', '100px'),
		_Utils_Tuple2('background', 'rgba(255,255,255,0.8)'),
		_Utils_Tuple2('border', '1px solid #d3d3d3'),
		_Utils_Tuple2('border-radius', '5px'),
		_Utils_Tuple2('pointer-events', 'none')
	]);
var $terezka$line_charts$LineChart$Coordinate$scaleSvgY = F2(
	function (system, value) {
		return (value * $terezka$line_charts$Internal$Coordinate$lengthY(system)) / $terezka$line_charts$Internal$Coordinate$reachY(system);
	});
var $terezka$line_charts$LineChart$Coordinate$toSvgY = F2(
	function (system, value) {
		return A2($terezka$line_charts$LineChart$Coordinate$scaleSvgY, system, system.ck.a8 - value) + system.a6.dl.ea;
	});
var $terezka$line_charts$Internal$Junk$hoverAt = F5(
	function (system, x, y, styles, view) {
		var yPercentage = (A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y) * 100) / system.a6.d2.ax;
		var space = A2($terezka$line_charts$Internal$Junk$shouldFlip, system, x) ? (-15) : 15;
		var xPercentage = ((A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x) + space) * 100) / system.a6.d2.aH;
		var positionStyles = _List_fromArray(
			[
				_Utils_Tuple2(
				'left',
				$elm$core$String$fromFloat(xPercentage) + '%'),
				_Utils_Tuple2(
				'top',
				$elm$core$String$fromFloat(yPercentage) + '%'),
				_Utils_Tuple2('margin-right', '-400px'),
				_Utils_Tuple2('position', 'absolute'),
				A2($terezka$line_charts$Internal$Junk$shouldFlip, system, x) ? _Utils_Tuple2('transform', 'translateX(-100%)') : _Utils_Tuple2('transform', 'translateX(0)')
			]);
		var containerStyles = _Utils_ap(
			$terezka$line_charts$Internal$Junk$standardStyles,
			_Utils_ap(positionStyles, styles));
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var p = _v0.a;
					var v = _v0.b;
					return A2($elm$html$Html$Attributes$style, p, v);
				},
				containerStyles),
			view);
	});
var $terezka$line_charts$Internal$Junk$middle = F2(
	function (r, system) {
		var range = r(system);
		return range.fl + ((range.a8 - range.fl) / 2);
	});
var $terezka$line_charts$Internal$Junk$hover = F3(
	function (system, x, styles) {
		var y = A2(
			$terezka$line_charts$Internal$Junk$middle,
			function ($) {
				return $.ck;
			},
			system);
		var containerStyles = _Utils_ap(
			_List_fromArray(
				[
					A2($terezka$line_charts$Internal$Junk$shouldFlip, system, x) ? _Utils_Tuple2('transform', 'translate(-100%, -50%)') : _Utils_Tuple2('transform', 'translate(0, -50%)')
				]),
			styles);
		return A4($terezka$line_charts$Internal$Junk$hoverAt, system, x, y, containerStyles);
	});
var $avh4$elm_color$Color$toCssString = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	var roundTo = function (x) {
		return $elm$core$Basics$round(x * 1000) / 1000;
	};
	var pct = function (x) {
		return $elm$core$Basics$round(x * 10000) / 100;
	};
	return $elm$core$String$concat(
		_List_fromArray(
			[
				'rgba(',
				$elm$core$String$fromFloat(
				pct(r)),
				'%,',
				$elm$core$String$fromFloat(
				pct(g)),
				'%,',
				$elm$core$String$fromFloat(
				pct(b)),
				'%,',
				$elm$core$String$fromFloat(
				roundTo(a)),
				')'
			]));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $terezka$line_charts$Internal$Junk$viewHeader = $elm$html$Html$p(
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$style, 'margin-top', '3px'),
			A2($elm$html$Html$Attributes$style, 'margin-bottom', '5px'),
			A2($elm$html$Html$Attributes$style, 'padding', '3px'),
			A2($elm$html$Html$Attributes$style, 'border-bottom', '1px solid rgb(163, 163, 163)')
		]));
var $terezka$line_charts$Internal$Utils$viewMaybe = F2(
	function (a, view) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$svg$Svg$text(''),
			A2($elm$core$Maybe$map, view, a));
	});
var $terezka$line_charts$Internal$Junk$viewRow = F3(
	function (color, label, value) {
		return A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'margin', '3px'),
					A2($elm$html$Html$Attributes$style, 'color', color)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(label + (': ' + value))
				]));
	});
var $terezka$line_charts$Internal$Junk$hoverManyHtml = F8(
	function (system, toX, toY, formatX, formatY, first, hovered, series) {
		var x = A2(
			$elm$core$Maybe$withDefault,
			A2(
				$terezka$line_charts$Internal$Junk$middle,
				function ($) {
					return $.ci;
				},
				system),
			toX(first));
		var viewValue = function (_v0) {
			var color = _v0.a;
			var label = _v0.b;
			var data = _v0.c;
			return A2(
				$terezka$line_charts$Internal$Utils$viewMaybe,
				A2($terezka$line_charts$Internal$Junk$find, hovered, data),
				function (hovered_) {
					return A3(
						$terezka$line_charts$Internal$Junk$viewRow,
						$avh4$elm_color$Color$toCssString(color),
						label,
						formatY(hovered_));
				});
		};
		return A4(
			$terezka$line_charts$Internal$Junk$hover,
			system,
			x,
			_List_Nil,
			A2(
				$elm$core$List$cons,
				$terezka$line_charts$Internal$Junk$viewHeader(
					_List_fromArray(
						[
							$elm$html$Html$text(
							formatX(first))
						])),
				A2($elm$core$List$map, viewValue, series)));
	});
var $terezka$line_charts$Internal$Junk$Layers = F3(
	function (below, above, html) {
		return {bt: above, aK: below, bH: html};
	});
var $terezka$line_charts$Internal$Junk$none = F4(
	function (_v0, _v1, _v2, _v3) {
		return A3($terezka$line_charts$Internal$Junk$Layers, _List_Nil, _List_Nil, _List_Nil);
	});
var $terezka$line_charts$Internal$Utils$concat = F3(
	function (first, second, third) {
		return _Utils_ap(
			first,
			_Utils_ap(second, third));
	});
var $terezka$line_charts$Internal$Path$Line = function (a) {
	return {$: 1, a: a};
};
var $terezka$line_charts$Internal$Path$Move = function (a) {
	return {$: 0, a: a};
};
var $terezka$line_charts$Internal$Path$join = function (commands) {
	return A2($elm$core$String$join, ' ', commands);
};
var $terezka$line_charts$Internal$Path$bool = function (bool_) {
	return bool_ ? '1' : '0';
};
var $terezka$line_charts$Internal$Path$point = function (point_) {
	return $elm$core$String$fromFloat(point_.ci) + (' ' + $elm$core$String$fromFloat(point_.ck));
};
var $terezka$line_charts$Internal$Path$points = function (points_) {
	return A2(
		$elm$core$String$join,
		',',
		A2($elm$core$List$map, $terezka$line_charts$Internal$Path$point, points_));
};
var $terezka$line_charts$Internal$Path$toString = function (command) {
	switch (command.$) {
		case 9:
			return 'Z';
		case 0:
			var p = command.a;
			return 'M' + $terezka$line_charts$Internal$Path$point(p);
		case 1:
			var p = command.a;
			return 'L' + $terezka$line_charts$Internal$Path$point(p);
		case 2:
			var x = command.a;
			return 'H' + $elm$core$String$fromFloat(x);
		case 3:
			var y = command.a;
			return 'V' + $elm$core$String$fromFloat(y);
		case 4:
			var c1 = command.a;
			var c2 = command.b;
			var p = command.c;
			return 'C' + $terezka$line_charts$Internal$Path$points(
				_List_fromArray(
					[c1, c2, p]));
		case 5:
			var c1 = command.a;
			var p = command.b;
			return 'Q' + $terezka$line_charts$Internal$Path$points(
				_List_fromArray(
					[c1, p]));
		case 6:
			var c1 = command.a;
			var p = command.b;
			return 'Q' + $terezka$line_charts$Internal$Path$points(
				_List_fromArray(
					[c1, p]));
		case 7:
			var p = command.a;
			return 'T' + $terezka$line_charts$Internal$Path$point(p);
		default:
			var rx = command.a;
			var ry = command.b;
			var xAxisRotation = command.c;
			var largeArcFlag = command.d;
			var sweepFlag = command.e;
			var p = command.f;
			return 'A' + $terezka$line_charts$Internal$Path$join(
				_List_fromArray(
					[
						$elm$core$String$fromFloat(rx),
						$elm$core$String$fromFloat(ry),
						$elm$core$String$fromInt(xAxisRotation),
						$terezka$line_charts$Internal$Path$bool(largeArcFlag),
						$terezka$line_charts$Internal$Path$bool(sweepFlag),
						$terezka$line_charts$Internal$Path$point(p)
					]));
	}
};
var $terezka$line_charts$Internal$Path$Arc = F6(
	function (a, b, c, d, e, f) {
		return {$: 8, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $terezka$line_charts$Internal$Path$Close = {$: 9};
var $terezka$line_charts$Internal$Path$CubicBeziers = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $terezka$line_charts$Internal$Path$CubicBeziersShort = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $terezka$line_charts$Internal$Path$Horizontal = function (a) {
	return {$: 2, a: a};
};
var $terezka$line_charts$Internal$Path$QuadraticBeziers = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $terezka$line_charts$Internal$Path$QuadraticBeziersShort = function (a) {
	return {$: 7, a: a};
};
var $terezka$line_charts$Internal$Path$Vertical = function (a) {
	return {$: 3, a: a};
};
var $terezka$line_charts$LineChart$Coordinate$toSvg = F2(
	function (system, point) {
		return {
			ci: A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, point.ci),
			ck: A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, point.ck)
		};
	});
var $terezka$line_charts$Internal$Path$translate = F2(
	function (system, command) {
		switch (command.$) {
			case 0:
				var p = command.a;
				return $terezka$line_charts$Internal$Path$Move(
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			case 1:
				var p = command.a;
				return $terezka$line_charts$Internal$Path$Line(
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			case 2:
				var x = command.a;
				return $terezka$line_charts$Internal$Path$Horizontal(
					A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x));
			case 3:
				var y = command.a;
				return $terezka$line_charts$Internal$Path$Vertical(
					A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y));
			case 4:
				var c1 = command.a;
				var c2 = command.b;
				var p = command.c;
				return A3(
					$terezka$line_charts$Internal$Path$CubicBeziers,
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, c1),
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, c2),
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			case 5:
				var c1 = command.a;
				var p = command.b;
				return A2(
					$terezka$line_charts$Internal$Path$CubicBeziersShort,
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, c1),
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			case 6:
				var c1 = command.a;
				var p = command.b;
				return A2(
					$terezka$line_charts$Internal$Path$QuadraticBeziers,
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, c1),
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			case 7:
				var p = command.a;
				return $terezka$line_charts$Internal$Path$QuadraticBeziersShort(
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			case 8:
				var rx = command.a;
				var ry = command.b;
				var xAxisRotation = command.c;
				var largeArcFlag = command.d;
				var sweepFlag = command.e;
				var p = command.f;
				return A6(
					$terezka$line_charts$Internal$Path$Arc,
					rx,
					ry,
					xAxisRotation,
					largeArcFlag,
					sweepFlag,
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			default:
				return $terezka$line_charts$Internal$Path$Close;
		}
	});
var $terezka$line_charts$Internal$Path$description = F2(
	function (system, commands) {
		return $terezka$line_charts$Internal$Path$join(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$terezka$line_charts$Internal$Path$translate(system),
					$terezka$line_charts$Internal$Path$toString),
				commands));
	});
var $terezka$line_charts$Internal$Path$viewPath = function (attributes) {
	return A2($elm$svg$Svg$path, attributes, _List_Nil);
};
var $terezka$line_charts$Internal$Path$view = F3(
	function (system, attributes, commands) {
		return $terezka$line_charts$Internal$Path$viewPath(
			_Utils_ap(
				attributes,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d(
						A2($terezka$line_charts$Internal$Path$description, system, commands))
					])));
	});
var $terezka$line_charts$Internal$Svg$vertical = F5(
	function (system, userAttributes, x, y1, y2) {
		var attributes = A3(
			$terezka$line_charts$Internal$Utils$concat,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString($terezka$line_charts$LineChart$Colors$gray)),
					$elm$svg$Svg$Attributes$style('pointer-events: none;')
				]),
			userAttributes,
			_List_Nil);
		return A3(
			$terezka$line_charts$Internal$Path$view,
			system,
			attributes,
			_List_fromArray(
				[
					$terezka$line_charts$Internal$Path$Move(
					{ci: x, ck: y1}),
					$terezka$line_charts$Internal$Path$Line(
					{ci: x, ck: y1}),
					$terezka$line_charts$Internal$Path$Line(
					{ci: x, ck: y2})
				]));
	});
var $terezka$line_charts$Internal$Svg$verticalGrid = F3(
	function (system, userAttributes, x) {
		var attributes = A3(
			$terezka$line_charts$Internal$Utils$concat,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString($terezka$line_charts$LineChart$Colors$gray)),
					$elm$svg$Svg$Attributes$style('pointer-events: none;')
				]),
			userAttributes,
			_List_Nil);
		return A5($terezka$line_charts$Internal$Svg$vertical, system, attributes, x, system.ck.fl, system.ck.a8);
	});
var $terezka$line_charts$Internal$Junk$hoverMany = F3(
	function (hovered, formatX, formatY) {
		if (!hovered.b) {
			return $terezka$line_charts$Internal$Junk$none;
		} else {
			var first = hovered.a;
			var rest = hovered.b;
			return F4(
				function (series, toX, toY, system) {
					var xValue = A2(
						$elm$core$Maybe$withDefault,
						0,
						toX(first));
					return {
						bt: _List_Nil,
						aK: _List_fromArray(
							[
								A3($terezka$line_charts$Internal$Svg$verticalGrid, system, _List_Nil, xValue)
							]),
						bH: _List_fromArray(
							[
								A8($terezka$line_charts$Internal$Junk$hoverManyHtml, system, toX, toY, formatX, formatY, first, hovered, series)
							])
					};
				});
		}
	});
var $terezka$line_charts$LineChart$Junk$hoverMany = $terezka$line_charts$Internal$Junk$hoverMany;
var $terezka$line_charts$Internal$Events$distanceY = F3(
	function (system, searched, dot) {
		return $elm$core$Basics$abs(
			A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, dot.ck) - A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, searched.ck));
	});
var $elm$core$Basics$sqrt = _Basics_sqrt;
var $terezka$line_charts$Internal$Events$distance = F3(
	function (system, searched, dot) {
		return $elm$core$Basics$sqrt(
			A2(
				$elm$core$Basics$pow,
				A3($terezka$line_charts$Internal$Events$distanceX, system, searched, dot),
				2) + A2(
				$elm$core$Basics$pow,
				A3($terezka$line_charts$Internal$Events$distanceY, system, searched, dot),
				2));
	});
var $terezka$line_charts$Internal$Utils$withFirst = F2(
	function (stuff, process) {
		if (stuff.b) {
			var first = stuff.a;
			var rest = stuff.b;
			return $elm$core$Maybe$Just(
				A2(process, first, rest));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $terezka$line_charts$Internal$Events$getNearestHelp = F3(
	function (points, system, searched) {
		var distance_ = A2($terezka$line_charts$Internal$Events$distance, system, searched);
		var getClosest = F2(
			function (point, closest) {
				return (_Utils_cmp(
					distance_(closest.fC),
					distance_(point.fC)) < 0) ? closest : point;
			});
		return A2(
			$terezka$line_charts$Internal$Utils$withFirst,
			A2(
				$elm$core$List$filter,
				function ($) {
					return $.ff;
				},
				points),
			$elm$core$List$foldl(getClosest));
	});
var $terezka$line_charts$Internal$Events$withinRadius = F4(
	function (system, radius, searched, dot) {
		return _Utils_cmp(
			A3($terezka$line_charts$Internal$Events$distance, system, searched, dot),
			radius) < 1;
	});
var $terezka$line_charts$Internal$Events$getWithin = function (radius) {
	return F3(
		function (points, system, searchedSvg) {
			var searched = A2($terezka$line_charts$LineChart$Coordinate$toData, system, searchedSvg);
			var keepIfEligible = function (closest) {
				return A4($terezka$line_charts$Internal$Events$withinRadius, system, radius, searched, closest.fC) ? $elm$core$Maybe$Just(closest.ed) : $elm$core$Maybe$Nothing;
			};
			return A2(
				$elm$core$Maybe$andThen,
				keepIfEligible,
				A3($terezka$line_charts$Internal$Events$getNearestHelp, points, system, searched));
		});
};
var $terezka$line_charts$Internal$Events$hoverOne = function (msg) {
	return $terezka$line_charts$Internal$Events$custom(
		_List_fromArray(
			[
				A2(
				$terezka$line_charts$Internal$Events$onMouseMove,
				msg,
				$terezka$line_charts$Internal$Events$getWithin(30)),
				A3(
				$terezka$line_charts$Internal$Events$on,
				'touchstart',
				msg,
				$terezka$line_charts$Internal$Events$getWithin(100)),
				A3(
				$terezka$line_charts$Internal$Events$on,
				'touchmove',
				msg,
				$terezka$line_charts$Internal$Events$getWithin(100)),
				$terezka$line_charts$Internal$Events$onMouseLeave(
				msg($elm$core$Maybe$Nothing))
			]));
};
var $terezka$line_charts$LineChart$Events$hoverOne = $terezka$line_charts$Internal$Events$hoverOne;
var $terezka$line_charts$Internal$Junk$findSeries = F2(
	function (hovered, datas) {
		findSeries:
		while (true) {
			if (!datas.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var _v1 = datas.a;
				var color = _v1.a;
				var label = _v1.b;
				var data = _v1.c;
				var rest = datas.b;
				var _v2 = A2(
					$terezka$line_charts$Internal$Junk$find,
					_List_fromArray(
						[hovered]),
					data);
				if (!_v2.$) {
					var found = _v2.a;
					return $elm$core$Maybe$Just(
						_Utils_Tuple3(color, label, data));
				} else {
					var $temp$hovered = hovered,
						$temp$datas = rest;
					hovered = $temp$hovered;
					datas = $temp$datas;
					continue findSeries;
				}
			}
		}
	});
var $terezka$line_charts$Internal$Junk$hoverOneHtml = F6(
	function (series, system, toX, toY, properties, hovered) {
		var y = A2(
			$elm$core$Maybe$withDefault,
			A2(
				$terezka$line_charts$Internal$Junk$middle,
				function ($) {
					return $.ck;
				},
				system),
			toY(hovered));
		var x = A2(
			$elm$core$Maybe$withDefault,
			A2(
				$terezka$line_charts$Internal$Junk$middle,
				function ($) {
					return $.ci;
				},
				system),
			toX(hovered));
		var viewValue = function (_v1) {
			var label = _v1.a;
			var value = _v1.b;
			return A3(
				$terezka$line_charts$Internal$Junk$viewRow,
				'inherit',
				label,
				value(hovered));
		};
		var viewColorLabel = F2(
			function (color, label) {
				return A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '0'),
							A2($elm$html$Html$Attributes$style, 'color', color)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						]));
			});
		var viewHeaderOne = A2(
			$terezka$line_charts$Internal$Utils$viewMaybe,
			A2($terezka$line_charts$Internal$Junk$findSeries, hovered, series),
			function (_v0) {
				var color = _v0.a;
				var label = _v0.b;
				return $terezka$line_charts$Internal$Junk$viewHeader(
					_List_fromArray(
						[
							A2(
							viewColorLabel,
							$avh4$elm_color$Color$toCssString(color),
							label)
						]));
			});
		return A5(
			$terezka$line_charts$Internal$Junk$hoverAt,
			system,
			x,
			y,
			_List_Nil,
			A2(
				$elm$core$List$cons,
				viewHeaderOne,
				A2($elm$core$List$map, viewValue, properties)));
	});
var $terezka$line_charts$Internal$Junk$hoverOne = F2(
	function (hovered, properties) {
		return F4(
			function (series, toX, toY, system) {
				return {
					bt: _List_Nil,
					aK: _List_Nil,
					bH: _List_fromArray(
						[
							A2(
							$terezka$line_charts$Internal$Utils$viewMaybe,
							hovered,
							A5($terezka$line_charts$Internal$Junk$hoverOneHtml, series, system, toX, toY, properties))
						])
				};
			});
	});
var $terezka$line_charts$LineChart$Junk$hoverOne = $terezka$line_charts$Internal$Junk$hoverOne;
var $terezka$line_charts$Internal$Line$Series = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Line$SeriesConfig = F5(
	function (color, shape, dashing, label, data) {
		return {bz: color, cF: dashing, bA: data, fi: label, d0: shape};
	});
var $terezka$line_charts$Internal$Line$line = F4(
	function (color_, shape_, label_, data_) {
		return A5($terezka$line_charts$Internal$Line$SeriesConfig, color_, shape_, _List_Nil, label_, data_);
	});
var $terezka$line_charts$LineChart$line = $terezka$line_charts$Internal$Line$line;
var $terezka$line_charts$Internal$Grid$Lines = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $terezka$line_charts$Internal$Grid$lines = $terezka$line_charts$Internal$Grid$Lines;
var $terezka$line_charts$LineChart$Grid$lines = $terezka$line_charts$Internal$Grid$lines;
var $terezka$line_charts$Internal$Legends$None = {$: 0};
var $terezka$line_charts$Internal$Legends$none = $terezka$line_charts$Internal$Legends$None;
var $terezka$line_charts$LineChart$Legends$none = $terezka$line_charts$Internal$Legends$none;
var $terezka$line_charts$Internal$Container$Static = 0;
var $terezka$line_charts$Internal$Container$static = 0;
var $terezka$line_charts$LineChart$Container$static = $terezka$line_charts$Internal$Container$static;
var $elm_community$maybe_extra$Maybe$Extra$toList = function (m) {
	if (m.$ === 1) {
		return _List_Nil;
	} else {
		var x = m.a;
		return _List_fromArray(
			[x]);
	}
};
var $terezka$line_charts$Internal$Junk$addBelow = F2(
	function (below, layers) {
		return _Utils_update(
			layers,
			{
				aK: _Utils_ap(below, layers.aK)
			});
	});
var $terezka$line_charts$LineChart$chartAreaAttributes = function (system) {
	return _List_fromArray(
		[
			$elm$svg$Svg$Attributes$x(
			$elm$core$String$fromFloat(system.a6.dl.dd)),
			$elm$svg$Svg$Attributes$y(
			$elm$core$String$fromFloat(system.a6.dl.ea)),
			$elm$svg$Svg$Attributes$width(
			$elm$core$String$fromFloat(
				$terezka$line_charts$Internal$Coordinate$lengthX(system))),
			$elm$svg$Svg$Attributes$height(
			$elm$core$String$fromFloat(
				$terezka$line_charts$Internal$Coordinate$lengthY(system)))
		]);
};
var $terezka$line_charts$Internal$Events$toChartAttributes = F3(
	function (data, system, _v0) {
		var events = _v0;
		var order = function (_v1) {
			var outside = _v1.a;
			var event = _v1.b;
			return outside ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(event, data, system));
		};
		return A2($elm$core$List$filterMap, order, events);
	});
var $terezka$line_charts$LineChart$chartAreaPlatform = F3(
	function (config, data, system) {
		var attributes = $elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('transparent')
					]),
					$terezka$line_charts$LineChart$chartAreaAttributes(system),
					A3($terezka$line_charts$Internal$Events$toChartAttributes, data, system, config.cP)
				]));
		return A2($elm$svg$Svg$rect, attributes, _List_Nil);
	});
var $elm$svg$Svg$clipPath = $elm$svg$Svg$trustedNode('clipPath');
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $terezka$line_charts$Internal$Utils$toChartAreaId = function (id) {
	return 'chart__chart-area--' + id;
};
var $terezka$line_charts$LineChart$clipPath = function (system) {
	return A2(
		$elm$svg$Svg$clipPath,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$id(
				$terezka$line_charts$Internal$Utils$toChartAreaId(system.c1))
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$rect,
				$terezka$line_charts$LineChart$chartAreaAttributes(system),
				_List_Nil)
			]));
};
var $terezka$line_charts$Internal$Line$color = F3(
	function (_v0, _v1, data_) {
		var config = _v0;
		var line_ = _v1;
		var _v2 = config(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.ed;
				},
				data_));
		var style_ = _v2;
		return style_.bz(line_.bz);
	});
var $terezka$line_charts$Internal$Container$properties = F2(
	function (f, _v0) {
		var properties_ = _v0;
		return f(properties_);
	});
var $terezka$line_charts$Internal$Container$sizeStyles = F3(
	function (_v0, width, height) {
		var properties_ = _v0;
		var _v1 = properties_.d2;
		if (!_v1) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$Attributes$style,
					'height',
					$elm$core$String$fromFloat(height) + 'px'),
					A2(
					$elm$html$Html$Attributes$style,
					'width',
					$elm$core$String$fromFloat(width) + 'px')
				]);
		} else {
			return _List_Nil;
		}
	});
var $terezka$line_charts$LineChart$container = F4(
	function (config, _v0, junkHtml, plot) {
		var frame = _v0.a6;
		var userAttributes = A2(
			$terezka$line_charts$Internal$Container$properties,
			function ($) {
				return $.cp;
			},
			config.cD);
		var sizeStyles = A3($terezka$line_charts$Internal$Container$sizeStyles, config.cD, frame.d2.aH, frame.d2.ax);
		var styles = A2(
			$elm$core$List$cons,
			A2($elm$html$Html$Attributes$style, 'position', 'relative'),
			sizeStyles);
		return A2(
			$elm$html$Html$div,
			_Utils_ap(styles, userAttributes),
			A2($elm$core$List$cons, plot, junkHtml));
	});
var $terezka$line_charts$Internal$Line$data = function (_v0) {
	var config = _v0;
	return config.bA;
};
var $elm$svg$Svg$defs = $elm$svg$Svg$trustedNode('defs');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $terezka$line_charts$Internal$Junk$getLayers = F5(
	function (series, toX, toY, system, _v0) {
		var toLayers = _v0;
		return A4(toLayers, series, toX, toY, system);
	});
var $terezka$line_charts$Internal$Line$label = function (_v0) {
	var config = _v0;
	return config.fi;
};
var $terezka$line_charts$Internal$Events$toContainerAttributes = F3(
	function (data, system, _v0) {
		var events = _v0;
		var order = function (_v1) {
			var outside = _v1.a;
			var event = _v1.b;
			return outside ? $elm$core$Maybe$Just(
				A2(event, data, system)) : $elm$core$Maybe$Nothing;
		};
		return A2($elm$core$List$filterMap, order, events);
	});
var $terezka$line_charts$Internal$Data$Data = F3(
	function (user, point, isReal) {
		return {ff: isReal, fC: point, ed: user};
	});
var $terezka$line_charts$LineChart$setY = F2(
	function (datum, y) {
		return A3(
			$terezka$line_charts$Internal$Data$Data,
			datum.ed,
			A2($terezka$line_charts$Internal$Data$Point, datum.fC.ci, y),
			datum.ff);
	});
var $terezka$line_charts$LineChart$normalize = function (datasets) {
	if (datasets.b) {
		var highest = datasets.a;
		var belows = datasets.b;
		var toPercentage = F2(
			function (highest_, datum) {
				return A2($terezka$line_charts$LineChart$setY, datum, (100 * datum.fC.ck) / highest_.fC.ck);
			});
		return A2(
			$elm$core$List$map,
			A2($elm$core$List$map2, toPercentage, highest),
			A2($elm$core$List$cons, highest, belows));
	} else {
		return datasets;
	}
};
var $terezka$line_charts$LineChart$addBelows = F2(
	function (alldata, dataBelowAll) {
		var add = F2(
			function (below, datum) {
				return A2($terezka$line_charts$LineChart$setY, below, below.fC.ck + datum.fC.ck);
			});
		var iterate = F4(
			function (datum0, dataTop, dataBelowTop, result) {
				iterate:
				while (true) {
					var _v0 = _Utils_Tuple2(dataTop, dataBelowTop);
					if (_v0.a.b) {
						if (_v0.b.b) {
							var _v1 = _v0.a;
							var datum1 = _v1.a;
							var data = _v1.b;
							var _v2 = _v0.b;
							var datumBelow = _v2.a;
							var dataBelow = _v2.b;
							if (_Utils_cmp(datum1.fC.ci, datumBelow.fC.ci) > 0) {
								if (datumBelow.ff) {
									var $temp$datum0 = datum0,
										$temp$dataTop = A2($elm$core$List$cons, datum1, data),
										$temp$dataBelowTop = dataBelow,
										$temp$result = A2(
										$elm$core$List$cons,
										A2(add, datumBelow, datum0),
										result);
									datum0 = $temp$datum0;
									dataTop = $temp$dataTop;
									dataBelowTop = $temp$dataBelowTop;
									result = $temp$result;
									continue iterate;
								} else {
									var breakdata = _Utils_update(
										datum0,
										{ff: false});
									var $temp$datum0 = datum0,
										$temp$dataTop = A2($elm$core$List$cons, datum1, data),
										$temp$dataBelowTop = dataBelow,
										$temp$result = A2(
										$elm$core$List$cons,
										A2(add, datumBelow, datum0),
										result);
									datum0 = $temp$datum0;
									dataTop = $temp$dataTop;
									dataBelowTop = $temp$dataBelowTop;
									result = $temp$result;
									continue iterate;
								}
							} else {
								var $temp$datum0 = datum1,
									$temp$dataTop = data,
									$temp$dataBelowTop = A2($elm$core$List$cons, datumBelow, dataBelow),
									$temp$result = result;
								datum0 = $temp$datum0;
								dataTop = $temp$dataTop;
								dataBelowTop = $temp$dataBelowTop;
								result = $temp$result;
								continue iterate;
							}
						} else {
							var _v4 = _v0.a;
							var datum1 = _v4.a;
							var data = _v4.b;
							return result;
						}
					} else {
						if (_v0.b.b) {
							var _v3 = _v0.b;
							var datumBelow = _v3.a;
							var dataBelow = _v3.b;
							if (_Utils_cmp(datum0.fC.ci, datumBelow.fC.ci) < 1) {
								var $temp$datum0 = datum0,
									$temp$dataTop = _List_Nil,
									$temp$dataBelowTop = dataBelow,
									$temp$result = A2(
									$elm$core$List$cons,
									A2(add, datumBelow, datum0),
									result);
								datum0 = $temp$datum0;
								dataTop = $temp$dataTop;
								dataBelowTop = $temp$dataBelowTop;
								result = $temp$result;
								continue iterate;
							} else {
								var $temp$datum0 = datum0,
									$temp$dataTop = _List_Nil,
									$temp$dataBelowTop = dataBelow,
									$temp$result = A2($elm$core$List$cons, datumBelow, result);
								datum0 = $temp$datum0;
								dataTop = $temp$dataTop;
								dataBelowTop = $temp$dataBelowTop;
								result = $temp$result;
								continue iterate;
							}
						} else {
							return result;
						}
					}
				}
			});
		return $elm$core$List$reverse(
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$terezka$line_charts$Internal$Utils$withFirst,
					alldata,
					F2(
						function (first, rest) {
							return A4(iterate, first, rest, dataBelowAll, _List_Nil);
						}))));
	});
var $terezka$line_charts$LineChart$stack = function (dataset) {
	var stackBelows = F2(
		function (dataset_, result) {
			if (dataset_.b) {
				var data = dataset_.a;
				var belows = dataset_.b;
				return A2(
					stackBelows,
					belows,
					A2(
						$elm$core$List$cons,
						A3($elm$core$List$foldl, $terezka$line_charts$LineChart$addBelows, data, belows),
						result));
			} else {
				return result;
			}
		});
	return $elm$core$List$reverse(
		A2(stackBelows, dataset, _List_Nil));
};
var $terezka$line_charts$Internal$Axis$variable = function (_v0) {
	var config = _v0;
	return config.ef;
};
var $terezka$line_charts$LineChart$toDataPoints = F2(
	function (config, lines) {
		var y = $terezka$line_charts$Internal$Axis$variable(config.ck);
		var x = $terezka$line_charts$Internal$Axis$variable(config.ci);
		var addPoint = function (datum) {
			var _v1 = _Utils_Tuple2(
				x(datum),
				y(datum));
			if (!_v1.a.$) {
				if (!_v1.b.$) {
					var x_ = _v1.a.a;
					var y_ = _v1.b.a;
					return $elm$core$Maybe$Just(
						A3(
							$terezka$line_charts$Internal$Data$Data,
							datum,
							A2($terezka$line_charts$Internal$Data$Point, x_, y_),
							true));
				} else {
					var x_ = _v1.a.a;
					var _v2 = _v1.b;
					return $elm$core$Maybe$Just(
						A3(
							$terezka$line_charts$Internal$Data$Data,
							datum,
							A2($terezka$line_charts$Internal$Data$Point, x_, 0),
							false));
				}
			} else {
				if (!_v1.b.$) {
					var _v3 = _v1.a;
					var y_ = _v1.b.a;
					return $elm$core$Maybe$Nothing;
				} else {
					var _v4 = _v1.a;
					var _v5 = _v1.b;
					return $elm$core$Maybe$Nothing;
				}
			}
		};
		var data = A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				$terezka$line_charts$Internal$Line$data,
				$elm$core$List$filterMap(addPoint)),
			lines);
		var _v0 = config.co;
		switch (_v0.$) {
			case 0:
				return data;
			case 1:
				return data;
			case 2:
				return $terezka$line_charts$LineChart$stack(data);
			default:
				return $terezka$line_charts$LineChart$normalize(
					$terezka$line_charts$LineChart$stack(data));
		}
	});
var $terezka$line_charts$Internal$Coordinate$Frame = F2(
	function (margin, size) {
		return {dl: margin, d2: size};
	});
var $terezka$line_charts$Internal$Coordinate$Size = F2(
	function (width, height) {
		return {ax: height, aH: width};
	});
var $terezka$line_charts$LineChart$Coordinate$Range = F2(
	function (min, max) {
		return {a8: max, fl: min};
	});
var $terezka$line_charts$Internal$Axis$Range$applyX = F2(
	function (range, system) {
		switch (range.$) {
			case 0:
				var padMin = range.a;
				var padMax = range.b;
				var _v1 = system;
				var frame = _v1.a6;
				var _v2 = frame;
				var size = _v2.d2;
				var system_ = _Utils_update(
					system,
					{
						a6: _Utils_update(
							frame,
							{
								d2: _Utils_update(
									size,
									{
										aH: A2($elm$core$Basics$max, 1, (size.aH - padMin) - padMax)
									})
							})
					});
				var scale = $terezka$line_charts$LineChart$Coordinate$scaleDataX(system_);
				return A2(
					$terezka$line_charts$LineChart$Coordinate$Range,
					system.ci.fl - scale(padMin),
					system.ci.a8 + scale(padMax));
			case 1:
				var min = range.a;
				var max = range.b;
				return A2($terezka$line_charts$LineChart$Coordinate$Range, min, max);
			default:
				var toRange = range.a;
				return toRange(system.ci);
		}
	});
var $terezka$line_charts$Internal$Axis$Range$applyY = F2(
	function (range, system) {
		switch (range.$) {
			case 0:
				var padMin = range.a;
				var padMax = range.b;
				var _v1 = system;
				var frame = _v1.a6;
				var _v2 = frame;
				var size = _v2.d2;
				var system_ = _Utils_update(
					system,
					{
						a6: _Utils_update(
							frame,
							{
								d2: _Utils_update(
									size,
									{
										ax: A2($elm$core$Basics$max, 1, (size.ax - padMin) - padMax)
									})
							})
					});
				var scale = $terezka$line_charts$LineChart$Coordinate$scaleDataY(system_);
				return A2(
					$terezka$line_charts$LineChart$Coordinate$Range,
					system.ck.fl - scale(padMin),
					system.ck.a8 + scale(padMax));
			case 1:
				var min = range.a;
				var max = range.b;
				return A2($terezka$line_charts$LineChart$Coordinate$Range, min, max);
			default:
				var toRange = range.a;
				return toRange(system.ck);
		}
	});
var $terezka$line_charts$Internal$Coordinate$ground = function (range_) {
	return _Utils_update(
		range_,
		{
			fl: A2($elm$core$Basics$min, range_.fl, 0)
		});
};
var $terezka$line_charts$Internal$Area$hasArea = function (config) {
	switch (config.$) {
		case 0:
			return false;
		case 1:
			return true;
		case 2:
			return true;
		default:
			return true;
	}
};
var $terezka$line_charts$Internal$Axis$pixels = function (_v0) {
	var config = _v0;
	return config.dF;
};
var $terezka$line_charts$Internal$Axis$range = function (_v0) {
	var config = _v0;
	return config.dM;
};
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $terezka$line_charts$Internal$Coordinate$maximum = function (toValue) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(toValue),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$maximum,
			$elm$core$Maybe$withDefault(1)));
};
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $terezka$line_charts$Internal$Coordinate$minimum = function (toValue) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(toValue),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$minimum,
			$elm$core$Maybe$withDefault(0)));
};
var $terezka$line_charts$Internal$Coordinate$range = F2(
	function (toValue, data) {
		var range_ = {
			a8: A2($terezka$line_charts$Internal$Coordinate$maximum, toValue, data),
			fl: A2($terezka$line_charts$Internal$Coordinate$minimum, toValue, data)
		};
		return _Utils_eq(range_.fl, range_.a8) ? _Utils_update(
			range_,
			{a8: range_.a8 + 1}) : range_;
	});
var $terezka$line_charts$LineChart$toSystem = F2(
	function (config, data) {
		var yRange = A2(
			$terezka$line_charts$Internal$Coordinate$range,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.fC;
				},
				function ($) {
					return $.ck;
				}),
			data);
		var xRange = A2(
			$terezka$line_charts$Internal$Coordinate$range,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.fC;
				},
				function ($) {
					return $.ci;
				}),
			data);
		var size = A2(
			$terezka$line_charts$Internal$Coordinate$Size,
			$terezka$line_charts$Internal$Axis$pixels(config.ci),
			$terezka$line_charts$Internal$Axis$pixels(config.ck));
		var hasArea = $terezka$line_charts$Internal$Area$hasArea(config.co);
		var container_ = A2($terezka$line_charts$Internal$Container$properties, $elm$core$Basics$identity, config.cD);
		var frame = A2($terezka$line_charts$Internal$Coordinate$Frame, container_.dl, size);
		var adjustDomainRange = function (domain) {
			return hasArea ? $terezka$line_charts$Internal$Coordinate$ground(domain) : domain;
		};
		var system = {
			a6: frame,
			c1: container_.c1,
			ci: xRange,
			cj: xRange,
			ck: adjustDomainRange(yRange),
			cl: yRange
		};
		return _Utils_update(
			system,
			{
				ci: A2(
					$terezka$line_charts$Internal$Axis$Range$applyX,
					$terezka$line_charts$Internal$Axis$range(config.ci),
					system),
				ck: A2(
					$terezka$line_charts$Internal$Axis$Range$applyY,
					$terezka$line_charts$Internal$Axis$range(config.ck),
					system)
			});
	});
var $terezka$line_charts$Internal$Axis$ticks = function (_v0) {
	var config = _v0;
	return config.d7;
};
var $terezka$line_charts$Internal$Axis$Tick$properties = function (_v0) {
	var properties_ = _v0;
	return properties_;
};
var $terezka$line_charts$Internal$Axis$Ticks$ticks = F3(
	function (dataRange, range, _v0) {
		var values = _v0;
		return A2(
			$elm$core$List$map,
			$terezka$line_charts$Internal$Axis$Tick$properties,
			A2(values, dataRange, range));
	});
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $terezka$line_charts$Internal$Svg$gridDot = F3(
	function (radius, color, point) {
		return A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx(
					$elm$core$String$fromFloat(point.ci)),
					$elm$svg$Svg$Attributes$cy(
					$elm$core$String$fromFloat(point.ck)),
					$elm$svg$Svg$Attributes$r(
					$elm$core$String$fromFloat(radius)),
					$elm$svg$Svg$Attributes$fill(
					$avh4$elm_color$Color$toCssString(color))
				]),
			_List_Nil);
	});
var $terezka$line_charts$Internal$Grid$viewDots = F5(
	function (system, verticals, horizontals, radius, color) {
		var dot = F2(
			function (x, y) {
				return A2(
					$terezka$line_charts$LineChart$Coordinate$toSvg,
					system,
					A2($terezka$line_charts$LineChart$Coordinate$Point, x, y));
			});
		var dots_ = function (g) {
			return A2(
				$elm$core$List$map,
				dot(g),
				horizontals);
		};
		var alldots = A2($elm$core$List$concatMap, dots_, verticals);
		return A2(
			$elm$core$List$map,
			A2($terezka$line_charts$Internal$Svg$gridDot, radius, color),
			alldots);
	});
var $terezka$line_charts$Internal$Svg$horizontal = F5(
	function (system, userAttributes, y, x1, x2) {
		var attributes = A3(
			$terezka$line_charts$Internal$Utils$concat,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString($terezka$line_charts$LineChart$Colors$gray)),
					$elm$svg$Svg$Attributes$style('pointer-events: none;')
				]),
			userAttributes,
			_List_Nil);
		return A3(
			$terezka$line_charts$Internal$Path$view,
			system,
			attributes,
			_List_fromArray(
				[
					$terezka$line_charts$Internal$Path$Move(
					{ci: x1, ck: y}),
					$terezka$line_charts$Internal$Path$Line(
					{ci: x1, ck: y}),
					$terezka$line_charts$Internal$Path$Line(
					{ci: x2, ck: y})
				]));
	});
var $terezka$line_charts$Internal$Svg$horizontalGrid = F3(
	function (system, userAttributes, y) {
		var attributes = A3(
			$terezka$line_charts$Internal$Utils$concat,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString($terezka$line_charts$LineChart$Colors$gray)),
					$elm$svg$Svg$Attributes$style('pointer-events: none;')
				]),
			userAttributes,
			_List_Nil);
		return A5($terezka$line_charts$Internal$Svg$horizontal, system, attributes, y, system.ci.fl, system.ci.a8);
	});
var $terezka$line_charts$Internal$Grid$viewLines = F5(
	function (system, verticals, horizontals, width, color) {
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(width)),
				$elm$svg$Svg$Attributes$stroke(
				$avh4$elm_color$Color$toCssString(color))
			]);
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				A2($terezka$line_charts$Internal$Svg$horizontalGrid, system, attributes),
				horizontals),
			A2(
				$elm$core$List$map,
				A2($terezka$line_charts$Internal$Svg$verticalGrid, system, attributes),
				verticals));
	});
var $terezka$line_charts$Internal$Grid$view = F4(
	function (system, xAxis, yAxis, grid) {
		var hasGrid = function (tick) {
			return tick.cY ? $elm$core$Maybe$Just(tick.bh) : $elm$core$Maybe$Nothing;
		};
		var horizontals = A2(
			$elm$core$List$filterMap,
			hasGrid,
			A3(
				$terezka$line_charts$Internal$Axis$Ticks$ticks,
				system.cl,
				system.ck,
				$terezka$line_charts$Internal$Axis$ticks(yAxis)));
		var verticals = A2(
			$elm$core$List$filterMap,
			hasGrid,
			A3(
				$terezka$line_charts$Internal$Axis$Ticks$ticks,
				system.cj,
				system.ci,
				$terezka$line_charts$Internal$Axis$ticks(xAxis)));
		if (!grid.$) {
			var radius = grid.a;
			var color = grid.b;
			return A5($terezka$line_charts$Internal$Grid$viewDots, system, verticals, horizontals, radius, color);
		} else {
			var width = grid.a;
			var color = grid.b;
			return A5($terezka$line_charts$Internal$Grid$viewLines, system, verticals, horizontals, width, color);
		}
	});
var $terezka$line_charts$Internal$Svg$End = 2;
var $terezka$line_charts$Internal$Svg$Start = 0;
var $terezka$line_charts$Internal$Svg$anchorStyle = function (anchor) {
	var anchorString = function () {
		switch (anchor) {
			case 0:
				return 'start';
			case 1:
				return 'middle';
			default:
				return 'end';
		}
	}();
	return $elm$svg$Svg$Attributes$style('text-anchor: ' + (anchorString + ';'));
};
var $terezka$line_charts$Internal$Svg$Transfrom = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $terezka$line_charts$Internal$Svg$move = F3(
	function (system, x, y) {
		return A2(
			$terezka$line_charts$Internal$Svg$Transfrom,
			A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x),
			A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y));
	});
var $terezka$line_charts$Internal$Svg$offset = F2(
	function (x, y) {
		return A2($terezka$line_charts$Internal$Svg$Transfrom, x, y);
	});
var $terezka$line_charts$Internal$Svg$addPosition = F2(
	function (_v0, _v1) {
		var x = _v0.a;
		var y = _v0.b;
		var xf = _v1.a;
		var yf = _v1.b;
		return A2($terezka$line_charts$Internal$Svg$Transfrom, xf + x, yf + y);
	});
var $terezka$line_charts$Internal$Svg$toPosition = A2(
	$elm$core$List$foldr,
	$terezka$line_charts$Internal$Svg$addPosition,
	A2($terezka$line_charts$Internal$Svg$Transfrom, 0, 0));
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $terezka$line_charts$Internal$Svg$transform = function (translations) {
	var _v0 = $terezka$line_charts$Internal$Svg$toPosition(translations);
	var x = _v0.a;
	var y = _v0.b;
	return $elm$svg$Svg$Attributes$transform(
		'translate(' + ($elm$core$String$fromFloat(x) + (', ' + ($elm$core$String$fromFloat(y) + ')'))));
};
var $terezka$line_charts$Internal$Legends$viewFree = F5(
	function (system, placement, viewLabel, line, data) {
		var _v0 = function () {
			if (!placement) {
				return _Utils_Tuple3(data, 2, -10);
			} else {
				return _Utils_Tuple3(
					$elm$core$List$reverse(data),
					0,
					10);
			}
		}();
		var orderedPoints = _v0.a;
		var anchor = _v0.b;
		var xOffset = _v0.c;
		var transform = function (_v3) {
			var x = _v3.ci;
			var y = _v3.ck;
			return $terezka$line_charts$Internal$Svg$transform(
				_List_fromArray(
					[
						A3($terezka$line_charts$Internal$Svg$move, system, x, y),
						A2($terezka$line_charts$Internal$Svg$offset, xOffset, 3)
					]));
		};
		var viewLegend = function (_v2) {
			var point = _v2.fC;
			return A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						transform(point),
						$terezka$line_charts$Internal$Svg$anchorStyle(anchor)
					]),
				_List_fromArray(
					[
						viewLabel(
						$terezka$line_charts$Internal$Line$label(line))
					]));
		};
		return A2(
			$terezka$line_charts$Internal$Utils$viewMaybe,
			$elm$core$List$head(orderedPoints),
			viewLegend);
	});
var $terezka$line_charts$Internal$Legends$viewFrees = F3(
	function (_v0, placement, view_) {
		var system = _v0.d5;
		var lines = _v0.bR;
		var data = _v0.bA;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__legends')
				]),
			A3(
				$elm$core$List$map2,
				A3($terezka$line_charts$Internal$Legends$viewFree, system, placement, view_),
				lines,
				data));
	});
var $terezka$line_charts$Internal$Line$shape = function (_v0) {
	var config = _v0;
	return config.d0;
};
var $elm$core$Basics$pi = _Basics_pi;
var $elm$svg$Svg$Attributes$strokeOpacity = _VirtualDom_attribute('stroke-opacity');
var $terezka$line_charts$Internal$Dots$varietyAttributes = F2(
	function (color, variety) {
		switch (variety.$) {
			case 0:
				var width = variety.a;
				return _List_fromArray(
					[
						$elm$svg$Svg$Attributes$stroke(
						$avh4$elm_color$Color$toCssString(color)),
						$elm$svg$Svg$Attributes$strokeWidth(
						$elm$core$String$fromInt(width)),
						$elm$svg$Svg$Attributes$fill('white')
					]);
			case 2:
				var width = variety.a;
				var opacity = variety.b;
				return _List_fromArray(
					[
						$elm$svg$Svg$Attributes$stroke(
						$avh4$elm_color$Color$toCssString(color)),
						$elm$svg$Svg$Attributes$strokeWidth(
						$elm$core$String$fromInt(width)),
						$elm$svg$Svg$Attributes$strokeOpacity(
						$elm$core$String$fromFloat(opacity)),
						$elm$svg$Svg$Attributes$fill(
						$avh4$elm_color$Color$toCssString(color))
					]);
			case 1:
				var width = variety.a;
				return _List_fromArray(
					[
						$elm$svg$Svg$Attributes$stroke('white'),
						$elm$svg$Svg$Attributes$strokeWidth(
						$elm$core$String$fromInt(width)),
						$elm$svg$Svg$Attributes$fill(
						$avh4$elm_color$Color$toCssString(color))
					]);
			default:
				return _List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(
						$avh4$elm_color$Color$toCssString(color))
					]);
		}
	});
var $terezka$line_charts$Internal$Dots$viewCircle = F5(
	function (events, variety, color, area, point) {
		var radius = $elm$core$Basics$sqrt(area / $elm$core$Basics$pi);
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$cx(
				$elm$core$String$fromFloat(point.ci)),
				$elm$svg$Svg$Attributes$cy(
				$elm$core$String$fromFloat(point.ck)),
				$elm$svg$Svg$Attributes$r(
				$elm$core$String$fromFloat(radius))
			]);
		return A2(
			$elm$svg$Svg$circle,
			_Utils_ap(
				events,
				_Utils_ap(
					attributes,
					A2($terezka$line_charts$Internal$Dots$varietyAttributes, color, variety))),
			_List_Nil);
	});
var $terezka$line_charts$Internal$Dots$pathPlus = F2(
	function (area, point) {
		var side = $elm$core$Basics$sqrt(area / 5);
		var r6 = side / 2;
		var r3 = side;
		var commands = _List_fromArray(
			[
				'M' + ($elm$core$String$fromFloat(point.ci - r6) + (' ' + $elm$core$String$fromFloat((point.ck - r3) - r6))),
				'v' + $elm$core$String$fromFloat(r3),
				'h' + $elm$core$String$fromFloat(-r3),
				'v' + $elm$core$String$fromFloat(r3),
				'h' + $elm$core$String$fromFloat(r3),
				'v' + $elm$core$String$fromFloat(r3),
				'h' + $elm$core$String$fromFloat(r3),
				'v' + $elm$core$String$fromFloat(-r3),
				'h' + $elm$core$String$fromFloat(r3),
				'v' + $elm$core$String$fromFloat(-r3),
				'h' + $elm$core$String$fromFloat(-r3),
				'v' + $elm$core$String$fromFloat(-r3),
				'h' + $elm$core$String$fromFloat(-r3),
				'v' + $elm$core$String$fromFloat(r3)
			]);
		return A2($elm$core$String$join, ' ', commands);
	});
var $terezka$line_charts$Internal$Dots$viewCross = F5(
	function (events, variety, color, area, point) {
		var rotation = 'rotate(45 ' + ($elm$core$String$fromFloat(point.ci) + (' ' + ($elm$core$String$fromFloat(point.ck) + ')')));
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$d(
				A2($terezka$line_charts$Internal$Dots$pathPlus, area, point)),
				$elm$svg$Svg$Attributes$transform(rotation)
			]);
		return A2(
			$elm$svg$Svg$path,
			_Utils_ap(
				events,
				_Utils_ap(
					attributes,
					A2($terezka$line_charts$Internal$Dots$varietyAttributes, color, variety))),
			_List_Nil);
	});
var $terezka$line_charts$Internal$Dots$viewDiamond = F5(
	function (events, variety, color, area, point) {
		var side = $elm$core$Basics$sqrt(area);
		var rotation = 'rotate(45 ' + ($elm$core$String$fromFloat(point.ci) + (' ' + ($elm$core$String$fromFloat(point.ck) + ')')));
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$x(
				$elm$core$String$fromFloat(point.ci - (side / 2))),
				$elm$svg$Svg$Attributes$y(
				$elm$core$String$fromFloat(point.ck - (side / 2))),
				$elm$svg$Svg$Attributes$width(
				$elm$core$String$fromFloat(side)),
				$elm$svg$Svg$Attributes$height(
				$elm$core$String$fromFloat(side)),
				$elm$svg$Svg$Attributes$transform(rotation)
			]);
		return A2(
			$elm$svg$Svg$rect,
			_Utils_ap(
				events,
				_Utils_ap(
					attributes,
					A2($terezka$line_charts$Internal$Dots$varietyAttributes, color, variety))),
			_List_Nil);
	});
var $terezka$line_charts$Internal$Dots$viewPlus = F5(
	function (events, variety, color, area, point) {
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$d(
				A2($terezka$line_charts$Internal$Dots$pathPlus, area, point))
			]);
		return A2(
			$elm$svg$Svg$path,
			_Utils_ap(
				events,
				_Utils_ap(
					attributes,
					A2($terezka$line_charts$Internal$Dots$varietyAttributes, color, variety))),
			_List_Nil);
	});
var $terezka$line_charts$Internal$Dots$viewSquare = F5(
	function (events, variety, color, area, point) {
		var side = $elm$core$Basics$sqrt(area);
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$x(
				$elm$core$String$fromFloat(point.ci - (side / 2))),
				$elm$svg$Svg$Attributes$y(
				$elm$core$String$fromFloat(point.ck - (side / 2))),
				$elm$svg$Svg$Attributes$width(
				$elm$core$String$fromFloat(side)),
				$elm$svg$Svg$Attributes$height(
				$elm$core$String$fromFloat(side))
			]);
		return A2(
			$elm$svg$Svg$rect,
			_Utils_ap(
				events,
				_Utils_ap(
					attributes,
					A2($terezka$line_charts$Internal$Dots$varietyAttributes, color, variety))),
			_List_Nil);
	});
var $elm$core$Basics$degrees = function (angleInDegrees) {
	return (angleInDegrees * $elm$core$Basics$pi) / 180;
};
var $elm$core$Basics$tan = _Basics_tan;
var $terezka$line_charts$Internal$Dots$pathTriangle = F2(
	function (area, point) {
		var side = $elm$core$Basics$sqrt(
			(area * 4) / $elm$core$Basics$sqrt(3));
		var height = ($elm$core$Basics$sqrt(3) * side) / 2;
		var fromMiddle = height - (($elm$core$Basics$tan(
			$elm$core$Basics$degrees(30)) * side) / 2);
		var commands = _List_fromArray(
			[
				'M' + ($elm$core$String$fromFloat(point.ci) + (' ' + $elm$core$String$fromFloat(point.ck - fromMiddle))),
				'l' + ($elm$core$String$fromFloat((-side) / 2) + (' ' + $elm$core$String$fromFloat(height))),
				'h' + $elm$core$String$fromFloat(side),
				'z'
			]);
		return A2($elm$core$String$join, ' ', commands);
	});
var $terezka$line_charts$Internal$Dots$viewTriangle = F5(
	function (events, variety, color, area, point) {
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$d(
				A2($terezka$line_charts$Internal$Dots$pathTriangle, area, point))
			]);
		return A2(
			$elm$svg$Svg$path,
			_Utils_ap(
				events,
				_Utils_ap(
					attributes,
					A2($terezka$line_charts$Internal$Dots$varietyAttributes, color, variety))),
			_List_Nil);
	});
var $terezka$line_charts$Internal$Dots$viewShape = F5(
	function (system, _v0, shape, color, point) {
		var radius = _v0.b$;
		var variety = _v0.ce;
		var view_ = function () {
			switch (shape) {
				case 1:
					return $terezka$line_charts$Internal$Dots$viewCircle;
				case 2:
					return $terezka$line_charts$Internal$Dots$viewTriangle;
				case 3:
					return $terezka$line_charts$Internal$Dots$viewSquare;
				case 4:
					return $terezka$line_charts$Internal$Dots$viewDiamond;
				case 5:
					return $terezka$line_charts$Internal$Dots$viewCross;
				case 6:
					return $terezka$line_charts$Internal$Dots$viewPlus;
				default:
					return F5(
						function (_v2, _v3, _v4, _v5, _v6) {
							return $elm$svg$Svg$text('');
						});
			}
		}();
		var size = (2 * $elm$core$Basics$pi) * radius;
		var pointSvg = A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, point);
		return A5(view_, _List_Nil, variety, color, size, pointSvg);
	});
var $terezka$line_charts$Internal$Dots$viewSample = F5(
	function (_v0, shape, color, system, data) {
		var config = _v0;
		var _v1 = config.fj(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.ed;
				},
				data));
		var style_ = _v1;
		return A4($terezka$line_charts$Internal$Dots$viewShape, system, style_, shape, color);
	});
var $elm$svg$Svg$Attributes$fillOpacity = _VirtualDom_attribute('fill-opacity');
var $terezka$line_charts$Internal$Area$opacity = function (config) {
	switch (config.$) {
		case 0:
			return 0;
		case 1:
			var opacity_ = config.a;
			return opacity_;
		case 2:
			var opacity_ = config.a;
			return opacity_;
		default:
			var opacity_ = config.a;
			return opacity_;
	}
};
var $terezka$line_charts$Internal$Line$toAreaAttributes = F3(
	function (_v0, _v1, area) {
		var serie = _v0;
		var style_ = _v1;
		return _List_fromArray(
			[
				$elm$svg$Svg$Attributes$class('chart__interpolation__area__fragment'),
				$elm$svg$Svg$Attributes$fill(
				$avh4$elm_color$Color$toCssString(
					style_.bz(serie.bz)))
			]);
	});
var $elm$svg$Svg$Attributes$strokeDasharray = _VirtualDom_attribute('stroke-dasharray');
var $terezka$line_charts$Internal$Line$toSeriesAttributes = F2(
	function (_v0, _v1) {
		var serie = _v0;
		var style_ = _v1;
		return _List_fromArray(
			[
				$elm$svg$Svg$Attributes$style('pointer-events: none;'),
				$elm$svg$Svg$Attributes$class('chart__interpolation__line__fragment'),
				$elm$svg$Svg$Attributes$stroke(
				$avh4$elm_color$Color$toCssString(
					style_.bz(serie.bz))),
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(style_.aH)),
				$elm$svg$Svg$Attributes$strokeDasharray(
				A2(
					$elm$core$String$join,
					' ',
					A2($elm$core$List$map, $elm$core$String$fromFloat, serie.cF))),
				$elm$svg$Svg$Attributes$fill('transparent')
			]);
	});
var $terezka$line_charts$Internal$Utils$viewIf = F2(
	function (condition, view) {
		return condition ? view(0) : $elm$svg$Svg$text('');
	});
var $terezka$line_charts$Internal$Line$viewSample = F5(
	function (_v0, line_, area, data_, sampleWidth) {
		var look = _v0;
		var style_ = look(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.ed;
				},
				data_));
		var sizeAttributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$x1('0'),
				$elm$svg$Svg$Attributes$y1('0'),
				$elm$svg$Svg$Attributes$x2(
				$elm$core$String$fromFloat(sampleWidth)),
				$elm$svg$Svg$Attributes$y2('0')
			]);
		var rectangleAttributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$x('0'),
				$elm$svg$Svg$Attributes$y('0'),
				$elm$svg$Svg$Attributes$height('9'),
				$elm$svg$Svg$Attributes$width(
				$elm$core$String$fromFloat(sampleWidth))
			]);
		var lineAttributes = A2($terezka$line_charts$Internal$Line$toSeriesAttributes, line_, style_);
		var areaAttributes = A2(
			$elm$core$List$cons,
			$elm$svg$Svg$Attributes$fillOpacity(
				$elm$core$String$fromFloat(
					$terezka$line_charts$Internal$Area$opacity(area))),
			A3($terezka$line_charts$Internal$Line$toAreaAttributes, line_, style_, area));
		var viewRectangle = function (_v1) {
			return A2(
				$elm$svg$Svg$rect,
				_Utils_ap(areaAttributes, rectangleAttributes),
				_List_Nil);
		};
		return A2(
			$elm$svg$Svg$g,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$line,
					_Utils_ap(lineAttributes, sizeAttributes),
					_List_Nil),
					A2(
					$terezka$line_charts$Internal$Utils$viewIf,
					$terezka$line_charts$Internal$Area$hasArea(area),
					viewRectangle)
				]));
	});
var $terezka$line_charts$Internal$Legends$viewSample = F4(
	function (_v0, sampleWidth, line, data) {
		var system = _v0.d5;
		var lineConfig = _v0.dh;
		var dotsConfig = _v0.cK;
		var area = _v0.co;
		var shape = $terezka$line_charts$Internal$Line$shape(line);
		var dotPosition = A2(
			$terezka$line_charts$LineChart$Coordinate$toData,
			system,
			A2($terezka$line_charts$Internal$Data$Point, sampleWidth / 2, 0));
		var color = A3($terezka$line_charts$Internal$Line$color, lineConfig, line, data);
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__sample')
				]),
			_List_fromArray(
				[
					A5($terezka$line_charts$Internal$Line$viewSample, lineConfig, line, area, data, sampleWidth),
					A6($terezka$line_charts$Internal$Dots$viewSample, dotsConfig, shape, color, system, data, dotPosition)
				]));
	});
var $terezka$line_charts$Internal$Legends$viewGrouped = F3(
	function (_arguments, sampleWidth, container) {
		var toLegend = F2(
			function (line, data) {
				return {
					fi: $terezka$line_charts$Internal$Line$label(line),
					fM: A4($terezka$line_charts$Internal$Legends$viewSample, _arguments, sampleWidth, line, data)
				};
			});
		var legends = A3($elm$core$List$map2, toLegend, _arguments.bR, _arguments.bA);
		return A2(container, _arguments.d5, legends);
	});
var $terezka$line_charts$Internal$Legends$view = function (_arguments) {
	var _v0 = _arguments.de;
	switch (_v0.$) {
		case 1:
			var placement = _v0.a;
			var view_ = _v0.b;
			return A3($terezka$line_charts$Internal$Legends$viewFrees, _arguments, placement, view_);
		case 2:
			var sampleWidth = _v0.a;
			var container = _v0.b;
			return A3(
				$terezka$line_charts$Internal$Legends$viewGrouped,
				_arguments,
				sampleWidth,
				container(_arguments));
		default:
			return $elm$svg$Svg$text('');
	}
};
var $terezka$line_charts$Internal$Area$opacityContainer = function (config) {
	switch (config.$) {
		case 0:
			return 1;
		case 1:
			var opacity_ = config.a;
			return 1;
		case 2:
			var opacity_ = config.a;
			return opacity_;
		default:
			var opacity_ = config.a;
			return opacity_;
	}
};
var $terezka$line_charts$Internal$Utils$unzip3 = function (pairs) {
	var step = F2(
		function (_v0, _v1) {
			var a = _v0.a;
			var b = _v0.b;
			var c = _v0.c;
			var aas = _v1.a;
			var bs = _v1.b;
			var cs = _v1.c;
			return _Utils_Tuple3(
				A2($elm$core$List$cons, a, aas),
				A2($elm$core$List$cons, b, bs),
				A2($elm$core$List$cons, c, cs));
		});
	return A3(
		$elm$core$List$foldr,
		step,
		_Utils_Tuple3(_List_Nil, _List_Nil, _List_Nil),
		pairs);
};
var $elm$core$List$map3 = _List_map3;
var $terezka$line_charts$Internal$Line$viewNormal = function (_v0) {
	var areas = _v0.a;
	var lines = _v0.b;
	var dots = _v0.c;
	var view_ = F3(
		function (area_, line_, dots_) {
			return A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('chart__line')
					]),
				_List_fromArray(
					[area_, line_, dots_]));
		});
	return A4($elm$core$List$map3, view_, areas, lines, dots);
};
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $terezka$line_charts$Internal$Data$isWithinRange = F2(
	function (system, point) {
		return _Utils_eq(
			A3($elm$core$Basics$clamp, system.ci.fl, system.ci.a8, point.ci),
			point.ci) && _Utils_eq(
			A3($elm$core$Basics$clamp, system.ck.fl, system.ck.a8, point.ck),
			point.ck);
	});
var $terezka$line_charts$Internal$Utils$part = F4(
	function (isReal, points, current, parts) {
		part:
		while (true) {
			if (points.b) {
				var first = points.a;
				var rest = points.b;
				if (isReal(first)) {
					var $temp$isReal = isReal,
						$temp$points = rest,
						$temp$current = _Utils_ap(
						current,
						_List_fromArray(
							[first])),
						$temp$parts = parts;
					isReal = $temp$isReal;
					points = $temp$points;
					current = $temp$current;
					parts = $temp$parts;
					continue part;
				} else {
					var $temp$isReal = isReal,
						$temp$points = rest,
						$temp$current = _List_Nil,
						$temp$parts = A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							current,
							$elm$core$Maybe$Just(first)),
						parts);
					isReal = $temp$isReal;
					points = $temp$points;
					current = $temp$current;
					parts = $temp$parts;
					continue part;
				}
			} else {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(current, $elm$core$Maybe$Nothing),
					parts);
			}
		}
	});
var $terezka$line_charts$Internal$Interpolation$linear = $elm$core$List$map(
	$elm$core$List$map($terezka$line_charts$Internal$Path$Line));
var $terezka$line_charts$Internal$Interpolation$First = {$: 0};
var $terezka$line_charts$Internal$Interpolation$Previous = function (a) {
	return {$: 1, a: a};
};
var $terezka$line_charts$Internal$Interpolation$monotoneCurve = F4(
	function (point0, point1, tangent0, tangent1) {
		var dx = (point1.ci - point0.ci) / 3;
		return A3(
			$terezka$line_charts$Internal$Path$CubicBeziers,
			{ci: point0.ci + dx, ck: point0.ck + (dx * tangent0)},
			{ci: point1.ci - dx, ck: point1.ck - (dx * tangent1)},
			point1);
	});
var $terezka$line_charts$Internal$Interpolation$slope2 = F3(
	function (point0, point1, t) {
		var h = point1.ci - point0.ci;
		return (!(!h)) ? ((((3 * (point1.ck - point0.ck)) / h) - t) / 2) : t;
	});
var $terezka$line_charts$Internal$Interpolation$sign = function (x) {
	return (x < 0) ? (-1) : 1;
};
var $terezka$line_charts$Internal$Interpolation$toH = F2(
	function (h0, h1) {
		return (!h0) ? ((h1 < 0) ? (0 * (-1)) : h1) : h0;
	});
var $terezka$line_charts$Internal$Interpolation$slope3 = F3(
	function (point0, point1, point2) {
		var h1 = point2.ci - point1.ci;
		var h0 = point1.ci - point0.ci;
		var s0h = A2($terezka$line_charts$Internal$Interpolation$toH, h0, h1);
		var s0 = (point1.ck - point0.ck) / s0h;
		var s1h = A2($terezka$line_charts$Internal$Interpolation$toH, h1, h0);
		var s1 = (point2.ck - point1.ck) / s1h;
		var p = ((s0 * h1) + (s1 * h0)) / (h0 + h1);
		var slope = ($terezka$line_charts$Internal$Interpolation$sign(s0) + $terezka$line_charts$Internal$Interpolation$sign(s1)) * A2(
			$elm$core$Basics$min,
			A2(
				$elm$core$Basics$min,
				$elm$core$Basics$abs(s0),
				$elm$core$Basics$abs(s1)),
			0.5 * $elm$core$Basics$abs(p));
		return $elm$core$Basics$isNaN(slope) ? 0 : slope;
	});
var $terezka$line_charts$Internal$Interpolation$monotonePart = F2(
	function (points, _v0) {
		var tangent = _v0.a;
		var commands = _v0.b;
		var _v1 = _Utils_Tuple2(tangent, points);
		_v1$4:
		while (true) {
			if (!_v1.a.$) {
				if (_v1.b.b && _v1.b.b.b) {
					if (_v1.b.b.b.b) {
						var _v2 = _v1.a;
						var _v3 = _v1.b;
						var p0 = _v3.a;
						var _v4 = _v3.b;
						var p1 = _v4.a;
						var _v5 = _v4.b;
						var p2 = _v5.a;
						var rest = _v5.b;
						var t1 = A3($terezka$line_charts$Internal$Interpolation$slope3, p0, p1, p2);
						var t0 = A3($terezka$line_charts$Internal$Interpolation$slope2, p0, p1, t1);
						return A2(
							$terezka$line_charts$Internal$Interpolation$monotonePart,
							A2(
								$elm$core$List$cons,
								p1,
								A2($elm$core$List$cons, p2, rest)),
							_Utils_Tuple2(
								$terezka$line_charts$Internal$Interpolation$Previous(t1),
								_Utils_ap(
									commands,
									_List_fromArray(
										[
											A4($terezka$line_charts$Internal$Interpolation$monotoneCurve, p0, p1, t0, t1)
										]))));
					} else {
						var _v9 = _v1.a;
						var _v10 = _v1.b;
						var p0 = _v10.a;
						var _v11 = _v10.b;
						var p1 = _v11.a;
						var t1 = A3($terezka$line_charts$Internal$Interpolation$slope3, p0, p1, p1);
						return _Utils_Tuple2(
							$terezka$line_charts$Internal$Interpolation$Previous(t1),
							_Utils_ap(
								commands,
								_List_fromArray(
									[
										A4($terezka$line_charts$Internal$Interpolation$monotoneCurve, p0, p1, t1, t1),
										$terezka$line_charts$Internal$Path$Line(p1)
									])));
					}
				} else {
					break _v1$4;
				}
			} else {
				if (_v1.b.b && _v1.b.b.b) {
					if (_v1.b.b.b.b) {
						var t0 = _v1.a.a;
						var _v6 = _v1.b;
						var p0 = _v6.a;
						var _v7 = _v6.b;
						var p1 = _v7.a;
						var _v8 = _v7.b;
						var p2 = _v8.a;
						var rest = _v8.b;
						var t1 = A3($terezka$line_charts$Internal$Interpolation$slope3, p0, p1, p2);
						return A2(
							$terezka$line_charts$Internal$Interpolation$monotonePart,
							A2(
								$elm$core$List$cons,
								p1,
								A2($elm$core$List$cons, p2, rest)),
							_Utils_Tuple2(
								$terezka$line_charts$Internal$Interpolation$Previous(t1),
								_Utils_ap(
									commands,
									_List_fromArray(
										[
											A4($terezka$line_charts$Internal$Interpolation$monotoneCurve, p0, p1, t0, t1)
										]))));
					} else {
						var t0 = _v1.a.a;
						var _v12 = _v1.b;
						var p0 = _v12.a;
						var _v13 = _v12.b;
						var p1 = _v13.a;
						var t1 = A3($terezka$line_charts$Internal$Interpolation$slope3, p0, p1, p1);
						return _Utils_Tuple2(
							$terezka$line_charts$Internal$Interpolation$Previous(t1),
							_Utils_ap(
								commands,
								_List_fromArray(
									[
										A4($terezka$line_charts$Internal$Interpolation$monotoneCurve, p0, p1, t0, t1),
										$terezka$line_charts$Internal$Path$Line(p1)
									])));
					}
				} else {
					break _v1$4;
				}
			}
		}
		return _Utils_Tuple2(tangent, commands);
	});
var $terezka$line_charts$Internal$Interpolation$monotoneSection = F2(
	function (points, _v0) {
		var tangent = _v0.a;
		var acc = _v0.b;
		var _v1 = function () {
			if (points.b) {
				var p0 = points.a;
				var rest = points.b;
				return A2(
					$terezka$line_charts$Internal$Interpolation$monotonePart,
					A2($elm$core$List$cons, p0, rest),
					_Utils_Tuple2(
						tangent,
						_List_fromArray(
							[
								$terezka$line_charts$Internal$Path$Line(p0)
							])));
			} else {
				return _Utils_Tuple2(tangent, _List_Nil);
			}
		}();
		var t0 = _v1.a;
		var commands = _v1.b;
		return _Utils_Tuple2(
			t0,
			A2($elm$core$List$cons, commands, acc));
	});
var $terezka$line_charts$Internal$Interpolation$monotone = function (sections) {
	return A3(
		$elm$core$List$foldr,
		$terezka$line_charts$Internal$Interpolation$monotoneSection,
		_Utils_Tuple2($terezka$line_charts$Internal$Interpolation$First, _List_Nil),
		sections).b;
};
var $terezka$line_charts$Internal$Interpolation$after = F2(
	function (a, b) {
		return _List_fromArray(
			[
				a,
				A2($terezka$line_charts$Internal$Data$Point, b.ci, a.ck),
				b
			]);
	});
var $terezka$line_charts$Internal$Interpolation$stepped = function (sections) {
	var expand = F2(
		function (result, section) {
			expand:
			while (true) {
				if (section.a.b) {
					if (section.a.b.b) {
						var _v1 = section.a;
						var a = _v1.a;
						var _v2 = _v1.b;
						var b = _v2.a;
						var rest = _v2.b;
						var broken = section.b;
						var $temp$result = _Utils_ap(
							result,
							A2($terezka$line_charts$Internal$Interpolation$after, a, b)),
							$temp$section = _Utils_Tuple2(
							A2($elm$core$List$cons, b, rest),
							broken);
						result = $temp$result;
						section = $temp$section;
						continue expand;
					} else {
						if (!section.b.$) {
							var _v3 = section.a;
							var last = _v3.a;
							var broken = section.b.a;
							return _Utils_ap(
								result,
								_List_fromArray(
									[
										A2($terezka$line_charts$Internal$Data$Point, broken.ci, last.ck)
									]));
						} else {
							var _v4 = section.a;
							var last = _v4.a;
							var _v5 = section.b;
							return result;
						}
					}
				} else {
					return result;
				}
			}
		});
	return A2(
		$elm$core$List$map,
		A2(
			$elm$core$Basics$composeR,
			expand(_List_Nil),
			$elm$core$List$map($terezka$line_charts$Internal$Path$Line)),
		sections);
};
var $terezka$line_charts$Internal$Interpolation$toCommands = F2(
	function (interpolation, data) {
		var pointsSections = $elm$core$List$map(
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Tuple$mapFirst(
					$elm$core$List$map(
						function ($) {
							return $.fC;
						})),
				$elm$core$Tuple$mapSecond(
					$elm$core$Maybe$map(
						function ($) {
							return $.fC;
						}))));
		var points = $elm$core$List$map(
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Tuple$first,
				$elm$core$List$map(
					function ($) {
						return $.fC;
					})));
		switch (interpolation) {
			case 0:
				return $terezka$line_charts$Internal$Interpolation$linear(
					points(data));
			case 1:
				return $terezka$line_charts$Internal$Interpolation$monotone(
					points(data));
			default:
				return $terezka$line_charts$Internal$Interpolation$stepped(
					pointsSections(data));
		}
	});
var $terezka$line_charts$Internal$Area$opacitySingle = function (config) {
	switch (config.$) {
		case 0:
			return 0;
		case 1:
			var opacity_ = config.a;
			return opacity_;
		case 2:
			var opacity_ = config.a;
			return 1;
		default:
			var opacity_ = config.a;
			return 1;
	}
};
var $terezka$line_charts$Internal$Path$toPoint = function (command) {
	switch (command.$) {
		case 9:
			return A2($terezka$line_charts$LineChart$Coordinate$Point, 0, 0);
		case 0:
			var p = command.a;
			return p;
		case 1:
			var p = command.a;
			return p;
		case 2:
			var x = command.a;
			return A2($terezka$line_charts$LineChart$Coordinate$Point, x, 0);
		case 3:
			var y = command.a;
			return A2($terezka$line_charts$LineChart$Coordinate$Point, 0, y);
		case 4:
			var c1 = command.a;
			var c2 = command.b;
			var p = command.c;
			return p;
		case 5:
			var c1 = command.a;
			var p = command.b;
			return p;
		case 6:
			var c1 = command.a;
			var p = command.b;
			return p;
		case 7:
			var p = command.a;
			return p;
		default:
			var rx = command.a;
			var ry = command.b;
			var xAxisRotation = command.c;
			var largeArcFlag = command.d;
			var sweepFlag = command.e;
			var p = command.f;
			return p;
	}
};
var $terezka$line_charts$Internal$Utils$towardsZero = function (_v0) {
	var max = _v0.a8;
	var min = _v0.fl;
	return A3($elm$core$Basics$clamp, min, max, 0);
};
var $terezka$line_charts$Internal$Utils$last = function (list) {
	return $elm$core$List$head(
		A2(
			$elm$core$List$drop,
			$elm$core$List$length(list) - 1,
			list));
};
var $terezka$line_charts$Internal$Utils$lastSafe = F2(
	function (first, rest) {
		return A2(
			$elm$core$Maybe$withDefault,
			first,
			$terezka$line_charts$Internal$Utils$last(rest));
	});
var $terezka$line_charts$Internal$Utils$viewWithEdges = F2(
	function (stuff, view) {
		if (stuff.b) {
			var first = stuff.a;
			var rest = stuff.b;
			return A3(
				view,
				first,
				rest,
				A2($terezka$line_charts$Internal$Utils$lastSafe, first, rest));
		} else {
			return $elm$svg$Svg$text('');
		}
	});
var $elm$svg$Svg$Attributes$clipPath = _VirtualDom_attribute('clip-path');
var $terezka$line_charts$Internal$Svg$withinChartArea = function (_v0) {
	var id = _v0.c1;
	return $elm$svg$Svg$Attributes$clipPath(
		'url(#' + ($terezka$line_charts$Internal$Utils$toChartAreaId(id) + ')'));
};
var $terezka$line_charts$LineChart$Junk$withinChartArea = $terezka$line_charts$Internal$Svg$withinChartArea;
var $terezka$line_charts$Internal$Line$viewArea = F5(
	function (_v0, line_, style_, interpolation, data_) {
		var system = _v0.d5;
		var lineConfig = _v0.dh;
		var area = _v0.co;
		var ground = function (point) {
			return A2(
				$terezka$line_charts$Internal$Data$Point,
				point.ci,
				$terezka$line_charts$Internal$Utils$towardsZero(system.ck));
		};
		var commands = F3(
			function (first, middle, last) {
				return A3(
					$terezka$line_charts$Internal$Utils$concat,
					_List_fromArray(
						[
							$terezka$line_charts$Internal$Path$Move(
							ground(
								$terezka$line_charts$Internal$Path$toPoint(first))),
							$terezka$line_charts$Internal$Path$Line(
							$terezka$line_charts$Internal$Path$toPoint(first))
						]),
					interpolation,
					_List_fromArray(
						[
							$terezka$line_charts$Internal$Path$Line(
							ground(
								$terezka$line_charts$Internal$Path$toPoint(last)))
						]));
			});
		var attributes = A2(
			$elm$core$List$cons,
			$terezka$line_charts$LineChart$Junk$withinChartArea(system),
			A2(
				$elm$core$List$cons,
				$elm$svg$Svg$Attributes$fillOpacity(
					$elm$core$String$fromFloat(
						$terezka$line_charts$Internal$Area$opacitySingle(area))),
				A3($terezka$line_charts$Internal$Line$toAreaAttributes, line_, style_, area)));
		return A2(
			$terezka$line_charts$Internal$Utils$viewWithEdges,
			interpolation,
			F3(
				function (first, middle, last) {
					return A3(
						$terezka$line_charts$Internal$Path$view,
						system,
						attributes,
						A3(commands, first, middle, last));
				}));
	});
var $terezka$line_charts$Internal$Dots$view = F2(
	function (_v0, data) {
		var system = _v0.d5;
		var dotsConfig = _v0.cK;
		var shape = _v0.d0;
		var color = _v0.bz;
		var _v1 = dotsConfig;
		var config = _v1;
		var _v2 = config.e8(data.ed);
		var style_ = _v2;
		return A5($terezka$line_charts$Internal$Dots$viewShape, system, style_, shape, color, data.fC);
	});
var $terezka$line_charts$Internal$Line$viewDot = F3(
	function (_arguments, _v0, _v1) {
		var lineConfig = _v0;
		var style_ = _v1;
		return $terezka$line_charts$Internal$Dots$view(
			{
				bz: style_.bz(lineConfig.bz),
				cK: _arguments.cK,
				d0: lineConfig.d0,
				d5: _arguments.d5
			});
	});
var $terezka$line_charts$Internal$Utils$viewWithFirst = F2(
	function (stuff, view) {
		if (stuff.b) {
			var first = stuff.a;
			var rest = stuff.b;
			return A2(view, first, rest);
		} else {
			return $elm$svg$Svg$text('');
		}
	});
var $terezka$line_charts$Internal$Line$viewSeries = F5(
	function (_v0, line_, style_, interpolation, data_) {
		var system = _v0.d5;
		var lineConfig = _v0.dh;
		var attributes = A2(
			$elm$core$List$cons,
			$terezka$line_charts$LineChart$Junk$withinChartArea(system),
			A2($terezka$line_charts$Internal$Line$toSeriesAttributes, line_, style_));
		return A2(
			$terezka$line_charts$Internal$Utils$viewWithFirst,
			data_,
			F2(
				function (first, _v1) {
					return A3(
						$terezka$line_charts$Internal$Path$view,
						system,
						attributes,
						A2(
							$elm$core$List$cons,
							$terezka$line_charts$Internal$Path$Move(first.fC),
							interpolation));
				}));
	});
var $terezka$line_charts$Internal$Line$viewSingle = F3(
	function (_arguments, line_, data_) {
		var style_ = function (_v1) {
			var look = _v1;
			return look(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.ed;
					},
					data_));
		}(_arguments.dh);
		var sections = A4(
			$terezka$line_charts$Internal$Utils$part,
			function ($) {
				return $.ff;
			},
			data_,
			_List_Nil,
			_List_Nil);
		var parts = A2($elm$core$List$map, $elm$core$Tuple$first, sections);
		var viewDots = A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__dots')
				]),
			A2(
				$elm$core$List$map,
				A3($terezka$line_charts$Internal$Line$viewDot, _arguments, line_, style_),
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeL,
						$terezka$line_charts$Internal$Data$isWithinRange(_arguments.d5),
						function ($) {
							return $.fC;
						}),
					$elm$core$List$concat(parts))));
		var commands = A2($terezka$line_charts$Internal$Interpolation$toCommands, _arguments.c7, sections);
		var viewAreas = function (_v0) {
			return A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('chart__interpolation__area')
					]),
				A3(
					$elm$core$List$map2,
					A3($terezka$line_charts$Internal$Line$viewArea, _arguments, line_, style_),
					commands,
					parts));
		};
		var viewSeriess = A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__interpolation__line')
				]),
			A3(
				$elm$core$List$map2,
				A3($terezka$line_charts$Internal$Line$viewSeries, _arguments, line_, style_),
				commands,
				parts));
		return _Utils_Tuple3(
			A2(
				$terezka$line_charts$Internal$Utils$viewIf,
				$terezka$line_charts$Internal$Area$hasArea(_arguments.co),
				viewAreas),
			viewSeriess,
			viewDots);
	});
var $terezka$line_charts$Internal$Line$viewStacked = F2(
	function (area, _v0) {
		var areas = _v0.a;
		var lines = _v0.b;
		var dots = _v0.c;
		var toList = F2(
			function (l, d) {
				return _List_fromArray(
					[l, d]);
			});
		var opacity = 'opacity: ' + $elm$core$String$fromFloat(
			$terezka$line_charts$Internal$Area$opacityContainer(area));
		var bottoms = $elm$core$List$concat(
			A3($elm$core$List$map2, toList, lines, dots));
		return _List_fromArray(
			[
				A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('chart__bottoms'),
						$elm$svg$Svg$Attributes$style(opacity)
					]),
				areas),
				A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('chart__tops')
					]),
				bottoms)
			]);
	});
var $terezka$line_charts$Internal$Line$view = F3(
	function (_arguments, lines, datas) {
		var container = $elm$svg$Svg$g(
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__lines')
				]));
		var buildSeriesViews = ($terezka$line_charts$Internal$Area$opacityContainer(_arguments.co) < 1) ? $terezka$line_charts$Internal$Line$viewStacked(_arguments.co) : $terezka$line_charts$Internal$Line$viewNormal;
		return container(
			buildSeriesViews(
				$terezka$line_charts$Internal$Utils$unzip3(
					A3(
						$elm$core$List$map2,
						$terezka$line_charts$Internal$Line$viewSingle(_arguments),
						lines,
						datas))));
	});
var $terezka$line_charts$LineChart$viewBoxAttribute = function (_v0) {
	var frame = _v0.a6;
	return $elm$svg$Svg$Attributes$viewBox(
		'0 0 ' + ($elm$core$String$fromFloat(frame.d2.aH) + (' ' + $elm$core$String$fromFloat(frame.d2.ax))));
};
var $terezka$line_charts$Internal$Axis$Line$config = function (_v0) {
	var config_ = _v0;
	return config_;
};
var $terezka$line_charts$Internal$Axis$Title$config = function (_v0) {
	var title = _v0;
	return title;
};
var $terezka$line_charts$Internal$Axis$Intersection$getY = function (_v0) {
	var func = _v0;
	return A2(
		$elm$core$Basics$composeL,
		function ($) {
			return $.ck;
		},
		func);
};
var $terezka$line_charts$Internal$Axis$attributesLine = F2(
	function (system, _v0) {
		var events = _v0.cP;
		var width = _v0.aH;
		var color = _v0.bz;
		return _Utils_ap(
			events,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$strokeWidth(
					$elm$core$String$fromFloat(width)),
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString(color)),
					$terezka$line_charts$Internal$Svg$withinChartArea(system)
				]));
	});
var $terezka$line_charts$Internal$Axis$viewHorizontalAxisLine = F3(
	function (system, axisPosition, config) {
		return A5(
			$terezka$line_charts$Internal$Svg$horizontal,
			system,
			A2($terezka$line_charts$Internal$Axis$attributesLine, system, config),
			axisPosition,
			config.cb,
			config.cN);
	});
var $terezka$line_charts$Internal$Axis$attributesTick = function (_v0) {
	var width = _v0.aH;
	var color = _v0.bz;
	return _List_fromArray(
		[
			$elm$svg$Svg$Attributes$strokeWidth(
			$elm$core$String$fromFloat(width)),
			$elm$svg$Svg$Attributes$stroke(
			$avh4$elm_color$Color$toCssString(color))
		]);
};
var $terezka$line_charts$Internal$Axis$Tick$isPositive = function (direction) {
	if (direction === 1) {
		return true;
	} else {
		return false;
	}
};
var $terezka$line_charts$Internal$Axis$lengthOfTick = function (_v0) {
	var length = _v0.bP;
	var direction = _v0.bC;
	return $terezka$line_charts$Internal$Axis$Tick$isPositive(direction) ? (-length) : length;
};
var $terezka$line_charts$Internal$Svg$Middle = 1;
var $terezka$line_charts$Internal$Axis$viewHorizontalLabel = F4(
	function (system, _v0, position, view) {
		var direction = _v0.bC;
		var length = _v0.bP;
		var yOffset = $terezka$line_charts$Internal$Axis$Tick$isPositive(direction) ? ((-5) - length) : (15 + length);
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$terezka$line_charts$Internal$Svg$transform(
					_List_fromArray(
						[
							A3($terezka$line_charts$Internal$Svg$move, system, position.ci, position.ck),
							A2($terezka$line_charts$Internal$Svg$offset, 0, yOffset)
						])),
					$terezka$line_charts$Internal$Svg$anchorStyle(1)
				]),
			_List_fromArray(
				[view]));
	});
var $terezka$line_charts$Internal$Svg$xTick = F5(
	function (system, height, userAttributes, y, x) {
		var attributes = A3(
			$terezka$line_charts$Internal$Utils$concat,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString($terezka$line_charts$LineChart$Colors$gray))
				]),
			userAttributes,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x))),
					$elm$svg$Svg$Attributes$x2(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x))),
					$elm$svg$Svg$Attributes$y1(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y))),
					$elm$svg$Svg$Attributes$y2(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y) + height))
				]));
		return A2($elm$svg$Svg$line, attributes, _List_Nil);
	});
var $terezka$line_charts$Internal$Axis$viewHorizontalTick = F3(
	function (system, point, tick) {
		var x = point.ci;
		var y = point.ck;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__tick')
				]),
			_List_fromArray(
				[
					A5(
					$terezka$line_charts$Internal$Svg$xTick,
					system,
					$terezka$line_charts$Internal$Axis$lengthOfTick(tick),
					$terezka$line_charts$Internal$Axis$attributesTick(tick),
					y,
					x),
					A2(
					$terezka$line_charts$Internal$Utils$viewMaybe,
					tick.fi,
					A3($terezka$line_charts$Internal$Axis$viewHorizontalLabel, system, tick, point))
				]));
	});
var $terezka$line_charts$Internal$Axis$viewHorizontalTitle = F3(
	function (system, at, _v0) {
		var title = _v0.d9;
		var position = at(
			A2(title.bh, system.cj, system.ci));
		var _v1 = title.dv;
		var xOffset = _v1.a;
		var yOffset = _v1.b;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__title'),
					$terezka$line_charts$Internal$Svg$transform(
					_List_fromArray(
						[
							A3($terezka$line_charts$Internal$Svg$move, system, position.ci, position.ck),
							A2($terezka$line_charts$Internal$Svg$offset, xOffset + 15, yOffset + 5)
						])),
					$terezka$line_charts$Internal$Svg$anchorStyle(0)
				]),
			_List_fromArray(
				[title.f9]));
	});
var $terezka$line_charts$Internal$Axis$viewHorizontal = F3(
	function (system, intersection, _v0) {
		var config = _v0;
		var viewConfig = {
			c8: A2($terezka$line_charts$Internal$Axis$Intersection$getY, intersection, system),
			dg: A3($terezka$line_charts$Internal$Axis$Line$config, config.cr, system.cj, system.ci),
			d7: A3($terezka$line_charts$Internal$Axis$Ticks$ticks, system.cj, system.ci, config.d7),
			d9: $terezka$line_charts$Internal$Axis$Title$config(config.d9)
		};
		var viewAxisLine = A2($terezka$line_charts$Internal$Axis$viewHorizontalAxisLine, system, viewConfig.c8);
		var at = function (x) {
			return {ci: x, ck: viewConfig.c8};
		};
		var viewTick = function (tick) {
			return A3(
				$terezka$line_charts$Internal$Axis$viewHorizontalTick,
				system,
				at(tick.bh),
				tick);
		};
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__axis--horizontal')
				]),
			_List_fromArray(
				[
					A3($terezka$line_charts$Internal$Axis$viewHorizontalTitle, system, at, viewConfig),
					viewAxisLine(viewConfig.dg),
					A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('chart__ticks')
						]),
					A2($elm$core$List$map, viewTick, viewConfig.d7))
				]));
	});
var $terezka$line_charts$Internal$Axis$Intersection$getX = function (_v0) {
	var func = _v0;
	return A2(
		$elm$core$Basics$composeL,
		function ($) {
			return $.ci;
		},
		func);
};
var $terezka$line_charts$Internal$Axis$viewVerticalAxisLine = F3(
	function (system, axisPosition, config) {
		return A5(
			$terezka$line_charts$Internal$Svg$vertical,
			system,
			A2($terezka$line_charts$Internal$Axis$attributesLine, system, config),
			axisPosition,
			config.cb,
			config.cN);
	});
var $terezka$line_charts$Internal$Axis$viewVerticalLabel = F4(
	function (system, _v0, position, view) {
		var direction = _v0.bC;
		var length = _v0.bP;
		var xOffset = $terezka$line_charts$Internal$Axis$Tick$isPositive(direction) ? (5 + length) : ((-5) - length);
		var anchor = $terezka$line_charts$Internal$Axis$Tick$isPositive(direction) ? 0 : 2;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$terezka$line_charts$Internal$Svg$transform(
					_List_fromArray(
						[
							A3($terezka$line_charts$Internal$Svg$move, system, position.ci, position.ck),
							A2($terezka$line_charts$Internal$Svg$offset, xOffset, 5)
						])),
					$terezka$line_charts$Internal$Svg$anchorStyle(anchor)
				]),
			_List_fromArray(
				[view]));
	});
var $terezka$line_charts$Internal$Svg$yTick = F5(
	function (system, width, userAttributes, x, y) {
		var attributes = A3(
			$terezka$line_charts$Internal$Utils$concat,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__tick'),
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString($terezka$line_charts$LineChart$Colors$gray))
				]),
			userAttributes,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x))),
					$elm$svg$Svg$Attributes$x2(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x) - width)),
					$elm$svg$Svg$Attributes$y1(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y))),
					$elm$svg$Svg$Attributes$y2(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y)))
				]));
		return A2($elm$svg$Svg$line, attributes, _List_Nil);
	});
var $terezka$line_charts$Internal$Axis$viewVerticalTick = F3(
	function (system, point, tick) {
		var x = point.ci;
		var y = point.ck;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__tick')
				]),
			_List_fromArray(
				[
					A5(
					$terezka$line_charts$Internal$Svg$yTick,
					system,
					$terezka$line_charts$Internal$Axis$lengthOfTick(tick),
					$terezka$line_charts$Internal$Axis$attributesTick(tick),
					x,
					y),
					A2(
					$terezka$line_charts$Internal$Utils$viewMaybe,
					tick.fi,
					A3($terezka$line_charts$Internal$Axis$viewVerticalLabel, system, tick, point))
				]));
	});
var $terezka$line_charts$Internal$Axis$viewVerticalTitle = F3(
	function (system, at, _v0) {
		var title = _v0.d9;
		var position = at(
			A2(title.bh, system.cl, system.ck));
		var _v1 = title.dv;
		var xOffset = _v1.a;
		var yOffset = _v1.b;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__title'),
					$terezka$line_charts$Internal$Svg$transform(
					_List_fromArray(
						[
							A3($terezka$line_charts$Internal$Svg$move, system, position.ci, position.ck),
							A2($terezka$line_charts$Internal$Svg$offset, xOffset + 2, yOffset - 10)
						])),
					$terezka$line_charts$Internal$Svg$anchorStyle(2)
				]),
			_List_fromArray(
				[title.f9]));
	});
var $terezka$line_charts$Internal$Axis$viewVertical = F3(
	function (system, intersection, _v0) {
		var config = _v0;
		var viewConfig = {
			c8: A2($terezka$line_charts$Internal$Axis$Intersection$getX, intersection, system),
			dg: A3($terezka$line_charts$Internal$Axis$Line$config, config.cr, system.cl, system.ck),
			d7: A3($terezka$line_charts$Internal$Axis$Ticks$ticks, system.cl, system.ck, config.d7),
			d9: $terezka$line_charts$Internal$Axis$Title$config(config.d9)
		};
		var viewAxisLine = A2($terezka$line_charts$Internal$Axis$viewVerticalAxisLine, system, viewConfig.c8);
		var at = function (y) {
			return {ci: viewConfig.c8, ck: y};
		};
		var viewTick = function (tick) {
			return A3(
				$terezka$line_charts$Internal$Axis$viewVerticalTick,
				system,
				at(tick.bh),
				tick);
		};
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__axis--vertical')
				]),
			_List_fromArray(
				[
					A3($terezka$line_charts$Internal$Axis$viewVerticalTitle, system, at, viewConfig),
					viewAxisLine(viewConfig.dg),
					A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('chart__ticks')
						]),
					A2($elm$core$List$map, viewTick, viewConfig.d7))
				]));
	});
var $terezka$line_charts$LineChart$viewCustom = F2(
	function (config, lines) {
		var junkLineInfo = function (line_) {
			return _Utils_Tuple3(
				A3($terezka$line_charts$Internal$Line$color, config.dg, line_, _List_Nil),
				$terezka$line_charts$Internal$Line$label(line_),
				$terezka$line_charts$Internal$Line$data(line_));
		};
		var getJunk = A3(
			$terezka$line_charts$Internal$Junk$getLayers,
			A2($elm$core$List$map, junkLineInfo, lines),
			$terezka$line_charts$Internal$Axis$variable(config.ci),
			$terezka$line_charts$Internal$Axis$variable(config.ck));
		var data = A2($terezka$line_charts$LineChart$toDataPoints, config, lines);
		var dataAll = $elm$core$List$concat(data);
		var dataSafe = A2(
			$elm$core$List$map,
			$elm$core$List$filter(
				function ($) {
					return $.ff;
				}),
			data);
		var dataAllSafe = $elm$core$List$concat(dataSafe);
		var system = A2($terezka$line_charts$LineChart$toSystem, config, dataAllSafe);
		var viewLines = $terezka$line_charts$Internal$Line$view(
			{co: config.co, cK: config.cJ, c7: config.c7, dh: config.dg, d5: system});
		var viewLegends = $terezka$line_charts$Internal$Legends$view(
			{
				co: config.co,
				bA: dataSafe,
				cK: config.cJ,
				de: config.de,
				dh: config.dg,
				bR: lines,
				d5: system,
				ci: $terezka$line_charts$Internal$Axis$variable(config.ci),
				ck: $terezka$line_charts$Internal$Axis$variable(config.ck)
			});
		var attributes = $elm$core$List$concat(
			_List_fromArray(
				[
					A2(
					$terezka$line_charts$Internal$Container$properties,
					function ($) {
						return $.cq;
					},
					config.cD),
					A3($terezka$line_charts$Internal$Events$toContainerAttributes, dataAll, system, config.cP),
					_List_fromArray(
					[
						$terezka$line_charts$LineChart$viewBoxAttribute(system)
					])
				]));
		var addGrid = $terezka$line_charts$Internal$Junk$addBelow(
			A4($terezka$line_charts$Internal$Grid$view, system, config.ci, config.ck, config.cY));
		var junk = addGrid(
			A2(getJunk, system, config.da));
		return A4(
			$terezka$line_charts$LineChart$container,
			config,
			system,
			junk.bH,
			A2(
				$elm$svg$Svg$svg,
				attributes,
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$defs,
						_List_Nil,
						_List_fromArray(
							[
								$terezka$line_charts$LineChart$clipPath(system)
							])),
						A2(
						$elm$svg$Svg$g,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$class('chart__junk--below')
							]),
						junk.aK),
						A2(viewLines, lines, data),
						A3($terezka$line_charts$LineChart$chartAreaPlatform, config, dataAll, system),
						A3($terezka$line_charts$Internal$Axis$viewHorizontal, system, config.c8, config.ci),
						A3($terezka$line_charts$Internal$Axis$viewVertical, system, config.c8, config.ck),
						viewLegends,
						A2(
						$elm$svg$Svg$g,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$class('chart__junk--above')
							]),
						junk.bt)
					])));
	});
var $author$project$Projection$view = F6(
	function (userConfig, colors, budgets, investmentStrategys, timelines, model) {
		var startingYear = userConfig.d3;
		var dots = function () {
			var styleLegend = function (_v1) {
				return $terezka$line_charts$LineChart$Dots$full(7);
			};
			var styleIndividual = function (datum) {
				return $terezka$line_charts$LineChart$Dots$full(
					datum.bl ? 10 : 0);
			};
			return $terezka$line_charts$LineChart$Dots$customAny(
				{e8: styleIndividual, fj: styleLegend});
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('line-chart-1'),
					$elm$html$Html$Attributes$class('w-full')
				]),
			_List_fromArray(
				[
					A2(
					$terezka$line_charts$LineChart$viewCustom,
					{
						co: $terezka$line_charts$LineChart$Area$default,
						cD: $terezka$line_charts$LineChart$Container$custom(
							{
								cp: _List_Nil,
								cq: _List_Nil,
								c1: 'line-chart-1',
								dl: A4($terezka$line_charts$LineChart$Container$Margin, 30, 50, 30, 80),
								d2: $terezka$line_charts$LineChart$Container$static
							}),
						cJ: dots,
						cP: userConfig.d1 ? $terezka$line_charts$LineChart$Events$hoverMany($elm$core$Basics$identity) : $terezka$line_charts$LineChart$Events$hoverOne(
							A2($elm$core$Basics$composeR, $elm_community$maybe_extra$Maybe$Extra$toList, $elm$core$Basics$identity)),
						cY: A2($terezka$line_charts$LineChart$Grid$lines, 1, $terezka$line_charts$LineChart$Colors$grayLight),
						c7: $terezka$line_charts$LineChart$Interpolation$default,
						c8: $terezka$line_charts$LineChart$Axis$Intersection$default,
						da: userConfig.d1 ? A3(
							$terezka$line_charts$LineChart$Junk$hoverMany,
							model.am,
							function (d) {
								return A2(
									$myrho$elm_round$Round$round,
									1,
									startingYear + function (m) {
										return m / 12;
									}(d.U)) + ' Years';
							},
							function (d) {
								return $author$project$Util$formatMoney(d.V);
							}) : A2(
							$terezka$line_charts$LineChart$Junk$hoverOne,
							$elm$core$List$head(model.am),
							_List_fromArray(
								[
									_Utils_Tuple2(
									'Year',
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.U;
										},
										A2(
											$elm$core$Basics$composeR,
											function (m) {
												return m / 12;
											},
											A2(
												$elm$core$Basics$composeR,
												$elm$core$Basics$add(startingYear),
												$myrho$elm_round$Round$round(1))))),
									_Utils_Tuple2(
									'',
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.V;
										},
										$author$project$Util$formatMoney))
								])),
						de: $terezka$line_charts$LineChart$Legends$none,
						dg: $terezka$line_charts$LineChart$Line$default,
						ci: A3(
							$terezka$line_charts$LineChart$Axis$default,
							userConfig.ei,
							'Year',
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.U;
								},
								function (m) {
									return startingYear + (m / 12);
								})),
						ck: $terezka$line_charts$LineChart$Axis$custom(
							{
								cr: $terezka$line_charts$LineChart$Axis$Line$full($terezka$line_charts$LineChart$Colors$black),
								dF: userConfig.cZ,
								dM: $terezka$line_charts$LineChart$Axis$Range$default,
								d7: $terezka$line_charts$LineChart$Axis$Ticks$default,
								d9: $terezka$line_charts$LineChart$Axis$Title$default(''),
								ef: A2(
									$elm$core$Basics$composeL,
									$elm$core$Maybe$Just,
									function ($) {
										return $.V;
									})
							})
					},
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, _v0) {
								var track = _v0.a;
								var color = _v0.b;
								return A4(
									$terezka$line_charts$LineChart$line,
									color,
									$terezka$line_charts$LineChart$Dots$circle,
									track.eI,
									A4(
										$author$project$Projection$generateMonthlyAssets,
										{an: userConfig.an},
										budgets,
										investmentStrategys,
										track.dZ));
							}),
						A2($elm_community$list_extra$List$Extra$zip, timelines, colors)))
				]));
	});
var $author$project$Icon$CaretDown = 1;
var $author$project$App$ToggleAuthVisibility = {$: 13};
var $author$project$Auth$authStateToString = function (authState) {
	switch (authState.$) {
		case 0:
			return 'Login (Nothing saved yet)';
		case 1:
			return 'Welcome back...';
		case 2:
			return 'Grabbing your board...';
		case 3:
			return 'Welcome! Creating a new board...';
		default:
			var user = authState.a.ed;
			return 'Logged In: ' + user.cM;
	}
};
var $author$project$Auth$EditEmail = function (a) {
	return {$: 0, a: a};
};
var $author$project$Auth$EditPassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$Auth$LogIn = function (a) {
	return {$: 2, a: a};
};
var $author$project$Auth$Register = function (a) {
	return {$: 5, a: a};
};
var $author$project$Auth$ToggleIsRegistering = function (a) {
	return {$: 4, a: a};
};
var $author$project$Style$buttonAction = $author$project$Style$withTrailingSpace('w-full p-1 bg-gray-300 text-white text-sm hover:bg-gray-700');
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$UI$checkbox_ = F3(
	function (maybeId, isChecked, onCheck) {
		return A2(
			$elm$html$Html$input,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('checkbox'),
					function () {
					if (!maybeId.$) {
						var id = maybeId.a;
						return $elm$html$Html$Attributes$id(id);
					} else {
						return A2($elm$html$Html$Attributes$style, '', '');
					}
				}(),
					$elm$html$Html$Attributes$checked(isChecked),
					$elm$html$Html$Events$onCheck(onCheck)
				]),
			_List_Nil);
	});
var $author$project$UI$checkbox = $author$project$UI$checkbox_($elm$core$Maybe$Nothing);
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Auth$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', '300px'),
				$elm$html$Html$Attributes$class('p-2 bg-white space-y-2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('grid grid-cols-3 gap-2')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Email'),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value(model.ao),
								$elm$html$Html$Events$onInput($author$project$Auth$EditEmail),
								$elm$html$Html$Attributes$class('col-span-2')
							]),
						_List_Nil),
						$elm$html$Html$text('Password'),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value(model.ap),
								$elm$html$Html$Events$onInput($author$project$Auth$EditPassword),
								$elm$html$Html$Attributes$type_('password'),
								$elm$html$Html$Attributes$class('col-span-2')
							]),
						_List_Nil),
						$elm$html$Html$text('New User?'),
						A2(
						$author$project$UI$checkbox,
						!_Utils_eq(model.aq, $elm$core$Maybe$Nothing),
						$author$project$Auth$ToggleIsRegistering)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('px-24')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								(!_Utils_eq(model.aq, $elm$core$Maybe$Nothing)) ? $author$project$Auth$Register(
									{cM: model.ao, dC: model.ap}) : $author$project$Auth$LogIn(
									{cM: model.ao, dC: model.ap})),
								$elm$html$Html$Attributes$class($author$project$Style$buttonAction)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								(!_Utils_eq(model.aq, $elm$core$Maybe$Nothing)) ? 'Register' : 'Login')
							]))
					]))
			]));
};
var $author$project$App$viewAuth = F2(
	function (viewport, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fixed shadow-xl'),
					A2($elm$html$Html$Attributes$style, 'right', '450px'),
					A2($elm$html$Html$Attributes$style, 'top', '0px'),
					A2($elm$html$Html$Attributes$style, 'transition', 'transform 1s'),
					A2(
					$elm$html$Html$Attributes$style,
					'transform',
					model.ab ? 'translateY(0px)' : ($author$project$Responsive$biggerThanIpad(viewport) ? 'translateY(-130px)' : 'translateY(-108px)'))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$AuthMsg,
							$author$project$Auth$view(model.aJ))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class($author$project$Style$bar + 'rounded-b-md'),
							$elm$html$Html$Events$onClick($author$project$App$ToggleAuthVisibility)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Auth$authStateToString(model.F)),
							A2(
							$elm$html$Html$button,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Icon$view,
									1,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'transition', 'transform 1s'),
											model.ab ? A2($elm$html$Html$Attributes$style, 'transform', 'rotate(180deg)') : A2($elm$html$Html$Attributes$style, '', '')
										]))
								]))
						]))
				]));
	});
var $terezka$line_charts$Internal$Dots$Disconnected = function (a) {
	return {$: 1, a: a};
};
var $terezka$line_charts$Internal$Dots$disconnected = F2(
	function (radius, border) {
		return A2(
			$terezka$line_charts$Internal$Dots$style,
			radius,
			$terezka$line_charts$Internal$Dots$Disconnected(border));
	});
var $terezka$line_charts$Internal$Dots$default = {
	e8: function (_v0) {
		return A2($terezka$line_charts$Internal$Dots$disconnected, 10, 2);
	},
	fj: function (_v1) {
		return A2($terezka$line_charts$Internal$Dots$disconnected, 10, 2);
	}
};
var $terezka$line_charts$LineChart$Dots$default = $terezka$line_charts$Internal$Dots$default;
var $terezka$line_charts$Internal$Dots$None = 0;
var $terezka$line_charts$LineChart$Dots$none = 0;
var $terezka$line_charts$Internal$Area$Stacked = function (a) {
	return {$: 2, a: a};
};
var $terezka$line_charts$Internal$Area$stacked = $terezka$line_charts$Internal$Area$Stacked;
var $terezka$line_charts$LineChart$Area$stacked = $terezka$line_charts$Internal$Area$stacked;
var $author$project$Projection$viewBreakdown = F6(
	function (userConfig, colors, budgets, investmentStrategys, timeline, model) {
		var startingYear = userConfig.d3;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('line-chart-1'),
					$elm$html$Html$Attributes$class('w-full')
				]),
			_List_fromArray(
				[
					A2(
					$terezka$line_charts$LineChart$viewCustom,
					{
						co: $terezka$line_charts$LineChart$Area$stacked(0.5),
						cD: $terezka$line_charts$LineChart$Container$custom(
							{
								cp: _List_Nil,
								cq: _List_Nil,
								c1: 'line-chart-1',
								dl: A4($terezka$line_charts$LineChart$Container$Margin, 30, 50, 30, 80),
								d2: $terezka$line_charts$LineChart$Container$static
							}),
						cJ: $terezka$line_charts$LineChart$Dots$default,
						cP: userConfig.d1 ? $terezka$line_charts$LineChart$Events$hoverMany($elm$core$Basics$identity) : $terezka$line_charts$LineChart$Events$hoverOne(
							A2($elm$core$Basics$composeR, $elm_community$maybe_extra$Maybe$Extra$toList, $elm$core$Basics$identity)),
						cY: A2($terezka$line_charts$LineChart$Grid$lines, 1, $terezka$line_charts$LineChart$Colors$grayLight),
						c7: $terezka$line_charts$LineChart$Interpolation$default,
						c8: $terezka$line_charts$LineChart$Axis$Intersection$default,
						da: userConfig.d1 ? A3(
							$terezka$line_charts$LineChart$Junk$hoverMany,
							model.am,
							function (d) {
								return A2(
									$myrho$elm_round$Round$round,
									1,
									startingYear + function (m) {
										return m / 12;
									}(d.U)) + ' Years';
							},
							function (d) {
								return $author$project$Util$formatMoney(d.V);
							}) : A2(
							$terezka$line_charts$LineChart$Junk$hoverOne,
							$elm$core$List$head(model.am),
							_List_fromArray(
								[
									_Utils_Tuple2(
									'Year',
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.U;
										},
										A2(
											$elm$core$Basics$composeR,
											function (m) {
												return m / 12;
											},
											A2(
												$elm$core$Basics$composeR,
												$elm$core$Basics$add(startingYear),
												$myrho$elm_round$Round$round(1))))),
									_Utils_Tuple2(
									'',
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.V;
										},
										$author$project$Util$formatMoney))
								])),
						de: $terezka$line_charts$LineChart$Legends$none,
						dg: $terezka$line_charts$LineChart$Line$default,
						ci: A3(
							$terezka$line_charts$LineChart$Axis$default,
							userConfig.ei,
							'Year',
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.U;
								},
								function (m) {
									return startingYear + (m / 12);
								})),
						ck: $terezka$line_charts$LineChart$Axis$custom(
							{
								cr: $terezka$line_charts$LineChart$Axis$Line$full($terezka$line_charts$LineChart$Colors$black),
								dF: userConfig.cZ,
								dM: $terezka$line_charts$LineChart$Axis$Range$default,
								d7: $terezka$line_charts$LineChart$Axis$Ticks$default,
								d9: $terezka$line_charts$LineChart$Axis$Title$default('Net Worth'),
								ef: A2(
									$elm$core$Basics$composeL,
									$elm$core$Maybe$Just,
									function ($) {
										return $.V;
									})
							})
					},
					function () {
						var allStrats = $elm_community$list_extra$List$Extra$unique(
							A2(
								$elm$core$List$map,
								$elm$core$Tuple$first,
								$elm$core$List$concat(
									A2(
										$elm$core$List$map,
										$author$project$Types$InvestmentStrategy$toList,
										$author$project$UuidDict$values(investmentStrategys)))));
						var fillInMissing = function (list) {
							return A3(
								$elm$core$List$foldl,
								F2(
									function (toCheck, l) {
										return A2(
											$elm$core$List$member,
											toCheck,
											A2($elm$core$List$map, $elm$core$Tuple$first, l)) ? l : A2(
											$elm$core$List$cons,
											_Utils_Tuple2(toCheck, 0),
											l);
									}),
								list,
								allStrats);
						};
						return A2(
							$elm$core$List$map,
							function (_v1) {
								var color = _v1.a;
								var datam = _v1.b;
								return A4(
									$terezka$line_charts$LineChart$line,
									color,
									$terezka$line_charts$LineChart$Dots$none,
									A2(
										$elm$core$Maybe$withDefault,
										'',
										A2(
											$elm$core$Maybe$map,
											$elm$core$Tuple$first,
											$elm$core$List$head(datam))),
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, _v2) {
												var amt = _v2.b;
												return {U: i, V: amt, bl: false};
											}),
										datam));
							},
							A2(
								$elm_community$list_extra$List$Extra$zip,
								colors,
								A2(
									$elm$core$List$map,
									function (_v0) {
										var x = _v0.a;
										var xs = _v0.b;
										return A2($elm$core$List$cons, x, xs);
									},
									A2(
										$elm_community$list_extra$List$Extra$gatherWith,
										F2(
											function (z, b) {
												return _Utils_eq(z.a, b.a);
											}),
										$elm$core$List$concat(
											A2(
												$elm$core$List$map,
												fillInMissing,
												A2(
													$elm_community$list_extra$List$Extra$removeAt,
													0,
													A4(
														$author$project$Projection$generate_,
														{an: userConfig.an},
														budgets,
														investmentStrategys,
														timeline))))))));
					}())
				]));
	});
var $author$project$Icon$CaretUp = 0;
var $author$project$App$DockInvestmentStrategy = 1;
var $author$project$App$SetDock = function (a) {
	return {$: 10, a: a};
};
var $author$project$App$SetUserConfigInitialAmount = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$dockIsOpen = function (dock) {
	if (!dock.$) {
		return true;
	} else {
		return false;
	}
};
var $elm_community$html_extra$Html$Events$Extra$onChange = function (onChangeAction) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, onChangeAction, $elm$html$Html$Events$targetValue));
};
var $author$project$App$toggleDockVisibility = function (dock) {
	if (!dock.$) {
		var d = dock.a;
		return $elm$core$Result$Err(d);
	} else {
		var d = dock.a;
		return $elm$core$Result$Ok(d);
	}
};
var $author$project$BudgetEditor$AddNewBudget = {$: 4};
var $author$project$Style$buttonDotted = $author$project$Style$withTrailingSpace('grid rounded border-dashed border-4 border-black m-5 h-24 place-items-center hover:bg-gray-400 bg-opacity-25 hover:cursor-pointer');
var $author$project$BudgetEditor$AddMortgage = function (a) {
	return {$: 9, a: a};
};
var $author$project$BudgetEditor$AddNewBudgetItem = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$BudgetEditor$CloneBudget = function (a) {
	return {$: 6, a: a};
};
var $author$project$Icon$Copy = 2;
var $author$project$BudgetEditor$DeleteMortgage = function (a) {
	return {$: 11, a: a};
};
var $author$project$BudgetEditor$EditBudget = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$BudgetEditor$EditDescription = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$BudgetEditor$EditMortgage = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$BudgetEditor$RemoveBudget = function (a) {
	return {$: 8, a: a};
};
var $author$project$BudgetEditor$RemoveBudgetItem = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$UI$TooltipLeft = 0;
var $author$project$Icon$Trash = 4;
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $author$project$UI$tooltipPositionToString = function (tp) {
	switch (tp) {
		case 0:
			return 'left';
		case 1:
			return 'right';
		case 2:
			return 'top';
		default:
			return 'bottom';
	}
};
var $author$project$UI$withTooltip_ = F6(
	function (htmlFunc, buttonAttrs, id, position, tooltipText, htmlBody) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A3(
					$elm$html$Html$node,
					'paper-tooltip',
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'animation-delay', '0'),
							A2(
							$elm$html$Html$Attributes$attribute,
							'position',
							$author$project$UI$tooltipPositionToString(position)),
							$elm$html$Html$Attributes$for(id)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(tooltipText)
						])),
					A2(
					htmlFunc,
					_Utils_ap(
						buttonAttrs,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(id)
							])),
					htmlBody)
				]));
	});
var $author$project$UI$buttonWithTooltip = $author$project$UI$withTooltip_($elm$html$Html$button);
var $author$project$Types$Budget$calculateMortgageLengthMonths = $author$project$Types$Budget$V1$calculateMortgageLengthMonths;
var $author$project$Types$Budget$calculateMortgageRepaymentMonthlyActual = $author$project$Types$Budget$V1$calculateMortgageRepaymentMonthlyActual;
var $author$project$Types$Budget$calculateMortgageRepaymentMonthlyMinimum = $author$project$Types$Budget$V1$calculateMortgageRepaymentMonthlyMinimum;
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$UI$checkboxWithLabel = F4(
	function (isChecked, onCheck, id, body) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row items-center space-x-5')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$for(id),
							$elm$html$Html$Attributes$class('flex-grow')
						]),
					_List_fromArray(
						[body])),
					A3(
					$author$project$UI$checkbox_,
					$elm$core$Maybe$Just(id),
					isChecked,
					onCheck)
				]));
	});
var $author$project$Types$Budget$V1$getBalance = $author$project$Types$Budget$V1$getBalance_(
	{ch: true});
var $author$project$Types$Budget$getBalance = $author$project$Types$Budget$V1$getBalance;
var $author$project$Types$Budget$getIncomeSum = $author$project$Types$Budget$V1$getIncomeSum;
var $author$project$UI$intInput_ = F3(
	function (attrs, value, maybeToMsg) {
		return A2(
			$elm$html$Html$input,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(
						$elm$core$String$fromInt(value)),
						$elm$html$Html$Attributes$type_('number'),
						function () {
						if (!maybeToMsg.$) {
							var toMsg = maybeToMsg.a;
							return $elm_community$html_extra$Html$Events$Extra$onChange(
								A2(
									$elm$core$Basics$composeR,
									$elm$core$String$toInt,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$Maybe$withDefault(value),
										toMsg)));
						} else {
							return A2($elm$html$Html$Attributes$style, '', '');
						}
					}()
					]),
				attrs),
			_List_Nil);
	});
var $author$project$UI$floatInput = F3(
	function (attrs, value, maybeToMsg) {
		return A2(
			$elm$html$Html$input,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(
						$elm$core$String$fromFloat(value)),
						$elm$html$Html$Attributes$type_('number'),
						function () {
						if (!maybeToMsg.$) {
							var toMsg = maybeToMsg.a;
							return $elm_community$html_extra$Html$Events$Extra$onChange(
								A2(
									$elm$core$Basics$composeR,
									$elm$core$String$toFloat,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$Maybe$withDefault(value),
										toMsg)));
						} else {
							return A2($elm$html$Html$Attributes$style, '', '');
						}
					}()
					]),
				attrs),
			_List_Nil);
	});
var $author$project$UI$percentageInput_ = F3(
	function (attrs, value, maybeToMsg) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A3(
					$author$project$UI$floatInput,
					_Utils_ap(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '30px'),
								A2($elm$html$Html$Attributes$style, 'text-align', 'right')
							]),
						attrs),
					value,
					maybeToMsg),
					$elm$html$Html$text('%')
				]));
	});
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {cB: col, eB: contextStack, dH: problem, dV: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.dV, s.cB, x, s.b));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.dv) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $dmy$elm_pratt_parser$Pratt$Advanced$Config = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $dmy$elm_pratt_parser$Pratt$Advanced$filter = F3(
	function (_v0, currentPrecedence, leftExpression) {
		var precedence = _v0.a;
		var parser = _v0.b;
		return (_Utils_cmp(precedence, currentPrecedence) > 0) ? $elm$core$Maybe$Just(
			parser(leftExpression)) : $elm$core$Maybe$Nothing;
	});
var $dmy$elm_pratt_parser$Pratt$Advanced$operation = F3(
	function (config, precedence, leftExpression) {
		var conf = config;
		return $elm$parser$Parser$Advanced$oneOf(
			A2(
				$elm$core$List$filterMap,
				function (toOperation) {
					return A3(
						$dmy$elm_pratt_parser$Pratt$Advanced$filter,
						toOperation(config),
						precedence,
						leftExpression);
				},
				conf.ep));
	});
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $dmy$elm_pratt_parser$Pratt$Advanced$expressionHelp = function (_v0) {
	var config = _v0.a;
	var conf = config;
	var precedence = _v0.b;
	var leftExpression = _v0.c;
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			conf.fR),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$map,
					function (expr) {
						return $elm$parser$Parser$Advanced$Loop(
							_Utils_Tuple3(config, precedence, expr));
					},
					A3($dmy$elm_pratt_parser$Pratt$Advanced$operation, config, precedence, leftExpression)),
					$elm$parser$Parser$Advanced$succeed(
					$elm$parser$Parser$Advanced$Done(leftExpression))
				])));
};
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $dmy$elm_pratt_parser$Pratt$Advanced$subExpression = F2(
	function (precedence, config) {
		var conf = config;
		return A2(
			$elm$parser$Parser$Advanced$andThen,
			function (leftExpression) {
				return A2(
					$elm$parser$Parser$Advanced$loop,
					_Utils_Tuple3(config, precedence, leftExpression),
					$dmy$elm_pratt_parser$Pratt$Advanced$expressionHelp);
			},
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
					conf.fR),
				$elm$parser$Parser$Advanced$lazy(
					function (_v0) {
						return $elm$parser$Parser$Advanced$oneOf(
							A2(
								$elm$core$List$map,
								$elm$core$Basics$apR(config),
								conf.fz));
					})));
	});
var $dmy$elm_pratt_parser$Pratt$Advanced$expression = function (config) {
	return A2(
		$dmy$elm_pratt_parser$Pratt$Advanced$subExpression,
		0,
		{ep: config.ep, fz: config.fz, fR: config.fR});
};
var $dmy$elm_pratt_parser$Pratt$expression = $dmy$elm_pratt_parser$Pratt$Advanced$expression;
var $elm$parser$Parser$ExpectingFloat = {$: 5};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {cB: s.cB + (newOffset - s.dv), b: s.b, c: s.c, dv: newOffset, dV: s.dV, a: s.a};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 1) {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.dv, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.a);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.dV, s.cB - (floatOffset + s.dv), invalid, s.b));
		} else {
			if (_Utils_eq(s.dv, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.dv, intPair, s);
				} else {
					if (floatSettings.$ === 1) {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.dv, floatOffset, s.a));
						if (_v1.$ === 1) {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return function (s) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.dv, s.a)) {
			var zeroOffset = s.dv + 1;
			var baseOffset = zeroOffset + 1;
			return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.fc,
				c.e1,
				baseOffset,
				A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.fc,
				c.du,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.fc,
				c.cs,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.a),
				s) : A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.fc,
				c.cQ,
				c.c5,
				c.cS,
				_Utils_Tuple2(zeroOffset, 0),
				s)));
		} else {
			return A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.fc,
				c.cQ,
				c.c5,
				c.cS,
				A3($elm$parser$Parser$Advanced$consumeBase, 10, s.dv, s.a),
				s);
		}
	};
};
var $elm$parser$Parser$Advanced$float = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				cs: $elm$core$Result$Err(invalid),
				cQ: expecting,
				cS: $elm$core$Result$Ok($elm$core$Basics$identity),
				e1: $elm$core$Result$Err(invalid),
				c5: $elm$core$Result$Ok($elm$core$Basics$toFloat),
				fc: invalid,
				du: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$float = A2($elm$parser$Parser$Advanced$float, $elm$parser$Parser$ExpectingFloat, $elm$parser$Parser$ExpectingFloat);
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.dv, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $dmy$elm_pratt_parser$Pratt$Advanced$infixHelp = F4(
	function (_v0, operator, apply, config) {
		var leftPrecedence = _v0.a;
		var rightPrecedence = _v0.b;
		return _Utils_Tuple2(
			leftPrecedence,
			function (left) {
				return A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed(
							apply(left)),
						operator),
					A2($dmy$elm_pratt_parser$Pratt$Advanced$subExpression, rightPrecedence, config));
			});
	});
var $dmy$elm_pratt_parser$Pratt$Advanced$infixLeft = function (precedence) {
	return $dmy$elm_pratt_parser$Pratt$Advanced$infixHelp(
		_Utils_Tuple2(precedence, precedence));
};
var $dmy$elm_pratt_parser$Pratt$infixLeft = $dmy$elm_pratt_parser$Pratt$Advanced$infixLeft;
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$chompUntilEndOr = function (str) {
	return function (s) {
		var _v0 = A5(_Parser_findSubString, str, s.dv, s.dV, s.cB, s.a);
		var newOffset = _v0.a;
		var newRow = _v0.b;
		var newCol = _v0.c;
		var adjustedOffset = (newOffset < 0) ? $elm$core$String$length(s.a) : newOffset;
		return A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.dv, adjustedOffset) < 0,
			0,
			{cB: newCol, b: s.b, c: s.c, dv: adjustedOffset, dV: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.dv, s.dV, s.cB, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{cB: newCol, b: s.b, c: s.c, dv: newOffset, dV: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Advanced$lineComment = function (start) {
	return A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$token(start),
		$elm$parser$Parser$Advanced$chompUntilEndOr('\n'));
};
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$lineComment = function (str) {
	return $elm$parser$Parser$Advanced$lineComment(
		$elm$parser$Parser$toToken(str));
};
var $dmy$elm_pratt_parser$Pratt$Advanced$literal = $elm$core$Basics$always;
var $dmy$elm_pratt_parser$Pratt$literal = $dmy$elm_pratt_parser$Pratt$Advanced$literal;
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $dmy$elm_pratt_parser$Pratt$Advanced$prefix = F4(
	function (precedence, operator, apply, config) {
		return A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed(apply),
				operator),
			A2($dmy$elm_pratt_parser$Pratt$Advanced$subExpression, precedence, config));
	});
var $dmy$elm_pratt_parser$Pratt$prefix = $dmy$elm_pratt_parser$Pratt$Advanced$prefix;
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.dv, offset) < 0,
					0,
					{cB: col, b: s0.b, c: s0.c, dv: offset, dV: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.dv, s.dV, s.cB, s);
	};
};
var $elm$parser$Parser$Advanced$spaces = $elm$parser$Parser$Advanced$chompWhile(
	function (c) {
		return (c === ' ') || ((c === '\n') || (c === '\r'));
	});
var $elm$parser$Parser$spaces = $elm$parser$Parser$Advanced$spaces;
var $dmy$elm_pratt_parser$Pratt$subExpression = $dmy$elm_pratt_parser$Pratt$Advanced$subExpression;
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $author$project$CalculatorField$math = function () {
	var parenthesizedExpression = function (config) {
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$symbol('(')),
				$elm$parser$Parser$spaces),
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2($dmy$elm_pratt_parser$Pratt$subExpression, 0, config),
					$elm$parser$Parser$spaces),
				$elm$parser$Parser$symbol(')')));
	};
	var mathExpression = $dmy$elm_pratt_parser$Pratt$expression(
		{
			ep: _List_fromArray(
				[
					A3(
					$dmy$elm_pratt_parser$Pratt$infixLeft,
					1,
					$elm$parser$Parser$symbol('+'),
					$elm$core$Basics$add),
					A3(
					$dmy$elm_pratt_parser$Pratt$infixLeft,
					1,
					$elm$parser$Parser$symbol('-'),
					$elm$core$Basics$sub),
					A3(
					$dmy$elm_pratt_parser$Pratt$infixLeft,
					2,
					$elm$parser$Parser$symbol('*'),
					$elm$core$Basics$mul),
					A3(
					$dmy$elm_pratt_parser$Pratt$infixLeft,
					2,
					$elm$parser$Parser$symbol('x'),
					$elm$core$Basics$mul),
					A3(
					$dmy$elm_pratt_parser$Pratt$infixLeft,
					2,
					$elm$parser$Parser$symbol('/'),
					$elm$core$Basics$fdiv)
				]),
			fz: _List_fromArray(
				[
					$dmy$elm_pratt_parser$Pratt$literal($elm$parser$Parser$float),
					A3(
					$dmy$elm_pratt_parser$Pratt$prefix,
					3,
					$elm$parser$Parser$symbol('-'),
					$elm$core$Basics$negate),
					parenthesizedExpression
				]),
			fR: function () {
				var ifProgress = F2(
					function (parser, offset) {
						return A2(
							$elm$parser$Parser$map,
							function (newOffset) {
								return _Utils_eq(offset, newOffset) ? $elm$parser$Parser$Done(0) : $elm$parser$Parser$Loop(newOffset);
							},
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									parser),
								$elm$parser$Parser$getOffset));
					});
				return A2(
					$elm$parser$Parser$loop,
					0,
					ifProgress(
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$lineComment('#'),
									$elm$parser$Parser$spaces),
									$elm$parser$Parser$spaces
								]))));
			}()
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		A2($elm$parser$Parser$ignorer, mathExpression, $elm$parser$Parser$end));
}();
var $elm$html$Html$Attributes$rows = function (n) {
	return A2(
		_VirtualDom_attribute,
		'rows',
		$elm$core$String$fromInt(n));
};
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {cB: col, dH: problem, dV: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.dV, p.cB, p.dH);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{cB: 1, b: _List_Nil, c: 1, dv: 0, dV: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$CalculatorField$view = F3(
	function (attrs, value, toMsg) {
		return A2(
			$elm$html$Html$input,
			$elm$core$List$concat(
				_List_fromArray(
					[
						attrs,
						_List_fromArray(
						[
							$elm$html$Html$Attributes$rows(1),
							$elm$html$Html$Attributes$value(
							function () {
								if (value.$ === 1) {
									var _v1 = value.a;
									var expr = _v1.a;
									return expr;
								} else {
									var _v2 = value.a;
									var expr = _v2.a;
									return expr;
								}
							}()),
							$elm$html$Html$Attributes$title(
							function () {
								if (value.$ === 1) {
									var _v4 = value.a;
									var invalidExpr = _v4.a;
									return A2(
										$elm$core$String$join,
										'\n',
										_List_fromArray(
											[
												'Using previous value from last successful calculation:',
												'',
												'$' + $elm$core$String$fromInt(
												$author$project$Types$CalcResult$getValue(value)),
												'',
												'Invalid calculation',
												'',
												'Calculator accepts numbers and the following',
												'operations +  *  -  / and grouping with ()',
												'',
												'You can put comments in by starting a line with #'
											]));
								} else {
									var _v5 = value.a;
									var expr = _v5.a;
									var val = _v5.b;
									return A2(
										$elm$core$String$join,
										'',
										_List_fromArray(
											[
												'= $' + $elm$core$String$fromInt(val)
											]));
								}
							}()),
							$elm_community$html_extra$Html$Events$Extra$onChange(
							function (s) {
								return toMsg(
									A2(
										$elm$core$Result$map,
										function (f) {
											return _Utils_Tuple2(
												s,
												$elm$core$Basics$round(f));
										},
										A2(
											$elm$core$Result$mapError,
											$elm$core$Basics$always(
												_Utils_Tuple2(
													s,
													$author$project$Types$CalcResult$getValue(value))),
											A2($elm$parser$Parser$run, $author$project$CalculatorField$math, s))));
							}),
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[
										'',
										function () {
										if (value.$ === 1) {
											var invalidExpr = value.a;
											return 'border border-red-500';
										} else {
											var expr = value.a;
											return '';
										}
									}()
									])))
						])
					])),
			_List_Nil);
	});
var $author$project$BudgetEditor$viewBudget = F2(
	function (idPrefix, budget) {
		var viewMortgage = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('px-4')
				]),
			_List_fromArray(
				[
					A4(
					$author$project$UI$checkboxWithLabel,
					!_Utils_eq(budget.aO, $elm$core$Maybe$Nothing),
					function (isChecked) {
						return isChecked ? $author$project$BudgetEditor$AddMortgage(
							{ac: budget.c1}) : $author$project$BudgetEditor$DeleteMortgage(
							{ac: budget.c1});
					},
					$danyx23$elm_uuid$Uuid$toString(budget.c1) + '-mortageCheckbox',
					$elm$html$Html$text('Include a mortgage?')),
					function () {
					var _v5 = budget.aO;
					if (_v5.$ === 1) {
						return $elm$html$Html$text('');
					} else {
						var mortgage = _v5.a;
						var maxRepayments = $author$project$Types$Budget$getBalance(budget) + $author$project$Types$Budget$calculateMortgageRepaymentMonthlyActual(mortgage);
						var editMortgageMsg = $author$project$BudgetEditor$EditMortgage(
							{ac: budget.c1});
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mt-5 pl-4')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('grid grid-cols-2 gap-2')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Interest'),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-20 place-self-end')
												]),
											_List_fromArray(
												[
													A3(
													$author$project$UI$percentageInput_,
													_List_Nil,
													mortgage.ay,
													$elm$core$Maybe$Just(
														function (_new) {
															return editMortgageMsg(
																_Utils_update(
																	mortgage,
																	{ay: _new}));
														}))
												])),
											$elm$html$Html$text('Principal'),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-20 place-self-end')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('$'),
													A3(
													$author$project$UI$intInput_,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('w-16')
														]),
													mortgage.aA,
													$elm$core$Maybe$Just(
														function (_new) {
															return editMortgageMsg(
																_Utils_update(
																	mortgage,
																	{aA: _new}));
														}))
												])),
											$elm$html$Html$text('Length'),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-20 place-self-end')
												]),
											_List_fromArray(
												[
													A3(
													$author$project$UI$intInput_,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('w-6')
														]),
													mortgage.bQ,
													$elm$core$Maybe$Just(
														function (_new) {
															return editMortgageMsg(
																_Utils_update(
																	mortgage,
																	{bQ: _new}));
														})),
													$elm$html$Html$text('Years')
												])),
											$elm$html$Html$text('Extra Payments'),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-20 place-self-end')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('$'),
													A3(
													$author$project$UI$intInput_,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('w-8')
														]),
													mortgage.cn,
													$elm$core$Maybe$Just(
														function (_new) {
															return editMortgageMsg(
																_Utils_update(
																	mortgage,
																	{cn: _new}));
														}))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('font-bold mt-5')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Actual Payment (Including Extra)')
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A2($elm$core$Basics$composeL, $elm$html$Html$text, $elm$core$String$concat)(
											_List_fromArray(
												[
													'$' + A2(
													$myrho$elm_round$Round$round,
													2,
													$author$project$Types$Budget$calculateMortgageRepaymentMonthlyActual(mortgage)),
													' for ',
													$elm$core$String$fromInt(
													($author$project$Types$Budget$calculateMortgageLengthMonths(
														{
															ay: mortgage.ay,
															aA: mortgage.aA,
															dQ: $author$project$Types$Budget$calculateMortgageRepaymentMonthlyActual(mortgage)
														}) / 12) | 0) + ' Years'
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('pl-5')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('font-semibold mt-5')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Minimum Monthly Repayments')
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$core$Basics$composeL,
													$elm$html$Html$text,
													$elm$core$String$join(' '))(
													_List_fromArray(
														[
															'$' + A2(
															$myrho$elm_round$Round$round,
															2,
															$author$project$Types$Budget$calculateMortgageRepaymentMonthlyMinimum(mortgage)),
															'for',
															$elm$core$String$fromInt(mortgage.bQ),
															'Years'
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('font-semibold mt-5')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Shortest mortgage possible')
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$core$Basics$composeL,
													$elm$html$Html$text,
													$elm$core$String$join(' '))(
													_List_fromArray(
														[
															'$' + A2($myrho$elm_round$Round$round, 2, maxRepayments),
															'for',
															$elm$core$String$fromInt(
															($author$project$Types$Budget$calculateMortgageLengthMonths(
																{ay: mortgage.ay, aA: mortgage.aA, dQ: maxRepayments}) / 12) | 0),
															'Years'
														]))
												]))
										]))
								]));
					}
				}()
				]));
		var viewItem = F3(
			function (incomeOrExpense, budgetDetails, _v4) {
				var desc = _v4.a;
				var val = _v4.b;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row m-3 text-left space-x-2 w-fill')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('inline-block align-top flex-shrink')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(desc),
											A2($elm$html$Html$Attributes$style, 'width', '175px'),
											$elm_community$html_extra$Html$Events$Extra$onChange(
											function (newDesc) {
												return A3(
													$author$project$BudgetEditor$EditBudget,
													incomeOrExpense,
													budgetDetails,
													_Utils_Tuple2(newDesc, val));
											}),
											$elm$html$Html$Attributes$id(
											_Utils_ap(
												idPrefix,
												A3($author$project$BudgetEditor$generateItemDomId, 0, incomeOrExpense, budgetDetails)))
										]),
									_List_Nil)
								])),
							$elm$html$Html$text('$'),
							A3(
							$author$project$CalculatorField$view,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id(
									_Utils_ap(
										idPrefix,
										A3($author$project$BudgetEditor$generateItemDomId, 1, incomeOrExpense, budgetDetails))),
									$elm$html$Html$Attributes$class('flex-shrink w-1/2')
								]),
							val,
							function (calcResult) {
								return A3(
									$author$project$BudgetEditor$EditBudget,
									incomeOrExpense,
									budgetDetails,
									_Utils_Tuple2(desc, calcResult));
							}),
							A5(
							$author$project$UI$buttonWithTooltip,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									A2($author$project$BudgetEditor$RemoveBudgetItem, incomeOrExpense, budgetDetails)),
									$elm$html$Html$Attributes$tabindex(-1)
								]),
							'budget-remove-item-button',
							0,
							'Remove ' + desc,
							_List_fromArray(
								[
									$elm$html$Html$text('X')
								]))
						]));
			});
		var viewBalance = function () {
			var income = $author$project$Types$Budget$getIncomeSum(budget);
			var balance = $author$project$Types$Budget$getBalance(budget);
			var savingRate = $elm$core$Basics$round((balance / income) * 100);
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pb-2 border-t-2 pt-2 pb-4')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('float-right flex flex-row')
							]),
						_List_fromArray(
							[
								(savingRate > 0) ? A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('float-right flex flex-row')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-xs font-extralight')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('SAVING ')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('w-2')
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('border-b-2 border-black')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												$elm$core$String$fromInt(savingRate) + '%')
											]))
									])) : $elm$html$Html$text(''),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('w-8')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('float-right flex flex-row')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-xs font-extralight')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('BALANCE')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('w-2')
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('border-b-2 border-black')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												' $' + $elm$core$String$fromInt(balance))
											]))
									]))
							]))
					]));
		}();
		var addButton = function (incomeOrExpense) {
			return A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class($author$project$Style$buttonAction),
						$elm$html$Html$Events$onClick(
						A2(
							$author$project$BudgetEditor$AddNewBudgetItem,
							incomeOrExpense,
							{ac: budget.c1}))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						function () {
							if (!incomeOrExpense) {
								return 'Add Income';
							} else {
								return 'Add Expense';
							}
						}())
					]));
		};
		var viewItems = function (incomeOrExpense) {
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				$elm$core$List$concat(
					_List_fromArray(
						[
							_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										function () {
											if (!incomeOrExpense) {
												return 'Income';
											} else {
												return 'Expenses';
											}
										}())
									]))
							]),
							A2(
							$elm$core$List$map,
							function (_v2) {
								var i = _v2.a;
								var item = _v2.b;
								return A3(
									viewItem,
									incomeOrExpense,
									{ac: budget.c1, fg: i},
									item);
							},
							$elm$core$List$reverse(
								A2(
									$elm$core$List$sortBy,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$Tuple$second,
										A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $author$project$Types$CalcResult$getValue)),
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, x) {
												return _Utils_Tuple2(i, x);
											}),
										function () {
											if (!incomeOrExpense) {
												return budget.e6;
											} else {
												return budget.eS;
											}
										}())))),
							_List_fromArray(
							[
								addButton(incomeOrExpense)
							])
						])));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-left p-5 space-y-3'),
					A2($elm$html$Html$Attributes$style, 'min-width', '350px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row sticky top-0 py-2 bg-white space-x-5')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(budget.eI),
									$elm_community$html_extra$Html$Events$Extra$onChange(
									$author$project$BudgetEditor$EditDescription(
										{ac: budget.c1})),
									$elm$html$Html$Attributes$class('underline flex-grow text-lg'),
									$elm$html$Html$Attributes$id(
									_Utils_ap(
										idPrefix,
										$author$project$BudgetEditor$generateBudgetDescDomId(
											{ac: budget.c1})))
								]),
							_List_Nil),
							A5(
							$author$project$UI$buttonWithTooltip,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$BudgetEditor$CloneBudget(budget)),
									A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
								]),
							'budget-clone-button',
							0,
							'Clone Budget',
							_List_fromArray(
								[
									A2($author$project$Icon$view, 2, _List_Nil)
								])),
							A5(
							$author$project$UI$buttonWithTooltip,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$BudgetEditor$RemoveBudget(
										{ac: budget.c1})),
									A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
								]),
							'budget-delete-button',
							0,
							'Delete Budget',
							_List_fromArray(
								[
									A2($author$project$Icon$view, 4, _List_Nil)
								]))
						])),
					viewItems(0),
					viewItems(1),
					viewMortgage,
					viewBalance
				]));
	});
var $author$project$BudgetEditor$viewWithIdToAvoidScrollConflicts = F2(
	function (idPrefix, budgets) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'min-width', '100%'),
					A2($elm$html$Html$Attributes$style, 'height', '100%'),
					A2($elm$html$Html$Attributes$style, 'overflow-y', 'scroll'),
					$elm$html$Html$Attributes$class('bg-white pb-6')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('divide-y divide-gray-500')
						]),
					A2(
						$elm$core$List$map,
						$author$project$BudgetEditor$viewBudget(idPrefix),
						A2(
							$elm$core$List$sortBy,
							function ($) {
								return $.eI;
							},
							$author$project$UuidDict$values(budgets)))),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$BudgetEditor$AddNewBudget),
							$elm$html$Html$Attributes$class($author$project$Style$buttonDotted)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Add new budget')
						]))
				]));
	});
var $author$project$BudgetEditor$view = $author$project$BudgetEditor$viewWithIdToAvoidScrollConflicts('');
var $author$project$InvestmentStrategyEditor$AddNewInvestmentStrategy = {$: 9};
var $author$project$InvestmentStrategyEditor$AddNewOther = function (a) {
	return {$: 1, a: a};
};
var $author$project$InvestmentStrategyEditor$CloneInvestmentStrategy = function (a) {
	return {$: 11, a: a};
};
var $author$project$InvestmentStrategyEditor$EditDescription = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$InvestmentStrategyEditor$EditSavingsMax = function (a) {
	return {$: 6, a: a};
};
var $author$project$Icon$HelpCircle = 8;
var $author$project$InvestmentStrategyEditor$RemoveInvestmentStrategy = function (a) {
	return {$: 8, a: a};
};
var $author$project$InvestmentStrategyEditor$EditOtherAllocation = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$InvestmentStrategyEditor$EditOtherDescription = function (a) {
	return {$: 3, a: a};
};
var $author$project$InvestmentStrategyEditor$EditOtherInterest = function (a) {
	return {$: 4, a: a};
};
var $author$project$InvestmentStrategyEditor$EditSavingsInterest = function (a) {
	return {$: 5, a: a};
};
var $author$project$InvestmentStrategyEditor$RemoveOther = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$html$Html$Attributes$readonly = $elm$html$Html$Attributes$boolProperty('readOnly');
var $author$project$InvestmentStrategyEditor$viewAssetEditor = F3(
	function (assetType, investmentStrategy, _v0) {
		var allocation = _v0.a0;
		var interest = _v0.ay;
		var viewAssetDescription = A2(
			$elm$html$Html$input,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(
					$author$project$Types$InvestmentStrategy$assetToString(assetType)),
					A2($elm$html$Html$Attributes$style, 'width', '160px'),
					$elm$html$Html$Attributes$readonly(
					_Utils_eq(assetType, $author$project$Types$InvestmentStrategy$Savings)),
					function () {
					if (assetType.$ === 1) {
						var key = assetType.a;
						return $elm_community$html_extra$Html$Events$Extra$onChange(
							function (newDesc) {
								return $author$project$InvestmentStrategyEditor$EditOtherDescription(
									{a0: allocation, ay: interest, e: investmentStrategy.c1, ds: newDesc, dw: key});
							});
					} else {
						return A2($elm$html$Html$Attributes$style, '', '');
					}
				}(),
					function () {
					if (assetType.$ === 1) {
						var key = assetType.a;
						return $elm$html$Html$Attributes$id(
							A2(
								$author$project$InvestmentStrategyEditor$generateInvestmentStrategyOtherDescDomId,
								{e: investmentStrategy.c1},
								key));
					} else {
						return A2($elm$html$Html$Attributes$style, '', '');
					}
				}()
				]),
			_List_Nil);
		var viewAssetDeleteButton = function () {
			if (assetType.$ === 1) {
				var key = assetType.a;
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							A2(
								$author$project$InvestmentStrategyEditor$RemoveOther,
								{e: investmentStrategy.c1},
								key)),
							$elm$html$Html$Attributes$tabindex(-1),
							A2($elm$html$Html$Attributes$style, 'padding-left', '20px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('X')
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-white'),
							A2($elm$html$Html$Attributes$style, 'padding-left', '20px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('X')
						]));
			}
		}();
		var viewAssetAllocationAndInterest = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-none flex-row')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$UI$percentageInput_,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$readonly(
							_Utils_eq(assetType, $author$project$Types$InvestmentStrategy$Savings))
						]),
					allocation,
					function () {
						if (!assetType.$) {
							return $elm$core$Maybe$Nothing;
						} else {
							var key = assetType.a;
							return $elm$core$Maybe$Just(
								function (newVal) {
									return A3(
										$author$project$InvestmentStrategyEditor$EditOtherAllocation,
										{e: investmentStrategy.c1},
										key,
										newVal);
								});
						}
					}()),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'padding-left', '20px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(' @')
						])),
					A3(
					$author$project$UI$percentageInput_,
					_List_Nil,
					interest,
					function () {
						if (assetType.$ === 1) {
							var key = assetType.a;
							return $elm$core$Maybe$Just(
								function (newVal) {
									return $author$project$InvestmentStrategyEditor$EditOtherInterest(
										{ay: newVal, e: investmentStrategy.c1, db: key});
								});
						} else {
							return $elm$core$Maybe$Just(
								function (newVal) {
									return $author$project$InvestmentStrategyEditor$EditSavingsInterest(
										{ay: newVal, e: investmentStrategy.c1});
								});
						}
					}())
				]));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row m-3 text-left space-x-2')
				]),
			_List_fromArray(
				[viewAssetDescription, viewAssetAllocationAndInterest, viewAssetDeleteButton]));
	});
var $author$project$InvestmentStrategyEditor$viewInvestmentStrategy = function (investmentStrategy) {
	var savings = investmentStrategy.aC;
	var other = investmentStrategy.aS;
	var viewSavings = function (a) {
		return A3($author$project$InvestmentStrategyEditor$viewAssetEditor, $author$project$Types$InvestmentStrategy$Savings, investmentStrategy, a);
	};
	var viewOther = F2(
		function (key, a) {
			return A3(
				$author$project$InvestmentStrategyEditor$viewAssetEditor,
				$author$project$Types$InvestmentStrategy$Other(key),
				investmentStrategy,
				a);
		});
	var addButton = A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($author$project$Style$buttonAction),
				$elm$html$Html$Events$onClick(
				$author$project$InvestmentStrategyEditor$AddNewOther(
					{e: investmentStrategy.c1}))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Add other')
			]));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-left p-5 space-y-3')
			]),
		$elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex flex-row sticky top-0 py-2 bg-white space-x-5')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(investmentStrategy.eI),
										$elm_community$html_extra$Html$Events$Extra$onChange(
										$author$project$InvestmentStrategyEditor$EditDescription(
											{e: investmentStrategy.c1})),
										$elm$html$Html$Attributes$class('underline flex-grow text-lg'),
										$elm$html$Html$Attributes$id(
										$author$project$InvestmentStrategyEditor$generateInvestmentStrategyDescDomId(
											{e: investmentStrategy.c1}))
									]),
								_List_Nil),
								A5(
								$author$project$UI$buttonWithTooltip,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$InvestmentStrategyEditor$CloneInvestmentStrategy(investmentStrategy)),
										A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
									]),
								'investmentStrategy-clone-button',
								0,
								'Clone Investment Strategy',
								_List_fromArray(
									[
										A2($author$project$Icon$view, 2, _List_Nil)
									])),
								A5(
								$author$project$UI$buttonWithTooltip,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$InvestmentStrategyEditor$RemoveInvestmentStrategy(
											{e: investmentStrategy.c1})),
										A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
									]),
								'investmentStrategy-delete-button',
								0,
								'Delete Investment Strategy',
								_List_fromArray(
									[
										A2($author$project$Icon$view, 4, _List_Nil)
									]))
							])),
						viewSavings(savings),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex flex-grow pl-4'),
								$elm$html$Html$Attributes$title('If this is above $0, then all funds will be directed to your savings until it is at the limit.')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('⤷ Emergency fund/limit'),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('px-2')
									]),
								_List_fromArray(
									[
										A2($author$project$Icon$view, 8, _List_Nil)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pl-2')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('$'),
										A3(
										$author$project$UI$floatInput,
										_List_fromArray(
											[
												A2($elm$html$Html$Attributes$style, 'width', '70px')
											]),
										investmentStrategy.aC.a8,
										$elm$core$Maybe$Just(
											function (newVal) {
												return $author$project$InvestmentStrategyEditor$EditSavingsMax(
													{e: investmentStrategy.c1, a8: newVal});
											}))
									]))
							]))
					]),
					A2(
					$elm$core$List$map,
					function (_v0) {
						var k = _v0.a;
						var v = _v0.b;
						return A2(viewOther, k, v);
					},
					$elm$core$Dict$toList(other)),
					_List_fromArray(
					[addButton])
				])));
};
var $author$project$InvestmentStrategyEditor$view = function (investmentStrategys) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'min-width', '100%'),
				A2($elm$html$Html$Attributes$style, 'height', '100%'),
				A2($elm$html$Html$Attributes$style, 'overflow-y', 'scroll'),
				$elm$html$Html$Attributes$class('bg-white pb-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('divide-y divide-gray-500')
					]),
				A2(
					$elm$core$List$map,
					$author$project$InvestmentStrategyEditor$viewInvestmentStrategy,
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.eI;
						},
						$author$project$UuidDict$values(investmentStrategys)))),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$InvestmentStrategyEditor$AddNewInvestmentStrategy),
						$elm$html$Html$Attributes$class($author$project$Style$buttonDotted)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Add new Investment Strategy')
					]))
			]));
};
var $author$project$ScheduleEditor$AddNewSchedule = {$: 7};
var $author$project$ScheduleEditor$maybeDragItem = F2(
	function (dnd, items) {
		return A2(
			$elm$core$Maybe$andThen,
			function (_v0) {
				var dragIndex = _v0.cL;
				return $elm$core$List$head(
					A2($elm$core$List$drop, dragIndex, items));
			},
			$author$project$ScheduleEditor$dndSystem.c3(dnd));
	});
var $author$project$ScheduleEditor$ghostView = F2(
	function (dnd, items) {
		var _v0 = A2($author$project$ScheduleEditor$maybeDragItem, dnd, items);
		if (!_v0.$) {
			var allocation = _v0.a.a0;
			return A2(
				$elm$html$Html$div,
				$author$project$ScheduleEditor$dndSystem.eY(dnd),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('relative bg-gray-600 text-white pl-4 pr-12 rounded-lg'),
								A2($elm$html$Html$Attributes$style, 'width', '150px')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(allocation.a)
							]))
					]));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $j_maas$elm_ordered_containers$OrderedDict$size = function (_v0) {
	var orderedDict = _v0;
	return $elm$core$Dict$size(orderedDict.f);
};
var $author$project$ScheduleEditor$AddNewOther = function (a) {
	return {$: 2, a: a};
};
var $author$project$ScheduleEditor$CloneSchedule = function (a) {
	return {$: 9, a: a};
};
var $author$project$ScheduleEditor$EditDescription = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$ScheduleEditor$RemoveSchedule = function (a) {
	return {$: 6, a: a};
};
var $author$project$ScheduleEditor$EditOtherAllocation = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$ScheduleEditor$EditOtherAllocationColor = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$ScheduleEditor$EditOtherDescription = function (a) {
	return {$: 4, a: a};
};
var $author$project$ScheduleEditor$RemoveOther = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$ScheduleEditor$viewActivityEditor = F5(
	function (_v0, _v1, schedule, allocation, model) {
		var globalIndex = _v0.cW;
		var key = _v1.db;
		var viewActivityDescription = A2(
			$elm$html$Html$input,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(key),
					A2($elm$html$Html$Attributes$style, 'width', '160px'),
					$elm_community$html_extra$Html$Events$Extra$onChange(
					function (newDesc) {
						return $author$project$ScheduleEditor$EditOtherDescription(
							{a0: allocation, ds: newDesc, dw: key, b1: schedule.c1});
					}),
					$elm$html$Html$Attributes$id(
					A3(
						$author$project$ScheduleEditor$generateItemDomId,
						key,
						0,
						{b1: schedule.c1}))
				]),
			_List_Nil);
		var viewActivityDeleteButton = A5(
			$author$project$UI$buttonWithTooltip,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					A2(
						$author$project$ScheduleEditor$RemoveOther,
						{b1: schedule.c1},
						key)),
					$elm$html$Html$Attributes$tabindex(-1),
					A2($elm$html$Html$Attributes$style, 'padding-left', '20px')
				]),
			'schedule-remove-item-button',
			0,
			'Remove ' + key,
			_List_fromArray(
				[
					$elm$html$Html$text('X')
				]));
		var viewActivityColor = A2(
			$elm$html$Html$input,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(allocation.cC),
					$elm$html$Html$Attributes$type_('color'),
					A2($elm$html$Html$Attributes$style, 'width', '40px'),
					A2($elm$html$Html$Attributes$style, 'cursor', 'pointer'),
					$elm$html$Html$Events$onInput(
					function (newColor) {
						return A3(
							$author$project$ScheduleEditor$EditOtherAllocationColor,
							{b1: schedule.c1},
							key,
							{cC: newColor});
					})
				]),
			_List_Nil);
		var viewActivityAllocation = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-none flex-row')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$UI$floatInput,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '30px'),
							$elm$html$Html$Attributes$id(
							A3(
								$author$project$ScheduleEditor$generateItemDomId,
								key,
								1,
								{b1: schedule.c1}))
						]),
					allocation.a0,
					$elm$core$Maybe$Just(
						function (newVal) {
							return A3(
								$author$project$ScheduleEditor$EditOtherAllocation,
								{b1: schedule.c1},
								key,
								newVal);
						})),
					$elm$html$Html$text('Hours')
				]));
		var itemId = 'scheduleEditor-other-id-' + $elm$core$String$fromInt(globalIndex);
		var viewDragger = function () {
			var tailwindClasses = _List_fromArray(
				[
					$elm$html$Html$Attributes$id(itemId),
					A2($elm$html$Html$Attributes$style, 'cursor', 'ns-resize'),
					$elm$html$Html$Attributes$title('Drag to reorder')
				]);
			var attrs = function () {
				var _v2 = $author$project$ScheduleEditor$dndSystem.c3(model.ai);
				if (!_v2.$) {
					var dragIndex = _v2.a.cL;
					return tailwindClasses;
				} else {
					return _Utils_ap(
						tailwindClasses,
						A2($author$project$ScheduleEditor$dndSystem.eK, globalIndex, itemId));
				}
			}();
			return A2(
				$elm$html$Html$div,
				attrs,
				_List_fromArray(
					[
						$elm$html$Html$text('≡')
					]));
		}();
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-row m-3 text-left space-x-2')
					]),
				A2($author$project$ScheduleEditor$dndSystem.eL, globalIndex, itemId)),
			_List_fromArray(
				[viewDragger, viewActivityColor, viewActivityDescription, viewActivityAllocation, viewActivityDeleteButton]));
	});
var $author$project$ScheduleEditor$viewSchedule = F3(
	function (model, _v0, schedule) {
		var offset = _v0.dv;
		var freetime = schedule.eW;
		var other = schedule.aS;
		var viewOther = F3(
			function (key, a, globalIndex) {
				return A5(
					$author$project$ScheduleEditor$viewActivityEditor,
					globalIndex,
					{db: key},
					schedule,
					a,
					model);
			});
		var addButton = A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class($author$project$Style$buttonAction),
					$elm$html$Html$Events$onClick(
					$author$project$ScheduleEditor$AddNewOther(
						{b1: schedule.c1}))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Add another')
				]));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-left p-5 space-y-3'),
					A2($elm$html$Html$Attributes$style, 'min-width', '350px')
				]),
			$elm$core$List$concat(
				_List_fromArray(
					[
						_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-row sticky top-0 py-2 bg-white space-x-5')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(schedule.eI),
											$elm_community$html_extra$Html$Events$Extra$onChange(
											$author$project$ScheduleEditor$EditDescription(
												{b1: schedule.c1})),
											$elm$html$Html$Attributes$class('underline flex-grow text-lg'),
											$elm$html$Html$Attributes$id(
											$author$project$ScheduleEditor$generateScheduleDescDomId(
												{b1: schedule.c1}))
										]),
									_List_Nil),
									A5(
									$author$project$UI$buttonWithTooltip,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$ScheduleEditor$CloneSchedule(schedule)),
											A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
										]),
									'schedule-clone-button',
									0,
									'Clone Schedule',
									_List_fromArray(
										[
											A2($author$project$Icon$view, 2, _List_Nil)
										])),
									A5(
									$author$project$UI$buttonWithTooltip,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$ScheduleEditor$RemoveSchedule(
												{b1: schedule.c1})),
											A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
										]),
									'schedule-delete-button',
									0,
									'Delete Schedule',
									_List_fromArray(
										[
											A2($author$project$Icon$view, 4, _List_Nil)
										]))
								]))
						]),
						A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, _v1) {
								var k = _v1.a;
								var v = _v1.b;
								return A3(
									viewOther,
									k,
									v,
									{cW: offset + i});
							}),
						$j_maas$elm_ordered_containers$OrderedDict$toList(other)),
						_List_fromArray(
						[
							addButton,
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('border-t-2 pt-2 pb-4')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('float-right flex flex-row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('float-right flex flex-row')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-xs font-extralight')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('FREETIME')
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('w-2')
														]),
													_List_Nil),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border-b-2 border-black')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$elm$core$String$fromFloat(freetime.a0) + ' Hours')
														]))
												]))
										]))
								]))
						])
					])));
	});
var $author$project$ScheduleEditor$view = F2(
	function (schedules, model) {
		var sortedSchedules = A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.eI;
			},
			$author$project$UuidDict$values(schedules));
		var lengths = A2(
			$elm$core$List$map,
			function (s) {
				return $j_maas$elm_ordered_containers$OrderedDict$size(s.aS);
			},
			sortedSchedules);
		var getOffset = function (i) {
			return $elm$core$List$sum(
				A2($elm$core$List$take, i, lengths));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'min-width', '100%'),
					A2($elm$html$Html$Attributes$style, 'height', '100%'),
					A2($elm$html$Html$Attributes$style, 'overflow-y', 'scroll'),
					$elm$html$Html$Attributes$class('bg-white pb-6')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$ScheduleEditor$ghostView,
					model.ai,
					$author$project$ScheduleEditor$getItems(schedules)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('divide-y divide-gray-500')
						]),
					A2(
						$elm$core$List$indexedMap,
						function (i) {
							return A2(
								$author$project$ScheduleEditor$viewSchedule,
								model,
								{
									dv: getOffset(i)
								});
						},
						sortedSchedules)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$ScheduleEditor$AddNewSchedule),
							$elm$html$Html$Attributes$class($author$project$Style$buttonDotted)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Add new schedule')
						]))
				]));
	});
var $author$project$App$viewDock = F2(
	function (viewport, model) {
		var tabButton = F2(
			function (text, dockType) {
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$App$SetDock(
								$elm$core$Result$Ok(dockType))),
							A2($elm$html$Html$Attributes$style, 'cursor', 'pointer'),
							_Utils_eq(
							model.t,
							$elm$core$Result$Ok(dockType)) ? $elm$html$Html$Attributes$class('p-2 font-bold underline') : $elm$html$Html$Attributes$class('p-2 hover:underline')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(text)
						]));
			});
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$App$dockIsOpen(model.t) ? $elm$html$Html$Attributes$class('shadow-xl') : $elm$html$Html$Attributes$class('fixed shadow-xl'),
					A2($elm$html$Html$Attributes$style, 'transition', 'height 1s'),
					A2(
					$elm$html$Html$Attributes$style,
					'height',
					function () {
						var _v0 = model.t;
						if (!_v0.$) {
							if (_v0.a === 2) {
								var _v1 = _v0.a;
								return '100vh';
							} else {
								var _v2 = model.A;
								if (_v2.$ === 1) {
									var timelineIdx = _v2.a.r;
									return '97vh';
								} else {
									return $author$project$Responsive$biggerThanIpad(viewport) ? '67vh' : '73vh';
								}
							}
						} else {
							return '0px';
						}
					}()),
					A2($elm$html$Html$Attributes$style, 'right', '20px'),
					A2($elm$html$Html$Attributes$style, 'bottom', '41px'),
					A2(
					$elm$html$Html$Attributes$style,
					'min-width',
					$author$project$Responsive$biggerThanIpad(viewport) ? '400px' : '350px'),
					A2(
					$elm$html$Html$Attributes$style,
					'width',
					$author$project$Responsive$biggerThanIpad(viewport) ? '400px' : '350px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class($author$project$Style$bar + 'rounded-t-xl text-base -my-1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('space-x-8')
								]),
							_List_fromArray(
								[
									A2(tabButton, 'Budget', 0),
									A2(tabButton, 'Invest', 1),
									A2(tabButton, 'Schedule', 2)
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$App$SetDock(
										$author$project$App$toggleDockVisibility(model.t))),
									A2($elm$html$Html$Attributes$style, 'cursor', 'pointer'),
									$elm$html$Html$Attributes$class('px-2')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Icon$view,
									0,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'transition', 'transform 1s'),
											$author$project$App$dockIsOpen(model.t) ? A2($elm$html$Html$Attributes$style, 'transform', 'rotate(180deg)') : A2($elm$html$Html$Attributes$style, '', '')
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('h-full border-l border-dotted border-black border-opacity-25')
						]),
					_List_fromArray(
						[
							function () {
							var _v3 = model.t;
							if (!_v3.$) {
								switch (_v3.a) {
									case 0:
										var _v4 = _v3.a;
										return A2(
											$elm$html$Html$map,
											$author$project$App$BudgetEditorMsg,
											$author$project$BudgetEditor$view(
												A2(
													$author$project$UuidDict$map,
													F2(
														function (_v5, v) {
															return v.a;
														}),
													model.n)));
									case 1:
										var _v6 = _v3.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('bg-white h-full')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('flex flex-row space-x-2 px-6 py-2 mb-2 border-b-4 border-black')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('font-bold')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('initial Networth: ')
																])),
															A2(
															$elm$html$Html$div,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('$'),
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$value(
																			A2($myrho$elm_round$Round$round, 2, model.d.an)),
																			$elm$html$Html$Attributes$type_('number'),
																			$elm_community$html_extra$Html$Events$Extra$onChange($author$project$App$SetUserConfigInitialAmount),
																			$elm$html$Html$Attributes$class('w-20')
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$map,
													$author$project$App$InvestmentStrategyEditorMsg,
													$author$project$InvestmentStrategyEditor$view(model.z))
												]));
									default:
										var _v7 = _v3.a;
										return A2(
											$elm$html$Html$map,
											$author$project$App$ScheduleEditorMsg,
											A2($author$project$ScheduleEditor$view, model.C, model.aD));
								}
							} else {
								return $elm$html$Html$text('');
							}
						}()
						]))
				]));
	});
var $author$project$Types$Schedule$activityToString = function (activity) {
	if (!activity.$) {
		return 'Freetime';
	} else {
		var k = activity.a;
		return k;
	}
};
var $author$project$Types$Schedule$Freetime = {$: 0};
var $author$project$Types$Schedule$toList_ = function (is) {
	return $elm$core$List$concat(
		_List_fromArray(
			[
				A2(
				$elm$core$List$map,
				function (_v0) {
					var k = _v0.a;
					var v = _v0.b;
					return _Utils_Tuple2(
						$author$project$Types$Schedule$Other(k),
						v);
				},
				$j_maas$elm_ordered_containers$OrderedDict$toList(is.aS)),
				_List_fromArray(
				[
					_Utils_Tuple2($author$project$Types$Schedule$Freetime, is.eW)
				])
			]));
};
var $author$project$Types$Schedule$toList = function (is) {
	return A2(
		$elm$core$List$map,
		$elm$core$Tuple$mapFirst($author$project$Types$Schedule$activityToString),
		$author$project$Types$Schedule$toList_(is));
};
var $author$project$WeekEditor$viewReadonly = F3(
	function (budget, schedules, viewF) {
		var totalDuration = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.eN;
				},
				budget.eu));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row w-full')
				]),
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (i, scheduleBlock) {
						var _v0 = A2($author$project$UuidDict$get, scheduleBlock.b1, schedules);
						if (_v0.$ === 1) {
							return $elm$html$Html$text('');
						} else {
							var schedule = _v0.a;
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$Attributes$style,
										'width',
										A2(
											$elm$core$String$join,
											'',
											_List_fromArray(
												[
													$elm$core$String$fromInt(
													$elm$core$Basics$round((scheduleBlock.eN / totalDuration) * 100)),
													'%'
												])))
									]),
								_List_fromArray(
									[
										A2(
										viewF,
										{cR: 24, c2: 25},
										A2(
											$elm$core$List$map,
											function (_v1) {
												var title = _v1.a;
												var allocation = _v1.b.a0;
												var colorHex = _v1.b.cC;
												return _Utils_Tuple3(
													title,
													$elm$core$Basics$round(allocation),
													{cC: colorHex});
											},
											$author$project$Types$Schedule$toList(schedule)))
									]));
						}
					}),
				budget.eu));
	});
var $author$project$WeekEditor$ClearAffected = {$: 1};
var $author$project$WeekEditor$EditBudgetDescription = function (a) {
	return {$: 10, a: a};
};
var $avh4$elm_color$Color$black = A4($avh4$elm_color$Color$RgbaSpace, 0 / 255, 0 / 255, 0 / 255, 1.0);
var $author$project$UI$clearTextInput = F2(
	function (val, msg) {
		return A2(
			$elm$html$Html$input,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('bg-black bg-opacity-0 text-white text-lg font-bold w-full'),
					A2($elm$html$Html$Attributes$style, 'text-shadow', 'black 1px 1px 5px'),
					$elm$html$Html$Events$onInput(msg),
					$elm$html$Html$Attributes$value(val)
				]),
			_List_Nil);
	});
var $author$project$WeekEditor$containerStyles = _List_fromArray(
	[
		$elm$html$Html$Attributes$class('flex flex-row select-none'),
		A2($elm$html$Html$Attributes$style, 'transition', 'height 1s')
	]);
var $author$project$WeekEditor$ghostStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'background-color', 'gray'),
		A2($elm$html$Html$Attributes$style, 'opacity', '50%')
	]);
var $author$project$WeekEditor$getScheduleBlockWidth = F2(
	function (_v0, scheduleBlock) {
		var scalingFactorWidth = _v0.aT;
		return $elm$core$Basics$round((scheduleBlock.eN * 365) * scalingFactorWidth);
	});
var $author$project$WeekEditor$scheduleBlockStyles = F3(
	function (scalingFactorWidth, _v0, scheduleBlock) {
		var isResizing = _v0.aM;
		var scheduleBlockWidthStr = function (w) {
			return w + 'px';
		}(
			$elm$core$String$fromInt(
				A2($author$project$WeekEditor$getScheduleBlockWidth, scalingFactorWidth, scheduleBlock)));
		return _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'color', 'white'),
				A2($elm$html$Html$Attributes$style, 'cursor', 'pointer'),
				A2($elm$html$Html$Attributes$style, 'width', scheduleBlockWidthStr),
				A2(
				$elm$html$Html$Attributes$style,
				'transition',
				isResizing ? '' : 'width 0.5s')
			]);
	});
var $author$project$WeekEditor$ScheduleBlockClicked = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $elm$html$Html$br = _VirtualDom_node('br');
var $author$project$BudgetBlock$budgetBlockClasses = 'block bg-white text-xs p-2 rounded-b-xl shadow-xl border-4 border-gray-300';
var $author$project$BudgetBlock$calculateHeight = F2(
	function (_v0, val) {
		var scalingFactorHeight = _v0.P;
		return $elm$core$Basics$round((val / 13) * scalingFactorHeight);
	});
var $author$project$BudgetBlock$heightAttr = function (val) {
	return A2(
		$elm$html$Html$Attributes$style,
		'height',
		A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					$elm$core$String$fromInt(val),
					'px'
				])));
};
var $elm_community$html_extra$Html$Events$Extra$onClickStopPropagation = function (msg) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'click',
		$elm$json$Json$Decode$succeed(
			_Utils_Tuple2(msg, true)));
};
var $author$project$BudgetBlock$paddedIncomeToAccountForExpensesSpacing = F3(
	function (scalingFactorHeight, budgetCount, incomeTotal) {
		var paddingHeight = 8;
		return function (h) {
			return h + (2 * paddingHeight);
		}(
			A2(
				$author$project$BudgetBlock$calculateHeight,
				{P: scalingFactorHeight},
				incomeTotal));
	});
var $author$project$BudgetBlock$scaledHeightAttr = F2(
	function (scalingFactorHeight, val) {
		return $author$project$BudgetBlock$heightAttr(
			A2($author$project$BudgetBlock$calculateHeight, scalingFactorHeight, val));
	});
var $author$project$BudgetBlock$stripe = function (color) {
	return A2(
		$elm$html$Html$Attributes$style,
		'background-image',
		A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				['linear-gradient(-45deg,', color + ' 8.33%,', '#000 8.33%, #000 50%,', color + ' 50%,', color + ' 58.33% ,', '#000 58.33%, #000 100%)'])));
};
var $author$project$BudgetBlock$stripeWhite = function (color) {
	return A2(
		$elm$html$Html$Attributes$style,
		'background-image',
		A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				['linear-gradient(-45deg,', color + ' 8.33%,', '#fff 8.33%, #fff 50%,', color + ' 50%,', color + ' 58.33% ,', '#fff 58.33%, #fff 100%)'])));
};
var $author$project$BudgetBlock$view__ = F6(
	function (maybeOnClick, _v0, _v1, blocks, blockConfigF, body) {
		var scalingFactorHeight = _v0.P;
		var smallAmountThreshold = _v0.aW;
		var isWhite = _v0.aN;
		var incomeTotal = _v1.c2;
		var expensesTotal = _v1.cR;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class($author$project$BudgetBlock$budgetBlockClasses),
					A2($elm$html$Html$Attributes$style, 'transition', 'height 1s'),
					$author$project$BudgetBlock$heightAttr(
					A3(
						$author$project$BudgetBlock$paddedIncomeToAccountForExpensesSpacing,
						scalingFactorHeight,
						$elm$core$List$length(blocks),
						incomeTotal)),
					function () {
					if (!maybeOnClick.$) {
						var onClickBackground = maybeOnClick.a.bb;
						return $elm_community$html_extra$Html$Events$Extra$onClickStopPropagation(onClickBackground);
					} else {
						return A2($elm$html$Html$Attributes$style, '', '');
					}
				}()
				]),
			_Utils_ap(
				A2(
					$elm$core$List$map,
					function (_v3) {
						var b = _v3.a;
						var _v4 = _v3.b;
						var title = _v4.a;
						var amount = _v4.b;
						var colorHex = _v4.c.cC;
						var isBeyondSmallAmountThreshold = _Utils_cmp(
							A2(
								$author$project$BudgetBlock$calculateHeight,
								{P: scalingFactorHeight},
								amount),
							smallAmountThreshold) < 0;
						return _Utils_eq(
							title,
							$author$project$Types$Schedule$activityToString($author$project$Types$Schedule$Freetime)) ? $elm$html$Html$text('') : A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class(
									A2(
										$elm$core$String$join,
										' ',
										_List_fromArray(
											[
												'flex',
												'flex-row',
												'hover:bg-gray-800',
												'group',
												isWhite ? 'border-black border-b' : 'border-white border-b',
												'rounded-md'
											]))),
									A2($elm$html$Html$Attributes$style, 'transition', 'height 1s, transform 0.5s'),
									isWhite ? $author$project$BudgetBlock$stripeWhite(colorHex) : $author$project$BudgetBlock$stripe(colorHex),
									A2($elm$html$Html$Attributes$style, 'background-size', '8.5px 8.5px'),
									A2(
									$author$project$BudgetBlock$scaledHeightAttr,
									{P: scalingFactorHeight},
									amount),
									function () {
									if (!maybeOnClick.$) {
										var onClick = maybeOnClick.a.aP;
										return $elm_community$html_extra$Html$Events$Extra$onClickStopPropagation(
											onClick(b));
									} else {
										return A2($elm$html$Html$Attributes$style, '', '');
									}
								}(),
									isBeyondSmallAmountThreshold ? $elm$html$Html$Attributes$title(
									title + (' ' + $elm$core$String$fromInt(amount))) : $elm$html$Html$Attributes$title('Click to edit amount.')
								]),
							_List_fromArray(
								[
									isBeyondSmallAmountThreshold ? $elm$html$Html$text('') : A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class(
											'inline-block m-2 px-2 text-md transform group-hover:text-lg group-hover:translate-x-4' + (isWhite ? ' bg-black' : '')),
											A2($elm$html$Html$Attributes$style, 'transition', 'transform 0.5s, font-size 0.6s'),
											A2($elm$html$Html$Attributes$style, 'height', '1.5em')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(title),
											$elm$html$Html$text(
											' ' + $elm$core$String$fromInt(amount))
										]))
								]));
					},
					A2(
						$elm$core$List$map,
						function (b) {
							return _Utils_Tuple2(
								b,
								blockConfigF(b));
						},
						blocks)),
				_List_fromArray(
					[body])));
	});
var $author$project$BudgetBlock$view_ = function (_v0) {
	var onClick = _v0.aP;
	var onClickBackground = _v0.bb;
	var scalingFactorHeight = _v0.P;
	var smallAmountThreshold = _v0.aW;
	var isWhite = _v0.aN;
	if (scalingFactorHeight.$ === 1) {
		return F4(
			function (_v2, _v3, _v4, _v5) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bg-black bg-opacity-40 shadow-xl rounded-b shadow-white'),
							A2($elm$html$Html$Attributes$style, 'height', '3px')
						]),
					_List_Nil);
			});
	} else {
		var height = scalingFactorHeight.a;
		return A2(
			$author$project$BudgetBlock$view__,
			$elm$core$Maybe$Just(
				{aP: onClick, bb: onClickBackground}),
			{aN: isWhite, P: height, aW: smallAmountThreshold});
	}
};
var $author$project$WeekEditor$scheduleView = F3(
	function (_v0, schedules, scheduleBlock) {
		var scalingFactorHeight = _v0.P;
		var _v1 = A2($author$project$UuidDict$get, scheduleBlock.b1, schedules);
		if (!_v1.$) {
			var schedule = _v1.a;
			return A5(
				$author$project$BudgetBlock$view_,
				{
					aN: true,
					aP: function (_v2) {
						var activity = _v2.a;
						return A2(
							$author$project$WeekEditor$ScheduleBlockClicked,
							{b1: scheduleBlock.b1},
							activity);
					},
					bb: A2(
						$author$project$WeekEditor$ScheduleBlockClicked,
						{b1: scheduleBlock.b1},
						$author$project$Types$Schedule$Freetime),
					P: scalingFactorHeight,
					aW: 20
				},
				{cR: 24, c2: 25},
				$author$project$Types$Schedule$toList_(schedule),
				function (_v3) {
					var activity = _v3.a;
					var allocation = _v3.b.a0;
					var colorHex = _v3.b.cC;
					return _Utils_Tuple3(
						$author$project$Types$Schedule$activityToString(activity),
						$elm$core$Basics$round(allocation),
						{cC: colorHex});
				},
				$elm$html$Html$text(''));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'height', '200px'),
						$elm$html$Html$Attributes$class('border-dashed border-4 border-red-400 p-5 bg-white text-black')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('The budget that was being used was removed.'),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								$elm$html$Html$text('Please reselect another.')
							]))
					]));
		}
	});
var $author$project$WeekEditor$ghostView = F6(
	function (scalingFactors, isResizing, color, dnd, schedules, scheduleBlocks) {
		var maybeDragScheduleBlock = A2(
			$elm$core$Maybe$andThen,
			function (_v1) {
				var dragIndex = _v1.cL;
				return $elm$core$List$head(
					A2($elm$core$List$drop, dragIndex, scheduleBlocks));
			},
			$author$project$WeekEditor$dndSystem.c3(dnd));
		if (!maybeDragScheduleBlock.$) {
			var scheduleBlock = maybeDragScheduleBlock.a;
			return A2(
				$elm$html$Html$div,
				_Utils_ap(
					A3(
						$author$project$WeekEditor$scheduleBlockStyles,
						{aT: scalingFactors.aH},
						isResizing,
						scheduleBlock),
					_Utils_ap(
						$author$project$WeekEditor$ghostStyles,
						$author$project$WeekEditor$dndSystem.eY(dnd))),
				_List_fromArray(
					[
						$elm$html$Html$text('-'),
						A3(
						$author$project$WeekEditor$scheduleView,
						{P: scalingFactors.ax},
						schedules,
						scheduleBlock)
					]));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $elm$html$Html$Events$onMouseDown = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mousedown',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Icon$Cross = 5;
var $author$project$Icon$Cut = 3;
var $author$project$WeekEditor$ResizeDrag = function (a) {
	return {$: 3, a: a};
};
var $author$project$WeekEditor$ResizeEnd = {$: 4};
var $author$project$WeekEditor$ResizeStart = function (a) {
	return {$: 2, a: a};
};
var $author$project$WeekEditor$ScheduleBlockChangeDuration = function (a) {
	return {$: 6, a: a};
};
var $author$project$WeekEditor$ScheduleBlockChangeSchedule = function (a) {
	return {$: 5, a: a};
};
var $author$project$WeekEditor$ScheduleBlockDelete = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$WeekEditor$ScheduleBlockSplit = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Icon$Settings = 9;
var $author$project$WeekEditor$ToggleShortMenuVisibility = function (a) {
	return {$: 11, a: a};
};
var $author$project$WeekEditor$affectedStyles = _List_Nil;
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$defaultOptions = {bZ: true, cc: false};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$Event = F6(
	function (keys, button, clientPos, offsetPos, pagePos, screenPos) {
		return {et: button, by: clientPos, fh: keys, fu: offsetPos, fB: pagePos, fN: screenPos};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$BackButton = 4;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$ErrorButton = 0;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$ForwardButton = 5;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$MainButton = 1;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$MiddleButton = 2;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$SecondButton = 3;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonFromId = function (id) {
	switch (id) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		default:
			return 0;
	}
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonDecoder = A2(
	$elm$json$Json$Decode$map,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonFromId,
	A2($elm$json$Json$Decode$field, 'button', $elm$json$Json$Decode$int));
var $mpizenberg$elm_pointer_events$Internal$Decode$clientPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'clientX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'clientY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$Keys = F3(
	function (alt, ctrl, shift) {
		return {eo: alt, eD: ctrl, fQ: shift};
	});
var $mpizenberg$elm_pointer_events$Internal$Decode$keys = A4(
	$elm$json$Json$Decode$map3,
	$mpizenberg$elm_pointer_events$Internal$Decode$Keys,
	A2($elm$json$Json$Decode$field, 'altKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'ctrlKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'shiftKey', $elm$json$Json$Decode$bool));
var $mpizenberg$elm_pointer_events$Internal$Decode$offsetPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'offsetX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'offsetY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$pagePos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'pageX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'pageY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$screenPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'screenX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'screenY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$eventDecoder = A7($elm$json$Json$Decode$map6, $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$Event, $mpizenberg$elm_pointer_events$Internal$Decode$keys, $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonDecoder, $mpizenberg$elm_pointer_events$Internal$Decode$clientPos, $mpizenberg$elm_pointer_events$Internal$Decode$offsetPos, $mpizenberg$elm_pointer_events$Internal$Decode$pagePos, $mpizenberg$elm_pointer_events$Internal$Decode$screenPos);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions = F3(
	function (event, options, tag) {
		return A2(
			$elm$html$Html$Events$custom,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (ev) {
					return {
						ae: tag(ev),
						bZ: options.bZ,
						cc: options.cc
					};
				},
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$eventDecoder));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onDown = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions, 'mousedown', $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$defaultOptions);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onMove = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions, 'mousemove', $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$defaultOptions);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions, 'mouseup', $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$defaultOptions);
var $elm$html$Html$option = _VirtualDom_node('option');
var $author$project$WeekEditor$placeholderStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'background-color', 'dimgray')
	]);
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$html$Html$Attributes$step = function (n) {
	return A2($elm$html$Html$Attributes$stringProperty, 'step', n);
};
var $author$project$WeekEditor$scheduleBlockView = F8(
	function (scalingFactors, _v0, color, schedules, _v1, _v2, index, scheduleBlock) {
		var isResizing = _v0.aM;
		var dnd = _v1.ai;
		var affected = _v1._;
		var visibleShortMenus = _v1.S;
		var budgetId = _v2.ac;
		var withResizeDragger = function (child) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-row'),
						isResizing ? $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onMove($author$project$WeekEditor$ResizeDrag) : A2($elm$html$Html$Attributes$style, '', ''),
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp(
						$elm$core$Basics$always($author$project$WeekEditor$ResizeEnd))
					]),
				_List_fromArray(
					[
						child,
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '10px'),
								A2($elm$html$Html$Attributes$style, 'cursor', 'col-resize'),
								$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onDown(
								function (e) {
									return $author$project$WeekEditor$ResizeStart(
										{bw: index, bO: e.by.a});
								})
							]),
						_List_Nil)
					]));
		};
		var viewScheduleSelect = A2(
			$elm$html$Html$select,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex-initial w-max text-black'),
					A2($elm$html$Html$Attributes$style, 'max-width', '75%'),
					$elm$html$Html$Events$onInput(
					function (rawVal) {
						var _v4 = $danyx23$elm_uuid$Uuid$fromString(rawVal);
						if (!_v4.$) {
							var scheduleId = _v4.a;
							return $author$project$WeekEditor$ScheduleBlockChangeSchedule(
								{Q: index, b1: scheduleId});
						} else {
							return $author$project$WeekEditor$ResizeEnd;
						}
					}),
					$elm$html$Html$Attributes$title('Change schedule for this chapter'),
					$elm$html$Html$Attributes$tabindex(-1)
				]),
			A2(
				$elm$core$List$map,
				function (b) {
					return A2(
						$elm$html$Html$option,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value(
								$danyx23$elm_uuid$Uuid$toString(b.c1)),
								$elm$html$Html$Attributes$class('text-black'),
								$elm$html$Html$Attributes$selected(
								_Utils_eq(
									$danyx23$elm_uuid$Uuid$toString(scheduleBlock.b1),
									$danyx23$elm_uuid$Uuid$toString(b.c1)))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(b.eI)
							]));
				},
				$author$project$UuidDict$values(schedules)));
		var viewDurationYearsInput = A2(
			$elm$html$Html$input,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(
					A2($myrho$elm_round$Round$round, 2, scheduleBlock.eN)),
					$elm$html$Html$Attributes$type_('number'),
					$elm$html$Html$Attributes$step('0.02'),
					$elm$html$Html$Attributes$min('0.0'),
					$elm_community$html_extra$Html$Events$Extra$onChange(
					function (newVal) {
						return $author$project$WeekEditor$ScheduleBlockChangeDuration(
							{dq: newVal, Q: index});
					}),
					$elm$html$Html$Attributes$class('w-16 text-black text-center'),
					$elm$html$Html$Attributes$title('Change chapter duration')
				]),
			_List_Nil);
		var viewDragger = function (moreAttrs) {
			return A2(
				$elm$html$Html$div,
				$elm$core$List$concat(
					_List_fromArray(
						[
							moreAttrs,
							_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex-grow'),
								A2($elm$html$Html$Attributes$style, 'cursor', 'col-resize')
							])
						])),
				_List_fromArray(
					[
						$elm$html$Html$text('Days')
					]));
		};
		var viewDeleteScheduleBlockButton = A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('grid place-items-center w-10 border border-white rounded'),
					$elm$html$Html$Attributes$title('Delete scheduleBlock'),
					$elm$html$Html$Events$onClick(
					A2(
						$author$project$WeekEditor$ScheduleBlockDelete,
						{Q: index},
						scheduleBlock)),
					$elm$html$Html$Attributes$tabindex(-1)
				]),
			_List_fromArray(
				[
					A2($author$project$Icon$view, 4, _List_Nil)
				]));
		var viewCutScheduleBlockButton = A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('grid place-items-center w-10 border border-white rounded'),
					$elm$html$Html$Attributes$title('Split scheduleBlock'),
					$elm$html$Html$Events$onClick(
					A2(
						$author$project$WeekEditor$ScheduleBlockSplit,
						{Q: index},
						scheduleBlock)),
					$elm$html$Html$Attributes$tabindex(-1)
				]),
			_List_fromArray(
				[
					A2($author$project$Icon$view, 3, _List_Nil)
				]));
		var viewTitleBody = function (moreAttrs) {
			return _List_fromArray(
				[
					viewScheduleSelect,
					viewDurationYearsInput,
					viewDragger(moreAttrs),
					viewCutScheduleBlockButton,
					viewDeleteScheduleBlockButton
				]);
		};
		var viewTitle = function (moreAttrs) {
			var showShortMenu = A2($elm$core$Set$member, index, visibleShortMenus);
			var scheduleBlockWidth = A2(
				$author$project$WeekEditor$getScheduleBlockWidth,
				{aT: scalingFactors.aH},
				scheduleBlock);
			return (scheduleBlockWidth < 400) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('z-30 flex flex-row sticky top-0 p-1 space-x-2 select-none text-sm'),
						A2(
						$elm$html$Html$Attributes$style,
						'background-color',
						$avh4$elm_color$Color$toCssString(color)),
						$elm$html$Html$Attributes$title('Drag to reorder chapters')
					]),
				_List_fromArray(
					[
						viewScheduleSelect,
						A2(
						$elm$html$Html$div,
						_Utils_ap(
							moreAttrs,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-grow'),
									A2($elm$html$Html$Attributes$style, 'cursor', 'ew-resize')
								])),
						_List_Nil),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('grid place-items-center w-10 border border-white rounded'),
								$elm$html$Html$Attributes$title('Delete scheduleBlock'),
								$elm$html$Html$Events$onClick(
								$author$project$WeekEditor$ToggleShortMenuVisibility(
									{Q: index})),
								$elm$html$Html$Attributes$tabindex(-1)
							]),
						_List_fromArray(
							[
								A2(
								$author$project$Icon$view,
								showShortMenu ? 5 : 9,
								_List_Nil)
							])),
						showShortMenu ? A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('relative')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('absolute top-5 right-0 bg-gray-500 z-40 shadow-lg p-2 space-y-2'),
										A2($elm$html$Html$Attributes$style, 'width', '150px')
									]),
								_List_fromArray(
									[
										viewScheduleSelect,
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex flex-row space-x-2')
											]),
										_List_fromArray(
											[
												viewDurationYearsInput,
												viewDragger(moreAttrs)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex flex-row')
											]),
										_List_fromArray(
											[
												viewCutScheduleBlockButton,
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-grow')
													]),
												_List_Nil),
												viewDeleteScheduleBlockButton
											]))
									]))
							])) : $elm$html$Html$text('')
					])) : A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('z-30 flex flex-row sticky top-0 p-1 space-x-2 select-none text-sm'),
						A2(
						$elm$html$Html$Attributes$style,
						'background-color',
						$avh4$elm_color$Color$toCssString(color)),
						$elm$html$Html$Attributes$title('Drag to reorder chapters')
					]),
				viewTitleBody(moreAttrs));
		};
		var scheduleBlockId = A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[
					'hrdrag',
					$danyx23$elm_uuid$Uuid$toString(budgetId),
					$elm$core$String$fromInt(index)
				]));
		var attrs = A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$id(scheduleBlockId),
			_Utils_ap(
				A3(
					$author$project$WeekEditor$scheduleBlockStyles,
					{aT: scalingFactors.aH},
					{aM: isResizing},
					scheduleBlock),
				A2($elm$core$List$member, index, affected) ? $author$project$WeekEditor$affectedStyles : _List_Nil));
		var _v3 = $author$project$WeekEditor$dndSystem.c3(dnd);
		if (!_v3.$) {
			var dragIndex = _v3.a.cL;
			return (!_Utils_eq(dragIndex, index)) ? withResizeDragger(
				A2(
					$elm$html$Html$div,
					_Utils_ap(
						attrs,
						A2($author$project$WeekEditor$dndSystem.eL, index, scheduleBlockId)),
					_List_fromArray(
						[
							viewTitle(_List_Nil),
							A3(
							$author$project$WeekEditor$scheduleView,
							{P: scalingFactors.ax},
							schedules,
							scheduleBlock)
						]))) : withResizeDragger(
				A2(
					$elm$html$Html$div,
					_Utils_ap(attrs, $author$project$WeekEditor$placeholderStyles),
					_List_fromArray(
						[
							viewTitle(_List_Nil),
							A3(
							$author$project$WeekEditor$scheduleView,
							{P: scalingFactors.ax},
							schedules,
							scheduleBlock)
						])));
		} else {
			return withResizeDragger(
				A2(
					$elm$html$Html$div,
					A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('sticky top-0'),
						attrs),
					_List_fromArray(
						[
							viewTitle(
							A2($author$project$WeekEditor$dndSystem.eK, index, scheduleBlockId)),
							A3(
							$author$project$WeekEditor$scheduleView,
							{P: scalingFactors.ax},
							schedules,
							scheduleBlock)
						])));
		}
	});
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$WeekEditor$view = F5(
	function (scalingFactors, color, model, budget, schedules) {
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Events$onMouseDown($author$project$WeekEditor$ClearAffected)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sticky pb-1 pt-4'),
							A2($elm$html$Html$Attributes$style, 'width', '500px')
						]),
					_List_fromArray(
						[
							A2($author$project$UI$clearTextInput, budget.eI, $author$project$WeekEditor$EditBudgetDescription)
						])),
					A2(
					$elm$html$Html$div,
					$author$project$WeekEditor$containerStyles,
					A2(
						$elm$core$List$indexedMap,
						A6(
							$author$project$WeekEditor$scheduleBlockView,
							scalingFactors,
							{
								aM: !_Utils_eq(model.T, $elm$core$Maybe$Nothing)
							},
							$avh4$elm_color$Color$black,
							schedules,
							model,
							{ac: budget.c1}),
						budget.eu)),
					A6(
					$author$project$WeekEditor$ghostView,
					scalingFactors,
					{
						aM: !_Utils_eq(model.T, $elm$core$Maybe$Nothing)
					},
					$avh4$elm_color$Color$black,
					model.ai,
					schedules,
					budget.eu)
				]));
	});
var $author$project$App$viewSchedules = F2(
	function (colors, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'overflow-x', 'scroll'),
					A2($elm$html$Html$Attributes$style, 'overflow-y', 'scroll'),
					$elm$html$Html$Attributes$class('space-y-5 w-full mt-8')
				]),
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (i, _v0) {
						var color = _v0.a;
						var _v1 = _v0.b;
						var budget = _v1.a;
						var timelineEditor = _v1.b;
						var buttonStyles = function (attrs) {
							return _Utils_ap(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class(' grid place-items-center rounded-md border-2 border-white border-solid w-24 h-8 bg-black bg-opacity-50')
									]),
								attrs);
						};
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id(
									$author$project$App$generateTimelineDomId(i)),
									$elm$html$Html$Attributes$class('w-full')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$map,
									$author$project$App$ScheduleWeekEditorMsg(
										{ac: budget.c1}),
									A5(
										$author$project$WeekEditor$view,
										{
											ax: A2(
												$elm$core$Maybe$map,
												function (x) {
													return x * 90;
												},
												model.d.a1),
											aH: model.d.bo
										},
										color,
										timelineEditor,
										budget,
										model.C))
								]));
					}),
				A2(
					$elm_community$list_extra$List$Extra$zip,
					colors,
					A2(
						$elm$core$List$sortBy,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Tuple$first,
							function ($) {
								return $.eI;
							}),
						$author$project$UuidDict$values(model.n)))));
	});
var $author$project$BudgetBlock$viewStripey = F2(
	function (totals, blocks) {
		return A6(
			$author$project$BudgetBlock$view__,
			$elm$core$Maybe$Nothing,
			{aN: true, P: 100, aW: 2},
			totals,
			blocks,
			$elm$core$Basics$identity,
			$elm$html$Html$text(''));
	});
var $author$project$App$CloneTimelineRequest = F2(
	function (a, b) {
		return {$: 16, a: a, b: b};
	});
var $author$project$App$DeleteTimeline = function (a) {
	return {$: 18, a: a};
};
var $author$project$App$PageTimelineDetail = function (a) {
	return {$: 1, a: a};
};
var $author$project$Icon$Search = 6;
var $author$project$UI$TooltipTop = 2;
var $author$project$App$WithTime = function (a) {
	return {$: 19, a: a};
};
var $author$project$TimelineEditor$ClearAffected = {$: 1};
var $author$project$TimelineEditor$EditTimelineDescription = function (a) {
	return {$: 11, a: a};
};
var $author$project$TimelineEditor$BudgetBlockClicked = function (a) {
	return {$: 10, a: a};
};
var $author$project$Types$Budget$getExpensesSum = $author$project$Types$Budget$V1$getExpensesSum;
var $author$project$BudgetBlock$view = F3(
	function (_v0, budget, body) {
		var onClick = _v0.aP;
		var scalingFactorHeight = _v0.P;
		return A5(
			$author$project$BudgetBlock$view_,
			{
				aN: false,
				aP: function (_v1) {
					var desc = _v1.a;
					return onClick(
						{ac: budget.c1, eI: desc});
				},
				bb: onClick(
					{
						ac: budget.c1,
						eI: A2(
							$elm$core$Maybe$withDefault,
							'',
							A2(
								$elm$core$Maybe$map,
								$elm$core$Tuple$first,
								$elm$core$List$head(budget.e6)))
					}),
				P: scalingFactorHeight,
				aW: 30
			},
			{
				cR: $author$project$Types$Budget$getExpensesSum(budget),
				c2: $author$project$Types$Budget$getIncomeSum(budget)
			},
			$elm$core$List$reverse(
				A2(
					$elm$core$List$sortBy,
					A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $author$project$Types$CalcResult$getValue),
					budget.eS)),
			function (_v2) {
				var d = _v2.a;
				var calcResult = _v2.b;
				return _Utils_Tuple3(
					d,
					$author$project$Types$CalcResult$getValue(calcResult),
					{cC: '#555'});
			},
			body);
	});
var $author$project$TimelineEditor$MortgageHovered = function (a) {
	return {$: 12, a: a};
};
var $author$project$TimelineEditor$getSegmentWidth = F2(
	function (_v0, segment) {
		var scalingFactorWidth = _v0.aT;
		return $elm$core$Basics$round(
			function (w) {
				return w * 0.7;
			}((segment.aL * 365) * scalingFactorWidth));
	});
var $avh4$elm_color$Color$rgba = F4(
	function (r, g, b, a) {
		return A4($avh4$elm_color$Color$RgbaSpace, r, g, b, a);
	});
var $terezka$line_charts$LineChart$Colors$transparent = A4($avh4$elm_color$Color$rgba, 0, 0, 0, 0);
var $terezka$line_charts$Internal$Axis$Line$none = $terezka$line_charts$Internal$Axis$Line$custom(
	F2(
		function (_v0, _v1) {
			var min = _v1.fl;
			var max = _v1.a8;
			return {bz: $terezka$line_charts$LineChart$Colors$transparent, cN: max, cP: _List_Nil, cb: min, aH: 0};
		}));
var $terezka$line_charts$Internal$Axis$none = F2(
	function (pixels_, variable_) {
		return $terezka$line_charts$Internal$Axis$custom(
			{
				cr: $terezka$line_charts$Internal$Axis$Line$none,
				dF: pixels_,
				dM: A2($terezka$line_charts$Internal$Axis$Range$padded, 20, 20),
				d7: $terezka$line_charts$Internal$Axis$Ticks$custom(
					F2(
						function (_v0, _v1) {
							return _List_Nil;
						})),
				d9: $terezka$line_charts$Internal$Axis$Title$default(''),
				ef: A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, variable_)
			});
	});
var $terezka$line_charts$LineChart$Axis$none = $terezka$line_charts$Internal$Axis$none;
var $author$project$TimelineEditor$viewMortgageChart = F7(
	function (_v0, color, _v1, budget, _v2, segment, mortgage) {
		var width = _v0.aH;
		var height = _v0.ax;
		var hovered = _v1.am;
		var segmentId = _v2.aU;
		var mortgageChartId = 'mortgage-line-chart-' + ($danyx23$elm_uuid$Uuid$toString(budget.c1) + segmentId);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id(mortgageChartId),
					$elm$html$Html$Attributes$class('w-full text-black'),
					A2($elm$html$Html$Attributes$style, 'margin-top', '10px'),
					A2($elm$html$Html$Attributes$style, 'margin-left', '-20px')
				]),
			_List_fromArray(
				[
					A2(
					$terezka$line_charts$LineChart$viewCustom,
					{
						co: $terezka$line_charts$LineChart$Area$stacked(0.5),
						cD: $terezka$line_charts$LineChart$Container$custom(
							{
								cp: _List_Nil,
								cq: _List_Nil,
								c1: mortgageChartId,
								dl: A4($terezka$line_charts$LineChart$Container$Margin, 0, 0, 0, 0),
								d2: $terezka$line_charts$LineChart$Container$static
							}),
						cJ: $terezka$line_charts$LineChart$Dots$default,
						cP: $terezka$line_charts$LineChart$Events$hoverMany(
							function (list) {
								return $author$project$TimelineEditor$MortgageHovered(
									_Utils_Tuple2(
										{cA: mortgageChartId},
										list));
							}),
						cY: A2($terezka$line_charts$LineChart$Grid$lines, 1, $terezka$line_charts$LineChart$Colors$grayLight),
						c7: $terezka$line_charts$LineChart$Interpolation$default,
						c8: $terezka$line_charts$LineChart$Axis$Intersection$default,
						da: A3(
							$terezka$line_charts$LineChart$Junk$hoverMany,
							function () {
								if (!hovered.$) {
									var _v4 = hovered.a;
									var chartId = _v4.a.cA;
									var hoveredList = _v4.b;
									return _Utils_eq(chartId, mortgageChartId) ? hoveredList : _List_Nil;
								} else {
									return _List_Nil;
								}
							}(),
							function (d) {
								return A2(
									$myrho$elm_round$Round$round,
									1,
									function (m) {
										return m / 12;
									}(d.U)) + ' Years';
							},
							function (d) {
								return $author$project$Util$formatMoney(d.V);
							}),
						de: $terezka$line_charts$LineChart$Legends$none,
						dg: $terezka$line_charts$LineChart$Line$default,
						ci: A2(
							$terezka$line_charts$LineChart$Axis$none,
							A2(
								$author$project$TimelineEditor$getSegmentWidth,
								{aT: width},
								segment) + 15,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.U;
								},
								function (m) {
									return m / 12;
								})),
						ck: A2(
							$terezka$line_charts$LineChart$Axis$none,
							A2(
								$author$project$BudgetBlock$calculateHeight,
								{
									P: A2($elm$core$Maybe$withDefault, $author$project$UserConfig$const_TIMELINE_HEIGHT_MIN, height)
								},
								$elm$core$Basics$round(
									$author$project$Types$Budget$calculateMortgageRepaymentMonthlyActual(mortgage))),
							function ($) {
								return $.V;
							})
					},
					function () {
						var segmentMonths = $elm$core$Basics$round(segment.aL * 12);
						var repayments = $author$project$Types$Budget$generateMortgagePrincipalAndInterestSplitWithExtraRepayments(mortgage);
						var monthsMissing = segmentMonths - $elm$core$List$length(repayments);
						var trimmedOrPaddedList = (_Utils_cmp(
							$elm$core$List$length(repayments),
							segmentMonths) < 0) ? _Utils_ap(
							repayments,
							A2(
								$elm$core$List$repeat,
								monthsMissing,
								{c6: 0, bi: 0})) : A2($elm$core$List$take, segmentMonths, repayments);
						var toMortgageData = function (get) {
							return A2(
								$elm$core$List$indexedMap,
								F2(
									function (i, x) {
										return {
											U: i,
											V: get(x)
										};
									}),
								trimmedOrPaddedList);
						};
						return _List_fromArray(
							[
								A4(
								$terezka$line_charts$LineChart$line,
								$terezka$line_charts$LineChart$Colors$black,
								$terezka$line_charts$LineChart$Dots$none,
								'Interest paid',
								toMortgageData(
									function ($) {
										return $.c6;
									})),
								A4(
								$terezka$line_charts$LineChart$line,
								color,
								$terezka$line_charts$LineChart$Dots$none,
								'Principal reduction',
								toMortgageData(
									function ($) {
										return $.bi;
									}))
							]);
					}())
				]));
	});
var $author$project$TimelineEditor$budgetView = F6(
	function (scalingFactors, color, mortgageHover, budgets, segmentId, segment) {
		var width = scalingFactors.aH;
		var height = scalingFactors.ax;
		var _v0 = A2($author$project$UuidDict$get, segment.ac, budgets);
		if (!_v0.$) {
			var budget = _v0.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('z-20')
					]),
				_List_fromArray(
					[
						A3(
						$author$project$BudgetBlock$view,
						{aP: $author$project$TimelineEditor$BudgetBlockClicked, P: height},
						budget,
						function () {
							var _v1 = budget.aO;
							if (!_v1.$) {
								var mortgage = _v1.a;
								return A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'margin-top', '-20px')
										]),
									_List_fromArray(
										[
											A7($author$project$TimelineEditor$viewMortgageChart, scalingFactors, color, mortgageHover, budget, segmentId, segment, mortgage)
										]));
							} else {
								return $elm$html$Html$text('');
							}
						}())
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'height', '200px'),
						$elm$html$Html$Attributes$class('border-dashed border-4 border-red-400 p-5 bg-white text-black')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('The budget that was being used was removed.'),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								$elm$html$Html$text('Please reselect another.')
							]))
					]));
		}
	});
var $author$project$TimelineEditor$ghostStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'background-color', 'gray'),
		A2($elm$html$Html$Attributes$style, 'opacity', '50%')
	]);
var $author$project$TimelineEditor$segmentStyles = F3(
	function (scalingFactorWidth, _v0, segment) {
		var isResizing = _v0.aM;
		var segmentWidthStr = function (w) {
			return w + 'px';
		}(
			$elm$core$String$fromInt(
				A2($author$project$TimelineEditor$getSegmentWidth, scalingFactorWidth, segment)));
		return _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'color', 'white'),
				A2($elm$html$Html$Attributes$style, 'cursor', 'pointer'),
				A2($elm$html$Html$Attributes$style, 'width', segmentWidthStr),
				A2(
				$elm$html$Html$Attributes$style,
				'transition',
				isResizing ? '' : 'width 0.5s')
			]);
	});
var $author$project$TimelineEditor$ghostView = F5(
	function (scalingFactors, color, dnd, budgets, segments) {
		var maybeDragSegment = A2(
			$elm$core$Maybe$andThen,
			function (_v1) {
				var dragIndex = _v1.cL;
				return $elm$core$List$head(
					A2($elm$core$List$drop, dragIndex, segments));
			},
			$author$project$TimelineEditor$dndSystem.c3(dnd));
		if (!maybeDragSegment.$) {
			var segment = maybeDragSegment.a;
			return A2(
				$elm$html$Html$div,
				_Utils_ap(
					A3(
						$author$project$TimelineEditor$segmentStyles,
						{aT: scalingFactors.aH},
						{aM: false},
						segment),
					_Utils_ap(
						$author$project$TimelineEditor$ghostStyles,
						$author$project$TimelineEditor$dndSystem.eY(dnd))),
				_List_fromArray(
					[
						$elm$html$Html$text('-'),
						A6(
						$author$project$TimelineEditor$budgetView,
						scalingFactors,
						color,
						{am: $elm$core$Maybe$Nothing},
						budgets,
						{aU: 'ghost'},
						segment)
					]));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$TimelineEditor$getHeightBasedOnBudget = F3(
	function (_v0, budgets, segments) {
		var scalingFactorHeight = _v0.P;
		var budgetsSelectedInSegments = $elm_community$maybe_extra$Maybe$Extra$values(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.ac;
					},
					function (bId) {
						return A2($author$project$UuidDict$get, bId, budgets);
					}),
				segments));
		var heightInt = function () {
			var maxIncome = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$List$maximum(
					A2($elm$core$List$map, $author$project$Types$Budget$getIncomeSum, budgetsSelectedInSegments)));
			var maxExpenses = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$List$maximum(
					A2($elm$core$List$map, $author$project$Types$Budget$getExpensesSum, budgetsSelectedInSegments)));
			return A3(
				$author$project$BudgetBlock$paddedIncomeToAccountForExpensesSpacing,
				scalingFactorHeight,
				$elm$core$List$length(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.eS;
						},
						budgetsSelectedInSegments)),
				A2($elm$core$Basics$max, maxIncome, maxExpenses));
		}();
		return A2(
			$elm$html$Html$Attributes$style,
			'height',
			$elm$core$String$fromInt(heightInt) + 'px');
	});
var $author$project$TimelineEditor$containerStyles = F3(
	function (scalingFactors, budgets, segments) {
		return _List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex flex-row select-none'),
				function () {
				var _v0 = scalingFactors.ax;
				if (!_v0.$) {
					var height = _v0.a;
					return A3(
						$author$project$TimelineEditor$getHeightBasedOnBudget,
						{P: height},
						budgets,
						segments);
				} else {
					return A2($elm$html$Html$Attributes$style, '', '');
				}
			}(),
				A2($elm$html$Html$Attributes$style, 'transition', 'height 1s')
			]);
	});
var $author$project$TimelineEditor$ResizeDrag = function (a) {
	return {$: 3, a: a};
};
var $author$project$TimelineEditor$ResizeEnd = {$: 4};
var $author$project$TimelineEditor$ResizeStart = function (a) {
	return {$: 2, a: a};
};
var $author$project$TimelineEditor$SegmentChangeBudget = function (a) {
	return {$: 5, a: a};
};
var $author$project$TimelineEditor$SegmentChangeDuration = function (a) {
	return {$: 7, a: a};
};
var $author$project$TimelineEditor$SegmentChangeInvestmentStrategy = function (a) {
	return {$: 6, a: a};
};
var $author$project$TimelineEditor$SegmentDelete = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$TimelineEditor$SegmentSplit = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$TimelineEditor$ToggleShortMenuVisibility = function (a) {
	return {$: 13, a: a};
};
var $author$project$UI$TooltipBottom = 3;
var $author$project$UI$TooltipRight = 1;
var $author$project$TimelineEditor$affectedStyles = _List_Nil;
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $author$project$TimelineEditor$placeholderStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'background-color', 'dimgray')
	]);
var $author$project$UI$selectWithTooltip = $author$project$UI$withTooltip_($elm$html$Html$select);
var $author$project$TimelineEditor$segmentView = function (_v0) {
	return function (scalingFactors) {
		return function (_v1) {
			return function (color) {
				return function (budgets) {
					return function (investmentStrategys) {
						return function (_v2) {
							return function (_v3) {
								return function (index) {
									return function (segment) {
										var idPrefix = _v0.e3;
										var isResizing = _v1.aM;
										var dnd = _v2.ai;
										var affected = _v2._;
										var mortgageHovered = _v2.ba;
										var segmentResizing = _v2.W;
										var visibleShortMenus = _v2.S;
										var timelineId = _v3.d8;
										var withResizeDragger = function (child) {
											return A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex flex-row'),
														isResizing ? $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onMove($author$project$TimelineEditor$ResizeDrag) : A2($elm$html$Html$Attributes$style, '', ''),
														$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp(
														$elm$core$Basics$always($author$project$TimelineEditor$ResizeEnd))
													]),
												_List_fromArray(
													[
														child,
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																A2($elm$html$Html$Attributes$style, 'width', '10px'),
																A2($elm$html$Html$Attributes$style, 'cursor', 'col-resize'),
																$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onDown(
																function (e) {
																	return $author$project$TimelineEditor$ResizeStart(
																		{bO: e.by.a, b3: index});
																})
															]),
														_List_Nil)
													]));
										};
										var viewDurationYearsInput = A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$value(
													A2($myrho$elm_round$Round$round, 2, segment.aL)),
													$elm$html$Html$Attributes$type_('number'),
													$elm$html$Html$Attributes$step('0.02'),
													$elm$html$Html$Attributes$min('0.0'),
													$elm_community$html_extra$Html$Events$Extra$onChange(
													function (newVal) {
														return $author$project$TimelineEditor$SegmentChangeDuration(
															{dr: newVal, E: index});
													}),
													$elm$html$Html$Attributes$class('w-16 text-black text-center'),
													$elm$html$Html$Attributes$title('Change chapter duration')
												]),
											_List_Nil);
										var viewDragger = function (moreAttrs) {
											return A2(
												$elm$html$Html$div,
												$elm$core$List$concat(
													_List_fromArray(
														[
															moreAttrs,
															_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-grow'),
																A2($elm$html$Html$Attributes$style, 'cursor', 'col-resize')
															])
														])),
												_List_fromArray(
													[
														$elm$html$Html$text('Years')
													]));
										};
										var viewDeleteSegmentButton = A5(
											$author$project$UI$buttonWithTooltip,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('grid place-items-center w-10 border border-white rounded'),
													$elm$html$Html$Attributes$title('Delete segment'),
													$elm$html$Html$Events$onClick(
													A2(
														$author$project$TimelineEditor$SegmentDelete,
														{E: index},
														segment)),
													$elm$html$Html$Attributes$tabindex(-1)
												]),
											'timeline-delete-btn',
											3,
											'Delete Segment',
											_List_fromArray(
												[
													A2($author$project$Icon$view, 4, _List_Nil)
												]));
										var viewCutSegmentButton = A5(
											$author$project$UI$buttonWithTooltip,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('grid place-items-center w-10 border border-white rounded'),
													$elm$html$Html$Events$onClick(
													A2(
														$author$project$TimelineEditor$SegmentSplit,
														{E: index},
														segment)),
													$elm$html$Html$Attributes$tabindex(-1)
												]),
											'timeline-split-btn',
											3,
											'Split segment',
											_List_fromArray(
												[
													A2($author$project$Icon$view, 3, _List_Nil)
												]));
										var viewBudgetSelect_ = function (_v7) {
											var width = _v7.aH;
											var maxWidth = _v7.bS;
											return A5(
												$author$project$UI$selectWithTooltip,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-initial text-black'),
														A2(
														$elm$html$Html$Attributes$style,
														'max-width',
														A2($elm$core$Maybe$withDefault, '180px', maxWidth)),
														A2(
														$elm$html$Html$Attributes$style,
														'width',
														A2($elm$core$Maybe$withDefault, '180px', width)),
														$elm$html$Html$Attributes$name('budget-opt' + segment.eI),
														$elm$html$Html$Events$onInput(
														function (rawVal) {
															var _v6 = $danyx23$elm_uuid$Uuid$fromString(rawVal);
															if (!_v6.$) {
																var budgetId = _v6.a;
																return $author$project$TimelineEditor$SegmentChangeBudget(
																	{ac: budgetId, E: index});
															} else {
																return $author$project$TimelineEditor$ResizeEnd;
															}
														}),
														$elm$html$Html$Attributes$tabindex(-1)
													]),
												'budget-select',
												1,
												'Change budget for this chapter',
												A2(
													$elm$core$List$map,
													function (b) {
														return A2(
															$elm$html$Html$option,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$value(
																	$danyx23$elm_uuid$Uuid$toString(b.c1)),
																	$elm$html$Html$Attributes$class('text-black'),
																	$elm$html$Html$Attributes$selected(
																	_Utils_eq(
																		$danyx23$elm_uuid$Uuid$toString(segment.ac),
																		$danyx23$elm_uuid$Uuid$toString(b.c1)))
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(b.eI)
																]));
													},
													$author$project$UuidDict$values(budgets)));
										};
										var viewBudgetSelect = viewBudgetSelect_(
											{bS: $elm$core$Maybe$Nothing, aH: $elm$core$Maybe$Nothing});
										var strategies = $author$project$UuidDict$values(investmentStrategys);
										var viewInvestSelect = $elm$core$List$isEmpty(strategies) ? $elm$html$Html$text('') : A2(
											$elm$html$Html$select,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-black'),
													$elm$html$Html$Attributes$name('investmentStrategy-opt' + segment.eI),
													$elm$html$Html$Events$onInput(
													function (rawVal) {
														var _v5 = $danyx23$elm_uuid$Uuid$fromString(rawVal);
														if (!_v5.$) {
															var investmentStrategyId = _v5.a;
															return $author$project$TimelineEditor$SegmentChangeInvestmentStrategy(
																{e: investmentStrategyId, E: index});
														} else {
															return $author$project$TimelineEditor$ResizeEnd;
														}
													}),
													$elm$html$Html$Attributes$title('Change investmentStrategy for this chapter'),
													$elm$html$Html$Attributes$tabindex(-1)
												]),
											A2(
												$elm$core$List$map,
												function (b) {
													return A2(
														$elm$html$Html$option,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$value(
																$danyx23$elm_uuid$Uuid$toString(b.c1)),
																$elm$html$Html$Attributes$class('text-black'),
																$elm$html$Html$Attributes$selected(
																_Utils_eq(
																	$danyx23$elm_uuid$Uuid$toString(segment.e),
																	$danyx23$elm_uuid$Uuid$toString(b.c1)))
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(b.eI)
															]));
												},
												strategies));
										var viewTitleBody = function (moreAttrs) {
											return _List_fromArray(
												[
													viewBudgetSelect,
													viewInvestSelect,
													viewDurationYearsInput,
													viewDragger(moreAttrs),
													viewCutSegmentButton,
													viewDeleteSegmentButton
												]);
										};
										var viewTitle = function (moreAttrs) {
											var showShortMenu = A2($elm$core$Set$member, index, visibleShortMenus);
											var shortMenu = A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('absolute top-5 right-0 bg-gray-500 z-40 shadow-lg p-2 space-y-2'),
														A2($elm$html$Html$Attributes$style, 'width', '150px')
													]),
												_List_fromArray(
													[
														viewBudgetSelect_(
														{
															bS: $elm$core$Maybe$Just('90%'),
															aH: $elm$core$Maybe$Just('90%')
														}),
														viewInvestSelect,
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex flex-row space-x-2')
															]),
														_List_fromArray(
															[
																viewDurationYearsInput,
																viewDragger(moreAttrs)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex flex-row')
															]),
														_List_fromArray(
															[
																viewCutSegmentButton,
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-grow')
																	]),
																_List_Nil),
																viewDeleteSegmentButton
															]))
													]));
											var settingsButton = A5(
												$author$project$UI$buttonWithTooltip,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('grid place-items-center w-10 border border-white rounded'),
														$elm$html$Html$Events$onClick(
														$author$project$TimelineEditor$ToggleShortMenuVisibility(
															{E: index})),
														$elm$html$Html$Attributes$tabindex(-1)
													]),
												'timeline-chapter-settings',
												3,
												'Timeline Chapter Settings',
												_List_fromArray(
													[
														A2(
														$author$project$Icon$view,
														showShortMenu ? 5 : 9,
														_List_Nil)
													]));
											var segmentWidth = A2(
												$author$project$TimelineEditor$getSegmentWidth,
												{aT: scalingFactors.aH},
												segment);
											return (segmentWidth < 500) ? A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('z-20 flex flex-row sticky top-0 p-1 space-x-2 select-none text-sm'),
														A2(
														$elm$html$Html$Attributes$style,
														'background-color',
														$avh4$elm_color$Color$toCssString(color)),
														$elm$html$Html$Attributes$title('Drag to reorder chapters')
													]),
												$elm$core$List$concat(
													_List_fromArray(
														[
															(segmentWidth > 220) ? _List_fromArray(
															[viewBudgetSelect]) : _List_Nil,
															_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_Utils_ap(
																	moreAttrs,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('flex-grow'),
																			A2($elm$html$Html$Attributes$style, 'cursor', 'ew-resize')
																		])),
																_List_Nil),
																settingsButton,
																showShortMenu ? shortMenu : $elm$html$Html$text('')
															])
														]))) : A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('z-30 flex flex-row sticky top-0 p-1 space-x-2 select-none text-sm'),
														A2(
														$elm$html$Html$Attributes$style,
														'background-color',
														$avh4$elm_color$Color$toCssString(color)),
														$elm$html$Html$Attributes$title('Drag to reorder chapters')
													]),
												viewTitleBody(moreAttrs));
										};
										var segmentId = A2(
											$elm$core$String$join,
											'-',
											_List_fromArray(
												[
													idPrefix,
													'hrdrag',
													$danyx23$elm_uuid$Uuid$toString(timelineId),
													$elm$core$String$fromInt(index)
												]));
										var getBudgetDesc = function (budgetId) {
											return A2(
												$elm$core$Maybe$withDefault,
												'',
												A2(
													$elm$core$Maybe$map,
													function ($) {
														return $.eI;
													},
													A2($author$project$UuidDict$get, budgetId, budgets)));
										};
										var attrs = A2(
											$elm$core$List$cons,
											$elm$html$Html$Attributes$id(segmentId),
											_Utils_ap(
												A3(
													$author$project$TimelineEditor$segmentStyles,
													{aT: scalingFactors.aH},
													{
														aM: !_Utils_eq(segmentResizing, $elm$core$Maybe$Nothing)
													},
													segment),
												A2($elm$core$List$member, index, affected) ? $author$project$TimelineEditor$affectedStyles : _List_Nil));
										var _v4 = $author$project$TimelineEditor$dndSystem.c3(dnd);
										if (!_v4.$) {
											var dragIndex = _v4.a.cL;
											return (!_Utils_eq(dragIndex, index)) ? withResizeDragger(
												A2(
													$elm$html$Html$div,
													_Utils_ap(
														attrs,
														A2($author$project$TimelineEditor$dndSystem.eL, index, segmentId)),
													_List_fromArray(
														[
															viewTitle(_List_Nil),
															A6(
															$author$project$TimelineEditor$budgetView,
															scalingFactors,
															color,
															{am: mortgageHovered},
															budgets,
															{aU: segmentId},
															segment)
														]))) : withResizeDragger(
												A2(
													$elm$html$Html$div,
													_Utils_ap(attrs, $author$project$TimelineEditor$placeholderStyles),
													_List_fromArray(
														[
															viewTitle(_List_Nil),
															A6(
															$author$project$TimelineEditor$budgetView,
															scalingFactors,
															color,
															{am: mortgageHovered},
															budgets,
															{aU: segmentId},
															segment)
														])));
										} else {
											return withResizeDragger(
												A2(
													$elm$html$Html$div,
													A2(
														$elm$core$List$cons,
														$elm$html$Html$Attributes$class('sticky top-0'),
														attrs),
													_List_fromArray(
														[
															viewTitle(
															A2($author$project$TimelineEditor$dndSystem.eK, index, segmentId)),
															A6(
															$author$project$TimelineEditor$budgetView,
															scalingFactors,
															color,
															{am: mortgageHovered},
															budgets,
															{aU: segmentId},
															segment)
														])));
										}
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$TimelineEditor$viewTimelines = F7(
	function (idPrefix, scalingFactors, color, model, timeline, budgets, investmentStrategys) {
		return A2(
			$elm$html$Html$div,
			A3($author$project$TimelineEditor$containerStyles, scalingFactors, budgets, timeline.dZ),
			A2(
				$elm$core$List$indexedMap,
				A8(
					$author$project$TimelineEditor$segmentView,
					idPrefix,
					scalingFactors,
					{
						aM: !_Utils_eq(model.W, $elm$core$Maybe$Nothing)
					},
					color,
					budgets,
					investmentStrategys,
					model,
					{d8: timeline.c1}),
				timeline.dZ));
	});
var $author$project$TimelineEditor$view = F7(
	function (idPrefix, scalingFactors, color, model, timeline, budgets, investmentStrategys) {
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Events$onMouseDown($author$project$TimelineEditor$ClearAffected)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sticky pb-1'),
							A2($elm$html$Html$Attributes$style, 'width', '500px')
						]),
					_List_fromArray(
						[
							A2($author$project$UI$clearTextInput, timeline.eI, $author$project$TimelineEditor$EditTimelineDescription)
						])),
					A7($author$project$TimelineEditor$viewTimelines, idPrefix, scalingFactors, color, model, timeline, budgets, investmentStrategys),
					A5($author$project$TimelineEditor$ghostView, scalingFactors, color, model.ai, budgets, timeline.dZ)
				]));
	});
var $author$project$App$viewTimelines = F2(
	function (colors, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'overflow-x', 'scroll'),
					A2($elm$html$Html$Attributes$style, 'overflow-y', 'scroll'),
					$elm$html$Html$Attributes$class('space-y-5 w-full')
				]),
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (i, _v0) {
						var color = _v0.a;
						var _v1 = _v0.b;
						var timeline = _v1.a;
						var timelineEditor = _v1.b;
						var buttonStyles = function (attrs) {
							return _Utils_ap(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class(' grid place-items-center rounded-md border-2 border-white border-solid w-24 h-8 bg-black bg-opacity-50')
									]),
								attrs);
						};
						var timelineButtons = A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-row sticky z-10 space-x-4 mt-2'),
									A2($elm$html$Html$Attributes$style, 'width', '300px'),
									A2($elm$html$Html$Attributes$style, 'left', '50px'),
									A2($elm$html$Html$Attributes$style, 'bottom', '0px')
								]),
							_List_fromArray(
								[
									A5(
									$author$project$UI$buttonWithTooltip,
									buttonStyles(
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												$author$project$App$WithTime(
													$author$project$App$CloneTimelineRequest(
														{r: i})))
											])),
									'clone-timeline-btn',
									2,
									'Clone Timeline',
									_List_fromArray(
										[
											A2(
											$author$project$Icon$view,
											2,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$style, 'color', 'white')
												]))
										])),
									($elm$core$Array$length(model.g) === 1) ? $elm$html$Html$text('') : A5(
									$author$project$UI$buttonWithTooltip,
									buttonStyles(
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												$author$project$App$DeleteTimeline(
													{r: i})),
												$elm$html$Html$Attributes$title('Delete timeline')
											])),
									'delete-timeline-btn',
									2,
									'Delete timeline',
									_List_fromArray(
										[
											A2(
											$author$project$Icon$view,
											4,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$style, 'color', 'white')
												]))
										])),
									A5(
									$author$project$UI$buttonWithTooltip,
									buttonStyles(
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												$author$project$App$SetPage(
													$author$project$App$PageTimelineDetail(
														{r: i})))
											])),
									'open-asset-breakdown-chart-btn',
									2,
									'Open asset breakdown chart',
									_List_fromArray(
										[
											A2(
											$author$project$Icon$view,
											6,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$style, 'color', 'white')
												]))
										]))
								]));
						var viewTimeline = A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id(
									$author$project$App$generateTimelineDomId(i)),
									$elm$html$Html$Attributes$class('w-full')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$map,
									$author$project$App$TimelineEditorMsg(i),
									A7(
										$author$project$TimelineEditor$view,
										{e3: ''},
										{ax: model.d.a1, aH: model.d.bo},
										color,
										timelineEditor,
										timeline,
										A2(
											$author$project$UuidDict$map,
											F2(
												function (_v3, v) {
													return v.a;
												}),
											model.n),
										model.z)),
									function () {
									var _v4 = model.A;
									if (_v4.$ === 1) {
										var timelineIdx = _v4.a.r;
										return $elm$html$Html$text('');
									} else {
										return timelineButtons;
									}
								}()
								]));
						var _v2 = model.A;
						if (_v2.$ === 1) {
							var timelineIdx = _v2.a.r;
							return (!_Utils_eq(i, timelineIdx)) ? $elm$html$Html$text('') : viewTimeline;
						} else {
							return viewTimeline;
						}
					}),
				A2(
					$elm_community$list_extra$List$Extra$zip,
					colors,
					$elm$core$Array$toList(model.g))));
	});
var $author$project$App$ToggleUserConfigVisibility = {$: 12};
var $author$project$App$UserConfigMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Icon$Columns = 11;
var $author$project$Icon$CreditCard = 12;
var $author$project$UserConfig$EditBudgetBlockHeightScalingFactor = function (a) {
	return {$: 1, a: a};
};
var $author$project$UserConfig$EditChartHeightPercent = function (a) {
	return {$: 2, a: a};
};
var $author$project$UserConfig$EditTimelineScalingFactor = function (a) {
	return {$: 0, a: a};
};
var $author$project$Icon$LineChart = 13;
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $author$project$UserConfig$view = function (userConfig) {
	var inputRangeWidth = A2($elm$html$Html$Attributes$style, 'width', '125px');
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex flex-row text-left font-bold'),
				A2($elm$html$Html$Attributes$style, 'overflow-y', 'hidden'),
				A2($elm$html$Html$Attributes$style, 'background', 'white ')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-row space-x-3')
					]),
				_List_fromArray(
					[
						A6(
						$author$project$UI$withTooltip_,
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex flex-row space-x-2')
							]),
						'settings-timeline-height',
						3,
						'Timeline Width',
						_List_fromArray(
							[
								A2($author$project$Icon$view, 11, _List_Nil),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(
										A2($myrho$elm_round$Round$round, 2, userConfig.bo)),
										$elm$html$Html$Attributes$type_('range'),
										inputRangeWidth,
										$elm$html$Html$Attributes$min('0.1'),
										$elm$html$Html$Attributes$max(
										$elm$core$String$fromFloat($author$project$UserConfig$budgetBlockWidthFactorMax)),
										$elm$html$Html$Attributes$step('0.1'),
										$elm_community$html_extra$Html$Events$Extra$onChange($author$project$UserConfig$EditTimelineScalingFactor)
									]),
								_List_Nil)
							])),
						A6(
						$author$project$UI$withTooltip_,
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex flex-row space-x-2')
							]),
						'settings-chapter-height-slider',
						3,
						'Chapter Height',
						_List_fromArray(
							[
								A2($author$project$Icon$view, 12, _List_Nil),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(
										A2(
											$myrho$elm_round$Round$round,
											2,
											A2($elm$core$Maybe$withDefault, $author$project$UserConfig$const_TIMELINE_HEIGHT_MIN, userConfig.a1))),
										$elm$html$Html$Attributes$type_('range'),
										inputRangeWidth,
										$elm$html$Html$Attributes$min(
										$elm$core$String$fromFloat($author$project$UserConfig$const_TIMELINE_HEIGHT_MIN)),
										$elm$html$Html$Attributes$max(
										$elm$core$String$fromFloat($author$project$UserConfig$budgetBlockHeightFactorMax)),
										$elm$html$Html$Attributes$step('0.1'),
										$elm_community$html_extra$Html$Events$Extra$onChange($author$project$UserConfig$EditBudgetBlockHeightScalingFactor)
									]),
								_List_Nil)
							])),
						A6(
						$author$project$UI$withTooltip_,
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex flex-row space-x-2')
							]),
						'settings-chart-height',
						3,
						'Chart Height',
						_List_fromArray(
							[
								A2($author$project$Icon$view, 13, _List_Nil),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(
										A2($myrho$elm_round$Round$round, 2, userConfig.bx)),
										$elm$html$Html$Attributes$type_('range'),
										inputRangeWidth,
										$elm$html$Html$Attributes$min(
										$elm$core$String$fromFloat($author$project$UserConfig$chartHeightPercentMin)),
										$elm$html$Html$Attributes$max(
										$elm$core$String$fromFloat(1)),
										$elm$html$Html$Attributes$step('0.1'),
										$elm_community$html_extra$Html$Events$Extra$onChange($author$project$UserConfig$EditChartHeightPercent)
									]),
								_List_Nil)
							]))
					]))
			]));
};
var $author$project$App$viewUserConfigDrawer = F2(
	function (viewport, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fixed shadow-xl flex flex-row'),
					A2($elm$html$Html$Attributes$style, 'left', '150px'),
					A2($elm$html$Html$Attributes$style, 'top', '0px'),
					A2($elm$html$Html$Attributes$style, 'height', '35px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class($author$project$Style$bar + 'rounded-bl-md'),
							$elm$html$Html$Events$onClick($author$project$App$ToggleUserConfigVisibility),
							A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Settings')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'transition', 'width 1s'),
							A2($elm$html$Html$Attributes$style, 'max-height', '35px'),
							A2($elm$html$Html$Attributes$style, 'white-space', 'nowrap'),
							A2($elm$html$Html$Attributes$style, 'overflow', 'hidden'),
							A2(
							$elm$html$Html$Attributes$style,
							'width',
							model.Y ? '500px' : '0px'),
							$elm$html$Html$Attributes$class(
							model.Y ? 'bg-white p-2' : 'bg-white')
						]),
					model.Y ? _List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$App$UserConfigMsg,
							$author$project$UserConfig$view(model.d))
						]) : _List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class($author$project$Style$bar + 'rounded-br-md'),
							$elm$html$Html$Events$onClick($author$project$App$ToggleUserConfigVisibility),
							A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Icon$view,
									1,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'transition', 'transform 1s'),
											model.Y ? A2($elm$html$Html$Attributes$style, 'transform', 'rotate(90deg)') : A2($elm$html$Html$Attributes$style, 'transform', 'rotate(270deg)')
										]))
								]))
						]))
				]));
	});
var $author$project$App$view = F2(
	function (viewport, model) {
		var showSchedule = _Utils_eq(
			model.t,
			$elm$core$Result$Ok(2)) || _Utils_eq(
			model.t,
			$elm$core$Result$Err(2));
		var pageBackButton = A2(
			$author$project$UI$pageBackButton,
			$author$project$App$SetPage($author$project$App$PageTimelines),
			_List_fromArray(
				[
					A2($author$project$Icon$view, 7, _List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('py-2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Back')
						]))
				]));
		var colors = A2(
			$elm_community$list_extra$List$Extra$cycle,
			$elm$core$Array$length(model.g),
			$author$project$App$allColors);
		var budgetsWithoutEditors = A2(
			$author$project$UuidDict$map,
			F2(
				function (_v9, v) {
					return v.a;
				}),
			model.n);
		var timelineBudgets = function (_v8) {
			var timelineIdx = _v8.r;
			return A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Maybe$map,
					function (ids) {
						return A2(
							$elm$core$List$filterMap,
							function (_v7) {
								var duration = _v7.a;
								var id = _v7.b;
								return A2(
									$elm$core$Maybe$map,
									function (b) {
										return {cx: b, aL: duration};
									},
									A2($author$project$UuidDict$get, id, budgetsWithoutEditors));
							},
							ids);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$List$map(
							function (s) {
								return _Utils_Tuple2(s.aL, s.ac);
							}),
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Tuple$first,
								function ($) {
									return $.dZ;
								}),
							A2($elm$core$Array$get, timelineIdx, model.g)))));
		};
		var viewAllTheYears = function () {
			var _v6 = model.A;
			if (_v6.$ === 1) {
				var timelineIdx = _v6.a;
				var totalYears = $elm$core$List$sum(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.aL;
						},
						timelineBudgets(timelineIdx)));
				return A3(
					$author$project$LifeCalendar$view,
					viewport,
					model.C,
					timelineBudgets(timelineIdx));
			} else {
				return $elm$html$Html$text('');
			}
		}();
		var viewWeekBreakdown = function () {
			var _v4 = model.A;
			if (!_v4.$) {
				return $elm$html$Html$text('');
			} else {
				var timelineIdx = _v4.a;
				var totalYears = $elm$core$List$sum(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.aL;
						},
						timelineBudgets(timelineIdx)));
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							'flex flex-row w-full items-center my-10 space-x-2 ' + ($author$project$Responsive$biggerThanIpad(viewport) ? 'px-32' : 'px-20'))
						]),
					A2(
						$elm$core$List$cons,
						pageBackButton,
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, _v5) {
									var durationYears = _v5.aL;
									var budget = _v5.cx;
									return A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$id(
												$author$project$App$generateTimelineDomId(i)),
												A2(
												$elm$html$Html$Attributes$style,
												'width',
												A2(
													$elm$core$String$join,
													'',
													_List_fromArray(
														[
															$elm$core$String$fromInt(
															$elm$core$Basics$round((durationYears / totalYears) * 100)),
															'%'
														])))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('text-white')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(budget.eI)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('text-white')
													]),
												_List_fromArray(
													[
														A3($author$project$WeekEditor$viewReadonly, budget, model.C, $author$project$BudgetBlock$viewStripey)
													]))
											]));
								}),
							timelineBudgets(timelineIdx))));
			}
		}();
		var viewProjection = A2(
			$elm$html$Html$div,
			function () {
				var _v2 = model.A;
				if (_v2.$ === 1) {
					var timelineIdx = _v2.a.r;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-full bg-white rounded-lg shadow-xl border-4 border-gray-300 opacity-95 mb-5'),
							$elm$html$Html$Attributes$id('breakdown')
						]);
				} else {
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$Attributes$style,
							'height',
							function (i) {
								return i + 'vh';
							}(
								$elm$core$String$fromInt(
									$elm$core$Basics$round(model.d.bx * 100)))),
							$elm$html$Html$Attributes$class(
							'w-full bg-white rounded-lg shadow-xl border-4 border-gray-300 opacity-95 ' + ($author$project$Responsive$biggerThanIpad(viewport) ? 'mb-5' : 'mb-2'))
						]);
				}
			}(),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$map,
					$author$project$App$ProjectionMsg,
					function () {
						var _v3 = model.A;
						if (_v3.$ === 1) {
							var timelineIdx = _v3.a.r;
							return A6(
								$author$project$Projection$viewBreakdown,
								{
									cZ: $elm$core$Basics$round(viewport.br.ax * 0.8),
									an: model.d.an,
									d1: model.d.b_,
									d3: 0,
									ei: $elm$core$Basics$round(viewport.br.aH - 100)
								},
								$author$project$App$allColors,
								budgetsWithoutEditors,
								model.z,
								A2(
									$elm$core$Maybe$withDefault,
									_List_Nil,
									A2(
										$elm$core$Maybe$map,
										A2(
											$elm$core$Basics$composeR,
											$elm$core$Tuple$first,
											function ($) {
												return $.dZ;
											}),
										A2($elm$core$Array$get, timelineIdx, model.g))),
								model.aB);
						} else {
							return A6(
								$author$project$Projection$view,
								{
									cZ: $elm$core$Basics$round(viewport.br.ax * (model.d.bx - 0.02)),
									an: model.d.an,
									d1: model.d.b_,
									d3: 0,
									ei: $elm$core$Basics$round(viewport.br.aH - 100)
								},
								colors,
								budgetsWithoutEditors,
								model.z,
								A2(
									$elm$core$List$map,
									$elm$core$Tuple$first,
									$elm$core$Array$toList(model.g)),
								model.aB);
						}
					}())
				]));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					A2(
						$elm$core$String$join,
						' ',
						_List_fromArray(
							[
								function () {
								var _v0 = model.A;
								if (_v0.$ === 1) {
									var timelineIdx = _v0.a;
									return '';
								} else {
									return 'overflow-hidden';
								}
							}(),
								'text-sm p-5'
							]))),
					A2($elm$html$Html$Attributes$style, 'background-image', 'url(./topGrayBlurCircleDark.jpg)'),
					A2($elm$html$Html$Attributes$style, 'background-repeat', 'repeat'),
					A2($elm$html$Html$Attributes$style, 'min-height', '100vh')
				]),
			_List_fromArray(
				[
					model.d.b_ ? viewAllTheYears : $elm$html$Html$text(''),
					showSchedule ? $elm$html$Html$text('') : viewProjection,
					viewWeekBreakdown,
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							'flex flex-row ' + ($author$project$Responsive$biggerThanIpad(viewport) ? 'space-x' : '')),
							A2($elm$html$Html$Attributes$style, 'transition', 'height 1s'),
							A2(
							$elm$html$Html$Attributes$style,
							'height',
							function () {
								if (showSchedule) {
									return '97vh';
								} else {
									var _v1 = model.A;
									if (_v1.$ === 1) {
										var timelineIdx = _v1.a.r;
										return '100vh';
									} else {
										return $author$project$Responsive$biggerThanIpad(viewport) ? function (i) {
											return i + 'vh';
										}(
											$elm$core$String$fromInt(
												function (i) {
													return A2($elm$core$Basics$max, 65, i);
												}(
													function (i) {
														return (100 - 3) - i;
													}(
														$elm$core$Basics$round(model.d.bx * 100))))) : '77vh';
									}
								}
							}())
						]),
					_List_fromArray(
						[
							showSchedule ? A2($author$project$App$viewSchedules, $author$project$App$allColors, model) : A2($author$project$App$viewTimelines, colors, model),
							A2($author$project$App$viewDock, viewport, model)
						])),
					A2($author$project$App$viewUserConfigDrawer, viewport, model),
					A2($author$project$App$viewAuth, viewport, model)
				]));
	});
var $author$project$Responsive$isMobile = function (viewport) {
	return viewport.br.aH < 500;
};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Landing$viewCTA = function (h) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container mx-auto text-center py-6 mb-12')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('w-full my-2 text-5xl font-bold leading-tight text-center text-white')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Want to see it all together?')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('w-full mb-4')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$h3,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('my-4 text-3xl leading-tight')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('You can always come back here if it\'s all too confusing.')
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out'),
						$elm$html$Html$Events$onClick(h.bF)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Take me to the App!')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('my-4 text-m leading-tight')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('No signup required, Nothing is saved until you create an account.')
					]))
			]));
};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$autoplay = $elm$html$Html$Attributes$boolProperty('autoplay');
var $elm$html$Html$Attributes$controls = $elm$html$Html$Attributes$boolProperty('controls');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$Attributes$loop = $elm$html$Html$Attributes$boolProperty('loop');
var $elm$html$Html$source = _VirtualDom_node('source');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$html$Html$video = _VirtualDom_node('video');
var $author$project$Landing$viewCards = A2(
	$elm$html$Html$section,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('py-8')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container mx-auto flex flex-wrap pt-4 pb-12')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-full my-2 text-5xl font-bold leading-tight text-center text-white')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Other features')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-full mb-4')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-1 p-12 bg-white rounded-t rounded-b-none overflow-hidden shadow')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex flex-wrap no-underline hover:no-underline'),
											$elm$html$Html$Attributes$href('#')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-full font-bold text-xl text-gray-800 px-6')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Mortgage modelling')
												])),
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-gray-800 text-base px-6 mb-5')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('See how much interest you are paying to the bank over time.')
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex items-center justify-center')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$video,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('video_demo_manual_autoplay_in_js_2'),
									A2($elm$html$Html$Attributes$attribute, 'muted', ''),
									$elm$html$Html$Attributes$loop(true),
									$elm$html$Html$Attributes$autoplay(true),
									$elm$html$Html$Attributes$controls(true),
									$elm$html$Html$Attributes$class('p-1 rounded-xl shadow-xl my-4 bg-white')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$source,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src('./mortgage.mp4'),
											$elm$html$Html$Attributes$type_('video/mp4')
										]),
									_List_Nil)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$video,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('video_demo_manual_autoplay_in_js_1'),
									A2($elm$html$Html$Attributes$attribute, 'muted', ''),
									$elm$html$Html$Attributes$loop(true),
									$elm$html$Html$Attributes$autoplay(true),
									$elm$html$Html$Attributes$controls(true),
									$elm$html$Html$Attributes$class('p-1 rounded-xl shadow-xl my-4 bg-white')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$source,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src('./schedule-2.mp4#t=53'),
											$elm$html$Html$Attributes$type_('video/mp4')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-1 p-12 bg-white rounded-t rounded-b-none overflow-hidden shadow')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex flex-wrap no-underline hover:no-underline'),
											$elm$html$Html$Attributes$href('#')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-full font-bold text-xl text-gray-800 px-6')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Take control of your time.')
												])),
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-gray-800 text-base px-6 mb-5')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Paint what each of your budgets costs you in terms of time over each week.')
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex items-center justify-end')
										]),
									_List_Nil)
								]))
						]))
				]))
		]));
var $elm$html$Html$img = _VirtualDom_node('img');
var $author$project$Util$nbsp = '\u00A0';
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$Tutorial$viewBudget = function (model) {
	return A2(
		$elm$html$Html$map,
		$author$project$Tutorial$BudgetEditorMsg,
		$author$project$BudgetEditor$view(
			A2(
				$author$project$UuidDict$map,
				F2(
					function (_v0, v) {
						return v.a;
					}),
				model.n)));
};
var $author$project$Tutorial$allColors = _List_fromArray(
	[$terezka$line_charts$LineChart$Colors$purple, $terezka$line_charts$LineChart$Colors$green, $terezka$line_charts$LineChart$Colors$red, $terezka$line_charts$LineChart$Colors$blue, $terezka$line_charts$LineChart$Colors$gold, $terezka$line_charts$LineChart$Colors$rust, $terezka$line_charts$LineChart$Colors$pink, $terezka$line_charts$LineChart$Colors$teal]);
var $author$project$Tutorial$viewProjectionOnly = F2(
	function (viewport, model) {
		var colors = A2(
			$elm_community$list_extra$List$Extra$cycle,
			$elm$core$Array$length(model.g),
			$author$project$Tutorial$allColors);
		var budgetsWithoutEditors = A2(
			$author$project$UuidDict$map,
			F2(
				function (_v2, v) {
					return v.a;
				}),
			model.n);
		var timelineBudgets = function (_v1) {
			var timelineIdx = _v1.r;
			return A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Maybe$map,
					function (ids) {
						return A2(
							$elm$core$List$filterMap,
							function (_v0) {
								var duration = _v0.a;
								var id = _v0.b;
								return A2(
									$elm$core$Maybe$map,
									function (b) {
										return _Utils_Tuple2(duration, b);
									},
									A2($author$project$UuidDict$get, id, budgetsWithoutEditors));
							},
							ids);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$List$map(
							function (s) {
								return _Utils_Tuple2(s.aL, s.ac);
							}),
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Tuple$first,
								function ($) {
									return $.dZ;
								}),
							A2($elm$core$Array$get, timelineIdx, model.g)))));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('w-full bg-white rounded-lg shadow-xl border-4 border-gray-300')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$map,
					$author$project$Tutorial$ProjectionMsg,
					A6(
						$author$project$Projection$view,
						{
							cZ: $elm$core$Basics$round(viewport.br.ax / 4),
							an: 50000,
							d1: true,
							d3: 0,
							ei: 1000
						},
						colors,
						budgetsWithoutEditors,
						model.z,
						A2(
							$elm$core$List$map,
							$elm$core$Tuple$first,
							$elm$core$Array$toList(model.g)),
						model.aB))
				]));
	});
var $author$project$Landing$viewSeperator = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('w-full m-16'),
			A2($elm$html$Html$Attributes$style, 'margin-bottom', '10vh'),
			A2($elm$html$Html$Attributes$style, 'margin-top', '10vh')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t')
				]),
			_List_Nil)
		]));
var $author$project$Tutorial$generateTimelineDomId = function (idx) {
	return A2(
		$elm$core$String$join,
		'-',
		_List_fromArray(
			[
				'timeline',
				$elm$core$String$fromInt(idx)
			]));
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $author$project$Tutorial$CloneTimelineRequest = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Tutorial$DeleteTimeline = function (a) {
	return {$: 7, a: a};
};
var $author$project$Tutorial$WithTime = function (a) {
	return {$: 8, a: a};
};
var $elm_community$html_extra$Html$Extra$nothing = $elm$html$Html$text('');
var $elm_community$html_extra$Html$Extra$viewIf = F2(
	function (condition, html) {
		return condition ? html : $elm_community$html_extra$Html$Extra$nothing;
	});
var $author$project$Tutorial$viewTimelines = F4(
	function (_v0, idGenerator, colors, model) {
		var withButtons = _v0.cg;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'overflow-x', 'scroll'),
					A2($elm$html$Html$Attributes$style, 'overflow-y', 'scroll'),
					$elm$html$Html$Attributes$class('space-y-5 w-full')
				]),
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (i, _v1) {
						var color = _v1.a;
						var _v2 = _v1.b;
						var timeline = _v2.a;
						var timelineEditor = _v2.b;
						var timelinesViewFunc = withButtons ? $author$project$TimelineEditor$view : $author$project$TimelineEditor$viewTimelines;
						var buttonStyles = function (attrs) {
							return _Utils_ap(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class(' grid place-items-center rounded-md border-2 border-white border-solid w-24 h-8 bg-black bg-opacity-50')
									]),
								attrs);
						};
						var timelineButtons = A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-row sticky z-10 space-x-4'),
									A2($elm$html$Html$Attributes$style, 'width', '300px'),
									A2($elm$html$Html$Attributes$style, 'left', '50px'),
									A2($elm$html$Html$Attributes$style, 'bottom', '0px'),
									A2($elm$html$Html$Attributes$style, 'overflow', 'auto')
								]),
							_List_fromArray(
								[
									A5(
									$author$project$UI$buttonWithTooltip,
									buttonStyles(
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												$author$project$Tutorial$WithTime(
													$author$project$Tutorial$CloneTimelineRequest(
														{r: i})))
											])),
									'tutorial-clone-timeline-btn',
									2,
									'Clone Timeline',
									_List_fromArray(
										[
											A2(
											$author$project$Icon$view,
											2,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$style, 'color', 'white')
												]))
										])),
									($elm$core$Array$length(model.g) === 1) ? $elm$html$Html$text('') : A5(
									$author$project$UI$buttonWithTooltip,
									buttonStyles(
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												$author$project$Tutorial$DeleteTimeline(
													{r: i}))
											])),
									'tutorial-delete-timeline-btn',
									2,
									'Delete Timeline',
									_List_fromArray(
										[
											A2(
											$author$project$Icon$view,
											4,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$style, 'color', 'white')
												]))
										]))
								]));
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id(
									idGenerator(i)),
									$elm$html$Html$Attributes$class('w-full text-black')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$map,
									$author$project$Tutorial$TimelineEditorMsg(i),
									A7(
										timelinesViewFunc,
										{
											e3: idGenerator(i)
										},
										{
											ax: $elm$core$Maybe$Just(0.4),
											aH: 0.19
										},
										color,
										timelineEditor,
										timeline,
										A2(
											$author$project$UuidDict$map,
											F2(
												function (_v3, v) {
													return v.a;
												}),
											model.n),
										$author$project$UuidDict$empty)),
									A2($elm_community$html_extra$Html$Extra$viewIf, withButtons, timelineButtons)
								]));
					}),
				A2(
					$elm_community$list_extra$List$Extra$zip,
					colors,
					$elm$core$Array$toList(model.g))));
	});
var $author$project$Tutorial$viewTimelinesOnly_ = F2(
	function (_v0, model) {
		var viewSingleOnly = _v0.ga;
		var modelWithTimeline = viewSingleOnly ? _Utils_update(
			model,
			{
				g: A2(
					$elm$core$Maybe$withDefault,
					model.g,
					A2(
						$elm$core$Maybe$map,
						A2($elm$core$Basics$composeR, $elm$core$List$singleton, $elm$core$Array$fromList),
						A2($elm$core$Array$get, 0, model.g)))
			}) : model;
		var colors = A2(
			$elm_community$list_extra$List$Extra$cycle,
			$elm$core$Array$length(model.g),
			$author$project$Tutorial$allColors);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row '),
					A2($elm$html$Html$Attributes$style, 'transition', 'height 1s')
				]),
			_List_fromArray(
				[
					A4(
					$author$project$Tutorial$viewTimelines,
					{cg: true},
					$author$project$Tutorial$generateTimelineDomId,
					colors,
					modelWithTimeline)
				]));
	});
var $author$project$Tutorial$viewTimelinesOnly = $author$project$Tutorial$viewTimelinesOnly_(
	{ga: false});
var $author$project$Landing$viewFeatures = F2(
	function (viewport, model) {
		var withShadow = 'w-full sm:h-64 mx-auto shadow-xl rounded-xl';
		var viewRight = F2(
			function (viewLF, viewRF) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-wrap flex-col-reverse sm:flex-row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-full sm:w-1/2 p-6 mt-6')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('align-middle')
										]),
									viewLF)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-full sm:w-1/2 p-6 mt-6')
								]),
							viewRF)
						]));
			});
		var viewLeft = F2(
			function (viewLF, viewRF) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-wrap')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-5/6 sm:w-1/2 p-6')
								]),
							viewLF),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-full sm:w-1/2 p-6')
								]),
							viewRF)
						]));
			});
		var viewImg = function (path) {
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(''),
						$elm$html$Html$Attributes$src(path)
					]),
				_List_Nil);
		};
		var viewCopy = F2(
			function (heading, body) {
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$h3,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('text-3xl text-gray-800 font-bold leading-none mb-3')
							]),
						heading),
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('text-gray-600 mb-8')
							]),
						body)
					]);
			});
		var viewLorem = A2(
			viewCopy,
			_List_fromArray(
				[
					$elm$html$Html$text('Lorem ipsum dolor sit amet            ')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc commodo posuere et sit amet ligula.              '),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$elm$html$Html$text('              '),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$elm$html$Html$text('              Images from:              '),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-pink-500 underline'),
							$elm$html$Html$Attributes$href('https://undraw.co/')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('undraw.co')
						]))
				]));
		var tryText = function (html) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('inline-block')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('font-bold')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('👇 Play along:'),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-gray-500')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(' (the following is interactive)')
									]))
							])),
						html
					]));
		};
		var randomImg = function (_int) {
			return 'https://picsum.photos/300/200?random=' + $elm$core$String$fromInt(_int);
		};
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-black bg-white border-b py-8')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container max-w-5xl mx-auto m-8 space-y-8')
						]),
					_List_fromArray(
						[
							$author$project$Landing$viewSeperator,
							A2(
							viewRight,
							A2(
								viewCopy,
								_List_fromArray(
									[
										$elm$html$Html$text('Is your life really just a bunch of cells in a spreadsheet?')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('See your current and alternate budgets as multiple chapters and see how they span over time.'),
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										A2($elm$html$Html$br, _List_Nil, _List_Nil)
									])),
							_List_fromArray(
								[
									viewImg('./landing_park.svg')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('grid justify-items-center bg-white text-black')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('place-self-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$map,
											$elm$core$Basics$identity,
											A2($author$project$Tutorial$viewProjectionOnly, viewport, model.J))
										]))
								])),
							$author$project$Landing$viewSeperator,
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'margin-bottom', '20vh'),
									A2($elm$html$Html$Attributes$style, 'margin-top', '20vh')
								]),
							_List_fromArray(
								[
									tryText(
									A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('- Click on \"Cutting down expenses\" or \"Quit job\" to change the budget for that chapter.'),
												A2($elm$html$Html$br, _List_Nil, _List_Nil),
												A2($elm$html$Html$br, _List_Nil, _List_Nil),
												$elm$html$Html$text('- Drag the coloured to bar left or right to re-order the chapters')
											]))),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('grid justify-items-center text-black')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('place-self-center p-5')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$map,
													$elm$core$Basics$identity,
													A2(
														$author$project$Tutorial$viewTimelinesOnly_,
														{ga: true},
														model.J))
												]))
										]))
								])),
							$author$project$Landing$viewSeperator,
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									tryText(
									A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Click on the' + $author$project$Util$nbsp),
														A2($author$project$Icon$view, 2, _List_Nil),
														$elm$html$Html$text($author$project$Util$nbsp + 'icon below, this will create a clone/duplicate of the coloured timeline.')
													]))
											])))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('grid justify-items-center text-black')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('place-self-center p-5')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$map,
											$elm$core$Basics$identity,
											$author$project$Tutorial$viewTimelinesOnly(model.J))
										]))
								])),
							$author$project$Landing$viewSeperator,
							A2(
							viewRight,
							A2(
								viewCopy,
								_List_fromArray(
									[
										$elm$html$Html$text('So it\'s like a spreadsheet?')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Sort of, but more fun! Think of it as a more powerful napkin. Think freely instead of being bogged into your spreadsheet works.'),
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										tryText(
										A2(
											$elm$html$Html$div,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('- Click on any of the black boxes to edit its formula.')
														]))
												]))),
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class(withShadow + 'bg-black text-black'),
												A2($elm$html$Html$Attributes$style, 'width', '450px'),
												A2($elm$html$Html$Attributes$style, 'height', '500px'),
												A2($elm$html$Html$Attributes$style, 'overflow-y', 'scroll')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$map,
												$elm$core$Basics$identity,
												$author$project$Tutorial$viewTimelinesOnly(model.J))
											]))
									])),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class(withShadow + 'bg-black text-black'),
											A2($elm$html$Html$Attributes$style, 'width', '450px'),
											A2($elm$html$Html$Attributes$style, 'height', '800px')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$map,
											$elm$core$Basics$identity,
											$author$project$Tutorial$viewBudget(model.J))
										]))
								]))
						]))
				]));
	});
var $author$project$Landing$viewHero = function (viewport) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pt-24')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('uppercase tracking-loose w-full')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('')
									])),
								A2(
								$elm$html$Html$h1,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('my-4 text-5xl font-bold leading-tight')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Life is messy')
									])),
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('leading-normal text-2xl mb-8')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Is your spreadsheet flexible enough to keep up?')
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('w-full md:w-3/5 py-6 text-center')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$video,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$id('video_demo_manual_autoplay_in_js'),
										A2($elm$html$Html$Attributes$attribute, 'muted', ''),
										$elm$html$Html$Attributes$loop(true),
										$elm$html$Html$Attributes$autoplay(true),
										$elm$html$Html$Attributes$controls(true),
										$elm$html$Html$Attributes$class('rounded-xl shadow-xl')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$source,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src('./showcase.mp4'),
												$elm$html$Html$Attributes$type_('video/mp4')
											]),
										_List_Nil)
									])),
								$author$project$Responsive$isMobile(viewport) ? A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('p-4')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('This is currently not optimized for mobile use, open this page on your computer if you find the video above interesting.')
									])) : $elm$html$Html$text('')
							]))
					]))
			]));
};
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Landing$viewNav = function (h) {
	return A2(
		$elm$html$Html$nav,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fixed w-full z-30 top-0 text-white'),
				$elm$html$Html$Attributes$id('header')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pl-4 flex items-center')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Stories of Worth')
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('block lg:hidden pr-4')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex items-center p-1 text-pink-800 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out'),
										$elm$html$Html$Attributes$id('nav-toggle')
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20'),
								$elm$html$Html$Attributes$id('nav-content')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('list-reset lg:flex justify-end flex-1 items-center')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out'),
										$elm$html$Html$Attributes$id('navAction'),
										$elm$html$Html$Events$onClick(h.bF)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Go to App (no signup required)')
									]))
							]))
					])),
				A2(
				$elm$html$Html$hr,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('border-b border-gray-100 opacity-25 my-0 py-0')
					]),
				_List_Nil),
				$elm$html$Html$text('    ')
			]));
};
var $elm$html$Html$li = _VirtualDom_node('li');
var $author$project$Landing$viewPricing = A2(
	$elm$html$Html$section,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('bg-gray-100 py-8')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container mx-auto px-2 pt-4 pb-12 text-gray-800')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Pricing')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-full mb-4')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-full my-2 leading-tight text-center text-gray-800')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Everything is free during the beta')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('p-8 text-3xl font-bold text-center border-b-4')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Free              ')
												])),
											A2(
											$elm$html$Html$ul,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-full text-center text-sm')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$li,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border-b py-4')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Multiple budgets')
														])),
													A2(
													$elm$html$Html$li,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border-b py-4')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Cloning budgets')
														])),
													A2(
													$elm$html$Html$li,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border-b py-4')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Scenario planning with multiple timelines with chapters')
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 shadow-lg z-10')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-full p-8 text-3xl font-bold text-center')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Basic')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('h-1 w-full gradient my-0 py-0 rounded-t')
												]),
											_List_Nil),
											A2(
											$elm$html$Html$ul,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-full text-center text-base font-bold')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$li,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border-b py-4')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Saving your progress')
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-full pt-6 text-4xl font-bold text-center')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('$TBA'),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-base')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('/ per month')
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('p-8 text-3xl font-bold text-center border-b-4')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Pro              ')
												])),
											A2(
											$elm$html$Html$ul,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-full text-center text-sm')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$li,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border-b py-4')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Multiple Investment Strategies')
														])),
													A2(
													$elm$html$Html$li,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border-b py-4')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Mortgage Modelling')
														])),
													A2(
													$elm$html$Html$li,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border-b py-4')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Schedules')
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-full pt-6 text-3xl text-gray-600 font-bold text-center')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('$TBA'),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('text-base')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('/ per month')
														]))
												]))
										]))
								]))
						]))
				]))
		]));
var $elm$svg$Svg$Attributes$fillRule = _VirtualDom_attribute('fill-rule');
var $elm$svg$Svg$Attributes$opacity = _VirtualDom_attribute('opacity');
var $elm$svg$Svg$Attributes$version = _VirtualDom_attribute('version');
var $author$project$Landing$viewWaveDown = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('relative -mt-12 lg:-mt-24')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$svg,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$version('1.1'),
					$elm$svg$Svg$Attributes$viewBox('0 0 1428 174')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$fill('none'),
							$elm$svg$Svg$Attributes$fillRule('evenodd'),
							$elm$svg$Svg$Attributes$stroke('none'),
							$elm$svg$Svg$Attributes$strokeWidth('1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$svg$Svg$g,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$fill('#FFFFFF'),
									$elm$svg$Svg$Attributes$fillRule('nonzero'),
									$elm$svg$Svg$Attributes$transform('translate(-2.000000, 44.000000)')
								]),
							_List_fromArray(
								[
									A2(
									$elm$svg$Svg$path,
									_List_fromArray(
										[
											$elm$svg$Svg$Attributes$d('M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496'),
											$elm$svg$Svg$Attributes$opacity('0.100000001')
										]),
									_List_Nil),
									A2(
									$elm$svg$Svg$path,
									_List_fromArray(
										[
											$elm$svg$Svg$Attributes$d('M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z'),
											$elm$svg$Svg$Attributes$opacity('0.100000001')
										]),
									_List_Nil),
									A2(
									$elm$svg$Svg$path,
									_List_fromArray(
										[
											$elm$svg$Svg$Attributes$d('M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z'),
											$elm$svg$Svg$Attributes$id('Path-4'),
											$elm$svg$Svg$Attributes$opacity('0.200000003')
										]),
									_List_Nil)
								])),
							A2(
							$elm$svg$Svg$g,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$fill('#FFFFFF'),
									$elm$svg$Svg$Attributes$fillRule('nonzero'),
									$elm$svg$Svg$Attributes$transform('translate(-4.000000, 76.000000)')
								]),
							_List_fromArray(
								[
									A2(
									$elm$svg$Svg$path,
									_List_fromArray(
										[
											$elm$svg$Svg$Attributes$d('M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z')
										]),
									_List_Nil)
								]))
						]))
				]))
		]));
var $author$project$Landing$viewWaveTop = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$class('wave-top'),
			$elm$svg$Svg$Attributes$version('1.1'),
			$elm$svg$Svg$Attributes$viewBox('0 0 1439 147')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$fill('none'),
					$elm$svg$Svg$Attributes$fillRule('evenodd'),
					$elm$svg$Svg$Attributes$stroke('none'),
					$elm$svg$Svg$Attributes$strokeWidth('1')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$fillRule('nonzero'),
							$elm$svg$Svg$Attributes$transform('translate(-1.000000, -14.000000)')
						]),
					_List_fromArray(
						[
							A2(
							$elm$svg$Svg$g,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$class('wave'),
									$elm$svg$Svg$Attributes$fill('#f8fafc')
								]),
							_List_fromArray(
								[
									A2(
									$elm$svg$Svg$path,
									_List_fromArray(
										[
											$elm$svg$Svg$Attributes$d('M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z')
										]),
									_List_Nil)
								])),
							A2(
							$elm$svg$Svg$g,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$fill('#FFFFFF'),
									$elm$svg$Svg$Attributes$transform('translate(1.000000, 15.000000)')
								]),
							_List_fromArray(
								[
									A2(
									$elm$svg$Svg$g,
									_List_fromArray(
										[
											$elm$svg$Svg$Attributes$transform('translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ')
										]),
									_List_fromArray(
										[
											A2(
											$elm$svg$Svg$path,
											_List_fromArray(
												[
													$elm$svg$Svg$Attributes$d('M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496'),
													$elm$svg$Svg$Attributes$opacity('0.100000001')
												]),
											_List_Nil),
											A2(
											$elm$svg$Svg$path,
											_List_fromArray(
												[
													$elm$svg$Svg$Attributes$d('M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z'),
													$elm$svg$Svg$Attributes$opacity('0.100000001')
												]),
											_List_Nil),
											A2(
											$elm$svg$Svg$path,
											_List_fromArray(
												[
													$elm$svg$Svg$Attributes$d('M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z'),
													$elm$svg$Svg$Attributes$opacity('0.200000003')
												]),
											_List_Nil)
										]))
								]))
						]))
				]))
		]));
var $author$project$Landing$view = F3(
	function (h, viewport, model) {
		var hideOnMobile = function (v) {
			return $author$project$Responsive$isMobile(viewport) ? $elm$html$Html$text('') : v;
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('leading-normal tracking-normal text-white gradient'),
					A2($elm$html$Html$Attributes$style, 'background-image', 'url(./topGrayBlurCircleDark.jpg)')
				]),
			_List_fromArray(
				[
					$author$project$Landing$viewNav(h),
					$author$project$Landing$viewHero(viewport),
					hideOnMobile($author$project$Landing$viewWaveDown),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('overview')
						]),
					_List_Nil),
					hideOnMobile(
					A2(
						$elm$html$Html$map,
						h.f2,
						A2($author$project$Landing$viewFeatures, viewport, model))),
					hideOnMobile($author$project$Landing$viewWaveTop),
					$author$project$Landing$viewCards,
					$author$project$Landing$viewWaveDown,
					hideOnMobile($author$project$Landing$viewPricing),
					hideOnMobile($author$project$Landing$viewWaveTop),
					hideOnMobile($author$project$Landing$viewWaveDown),
					hideOnMobile(
					A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'height', '30vh'),
								A2($elm$html$Html$Attributes$style, 'background-color', '#f8fafc')
							]),
						_List_Nil)),
					hideOnMobile($author$project$Landing$viewWaveTop),
					hideOnMobile(
					$author$project$Landing$viewCTA(h)),
					hideOnMobile($author$project$Landing$viewWaveDown),
					hideOnMobile(
					A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'height', '30vh'),
								A2($elm$html$Html$Attributes$style, 'background-color', '#f8fafc')
							]),
						_List_Nil)),
					hideOnMobile($author$project$Landing$viewWaveTop)
				]));
	});
var $author$project$Main$view = function (model) {
	var _v0 = model.I;
	if (!_v0.$) {
		var _v1 = _v0.a;
		var landing = _v1.a;
		var authState = _v1.b;
		return A3(
			$author$project$Landing$view,
			{
				bF: A2(
					$author$project$Main$GotoAppRequest,
					$author$project$Landing$modelToBoardBlob(landing),
					authState),
				f2: A2($elm$core$Basics$composeR, $author$project$Main$PageMsgLanding, $author$project$Main$GotPageMsg)
			},
			model.br,
			landing);
	} else {
		var app = _v0.a;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$UI$pageBackButton,
					A2(
						$author$project$Main$GotoLandingPage,
						$author$project$App$modelToBoardBlob(app),
						app.F),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Home')
								]))
						])),
					A2(
					$elm$html$Html$map,
					A2($elm$core$Basics$composeR, $author$project$Main$PageMsgApp, $author$project$Main$GotPageMsg),
					A2($author$project$App$view, model.br, app))
				]));
	}
};
var $author$project$Main$main = $elm$browser$Browser$element(
	{e9: $author$project$Main$init, fV: $author$project$Main$subscriptions, f8: $author$project$Main$update, f9: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));